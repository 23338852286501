<subs-form-layout>
  <subs-form-content *ngIf="!userRole">
    <div class="card">
      <div class="card-header">
        Utility Item Details - {{ utilityTableDisplayName }}
      </div>

      <div class="card-block" [formGroup]="form">
        <div class="clr-row">
          <div class="clr-col-6">
            <div class="clr-col-12">
              <subs-input
                class="clr-col-12"
                controlName="key"
                errorMessage="A item key required"
                label="Item Key"
                labelLength="7"
              >
              </subs-input>
            </div>
            <div class="clr-col-12">
              <subs-input
                class="clr-col-12"
                controlName="value"
                errorMessage="A item key required"
                label="Item Value"
                labelLength="7"
                [helperText]="valueHelperText"
              >
              </subs-input>
            </div>
          </div>
          <div class="clr-col-6"></div>
        </div>
      </div>
    </div>
  </subs-form-content>

  <subs-form-content *ngIf="userRole">
    <div class="clr-row">
      <div class="clr-col-6">
        <div class="card">
          <div class="card-header">
            Utility Item Details - {{ utilityTableDisplayName }}
          </div>

          <div class="card-block" [formGroup]="form">
            <div class="clr-col-12">
              <div class="clr-col-12">
                <subs-input
                  class="clr-col-12"
                  controlName="key"
                  errorMessage="A item key required"
                  label="Item Key"
                  labelLength="7"
                >
                </subs-input>
              </div>
              <div class="clr-col-12">
                <subs-textarea
                  class="clr-col-12"
                  controlName="value"
                  errorMessage="A item key required"
                  label="Item Value"
                  labelLength="7"
                  disabled="disabled"
                >
                </subs-textarea>
              </div>
            </div>
            <div class="clr-col-12"></div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          userRolePermissions && userRolePermissions.lookupItems.length > 0
        "
        class="clr-col-6"
      >
        <div class="card">
          <div class="card-header">
            User Role Permissions
          </div>
          <div class="card-block">
            <clr-datagrid data-test-id="permissions-grid">
              <clr-dg-column
                style="
                min-width:40px;
                width:40px;
              "
              >
                <ng-container> </ng-container>
              </clr-dg-column>

              <clr-dg-column style="width:250px;">
                <ng-container>
                  Key
                </ng-container>
              </clr-dg-column>

              <clr-dg-column>
                <ng-container>
                  Value
                </ng-container>
              </clr-dg-column>

              <clr-dg-row
                *ngFor="
                  let permissions of selectPermissions.controls;
                  let i = index
                "
                [formGroup]="permissions"
              >
                <clr-dg-cell
                  style="
                  min-width:40px;
                  padding-top: 0;
                  padding-bottom: 0;
                  display: flex;
                  align-items: center;
                "
                >
                  <input
                    type="checkbox"
                    clrCheckbox
                    name="selected"
                    formControlName="selected"
                    data-test-id="selected"
                    [value]="true"
                  />
                </clr-dg-cell>

                <clr-dg-cell>
                  {{ permissions.controls.permissionKey.value }}
                </clr-dg-cell>

                <clr-dg-cell>
                  {{ permissions.controls.permissionValue.value }}
                </clr-dg-cell>
              </clr-dg-row>

              <clr-dg-footer
                >{{ selectPermissions.length }} permissions</clr-dg-footer
              >
            </clr-datagrid>
          </div>
        </div>
      </div>
    </div>
  </subs-form-content>

  <subs-form-buttons>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="save(form.controls.id.value)"
      data-test-id="submit-utility-item"
    >
      Save
    </button>
    <button class="btn btn-outline" (click)="redirectToSearchPage()">
      Return to Search
    </button>
    <button
      *ngIf="canDelete"
      class="btn btn-outline"
      (click)="deleteUtilityItem()"
      data-test-id="delete-utility-item"
    >
      Delete
    </button>

    <button
      class="btn btn-outline"
      (click)="cancelEdit()"
      data-test-id="cancel-edit-utility-item"
    >
      Cancel
    </button>
  </subs-form-buttons>
</subs-form-layout>

<clr-modal
  [(clrModalOpen)]="showUnsavedChangesModal"
  [clrModalClosable]="false"
>
  <h3 class="modal-title">
    You have unsaved changes.
  </h3>
  <div class="modal-body">
    <subs-alert [alertBehavior]="alertSubject$"></subs-alert>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="confirmSave()"
      data-test-id="confirm-save-before-navigate"
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="discardChanges()"
    >
      Discard
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="cancelNavigation()"
    >
      Cancel
    </button>
  </div>
</clr-modal>

<clr-modal
  [(clrModalOpen)]="showDeleteModal"
  data-test-id="utility-item-delete-modal"
>
  <h3 class="modal-title">
    Confirm Delete?
  </h3>

  <div class="modal-body">
    <p>
      Are you sure you wish to delete this utility item?
    </p>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showDeleteModal = false"
    >
      Cancel
    </button>

    <button
      type="button"
      class="btn btn-primary"
      (click)="delete()"
      data-test-id="confirm-delete-project-year"
    >
      Delete
    </button>
  </div>
</clr-modal>
