<div class="form-container">
  <form
    clrForm
    [formGroup]="contactForm"
    style="
      flex-grow: 1;
      overflow-y: auto;      
      padding-right: 24px;
    "
  >
    <div class="card">
      <div class="card-header">Sub Recipient Entry for {{ projectTitle }}</div>
    </div>
    <subs-checklist [checklist]="checklist"> </subs-checklist>

    <subs-departmental-admin
      [form]="contactForm.controls.departmentalAdmin"
      data-test-id="departamentalAdmin"
    >
    </subs-departmental-admin>

    <subs-principal-investigator
      [form]="contactForm.controls.principalInvestigator"
      data-test-id="principalInvestigator"
    >
    </subs-principal-investigator>

    <div class="card">
      <div class="card-header">
        Central Office of Collaborating Institution
      </div>
      <div class="card-block">
        <div class="clr-row">
          <subs-input
            class="clr-col"
            label="Email Address"
            controlName="centralOfficeEmail"
          ></subs-input>
        </div>
      </div>
    </div>

    <subs-generic-contact
      [form]="contactForm.controls.signatory"
      data-test-id="signatory"
      title="Authorized Institutional Official (Signatory) of Collaborating Institution"
    >
    </subs-generic-contact>
    <subs-generic-contact
      [form]="contactForm.controls.institutionalAdmin"
      data-test-id="institutionalAdmin"
      title="Institutional Administrative Point of Contact of Collaborating Institution"
    >
    </subs-generic-contact>
    <subs-generic-contact
      [form]="contactForm.controls.institutionalFinancial"
      data-test-id="institutionalFinancial"
      title="Institutional Financial Contact of Collaborating Institution"
    >
    </subs-generic-contact>

    <subs-washu-contact
      [form]="contactForm.controls.washUPrincipalInvestigator"
      data-test-id="washUPrincipalInvestigator"
      title="Washington University Principal Investigator"
      [isPrincipalInvestigator]="true"
    >
    </subs-washu-contact>

    <subs-washu-contact
      [form]="contactForm.controls.washUDepartmentAdmin"
      data-test-id="washUAdmin"
      title="Washington University Department Administrator"
      [isPrincipalInvestigator]="false"
    >
    </subs-washu-contact>
  </form>
</div>
