<div class="card">
  <div class="card-header">
    Collaborator Awarded Budget and Performance Period
  </div>
  <div class="card-block" [formGroup]="form">
    <subs-file-upload
      id="fAndA-upload"
      label="F&A Rate Agreement"
      fileNameControlName="fAndARateAgreementFileName"
      fileIdControlName="fAndARateAgreementFileId"
      [acceptedMimeTypes]="['application/pdf']"
      acceptedMimeTypesErrorMessage="The F&A Rate Agreement must be a PDF document."
    ></subs-file-upload>

    <div class="clr-row">
      <clr-checkbox-container class="clr-col">
        <label class="clr-col-12 clr-col-md-auto">Same as Grant Period</label>
        <clr-checkbox-wrapper>
          <input
            class="clr-col-12 clr-col-md"
            type="checkbox"
            clrCheckbox
            name="sameAsGrantPeriod"
            value="true"
            formControlName="sameAsGrantPeriod"
            (click)="copyGrantPeriod($event.target.checked)"
          />
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
      <subs-input
        class="clr-col"
        label="* Estimated Total Funding"
        controlName="estimatedTotalFunding"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-date
        class="clr-col"
        label="* Current Budget From Date"
        controlName="budgetFromDate"
      >
      </subs-date>
      <subs-date
        class="clr-col"
        label="* Estimated Project Period From Date"
        controlName="estimatedProjectPeriodFromDate"
      >
      </subs-date>
    </div>

    <div class="clr-row">
      <subs-date
        class="clr-col"
        label="* Current Budget To Date"
        controlName="budgetThruDate"
      >
      </subs-date>
      <subs-date
        class="clr-col"
        label="* Estimated Project Period To Date"
        controlName="estimatedProjectPeriodToDate"
      >
      </subs-date>
    </div>

    <div class="clr-row">
      <subs-currency-input
        class="clr-col"
        label="* Budget Direct Costs"
        controlName="directCost"
        errorMessage="Maximum 8 digit whole number and 2 decimals. Example format xxx,xxx.xx"
      ></subs-currency-input>
      <subs-currency-input
        class="clr-col"
        label="* Budget Indirect Costs"
        controlName="indirectCost"
        errorMessage="Maximum 8 digit whole number and 2 decimals. Example format xxx,xxx.xx"
      ></subs-currency-input>
      <subs-currency-static-text
        class="clr-col"
        label="Total Costs This Action"
        controlName="totalCosts"
      ></subs-currency-static-text>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col"
        label="* F&A Rate (%)"
        controlName="fAndARate"
        errorMessage="Maximum 3 digit whole number and 2 decimals. Example format xxx.xx"
      ></subs-input>
      <subs-currency-input
        class="clr-col"
        label="* Cost Sharing Amount"
        controlName="costSharingAmount"
        errorMessage="Maximum 6 digit whole number and 2 decimals. Example format xxx,xxx.xx"
      ></subs-currency-input>
      <subs-select
        class="clr-col"
        controlName="indirectCostBaseMethod"
        label="* Indirect Cost Base Method"
      >
        <option value=""></option>
        <option value="NA">NA</option>
        <option value="MTDC">MTDC</option>
        <option value="TDC">TDC</option>
        <option value="OTHR">OTHER</option>
      </subs-select>
    </div>
    <div *ngIf="showDescription" class="clr-row">
      <subs-textarea
        class="clr-col"
        labelLength="8"
        label="Indirect Cost Base Method Description"
        controlName="indirectCostBaseMethodDesc"
        placeholder="* Please enter a description of other method."
        maxLength="4000"
      >
        errorMessage="Please enter a description of other method."
      </subs-textarea>
    </div>
  </div>
</div>
