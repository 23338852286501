import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'subs-primary-place-of-performance',
  templateUrl: './primary-place-of-performance.component.html',
})
export class PrimaryPlaceOfPerformanceComponent {
  @Input() form: UntypedFormGroup;
  @Output() sameAsSignatoryClicked: EventEmitter<void> = new EventEmitter<
    void
  >();

  async copySignatory() {
    this.sameAsSignatoryClicked.emit();
  }
}
