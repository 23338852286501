import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClrWizard } from '@clr/angular';
import { Institution, InstitutionsClient } from 'src/app/api.service';

@Component({
  selector: 'subs-institution-conversion',
  templateUrl: './institution-conversion.component.html',
})
export class InstitutionConversionComponent implements OnInit {
  @ViewChild('wizard') wizard: ClrWizard;
  formData: UntypedFormGroup;
  @Input() form: UntypedFormGroup;
  @Output() wizardFinished = new EventEmitter<Institution>();
  @Output() wizardClosed = new EventEmitter<boolean>();
  @Input() isOpen: boolean;

  institution: Institution;

  loadingFlag = false;
  errorFlag = false;

  constructor(
    private fb: UntypedFormBuilder,
    private institutionClient: InstitutionsClient,
  ) {}

  doCancel(): void {
    this.isOpen = false;
    this.wizardClosed.emit(true);
    this.wizard.reset();
    this.wizard.close();
  }

  onCommit(): void {
    const value: any = this.formData.get('dunsNumber').value;
    this.loadingFlag = true;
    this.errorFlag = false;

    this.institutionClient
      .search(
        value,
        undefined,
        'C',
        undefined,
        undefined,
        undefined,
        undefined,
        'Yes',
        undefined,
        undefined,
        true,
      )
      .subscribe(result => {
        if (result[0]) {
          this.setValueFormData(result[0]);
          this.institution = result[0];
          this.institution.id = this.form.get('id').value;
          this.institution.status = this.form.get('status').value;
          this.wizard.forceNext();
        } else {
          this.errorFlag = true;
        }
        this.loadingFlag = false;
      });
  }

  doFinish(): void {
    this.isOpen = false;
    this.wizardClosed.emit(true);
    this.wizardFinished.emit(this.institution);
    this.wizard.reset();
    this.setUpFormData();
    this.wizard.forceFinish();
  }

  ngOnInit(): void {
    this.setUpFormData();
  }

  private setUpFormData() {
    this.formData = this.fb.group({
      id: [],
      institutionName: [],
      status: ['IP'],
      primaryInstitution: '',
      dunsNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    });
  }

  private setValueFormData(institution: Institution) {
    this.formData.setValue({
      id: institution.id,
      institutionName: institution.institutionName,
      status: institution.status === 'C' ? 'Complete' : 'In Progress',
      primaryInstitution: institution.primaryInstitution,
      dunsNumber: this.formData.get('dunsNumber').value,
    });
  }
}
