import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ClrLoadingState } from '@clr/angular';
import { BehaviorSubject } from 'rxjs';
import { AlertOptions } from 'src/app/alerts/alert-options';
import { AuthService } from 'src/app/auth.service';

import { SubrecipientComment } from '../../api.service';

@Component({
  selector: 'subs-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
  comments = new BehaviorSubject<Array<SubrecipientComment>>([]);
  subrecipientId: any;
  formLevelAlerts$ = new BehaviorSubject<AlertOptions>(null);
  submitAlerts$ = new BehaviorSubject<AlertOptions>(null);
  submitState$ = new BehaviorSubject(ClrLoadingState.DEFAULT);

  commentsFormControl: UntypedFormControl;

  constructor(private fb: UntypedFormBuilder, private authService: AuthService) {}

  newComment = this.fb.group({
    comment: ['', [Validators.required]],
  });

  @Input()
  set form(val: UntypedFormControl) {
    this.commentsFormControl = val;
    this.comments.next(this.commentsFormControl.value);
    this.commentsFormControl.valueChanges.subscribe(this.comments);
  }
  @Input() checklist: UntypedFormGroup;
  @Input() projectTitle: string;

  save() {
    if (this.newComment.invalid) {
      this.submitAlerts$.next({
        type: 'danger',
        message: 'Please fix all field errors before saving the form.',
      });
      return;
    }

    this.submitState$.next(ClrLoadingState.LOADING);

    const commentsViewModel: SubrecipientComment = {
      ...this.newComment.value,
      created: new Date(),
      createdBy: this.authService.currentUser.name,
      subrecipientId: this.subrecipientId,
      returnCode: null,
    };

    if (!this.commentsFormControl.value) {
      this.commentsFormControl.setValue([commentsViewModel]);
    } else {
      let newValue = this.commentsFormControl.value;
      newValue.unshift(commentsViewModel);
      this.commentsFormControl.setValue(newValue);
    }

    this.newComment.reset();

    this.submitState$.next(ClrLoadingState.DEFAULT);
  }
}
