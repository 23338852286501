import { Component, OnInit } from '@angular/core';
import { delay, map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { MainLayoutService } from './main-layout.service';
import { UtilityTableKeyEnum } from '../api.service';
import { AppUtilitySearchService } from '../utilities/app-utility-search/app-utility-search.service';
import { FeatureService } from '../utilities/feature.service';
import { LookupItemsService } from '../utilities/lookup-items.service';
import { SubawardsService } from '../subawards/subawards.service';
import { SubStatusService } from '../utilities/subrecipient-status/sub-status.service';

@Component({
  selector: 'subs-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent implements OnInit {
  userName = this.authService.user.pipe(map(user => user && user.name));
  userRole = this.authService.currentUser.role;
  projectAsideVisible = false;
  addUtilityItemVisible = false;
  editUtilityItemVisible = false;
  editInstitutionAsideVisible = false;
  addInstitutionAsideVisible = false;
  addSubawardAsideVisible = false;
  subawardAsideVisible = false;
  addCurrentYearAsideVisible = false;
  addInstitutionActive = false;
  editInstitutionActive = false;
  projectId: number;
  institutionId = -1;
  utilityItemId = -1;
  subawardId: number;

  constructor(
    private authService: AuthService,
    private mainLayoutService: MainLayoutService,
    private utilitySearchService: AppUtilitySearchService,
    private featureService: FeatureService,
    public lookupItemService: LookupItemsService,
    private subawardsService: SubawardsService,
    private subStatusService: SubStatusService,
  ) {}

  ngOnInit(): void {
    this.subawardsService.setOsrsGaList();
    this.utilitySearchService.selectAppUtilityTables();
    this.subStatusService.loadSubStatuses();
    for (const value of Object.keys(UtilityTableKeyEnum)) {
      if (value === UtilityTableKeyEnum.AppFeatureLookup) {
        this.featureService.setFeatures(UtilityTableKeyEnum.AppFeatureLookup);
      } else {
        this.lookupItemService.loadLookupItems(value);
      }
    }
    this.mainLayoutService.addUtilityItemVisibleChange
      .pipe(delay(0))
      .subscribe(value => {
        this.addUtilityItemVisible = value;
      });
    this.mainLayoutService.editUtilityItemVisibleChange
      .pipe(delay(0))
      .subscribe(value => {
        this.editUtilityItemVisible = value;
      });
    this.mainLayoutService.projectSidebarVisibilityChange
      .pipe(delay(0))
      .subscribe(value => {
        this.projectAsideVisible = value;
      });
    this.mainLayoutService.editInstitutionSidebarVisibilityChange
      .pipe(delay(0))
      .subscribe(value => {
        this.editInstitutionAsideVisible = value;
      });
    this.mainLayoutService.addInstitutionSidebarVisibilityChange
      .pipe(delay(0))
      .subscribe(value => {
        this.addInstitutionAsideVisible = !this.authService.hasPermission(
          'OsrsAuthorityGlobalAccess',
        )
          ? value
          : true;
      });
    this.mainLayoutService.addInstitutionActiveChange
      .pipe(delay(0))
      .subscribe(value => {
        this.addInstitutionActive = value;
      });
    this.mainLayoutService.editInstitutionActiveChange
      .pipe(delay(0))
      .subscribe(value => {
        this.editInstitutionActive = value;
      });
    this.mainLayoutService.addSubawardSidebarVisibilityChange
      .pipe(delay(0))
      .subscribe(value => {
        this.addSubawardAsideVisible = value;
      });
    this.mainLayoutService.addCurrentYearAsideVisibleChange
      .pipe(delay(0))
      .subscribe(value => {
        this.addCurrentYearAsideVisible = value;
      });
    this.mainLayoutService.subawardSidebarVisibilityChange
      .pipe(delay(0))
      .subscribe(value => {
        this.subawardAsideVisible = value;
      });
    this.mainLayoutService.projectIdChange.subscribe(value => {
      this.projectId = value;
    });
    this.mainLayoutService.subawardIdChange.subscribe(value => {
      this.subawardId = value;
    });
    this.mainLayoutService.institutionIdChange.subscribe(value => {
      this.institutionId = value;
    });
    this.mainLayoutService.utilityItemIdChange.subscribe(value => {
      this.utilityItemId = value;
    });
  }

  public canAccessUtilities() {
    return this.authService.hasPermission('AccessUtilitiesMenuTab');
  }

  public canAccessCreateInstitution() {
    return this.authService.hasPermission('AccessInstitutionAddPage');
  }

  public canAccessEditInstitution() {
    return this.authService.hasPermission('AccessInstitutionEditPage');
  }

  public canAccessCreateUtilityItem() {
    return this.authService.hasPermission('AccessUtilityItemAddPage');
  }

  public canAccessEditUtilityItem() {
    return this.authService.hasPermission('AccessUtilityItemEditPage');
  }

  async logout() {
    await this.authService.logout();
  }
}
