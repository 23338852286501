import { Component, Input } from '@angular/core';

@Component({
  selector: 'subs-subaward-status-select',
  template: `
    <subs-select
      [controlName]="controlName"
      [labelLength]="labelLength"
      [disabled]="disabled"
    >
      <option *ngIf="includeEmptyStatus" value="" selected>&nbsp; </option>
      <ng-container *ngFor="let status of statuses">
        <option
          [disabled]="setDisabled(status)"
          *ngIf="setDisplay(status)"
          [value]="status.statusCode"
          >{{ status.statusDescription }}</option
        >
      </ng-container>
    </subs-select>
  `,
})
export class SubawardStatusSelectComponent {
  @Input() controlName: string;
  @Input() labelLength: number;
  @Input() includeEmptyStatus: boolean;
  @Input() displayOSRSStatus = true;
  @Input() displayDAStatus = true;
  @Input() disabled?: boolean;
  @Input() daDisabled = false;
  @Input() osrsDisabled = false;
  @Input() psoDisabled = false;
  @Input() statuses: {}[] = [];

  setDisabled(status: any) {
    if (status.statusCode === 'PSO') {
      return this.psoDisabled;
    } else {
      return status.statusType === 'OSRS Status'
        ? this.osrsDisabled
        : this.daDisabled;
    }
  }

  setDisplay(status: any) {
    return status.statusType === 'OSRS Status'
      ? this.displayOSRSStatus
      : this.displayDAStatus;
  }
}
