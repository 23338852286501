import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import {
  OutgoingActivityClient,
  OutgoingActivityReportModel,
} from 'src/app/api.service';
import { ExcelExportService } from '../excel-export.service';

@Component({
  selector: 'subs-outgoing-activity-report',
  templateUrl: './outgoing-activity-report.component.html',
  providers: [OutgoingActivityClient, FeedbackService],
})
export class OutgoingActivityReportComponent implements OnInit {
  searchForm = this.fb.group({
    fyStart: ['', [Validators.required]],
    fyEnd: ['', [Validators.required]],
  });

  years: number[] = [];
  alertSubject = this.feedbackService.alerts;
  submitState = this.feedbackService.submitState;
  userHasSearched = false;

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private outgoingActivityClient: OutgoingActivityClient,
    private excelExportService: ExcelExportService,
  ) {}

  ngOnInit() {
    const currentDate = new Date();
    let y = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const julyMonthNumber = 6; // javascript months start at the 0 index
    // Fiscal year starts in July. So if we are running the report in July 2021 it should be ran for fiscal year 2022
    if (month >= julyMonthNumber) {
      y++;
    }
    for (let i = 0; i + 2013 <= y; i++) {
      this.years.push(i + 2013);
    }
  }

  search() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.outgoingActivityClient
        .getReport(
          this.searchForm.controls.fyStart.value,
          this.searchForm.controls.fyEnd.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async (val) => await this.generateReport(val));
    }
  }

  async generateReport(results: Array<OutgoingActivityReportModel>) {
    if (results.length === 0) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }
    const columns = [
      {
        header: '',
        width: 33,
      },
      {
        header: 'Jul',
        width: 11,
      },
      {
        header: 'Aug',
        width: 11,
      },
      {
        header: 'Sept',
        width: 11,
      },
      {
        header: 'Oct',
        width: 11,
      },
      {
        header: 'Nov',
        width: 11,
      },
      {
        header: 'Dec',
        width: 11,
      },
      {
        header: 'Jan',
        width: 11,
      },
      {
        header: 'Feb',
        width: 11,
      },
      {
        header: 'Mar',
        width: 11,
      },
      {
        header: 'Apr',
        width: 11,
      },
      {
        header: 'May',
        width: 11,
      },
      {
        header: 'Jun',
        width: 11,
      },
      {
        header: 'Total',
        width: 11,
      },
    ];

    const data = this.generateReportData(results);

    const priorFyStart = (Number(this.searchForm.controls.fyStart.value) - 1)
      .toString()
      .slice(-2);
    const priorFyEnd = (Number(this.searchForm.controls.fyEnd.value) - 1)
      .toString()
      .slice(-2);

    const headers = [
      {
        title:
          'WIP SUMMARY BY MONTH - OUTGOING SUBS FISCAL YEAR ' +
          this.searchForm.controls.fyStart.value.slice(-2),
        columns: [
          {
            value: '',
          },
          {
            value: '07/' + priorFyStart,
          },
          {
            value: '08/' + priorFyStart,
          },
          {
            value: '09/' + priorFyStart,
          },
          {
            value: '10/' + priorFyStart,
          },
          {
            value: '11/' + priorFyStart,
          },
          {
            value: '12/' + priorFyStart,
          },
          {
            value: '01/' + this.searchForm.controls.fyStart.value.slice(-2),
          },
          {
            value: '02/' + this.searchForm.controls.fyStart.value.slice(-2),
          },
          {
            value: '03/' + this.searchForm.controls.fyStart.value.slice(-2),
          },
          {
            value: '04/' + this.searchForm.controls.fyStart.value.slice(-2),
          },
          {
            value: '05/' + this.searchForm.controls.fyStart.value.slice(-2),
          },
          {
            value: '06/' + this.searchForm.controls.fyStart.value.slice(-2),
          },
          {
            value: '',
          },
        ],
      },
      {
        title:
          'WIP SUMMARY BY MONTH - OUTGOING SUBS FISCAL YEAR ' +
          this.searchForm.controls.fyEnd.value.slice(-2),
        columns: [
          {
            value: '',
          },
          {
            value: '07/' + priorFyEnd,
          },
          {
            value: '08/' + priorFyEnd,
          },
          {
            value: '09/' + priorFyEnd,
          },
          {
            value: '10/' + priorFyEnd,
          },
          {
            value: '11/' + priorFyEnd,
          },
          {
            value: '12/' + priorFyEnd,
          },
          {
            value: '01/' + this.searchForm.controls.fyEnd.value.slice(-2),
          },
          {
            value: '02/' + this.searchForm.controls.fyEnd.value.slice(-2),
          },
          {
            value: '03/' + this.searchForm.controls.fyEnd.value.slice(-2),
          },
          {
            value: '04/' + this.searchForm.controls.fyEnd.value.slice(-2),
          },
          {
            value: '05/' + this.searchForm.controls.fyEnd.value.slice(-2),
          },
          {
            value: '06/' + this.searchForm.controls.fyEnd.value.slice(-2),
          },
          {
            value: 'YTD',
          },
        ],
      },
      columns,
    ];

    await this.excelExportService.generateExcel(
      'Outgoing SUB Activity Report',
      'Outgoing SUBS Activity-Fiscal Year Summary',
      data,
      columns,
      {
        value:
          "Activity by month of Sub's submission through : " +
          new Date().toLocaleDateString('en-US'),
        centered: true,
      },
      this.addHeadersAndStyles,
      headers,
    );
  }

  private addHeadersAndStyles(worksheet, headers) {
    worksheet.spliceRows(10, 0, [headers[0].title]);
    worksheet.mergeCells(10, 1, 10, headers[0].columns.length);
    worksheet.getCell('A10').alignment = { horizontal: 'center' };
    var gridHeader = [];
    headers[0].columns.forEach((d) => {
      gridHeader.push(d.value);
    });
    worksheet.spliceRows(11, 0, gridHeader);
    worksheet.spliceRows(18, 0, '');
    worksheet.spliceRows(22, 0, '');
    worksheet.spliceRows(24, 0, '', '');

    worksheet.spliceRows(26, 0, [headers[1].title]);
    worksheet.mergeCells(26, 1, 26, headers[1].columns.length);
    worksheet.getCell('A26').alignment = { horizontal: 'center' };
    gridHeader = [];
    headers[1].columns.forEach((d) => {
      gridHeader.push(d.value);
    });
    worksheet.spliceRows(27, 0, gridHeader);
    gridHeader = [];
    headers[2].forEach((d) => {
      gridHeader.push(d.header);
    });
    worksheet.spliceRows(28, 0, gridHeader);
    worksheet.spliceRows(34, 0, '');
    worksheet.spliceRows(38, 0, '');

    var percentageRange = [
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
    ];
    percentageRange.forEach((column) => {
      var cell = worksheet.getCell(column + '23');
      cell.value = cell.value + '%';
      cell = worksheet.getCell(column + '39');
      cell.value = cell.value + '%';
      cell = worksheet.getCell(column + '40');
      cell.value = cell.value + '%';
      cell = worksheet.getCell(column + '41');
      cell.value = cell.value + '%';
    });

    worksheet.getRow(28).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFE9F3FC',
        },
        bgColor: {
          argb: 'FFFFFFFF',
        },
      };
      cell.font = {
        color: {
          argb: '00000000',
        },
        bold: true,
      };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
    });
  }

  private generateReportData(results: Array<OutgoingActivityReportModel>) {
    const data = [];
    results.forEach((e) => {
      data.push([
        e.category,
        e.july,
        e.august,
        e.september,
        e.october,
        e.november,
        e.december,
        e.january,
        e.february,
        e.march,
        e.april,
        e.may,
        e.june,
        e.total,
      ]);
    });
    return data;
  }
}
