import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, empty, forkJoin, from, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  first,
  map,
  mergeMap,
  tap,
  toArray,
} from 'rxjs/operators';
import {
  Attachment,
  AttachmentsClient,
  File,
  FilesClient,
  GetProject as Project,
  PopulateAttachmentResult,
  ProjectsClient,
  Subaward,
  SubawardsClient,
  SubrecipientContractAttachment,
} from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';

@Component({
  selector: 'subs-select-attachments',
  templateUrl: './select-attachments.component.html',
  providers: [FeedbackService],
})
export class SelectAttachmentsComponent implements OnInit {
  private subaward: Subaward;
  private project: Project;
  private allAttachments = new BehaviorSubject<Attachment[]>([]);
  successSummary = new Array<PopulateAttachmentResult>();
  uploadComplete = false;
  showDuplicatedBookmarkModal = new BehaviorSubject<boolean>(false);
  duplicatedBookmarkMessage = '';

  projectInformation = this.fb.group({
    agreementNumber: [],
    proposalId: [],
    awardDepartment: [],
    awardYear: [],
    amendment: [],
    sponsorName: [],
    investigatorName: [],
    investigatorDepartmentName: [],
  });

  institutionInformation = this.fb.group({
    institutionId: [],
    institution: [],
    fund: [],
    subClass: [],
    dunsNumber: [],
    congDist: [],
    ein: [],
    foreign: [],
    ccr: [],
  });

  includeNOA = this.fb.group({
    includeNOA: [],
  });

  selectAttachments = this.fb.array([]);

  selectedFacePage = this.fb.group({
    selectedFacePage: [],
  });

  facePages = this.allAttachments.pipe(
    map(all =>
      all.filter(attachment => parseFloat(attachment.attachmentNum) < 1),
    ),
  );

  attachments = this.allAttachments.pipe(
    map(all =>
      all.filter(attachment => parseFloat(attachment.attachmentNum) >= 1),
    ),
  );

  submitState = this.feedbackService.submitState;
  disableButtons = this.feedbackService.pageLoadState.pipe(
    map(state => state !== ClrLoadingState.SUCCESS),
  );

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private subawardClient: SubawardsClient,
    private projectClient: ProjectsClient,
    private fileClient: FilesClient,
    private attachmentsClient: AttachmentsClient,
    private feedbackService: FeedbackService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    forkJoin(this.loadProjectAndSub(), this.loadAttachments())
      .pipe(this.feedbackService.providePageLoadFeedback())
      .subscribe();

    this.attachments.subscribe(attachments => {
      this.createAttachmentsForm(attachments);
    });
  }

  save() {
    this.feedbackService.beginLoading();

    forkJoin([
      this.createFacePage(),
      this.createNOA(),
      this.createAttachments(),
      this.createSOW(),
    ])
      .pipe(
        mergeMap(([facePage, noa, attachments, sow]) =>
          this.updateSubaward(facePage, noa, ...attachments, sow),
        ),
        this.feedbackService.provideFeedback(),
        tap(() => {
          this.uploadComplete = true;
        }),
      )
      .subscribe();
  }

  cancel() {
    this.router.navigateByUrl(`/subaward/${this.subaward.id}/subAttachments`);
  }

  navigateToSub() {
    this.router.navigateByUrl(`/subaward/${this.subaward.id}/subAttachments`);
  }

  getFailedAttachments() {
    const { selectedFacePage } = this.selectedFacePage.value;
    const attachment = this.allAttachments.value.find(
      a => a.id === selectedFacePage,
    );

    const selectedAttachments: Attachment[] = this.getSelectedAttachments();

    const failedAttachments = selectedAttachments.filter(
      x => this.successSummary.find(j => j.id === x.id) === undefined,
    );

    if (
      attachment &&
      this.successSummary.find(j => j.id === attachment.id) === undefined
    ) {
      failedAttachments.push(attachment);
    }

    return failedAttachments;
  }

  private sortAttachments(a: Attachment, b: Attachment): number {
    const attachmentNumberSort =
      parseFloat(a.attachmentNum) - parseFloat(b.attachmentNum);

    if (!isNaN(attachmentNumberSort) && attachmentNumberSort !== 0) {
      return attachmentNumberSort;
    }

    const attachmentStringSort = a.attachmentNum.localeCompare(b.attachmentNum);
    if (attachmentStringSort !== 0) {
      return attachmentStringSort;
    }

    return (a.description || '').localeCompare(b.description);
  }

  private loadProjectAndSub(): Observable<{
    project: Project;
    subaward: Subaward;
  }> {
    return this.getProjectAndSub().pipe(
      tap(({ project, subaward }) => {
        this.subaward = subaward;
        this.project = project;
        this.setProjectInformationFormValue(project, subaward);
        this.setInstitutionInformationFormValue(project, subaward);
      }),
    );
  }

  private loadAttachments(): Observable<Attachment[]> {
    return this.attachmentsClient
      .getAll(undefined, undefined, undefined, false)
      .pipe(
        tap(allAttachments => {
          allAttachments.sort((a, b) => this.sortAttachments(a, b));
          this.allAttachments.next(allAttachments);
        }),
      );
  }

  private createAttachmentsForm(attachments: Attachment[]) {
    this.selectAttachments.clear();

    for (const attachment of attachments) {
      const control = this.fb.group({
        id: [{ disabled: true }],
        description: [],
        selected: [],
        attachmentNumber: [],
      });

      control.setValue({
        id: attachment.id,
        attachmentNumber: attachment.attachmentNum,
        description: attachment.description,
        selected: false,
      });

      this.selectAttachments.push(control);
    }
  }

  private updateSubaward(...newAttachments: SubrecipientContractAttachment[]) {
    const updatedSubaward = cloneDeep(this.subaward);
    const nonNullAttachments = newAttachments.filter(a => !!a);

    let signaturePageExists = false;
    for (const attachment of updatedSubaward.contractAttachments) {
      if (attachment.signaturePage) {
        signaturePageExists = true;
        break;
      }
    }

    if (signaturePageExists) {
      for (const attachment of nonNullAttachments) {
        attachment.signaturePage = false;
      }
    }

    updatedSubaward.contractAttachments.push(...nonNullAttachments);

    return this.subawardClient.put(updatedSubaward.id, updatedSubaward);
  }

  private createNOA(): Observable<SubrecipientContractAttachment | null> {
    const { includeNOA } = this.includeNOA.value;

    if (!includeNOA) {
      return of(null);
    }

    const selectedNOA = this.project.noticeOfAwards.find(noa => noa.selected);
    if (!selectedNOA) {
      return of(null);
    }

    return this.fileClient.clone(selectedNOA.fileId).pipe(
      tap(x => {
        const success = {
          id: selectedNOA.id,
          attachmentName: selectedNOA.fileName,
          file: x,
          invalidBookmarks: [],
          emptyBookmarks: [],
        };
        this.successSummary.push(success);
      }),
      map(file =>
        this.ConstructContractAttachment(
          file,
          {
            ...selectedNOA,
            attachmentNum: '99.0',
          },
          false,
        ),
      ),
      catchError(() => {
        return of(null);
      }),
    );
  }

  private createSOW(): Observable<SubrecipientContractAttachment | null> {
    const selectedAttachments: Attachment[] = this.getSelectedAttachments();
    if (
      !selectedAttachments.some(attachment => attachment.attachmentNum == '5')
    ) {
      return of(null);
    }

    const uploadedSOWId = this.subaward.subrecipientInformation
      .collaboratorScopeOfWorkFileId;
    if (!uploadedSOWId) {
      return of(null);
    }

    return this.fileClient.clone(uploadedSOWId).pipe(
      map(file =>
        this.ConstructContractAttachment(
          file,
          {
            attachmentNum: '5.1',
          },
          false,
        ),
      ),
    );
  }

  private createAttachments(): Observable<SubrecipientContractAttachment[]> {
    const selectedAttachments: Attachment[] = this.getSelectedAttachments();

    return from(selectedAttachments).pipe(
      filter(attachment => !!attachment),
      concatMap(attachment =>
        this.subawardClient
          .populateAttachment(this.subaward.id, attachment.id)
          .pipe(
            tap(x => this.successSummary.push(x)),
            map(file => ({ file, attachment })),
            catchError(() => {
              return empty();
            }),
          ),
      ),
      map(({ file, attachment }) =>
        this.ConstructContractAttachment(file.file, attachment, false),
      ),
      toArray(),
    );
  }

  private getSelectedAttachments(): Attachment[] {
    return this.selectAttachments.value
      .filter(attachment => attachment.selected)
      .map(selected =>
        this.allAttachments.value.find(
          attachment => attachment.id === selected.id,
        ),
      )
      .filter(attachment => !!attachment);
  }

  private createFacePage(): Observable<SubrecipientContractAttachment | null> {
    const { selectedFacePage } = this.selectedFacePage.value;
    const attachment = this.allAttachments.value.find(
      a => a.id === selectedFacePage,
    );

    if (!attachment) {
      return of(null);
    }

    return this.subawardClient
      .populateAttachment(this.subaward.id, attachment.id)
      .pipe(
        tap(x => this.successSummary.push(x)),
        map(file =>
          this.ConstructContractAttachment(file.file, attachment, true),
        ),
        catchError(err => {
          if (err.errorType === 'DUPLICATED_BOOKMARK_FOUND') {
            this.duplicatedBookmarkMessage = err.error;
            this.showDuplicatedBookmarkModal.next(true);
            this.feedbackService.clearAlert();
          } else {
            return of(null);
          }
        }),
      );
  }

  private ConstructContractAttachment(
    clonedFile: File,
    attachment: Attachment,
    isSignaturePage: boolean,
  ): SubrecipientContractAttachment {
    return {
      fileId: clonedFile.id,
      fileName: attachment.attachmentFileName,
      created: new Date().toISOString(),
      fullAgreement: false,
      sequence: attachment.attachmentNum,
      signaturePage: isSignaturePage,
      signed: false,
      source: 'System',
      unilateral: attachment.unilateral,
      utilitiesAttachmentTemplateId: attachment.id,
    };
  }

  private getProjectAndSub(): Observable<{
    project: Project;
    subaward: Subaward;
  }> {
    return this.activatedRoute.params.pipe(
      filter(params => params.id),
      first(),
      mergeMap(params => this.subawardClient.get(params.id)),
      mergeMap(subaward =>
        this.projectClient
          .get(subaward.washUInformation.projectId)
          .pipe(map(project => ({ project, subaward }))),
      ),
    );
  }

  private setProjectInformationFormValue(project: Project, subaward: Subaward) {
    const formValue = this.mapToProjectInformationFormValue(project, subaward);
    this.projectInformation.setValue(formValue);
  }

  private mapToProjectInformationFormValue(
    project: Project,
    subaward: Subaward,
  ) {
    return {
      agreementNumber: subaward.washUInformation.agreementNumber,
      proposalId: project.proposalId,
      awardDepartment: project.awardDepartment,
      awardYear: project.awardYear,
      sponsorName: project.sponsorName,
      amendment: subaward.washUInformation.amendment,
      investigatorName:
        (subaward.contact.principalInvestigator.firstName || '') +
        ' ' +
        (subaward.contact.principalInvestigator.lastName || ''),
      investigatorDepartmentName:
        subaward.contact.principalInvestigator.principalInvestigatorDepartment,
    };
  }

  private setInstitutionInformationFormValue(
    project: Project,
    subaward: Subaward,
  ) {
    const formValue = this.mapInstitutionFormValue(project, subaward);
    this.institutionInformation.setValue(formValue);
  }

  private mapInstitutionFormValue(project: Project, subaward: Subaward) {
    return {
      institutionId: subaward.subrecipientInformation.institutionId,
      institution: subaward.subrecipientInformation.institutionName,
      fund: subaward.washUInformation.fund,
      subClass: subaward.washUInformation.issuingFundSubClassBudgetObject,
      dunsNumber: subaward.subrecipientInformation.dunsNumber,
      congDist: subaward.subrecipientInformation.congressionalDistrict,
      ein: subaward.subrecipientInformation.ein,
      foreign: subaward.subrecipientInformation.foreignInstitution
        ? 'Yes'
        : 'No',
      ccr: subaward.subrecipientInformation.registeredInSamGov ? 'Yes' : 'No',
    };
  }
}
