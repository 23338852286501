import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL, Training } from '../../api.service';
import {
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from 'rxjs/operators';
import {
  Observable,
  throwError as _observableThrow,
  of as _observableOf,
} from 'rxjs';

@Injectable()
export class TrainingService {
  private http: HttpClient;
  private baseUrl: string;
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string,
  ) {
    this.http = http;
    this.baseUrl =
      baseUrl !== undefined && baseUrl !== null
        ? baseUrl
        : window.environment.apiBaseUrl;
  }

  uploadTrainingFile(trainingFileToSave: File) {
    const data = new FormData();
    data.append('file', trainingFileToSave, trainingFileToSave.name);
    return this.http.post<TrainingFile>(`${this.baseUrl}/files`, data);
  }
}

export interface TrainingFile {
  id?: string;
  name: string;
}

export interface TrainingView {
  training: Training;
  trainingFile: TrainingFile;
}
