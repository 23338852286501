import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'subs-university-contact',
  templateUrl: './university-contact.component.html',
})
export class UniversityContactComponent {
  @Input()
  form: UntypedFormGroup;

  @Input()
  contactIndex: number;

  constructor() {}
}
