import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  LookupItem,
  Subaward,
  SubawardsClient,
  UserProfilesClient,
} from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class SubawardsService {
  private subaward = new BehaviorSubject<Subaward | null>(null);
  private subawardStatus = new BehaviorSubject<string | null>(null);
  private newPoCoRequestId = new BehaviorSubject<boolean | null>(null);
  private approved = new BehaviorSubject<boolean | null>(null);
  private internalDistributionSent = new BehaviorSubject<boolean | null>(null);
  private unilateralModSent = new BehaviorSubject<boolean | null>(null);
  private poNotificationSent = new BehaviorSubject<boolean | null>(null);
  private feaUploaded = new BehaviorSubject<boolean | null>(null);
  private newPoCoEncumbId = new BehaviorSubject<string | null>(null);
  private newSupplierContractId = new BehaviorSubject<string | null>(null);
  private indirectCostBaseMethod = new BehaviorSubject<string | null>(null);
  private osrsGaList = new BehaviorSubject<Array<string> | []>(null);

  constructor(
    private subawardClient: SubawardsClient,
    private userProfileClient: UserProfilesClient,
  ) {}

  get Subaward(): Observable<Subaward | null> {
    return this.subaward.asObservable();
  }

  get SubawardStatus(): Observable<string | null> {
    return this.subawardStatus.asObservable();
  }

  get Approved(): Observable<boolean | null> {
    return this.approved.asObservable();
  }

  get InternalDistributionSent(): Observable<boolean | null> {
    return this.internalDistributionSent.asObservable();
  }

  get UnilateralModSent(): Observable<boolean | null> {
    return this.unilateralModSent.asObservable();
  }

  get PoNotificationSent(): Observable<boolean | null> {
    return this.poNotificationSent.asObservable();
  }

  get FeaUploaded(): Observable<boolean | null> {
    return this.feaUploaded.asObservable();
  }

  get PoCoRequestId(): Observable<boolean | null> {
    return this.newPoCoRequestId.asObservable();
  }

  get PoCoEncumbId(): Observable<string | null> {
    return this.newPoCoEncumbId.asObservable();
  }

  get SupplierContractId(): Observable<string | null> {
    return this.newSupplierContractId.asObservable();
  }

  get IndirectCostBaseMethod(): Observable<string | null> {
    return this.indirectCostBaseMethod.asObservable();
  }

  get OsrsGaList(): Observable<Array<string> | null> {
    return this.osrsGaList.asObservable();
  }

  selectSubaward(id: number) {
    this.subawardClient.get(id).subscribe(this.subaward);
  }

  setsubawardStatus(status: string) {
    this.subawardStatus.next(status);
  }

  setApproved(approveClicked: boolean) {
    this.approved.next(approveClicked);
  }

  setInternalDistributionSent(emailSent: boolean) {
    this.internalDistributionSent.next(emailSent);
  }

  setUnilateralModSent(emailSent: boolean) {
    this.unilateralModSent.next(emailSent);
  }

  setPoNotificationSent(emailSent: boolean) {
    this.poNotificationSent.next(emailSent);
  }

  setFeaUploaded(feaUploaded: boolean) {
    this.feaUploaded.next(feaUploaded);
  }

  setSubawardContact(contact: any) {
    const updatedSubaward = this.subaward.getValue();
    updatedSubaward.contact = contact;
    this.subaward.next(updatedSubaward);
  }

  setPoCoRequestId(poCoRequestId: boolean) {
    this.newPoCoRequestId.next(poCoRequestId);
  }

  setPoCoEncumbId(poCoEncumbId: string) {
    this.newPoCoEncumbId.next(poCoEncumbId);
  }

  setSupplierContractId(supplierContractId: string) {
    this.newSupplierContractId.next(supplierContractId);
  }

  setIndirectCostBaseMethod(indirectCostBaseMethod: string) {
    this.indirectCostBaseMethod.next(indirectCostBaseMethod);
  }

  setOsrsGaList() {
    this.userProfileClient.getUsersByPermission('OsrsGaUser').subscribe(val => {
      const gaList = [];
      val.forEach(u => {
        gaList.push(u.employeeName);
      });
      this.osrsGaList.next(gaList);
    });
  }
}
