<clr-main-container>
  <clr-header class="header-6">
    <div class="branding clr-col-5">
      <a href="/" class="nav-link">
        <img
          src="assets/subsLogoWhite.png"
          style="height:4.3em; margin-right: 1em;"
        />
      </a>
    </div>

    <div class="branding">
      <a href="/" class="nav-link">
        <img
          src="assets/1linerev(RGB)1000-01.png"
          style="height:4em; margin-right: 1em;"
        />
      </a>
    </div>

    <div class="header-actions">
      <clr-dropdown>
        <button
          class="nav-text"
          clrDropdownTrigger
          aria-label="open user profile"
        >
          {{ userName | async }}
          <clr-icon shape="caret down"></clr-icon>
        </button>

        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <button clrDropdownItem (click)="logout()">Log out</button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </clr-header>

  <div class="content-container">
    <clr-vertical-nav [clrVerticalNavCollapsible]="true" [clr-nav-level]="1">
      <a
        clrVerticalNavLink
        routerLinkActive="active"
        routerLink="/"
        data-test-id="home-page-link"
      >
        <clr-icon shape="Home" clrVerticalNavIcon></clr-icon>

        Home
      </a>
      <clr-vertical-nav-group
        *subsPermission="['ProjectRead']"
        data-test-id="projects-nav-group"
        routerLinkActive="active"
      >
        <clr-icon shape="certificate" clrVerticalNavIcon></clr-icon>
        Projects

        <clr-vertical-nav-group-children *subsPermission="['ProjectRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/find-subawards"
            data-test-id="find-project-link"
          >
            Find Project
          </a>
        </clr-vertical-nav-group-children>

        <clr-vertical-nav-group-children *subsPermission="['ProjectCreate']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/project']"
          >
            Add Project
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *ngIf="projectAsideVisible">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/project', projectId]"
          >
            Project Summary
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *ngIf="addSubawardAsideVisible">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/subaward/project', projectId]"
          >
            Add Subaward Record
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *ngIf="addCurrentYearAsideVisible">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/subaward/changesToCurrentYear', subawardId]"
          >
            Add Subaward Record
          </a>
        </clr-vertical-nav-group-children>

        <clr-vertical-nav-group-children *ngIf="subawardAsideVisible">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/subaward', subawardId]"
          >
            Subaward Record
          </a>
        </clr-vertical-nav-group-children>
      </clr-vertical-nav-group>
      <clr-vertical-nav-group routerLinkActive="active">
        <clr-icon shape="file-group" clrVerticalNavIcon></clr-icon>
        Reports

        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/da-worklist"
          >
            DA Worklist
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/co-worklist"
          >
            Central Admin Worklist
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/collaborators-executed-subs"
          >
            Collaborators Executed Subs
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/cch-rollup-report"
          >
            CCH Rollup
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/osrs-review-report"
          >
            OSRS Review
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/new-institution-report"
          >
            New Institutions
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/institution-audit-report"
          >
            Institutions Audit
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/return-report"
          >
            Return to DA
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/ffata-report"
          >
            FFATA Grants/Contracts
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/outgoing-activity-report"
          >
            Outgoing SUB Activity by Month
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/outgoing-subs-status-report"
          >
            Outgoing SUBS Status
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children *subsPermission="['ReportRead']">
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/subs-executed"
          >
            SUBS Executed
          </a>
        </clr-vertical-nav-group-children>
      </clr-vertical-nav-group>
      <clr-vertical-nav-group
        routerLinkActive="active"
        *ngIf="
          addInstitutionAsideVisible ||
          editInstitutionAsideVisible ||
          canAccessUtilities()
        "
      >
        <clr-icon shape="bookmark" clrVerticalNavIcon></clr-icon>
        Utilities
        <clr-vertical-nav-group-children
          *subsPermission="['AccessInstitutionMaintenancePage']"
        >
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/find-institution"
          >
            Institution Maintenance
          </a>
        </clr-vertical-nav-group-children>

        <clr-vertical-nav-group-children
          *ngIf="addInstitutionAsideVisible && canAccessCreateInstitution()"
        >
          <a
            [ngClass]="{ active: addInstitutionActive }"
            clrVerticalNavLink
            [routerLinkActive]="addInstitutionActive ? 'active' : ''"
            routerLink="/institution"
          >
            Add Institution
          </a>
        </clr-vertical-nav-group-children>

        <clr-vertical-nav-group-children
          *ngIf="editInstitutionAsideVisible && canAccessEditInstitution()"
        >
          <a
            [ngClass]="{ active: editInstitutionActive }"
            clrVerticalNavLink
            [routerLinkActive]="editInstitutionActive ? 'active' : ''"
            [routerLink]="['/institution', institutionId]"
          >
            Institution Details
          </a>
        </clr-vertical-nav-group-children>

        <clr-vertical-nav-group-children
          *subsPermission="['AccessAttachmentFindPage']"
        >
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/find-attachment"
          >
            Find Attachment
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children
          *subsPermission="['AccessAttachmentAddPage']"
        >
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/attachment"
          >
            Add Attachment
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children
          *subsPermission="['AccessSubReturnCodesPage']"
        >
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/return-code"
          >
            Sub Return Codes
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children
          *subsPermission="['AccessSubMonitoringQuestionsPage']"
        >
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/monitoring-questions"
            title="Sub Monitoring Questions Table"
          >
            Sub Monitoring Questions Table
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children
          *subsPermission="['UtilitySubStatusRead']"
        >
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/sub-status"
          >
            Sub Statuses
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children
          *subsPermission="['AccessWashUContactsPage']"
        >
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/washington-university-contacts"
            title="Wash U. Contacts"
          >
            Wash U. Contacts
          </a>
        </clr-vertical-nav-group-children>

        <clr-vertical-nav-group-children
          *subsPermission="['AccessSecurityPage']"
        >
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/roles-security"
            title="Security"
          >
            Security
          </a>
        </clr-vertical-nav-group-children>

        <clr-vertical-nav-group-children
          *subsPermission="['AccessUtilityMaintenancePage']"
        >
          <a
            clrVerticalNavLink
            routerLinkActive="active"
            routerLink="/find-app-utility"
          >
            Utility Maintenance
          </a>
        </clr-vertical-nav-group-children>

        <clr-vertical-nav-group-children
          *ngIf="addUtilityItemVisible && canAccessCreateUtilityItem()"
        >
          <a
            [ngClass]="{ active: addInstitutionActive }"
            clrVerticalNavLink
            [routerLinkActive]="addInstitutionActive ? 'active' : ''"
            routerLink="/app-utility/item"
          >
            Add Utility Item
          </a>
        </clr-vertical-nav-group-children>

        <clr-vertical-nav-group-children
          *ngIf="editUtilityItemVisible && canAccessEditUtilityItem()"
        >
          <a
            [ngClass]="{ active: addInstitutionActive }"
            clrVerticalNavLink
            [routerLinkActive]="addInstitutionActive ? 'active' : ''"
            [routerLink]="['/app-utility/item', utilityItemId]"
          >
            Edit Utility Item
          </a>
        </clr-vertical-nav-group-children>
        <clr-vertical-nav-group-children
          *subsPermission="['AccessHomePageUtilities']"
        >
          <clr-vertical-nav-group
            routerLinkActive="active"
            *ngIf="
              addInstitutionAsideVisible ||
              editInstitutionAsideVisible ||
              canAccessUtilities()
            "
          >
            <clr-icon shape="home" clrVerticalNavIcon></clr-icon>

            Home Page Utilities
            <clr-vertical-nav-group-children
              *subsPermission="['AccessHomePageUtilities']"
            >
              <a
                clrVerticalNavLink
                routerLinkActive="active"
                routerLink="/announcements"
              >
                Announcements
              </a>
            </clr-vertical-nav-group-children>
            <clr-vertical-nav-group-children
              *subsPermission="['AccessHomePageUtilities']"
            >
              <a
                clrVerticalNavLink
                routerLinkActive="active"
                routerLink="/contact-list"
              >
                Contact List
              </a>
            </clr-vertical-nav-group-children>
            <clr-vertical-nav-group-children
              *subsPermission="['AccessHomePageUtilities']"
            >
              <a
                clrVerticalNavLink
                routerLinkActive="active"
                routerLink="/shortcut-links"
              >
                Shortcut Links
              </a>
            </clr-vertical-nav-group-children>
            <clr-vertical-nav-group-children
              *subsPermission="['AccessHomePageUtilities']"
            >
              <a
                clrVerticalNavLink
                routerLinkActive="active"
                routerLink="/training"
              >
                Training
              </a>
            </clr-vertical-nav-group-children>
          </clr-vertical-nav-group>
        </clr-vertical-nav-group-children>
      </clr-vertical-nav-group>
    </clr-vertical-nav>

    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
  </div>
</clr-main-container>
