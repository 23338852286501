import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Institution } from '../../../../api.service';
@Injectable({
  providedIn: 'root',
})
export class CollaboratorInstitutionSearchService {
  private selectedInstitution = new BehaviorSubject<Institution>(null);

  select(inst: Institution) {
    this.selectedInstitution.next(inst);
  }

  getSelectedInstitution() {
    return this.selectedInstitution.asObservable();
  }
}
