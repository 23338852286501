<div class="card">
  <div class="card-header">
    Institution Address
  </div>

  <div class="card-block">
    <div class="clr-row">
      <subs-input
        class="clr-col"
        controlName="centralOfficeEmail"
        maxLength="70"
      ></subs-input>
    </div>

    <subs-address [form]="form"></subs-address>
  </div>
</div>
