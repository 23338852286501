import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { isEqual } from 'lodash';
import { WashUContactsClient } from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';

@Component({
  selector: 'subs-washu-contacts',
  styleUrls: ['./washu-contacts.component.scss'],
  templateUrl: './washu-contacts.component.html',
  providers: [FeedbackService],
})
export class WashuContactsComponent implements OnInit {
  submitState = this.feedbackService.submitState;
  alertSubject$ = this.feedbackService.alerts;

  contacts = this.fb.array([]);
  form = this.fb.group({
    id: [],
    status: [],
    institutionName: [],
    contacts: this.contacts,
    institutionAddress: this.createAddressGroup(),
    osrsAddress: this.createAddressGroup(),
  });
  constructor(
    private fb: UntypedFormBuilder,
    private washuContactsClient: WashUContactsClient,
    private feedbackService: FeedbackService,
    private chRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.washuContactsClient.get().subscribe(val => {
      this.form.patchValue(val);

      val.contacts.forEach(c => {
        const cont = this.createContactGroup();
        cont.patchValue(c);
        this.contacts.push(cont);
      });
      this.chRef.detectChanges();
    });

    //=================Set Validators Based on the Country Selection=================================================
    this.SetValidatorsBasedOnCountry(this.form.controls.institutionAddress);
    this.SetValidatorsBasedOnCountry(this.form.controls.osrsAddress);
  }

  save() {
    const emptyContact = this.createContactGroup().value;
    this.contacts.controls.forEach((contact: UntypedFormGroup, index) => {
      if (isEqual(contact.value, emptyContact)) {
        this.contacts.removeAt(index);
      }
    });

    this.feedbackService.clearAlert();

    var stateErrorFound: boolean = false;
    var zipErrorFound: boolean = false;
    var countryName: string;
    var provinceOrState: string;

    // Check the dynamically created WashU contacts. Haven't figured out how to do this with valueChanges.subscribe()
    // like is done with institutionAddress and osrsAddress.
    this.contacts.controls.forEach((contact: UntypedFormGroup, index) => {
      const country = contact.get('address.countryCode').value;
      const state = contact.get('address.state').value;
      const zip = contact.get('address.zip').value;
      if (country === 'USA' || country === 'CAN') {
        if (!state.length) {
          stateErrorFound = true;
          provinceOrState = country === 'CAN' ? 'Province' : 'State';
          countryName = contact.get('address.countryName').value;
        }
        if (!zip.length) {
          zipErrorFound = true;
          provinceOrState = country === 'CAN' ? 'Province' : 'State';
          countryName = contact.get('address.countryName').value;
        }
      }
    });

    if (stateErrorFound) {
      this.feedbackService.alert(
        'Please select a ' +
          provinceOrState +
          ' for selected country: ' +
          countryName +
          ' before saving the form.',
      );
      return;
    }

    if (zipErrorFound) {
      this.feedbackService.alert(
        'Please enter a zip code for selected country: ' +
          countryName +
          ' before saving the form.',
      );
      return;
    }

    if (this.form.invalid) {
      this.markFormGroupTouched(this.form);
      this.feedbackService.alert(
        'Please fix all field errors before saving the form.',
      );
      // Check state and zip code lists for institutionAddress and osrsAddress (not WashU Contacts, which is checked above).
      // This will display any existing error using feedbackService
      this.checkStatesAndZips();
      return;
    } else {
      this.feedbackService.clearAlert();
    }

    this.feedbackService.beginLoading();

    const value = this.form.value;
    this.washuContactsClient
      .put(value)
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(result => {
        this.form.patchValue(result);
      });
  }

  public addContact() {
    const newContact = this.createContactGroup();
    if (
      !isEqual(
        this.contacts.at(this.contacts.length - 1).value,
        newContact.value,
      )
    ) {
      this.contacts.push(newContact);
    }
  }

  // these validators will be appended dynamically when conditional validation is implemented
  // (example: when the country selection requires the related fields (state, zip, etc.) to be populated)
  // see SetValidatorsBasedOnCountry()
  private stateValidators = [Validators.maxLength(2)];
  private zipValidators = [Validators.maxLength(30)];

  private createAddressGroup(): UntypedFormGroup {
    return this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      addressLine4: [''],
      city: [''],
      state: [''],
      zip: [''],
      countryCode: [''],
      countryName: [''],
    });
  }

  private createContactGroup(): UntypedFormGroup {
    return this.fb.group({
      title: [''],
      firstName: [''],
      middleName: [''],
      lastName: [''],
      nameSuffix: [''],
      phone: [''],
      fax: [''],
      email: [''],
      description: [''],
      contactType: ['', [Validators.required, RxwebValidators.unique()]],
      contactInstitutionId: [],
      osrsga: ['', Validators.pattern('^[a-zA-Z0-9]*$')],
      address: this.createAddressGroup(),
    });
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        // control is a FormGroup
        this.markFormGroupTouched(control);
      } else {
        // control is a FormControl
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
  }

  private SetValidatorsBasedOnCountry(addressComponent): void {
    const countryList = addressComponent.get('countryCode');
    const stateList = addressComponent.get('state');
    const zipList = addressComponent.get('zip');

    // Set Validators Based on the Country Selection
    countryList.valueChanges.subscribe(country => {
      if (country === 'USA' || country === 'CAN') {
        //add Validators.required
        stateList.setValidators(
          this.stateValidators.concat(Validators.required),
        );
        zipList.setValidators(this.zipValidators.concat(Validators.required));
        stateList.updateValueAndValidity();
        zipList.updateValueAndValidity();
      } else {
        //set to "normal" validators (not required)
        stateList.setValidators(this.stateValidators);
        zipList.setValidators(this.zipValidators);
        stateList.updateValueAndValidity();
        zipList.updateValueAndValidity();
      }
    });
  }

  private checkStatesAndZips(): boolean {
    if (this.validateStates(this.form.controls.institutionAddress)) {
      return true; // has errors
    }
    if (this.validateStates(this.form.controls.osrsAddress)) {
      return true; // has errors
    }
    if (this.validateZipCodes(this.form.controls.institutionAddress)) {
      return true; // has errors
    }
    if (this.validateZipCodes(this.form.controls.osrsAddress)) {
      return true; // has errors
    }
    return false;
  }

  private validateStates(addressComponent): boolean {
    const countryList = addressComponent.get('countryCode');
    const stateList = addressComponent.get('state');

    if (stateList.hasError('required')) {
      const country = countryList.value;
      const provinceOrState = country === 'CAN' ? 'Province' : 'State';
      this.feedbackService.alert(
        'Please select a ' +
          provinceOrState +
          ' for selected country code: ' +
          country +
          ' before saving the form.',
      );
      return true;
    } else {
      return false;
    }
  }

  private validateZipCodes(addressComponent): boolean {
    const countryList = addressComponent.get('countryCode');
    const zipList = addressComponent.get('zip');

    if (zipList.hasError('required')) {
      const country = countryList.value;
      this.feedbackService.alert(
        'Please enter a zip code for selected country code: ' +
          country +
          ' before saving the form.',
      );
      return true;
    } else {
      return false;
    }
  }
}
