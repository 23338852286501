export class IsRequiredValidator {
  static isRequired(inputs: string[]) {
    let invalidForm = false;
    let errorMessage = '';

    inputs.forEach(input => {
      if (input === '' || input === null || input === undefined) {
        invalidForm = true;
        errorMessage = 'Input is Required';
      }
    });
    return {
      invalidForm,
      errorMessage,
    };
  }
}
