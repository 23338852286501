import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LookupItem, UtilityClient } from '../api.service';

interface IFeature {
  featureName: string;
  featureEnabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private featuresList = new BehaviorSubject<Array<LookupItem> | []>(null);
  private enabled: BehaviorSubject<IFeature> = new BehaviorSubject<IFeature>(
    null,
  );

  constructor(private utilityClient: UtilityClient) {}

  public featureEnabled(name: string) {
    this.featuresList.subscribe((value) => {
      if (value && value.length > 0) {
        const enabled = value.some((f: LookupItem) => {
          return (
            f.key.toLowerCase() === name.toLowerCase() &&
            f.value.toLowerCase() === 'true'
          );
        });
        const feature: IFeature = {
          featureName: name,
          featureEnabled: enabled,
        };
        this.enabled.next(feature);
      }
    });
    return this.enabled.asObservable();
  }

  setFeatures(utilityTableKey: string) {
    this.utilityClient
      .getAllLookupItems(utilityTableKey)
      .subscribe((result) => {
        this.featuresList.next(result.lookupItems);
      });
  }
}
