import { Component, Input } from '@angular/core';

@Component({
  selector: 'subs-subaward-modtype-select',
  template: `
    <subs-select [errorMessage]="errorMessage" [label]="label" [controlName]="controlName" [labelLength]="labelLength" [disabled]="disabled">
      <option value="unilateral">Unilateral</option>
      <option value="bilateral">Bilateral</option>
    </subs-select>
  `,
})
export class SubawardModTypeSelectComponent {
  @Input() controlName: string;
  @Input() labelLength: number;
  @Input() disabled?: boolean;
  @Input() label: string;
  @Input() errorMessage: string;
}
