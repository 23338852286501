import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subaward } from 'src/app/api.service';

@Component({
  selector: 'subs-subaward-details',
  templateUrl: './subaward-details.component.html',
  styleUrls: ['./subaward-details.component.scss'],
})
export class SubawardDetailsComponent {
  @Input() form: UntypedFormGroup;
  @Input() checklist: UntypedFormGroup;
  @Input() projectTitle: string;
  @Input() questionIsVisible: boolean;
  @Input() subawardStatus: string;
  @Input() isModifiedAgreement: boolean;
  @Input() validatingGrantWorkTag: boolean;
  @Output() financialEmailClicked = new EventEmitter<boolean>();
  @Output() institutionalInformationUpload = new EventEmitter<Subaward>();
  @Output() sameAsGrantPeriodClicked: EventEmitter<void> = new EventEmitter<
    void
  >();
  @Output() sameAsSignatoryClicked: EventEmitter<void> = new EventEmitter<
    void
  >();
  @Output() grantWorkTagEntered = new EventEmitter<string>();
  @Output() poCoRequestIdEntered = new EventEmitter<string>();
  @Output() onCollaboratorInstitutionSelect: EventEmitter<boolean> = new EventEmitter<boolean>();
}
