import { Component, Input } from '@angular/core';

import { FeedbackService } from './feedback.service';

@Component({
  selector: 'subs-form-layout',

  styles: [
    `
      .form-container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    `,
    `
      .form {
        flex-grow: 1;
        overflow-y: scroll;
        margin: -24px -24px 0 0;
        padding: 24px 24px 24px 0;
      }
    `,
    `
      .form-footer {
        margin: 0 0 -12px -12px;
        padding: 12px 24px 12px 24px;
        width: calc(100% + 12px);
      }
    `,
  ],
  template: `
    <div class="form-container">
      <form clrForm class="form">
        <ng-content select="subs-form-content"></ng-content>
      </form>

      <div *ngIf="!hideFooter" class="card form-footer">
        <subs-alert [alertBehavior]="alertSubject"> </subs-alert>
        <ng-content select="subs-form-buttons"></ng-content>
      </div>
    </div>
  `,
})
export class ButtonFormLayoutComponent {
  alertSubject = this.feedbackService.alerts;
  @Input() hideFooter: boolean;
  constructor(private feedbackService: FeedbackService) {}
}
