<div class="form-container">
  <div
    style="
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 24px;"
  >
    <div class="card">
      <div class="card-header">Sub Recipient Entry for {{ projectTitle }}</div>
    </div>
    <subs-checklist [checklist]="checklist"> </subs-checklist>
    <div class="card">
      <div class="card-header">
        Comments
      </div>
      <div class="card-block">
        <subs-alert [alertBehavior]="formLevelAlerts$"> </subs-alert>
        <form clrForm [formGroup]="newComment">
          <div class="clr-row">
            <div class="text-area-container">
              <subs-textarea
                class="clr-col"
                labelLength="0"
                controlName="comment"
                placeholder="Add a comment."
                errorMessage="Please insert a comment."
              >
              </subs-textarea>
            </div>
          </div>
          <subs-alert [alertBehavior]="submitAlerts$"> </subs-alert>
          <button
            class="btn btn-primary"
            [clrLoading]="submitState$ | async"
            (click)="save()"
            data-test-id="add-comment"
            type="submit"
            [disabled]="!newComment.valid"
          >
            Add Comment
          </button>
        </form>
      </div>
      <div *ngFor="let comment of comments | async" class="card-block">
        <subs-comment-card [comment]="comment"> </subs-comment-card>
      </div>
    </div>
  </div>
</div>
