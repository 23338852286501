import { Injectable } from '@angular/core';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportMultipleDataSetsService {
  constructor() {}

  public async generateExcel(
    fileName: string,
    title: string,
    data: Array<{
      title: string;
      columns: { header: string; key: string; width: number }[];
      rows: any[];
      totals: { label: string; value: number; column: number }[];
    }>,
    filters?: { value?: string },
  ) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Report');

    // report header
    this.addReportHeader(worksheet, title, filters, data[0].columns.length);

    // data
    data.forEach((section, index) => {
      worksheet.addRow([]);
      this.mergeCells(
        worksheet,
        worksheet.lastRow.number,
        section.columns.length,
      );

      // section title
      const titleRow = worksheet.addRow([section.title]);
      titleRow.font = { bold: true, size: 14 };
      titleRow.alignment = { horizontal: 'center' };
      this.mergeCells(worksheet, titleRow.number, section.columns.length);

      // column headers
      const headerRow = worksheet.addRow(
        section.columns.map((col) => col.header),
      );
      headerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE9F3FC' },
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      // row's styling
      section.rows.forEach((row) => {
        const dataRow = worksheet.addRow(row);
        dataRow.eachCell((cell, colIndex) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          cell.alignment = { vertical: 'top' };

          const columnConfig = section.columns[colIndex - 1];
          if (columnConfig?.width) {
            worksheet.getColumn(colIndex).width = columnConfig.width;
          }

          if (colIndex === section.columns.length) {
            cell.border.right = { style: 'thin' };
          }
        });
      });

      // no results - user-friendly message
      if (section.rows.length === 0) {
        const noResultsRow = worksheet.addRow([
          'No result found for date range selected.',
        ]);
        noResultsRow.font = {
          italic: true,
          size: 12,
          color: { argb: '0000FF' },
        };
        noResultsRow.alignment = { horizontal: 'center' };
        this.mergeCells(worksheet, noResultsRow.number, section.columns.length);
      }

      // totals
      const totalsRow = worksheet.addRow([]);
      section.totals.forEach((total) => {
        const totalCell = totalsRow.getCell(total.column);
        totalCell.value = `${total.label}: ${total.value}`;
        totalCell.font = { bold: true };
        totalCell.alignment = { horizontal: 'right' };
      });
    });

    // excel generation
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fs.saveAs(blob, `${fileName}.xlsx`);
  }

  private addReportHeader(
    worksheet: Excel.Worksheet,
    title: string,
    filters?: { value?: string },
    numberOfColumns?: number,
  ) {
    const date = new Date();

    // report run date
    const dateRow = worksheet.addRow([date.toLocaleDateString()]);
    dateRow.font = { bold: true, size: 11 };
    dateRow.alignment = { horizontal: 'right' };
    this.mergeCells(worksheet, dateRow.number, numberOfColumns);

    // main report header
    const headerRows = [
      'Washington University',
      'Office of Sponsored Research Services',
      'SUB System Reports',
    ];

    headerRows.forEach((text) => {
      const row = worksheet.addRow([text]);
      row.font = { bold: true, size: 11 };
      row.alignment = { horizontal: 'center' };
      this.mergeCells(worksheet, row.number, numberOfColumns);
    });

    worksheet.addRow([]);
    this.mergeCells(worksheet, worksheet.lastRow.number, numberOfColumns);

    const titleRow = worksheet.addRow([title]);
    titleRow.font = { bold: true, size: 14 };
    titleRow.alignment = { horizontal: 'center' };
    this.mergeCells(worksheet, titleRow.number, numberOfColumns);

    // filters
    if (filters?.value) {
      const filterRow = worksheet.addRow([filters.value]);
      filterRow.font = { italic: true };
      this.mergeCells(worksheet, filterRow.number, numberOfColumns);
    }
  }

  private mergeCells(
    worksheet: Excel.Worksheet,
    rowNumber: number,
    columnCount: number,
  ) {
    worksheet.mergeCells(rowNumber, 1, rowNumber, columnCount);
  }
}
