<subs-form-layout [formGroup]="form">
  <subs-form-content>
    <div class="card">
      <div class="card-header">
        Sub Return Codes
      </div>
      <div class="card-block">
        <div class="clr-row">
          <button
            class="clr-col-lg-auto"
            class="btn btn-sm"
            (click)="onAdd()"
            aria-label="Add Return Code"
            title="Add Return Code"
            data-test-id="addLine-button"
            style="margin: 0 0 0 0.5rem;"
          >
            Add
          </button>
        </div>

        <clr-datagrid data-test-id="return-code-grid">
          <clr-dg-column class="custom-width"></clr-dg-column>
          <clr-dg-column>Return Code</clr-dg-column>
          <clr-dg-column>Return Reason</clr-dg-column>
          <clr-dg-row
            *ngFor="let returnCode of returnCodes.controls; let i = index"
            [formGroup]="returnCode"
          >
            <clr-dg-cell class="custom-width">{{ i + 1 }}</clr-dg-cell>

            <clr-dg-cell>
              <subs-input
                labelLength="0"
                controlName="code"
                maxLength="3"
                containerStyle="margin: 0"
                errorMessage="Return code is needed and must be unique."
              ></subs-input
            ></clr-dg-cell>

            <clr-dg-cell
              ><subs-input
                labelLength="0"
                maxLength="30"
                controlName="reason"
                containerStyle="margin: 0"
                errorMessage="Return reason is needed."
              ></subs-input>
            </clr-dg-cell>

            <clr-dg-action-overflow>
              <button
                class="action-item"
                (click)="deleteReturnCode(i)"
                clrPopoverCloseButton
                data-test-id="delete"
              >
                Delete
              </button>
            </clr-dg-action-overflow>
          </clr-dg-row>
        </clr-datagrid>

        <clr-modal [(clrModalOpen)]="deleteOpened">
          <h3 class="modal-title">
            Confirm Delete?
          </h3>

          <div class="modal-body">
            <p>
              Are you sure you would like to delete
              <strong>{{ returnCodeDisplayName() }}</strong
              >?
            </p>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline"
              (click)="cancelDeleteConfirmation()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="confirmDelete()"
              data-test-id="confirm-delete"
            >
              Delete
            </button>
          </div>
        </clr-modal>
      </div>
    </div>
  </subs-form-content>

  <subs-form-buttons>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="save()"
      data-test-id="submit-return-code"
    >
      Save
    </button>
  </subs-form-buttons>
</subs-form-layout>
