import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeClient } from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';

import { Employee } from './employee.model';

@Component({
  selector: 'subs-employee-lookup',
  templateUrl: './employee-lookup.component.html',
  providers: [FeedbackService],
})
export class EmployeeLookupComponent {
  @Input() allowSearchByEmployeeId = true;
  submitState = this.feedbackService.submitState;

  isModalOpen: boolean;

  searchForm = this.fb.group({
    employeeId: '',
    lastName: ['', Validators.minLength(2)],
    firstName: ['', Validators.minLength(2)],
  });

  alertSubject$ = this.feedbackService.alerts;

  employeeLookupSearchResults = new BehaviorSubject<Employee[] | null>(null);

  shouldShowNoResultsMessage = this.employeeLookupSearchResults.pipe(
    map(employees => employees && employees.length === 0),
  );

  shouldShowMaxResultsMessage = this.employeeLookupSearchResults.pipe(
    map(employees => employees && employees.length === 500),
  );

  employeeSelectionSubscriber: Subscriber<Employee>;

  constructor(
    private fb: UntypedFormBuilder,
    private employeeClient: EmployeeClient,
    private feedbackService: FeedbackService,
  ) {}

  search() {
    this.feedbackService.beginLoading();
    const searchParameters = this.searchForm.value;

    this.employeeClient
      .getEmployees(
        searchParameters.firstName,
        searchParameters.lastName,
        searchParameters.employeeId,
      )
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(results => this.employeeLookupSearchResults.next(results));
  }

  clear() {
    this.searchForm.reset();
    this.employeeLookupSearchResults.next(null);
    this.feedbackService.clearAlert();
  }

  lookupEmployee(): Observable<Employee> {
    return new Observable<Employee>(subscriber => {
      if (
        this.employeeSelectionSubscriber &&
        !this.employeeSelectionSubscriber.closed
      ) {
        this.employeeSelectionSubscriber.complete();
      }

      this.clear();

      this.employeeSelectionSubscriber = subscriber;

      this.isModalOpen = true;
    });
  }

  selectEmployee(employee: Employee) {
    this.employeeSelectionSubscriber.next(employee);
    this.employeeSelectionSubscriber.complete();
    this.isModalOpen = false;
  }
}
