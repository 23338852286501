<div class="card-block" data-test-id="bookmark-form">
  <div class="clr-row">
    <button
      class="clr-col-lg-auto"
      class="btn btn-sm"
      (click)="onAdd()"
      aria-label="Add Bookmark"
      title="Add Bookmark"
      data-test-id="addLine-button"
      style="margin: 0 0 0 0.5rem;"
    >
      Add
    </button>

    <button
      *ngIf="this.bookmarks.value.length > 0"
      class="clr-col-lg-auto"
      class="btn btn-sm"
      (click)="exportBookmarks()"
      aria-label="Export Bookmarks"
      title="Export Bookmarks"
      data-test-id="export-bookmarks-button"
      style="margin: 0 0 0 0.5rem;"
    >
      Export
    </button>
  </div>

  <clr-datagrid data-test-id="bookmark-grid">
    <clr-dg-column class="custom-width"></clr-dg-column>
    <clr-dg-column [style.width.px]="270">Bookmark Name</clr-dg-column>
    <clr-dg-column [style.width.px]="180">Field Type</clr-dg-column>
    <clr-dg-column [style.width.px]="220">Table Name</clr-dg-column>
    <clr-dg-column>Field Name</clr-dg-column>
    <clr-dg-column [style.width.px]="140">Contact Type</clr-dg-column>
    <clr-dg-row
      *ngFor="let bookmark of bookmarks.controls; let i = index"
      [formGroup]="bookmark"
    >
      <clr-dg-cell class="custom-width">{{ i + 1 }}</clr-dg-cell>

      <clr-dg-cell>
        <subs-input
          labelLength="0"
          controlName="name"
          maxLength="30"
          containerStyle="margin: 0"
          style="width:100%"
        ></subs-input
      ></clr-dg-cell>

      <clr-dg-cell
        ><subs-select
          labelLength="0"
          controlName="fieldType"
          containerStyle="margin: 0"
        >
          <option value="">&nbsp; </option>
          <option value="BM">Bookmark</option>
          <option value="CKB">Check Box</option>
          <option value="BMP">Proper Bookmark</option>
          <option value="BMT">Terms Bookmark</option>
        </subs-select>
      </clr-dg-cell>

      <clr-dg-cell>
        <subs-select
          labelLength="0"
          controlName="tableName"
          containerStyle="margin: 0"
        >
          <option value="">&nbsp; </option>
          <option value="Institution">Institution</option>
          <option value="InstitutionContact">Institution Contact</option>
          <option value="Address">Institution Address</option>
          <option value="SubrecipientPlaceOfPerformance"
            >Place of Performance</option
          >
          <option value="Project">Project</option>
          <option value="SubrecipientContact">Subrecipient Contact</option>
          <option value="Subrecipient">Subrecipient</option>
        </subs-select>
      </clr-dg-cell>

      <clr-dg-cell>
        <ng-container *ngFor="let fields of this.bookmarkFields | async">
          <subs-select
            labelLength="0"
            class="clr-col"
            controlName="fieldName"
            containerStyle="margin: 0"
            *ngIf="fields.tableName === bookmark.value.tableName"
          >
            <option value=""></option>

            <option *ngFor="let property of fields.fields" [value]="property">{{
              capitalCase(property)
            }}</option>
          </subs-select>
        </ng-container>
      </clr-dg-cell>

      <clr-dg-cell
        ><subs-select
          labelLength="0"
          controlName="contactType"
          containerStyle="margin: 0"
        >
          <option value="">&nbsp; </option>
          <option value="AI2">AI2</option>
          <option value="AIO">AIO</option>
          <option value="CDA">CDA</option>
          <option value="CPI">CPI</option>
          <option value="IA0">IA0</option>
          <option value="IA2">IA2</option>
          <option value="IA3">IA3</option>
          <option value="IA4">IA4</option>
          <option value="IA5">IA5</option>
          <option value="IA6">IA6</option>
          <option value="IA7">IA7</option>
          <option value="IA8">IA8</option>
          <option value="IA9">IA9</option>
          <option value="IAC">IAC</option>
          <option value="IFC">IFC</option>
          <option value="TES">TES</option>
          <option value="WDA">WDA</option>
          <option value="WPI">WPI</option>
        </subs-select>
      </clr-dg-cell>

      <clr-dg-action-overflow>
        <button
          class="action-item"
          (click)="deleteBookmark(i)"
          clrPopoverCloseButton
          data-test-id="delete"
        >
          Delete
        </button>
      </clr-dg-action-overflow>
    </clr-dg-row>
  </clr-datagrid>

  <clr-modal [(clrModalOpen)]="deleteOpened">
    <h3 class="modal-title">
      Confirm Delete?
    </h3>

    <div class="modal-body">
      <p>
        Are you sure you would like to delete
        <strong>{{ bookmarkDisplayName() }}</strong
        >?
      </p>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline"
        (click)="cancelDeleteConfirmation()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="confirmDelete()"
        data-test-id="confirm-delete"
      >
        Delete
      </button>
    </div>
  </clr-modal>
</div>
