import { UntypedFormGroup } from '@angular/forms';

export function markFormGroupTouched(formGroup: UntypedFormGroup) {
  (<any>Object).values(formGroup.controls).forEach(control => {
    if (control.controls) {
      // control is a FormGroup
      markFormGroupTouched(control);
    } else {
      // control is a FormControl
      control.markAsTouched();
      control.updateValueAndValidity();
    }
  });
}
