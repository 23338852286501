import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { capitalCase } from 'change-case';
import { BehaviorSubject } from 'rxjs';
import { Bookmark, BookmarkFields, BookmarkFieldsClient } from 'src/app/api.service';
import { ExcelExportService } from 'src/app/reports/excel-export.service';

import { BookmarkControlFactory } from './bookmark-control-factory';

@Component({
  selector: 'subs-bookmarks-list',
  templateUrl: './bookmarks-list.component.html',
  styleUrls: ['./bookmarks-list.component.scss'],
})
export class BookmarksListComponent implements OnInit {
  @Input() bookmarks: UntypedFormArray;
  @Output() idxToRemove: EventEmitter<number> = new EventEmitter<number>();
  @Output() bookmarkAdded: EventEmitter<UntypedFormGroup> = new EventEmitter<
    UntypedFormGroup
  >();

  indexToDelete = -1;
  bookmarkToDeleteName = '';
  deleteOpened = false;
  bookmarkFields = new BehaviorSubject<BookmarkFields[]>([]);

  constructor(
    private controlFactory: BookmarkControlFactory,
    private excelExportService: ExcelExportService,
    private cd: ChangeDetectorRef,
    private bookmarkFieldsClient: BookmarkFieldsClient,
  ) {}

  ngOnInit(): void {
    this.bookmarkFieldsClient.get().subscribe(f => this.bookmarkFields.next(f));
    this.bookmarks.valueChanges.subscribe(() => {
      this.cd.markForCheck();
    });
  }

  onAdd() {
    const control = this.controlFactory.createBookmarkControl({
      id: 0,
      attachmentId: 0,
      name: '',
      fieldType: '',
      tableName: '',
      fieldName: '',
      contactType: '',
    });
    this.bookmarkAdded.emit(control);
  }

  deleteBookmark(index: number) {
    this.indexToDelete = index;
    this.bookmarkToDeleteName = this.bookmarks.value[index].name;
    this.deleteOpened = true;
  }
  cancelDeleteConfirmation() {
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.idxToRemove.emit(this.indexToDelete);
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  bookmarkDisplayName() {
    if (
      this.bookmarkToDeleteName !== '' &&
      this.bookmarkToDeleteName !== null
    ) {
      return this.bookmarkToDeleteName;
    } else {
      return `bookmark ${this.indexToDelete + 1}`;
    }
  }

  private generateExportData(results: Array<Bookmark>) {
    const data = [];
    results.forEach(e => {
      data.push([
        e.name ? e.name : '',
        e.fieldType ? e.fieldType : '',
        e.tableName ? e.tableName : '',
        e.fieldName ? e.fieldName : '',
        e.contactType ? e.contactType : '',
      ]);
    });
    return data;
  }

  async exportBookmarks() {
    const columns = [
      {
        header: 'Bookmark Name',
        width: 25,
      },
      {
        header: 'Field Type',
        width: 20,
      },
      {
        header: 'Table Name',
        width: 20,
      },
      {
        header: 'Field Name',
        width: 20,
      },
      {
        header: 'Contact Type',
        width: 18,
      },
    ];

    const data = this.generateExportData(this.bookmarks.value as Bookmark[]);

    await this.excelExportService.generateExcel(
      'Bookmarks ' + new Date().toLocaleString(),
      'Bookmarks',
      data,
      columns,
    );
  }

  capitalCase(value: string): string {
    return capitalCase(value);
  }
}
