import { Component, OnInit } from '@angular/core';
import { ContactListClient } from 'src/app/api.service';

@Component({
  selector: 'subs-dashboard-contacts',
  templateUrl: './dashboard-contacts.component.html',
  styleUrls: ['./dashboard-contacts.component.scss'],
})
export class DashboardContactsComponent implements OnInit {
  contacts = [];

  constructor(private contactService: ContactListClient) {}

  ngOnInit() {
    this.getContactList();
  }

  getContactList() {
    this.contactService.get().subscribe(val => {
      this.contacts = val.sort((a, b) =>
        a.orderNumber > b.orderNumber ? 1 : -1,
      );
    });
  }
  openEmail(email: string) {
    window.open('mailto:' + email);
  }
}
