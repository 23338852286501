import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  UntypedFormControl,
  Validators,
  FormGroup,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SubrecipientAuditLog } from 'src/app/api.service';

@Component({
  selector: 'subs-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['./audit-history.component.scss'],
})
export class AuditHistoryComponent {
  auditLogs = new BehaviorSubject<Array<SubrecipientAuditLog>>([]);
  auditHistoryFormControl: UntypedFormControl;

  @Input()
  set form(val: UntypedFormControl) {
    this.auditHistoryFormControl = val;
    this.auditLogs.next(this.auditHistoryFormControl.value);
    this.auditHistoryFormControl.valueChanges.subscribe(this.auditLogs);
  }
}
