<clr-modal [(clrModalOpen)]="isOpen">
  <h3 class="modal-title">
    Comment
  </h3>

  <div class="modal-body" style="overflow-y:hidden;">
    <form [formGroup]="returnCommentForm">
      <div class="clr-row">
        <subs-select class="clr-col" controlName="returnCode">
          <option value=""></option>
          <option *ngFor="let code of this.returnCodes" [value]="code.code">{{
            code.code + ' - ' + code.reason
          }}</option>
        </subs-select>
      </div>
      <div class="clr-row">
        <subs-textarea
          class="clr-col"
          labelLength="0"
          controlName="comment"
          placeholder="Add a comment."
          errorMessage="Please insert a comment."
        >
        </subs-textarea>
      </div>
      <subs-alert
        style="padding-right: 0.2rem;"
        [alertBehavior]="warningSubject$"
      ></subs-alert>
      <subs-alert
        style="padding-right: 0.2rem;"
        [alertBehavior]="alertSubject$"
      ></subs-alert>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="save()"
      data-test-id="return-comment-save"
    >
      Return to DA
    </button>
    <button type="button" class="btn btn-outline" (click)="isOpen = false">
      Cancel
    </button>
  </div>
</clr-modal>
