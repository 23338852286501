import { Pipe, PipeTransform } from '@angular/core';
import { format, isValid, parseISO } from 'date-fns';

@Pipe({
  name: 'localTime',
})
export class LocalTimePipe implements PipeTransform {
  transform(value: any) {
    switch (typeof value) {
      case 'string': {
        const dateValue = parseISO(value + 'Z');

        if (!isValid(dateValue)) {
          return '';
        }
        return format(dateValue, 'PPpp');
      }
      case 'object': {
        if (Object.prototype.toString.call(value) === '[object Date]') {
          return format(value, 'PPpp');
        }
        return '';
      }
      default: {
        return '';
      }
    }
  }
}
