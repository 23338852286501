import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'subs-fixed-header',
  templateUrl: './fixed-header.component.html',
  styleUrls: ['./fixed-header.component.scss']
})
export class FixedHeaderComponent implements OnInit {
  @Input() header: string;

  constructor() { }

  ngOnInit() {
  }

}
