<div class="card" data-test-id="required-items">
  <div class="card-header">Required Items</div>
  <div class="card-block" [formGroup]="checklist">
    <div class="clr-row">
      <subs-checkbox
        class="clr-col"
        controlName="noticeOfAward"
        label="Notice Of Award Attached"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
      <subs-checkbox
        class="clr-col"
        controlName="contactInfo"
        label="Contact Info"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
      <subs-checkbox
        class="clr-col"
        controlName="collaboratorInstitutionalInfo"
        label="Collaborator Institutional Info"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
      <subs-checkbox
        class="clr-col"
        controlName="placeOfPerformance"
        label="Place Of Performance"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
    </div>
    <div class="clr-row">
      <subs-checkbox
        class="clr-col"
        controlName="scopeOfWorkAttached"
        label="Scope Of Work Attached"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
      <subs-checkbox
        class="clr-col"
        controlName="budget"
        label="Budget"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
      <subs-checkbox
        class="clr-col"
        controlName="account"
        label="Account Exists"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
      <subs-checkbox
        class="clr-col"
        controlName="fAndARateAgreementAttached"
        label="F&A Rate Agreement Attached"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
    </div>
    <div class="clr-row">
      <subs-checkbox
        class="clr-col"
        controlName="riskCategory"
        label="Risk Category"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
      <subs-checkbox
        class="clr-col"
        controlName="humanSubjects"
        label="Human Subjects"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
      <subs-checkbox
        class="clr-col"
        controlName="animalSubjects"
        label="Animal Subjects"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
      <subs-checkbox
        class="clr-col"
        controlName="dataMgmtSharingPlan"
        label="Data Management Sharing Plan Requirement"
        labelLength="8"
        disabled
      >
      </subs-checkbox>
    </div>
  </div>
</div>
