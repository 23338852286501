import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ShortcutLink, ShortcutLinksClient } from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { CompleteFormValidator } from 'src/app/validators/complete-form-validator/complete-form-validator';
import { IsRequiredValidator } from 'src/app/validators/is-required-validator/is-required-validator';
import { OrderNumberValidator } from 'src/app/validators/order-number-validator/order-number-validator';

@Component({
  selector: 'subs-shortcut-links',
  templateUrl: './shortcut-links.component.html',
  styleUrls: ['./shortcut-links.component.scss'],
  providers: [FeedbackService],
})
export class ShortcutLinksComponent implements OnInit {
  submitState$ = this.feedbackService.submitState;
  showSavedModal = false;
  shortcutLinks$: Observable<ShortcutLink[]>;
  indexToDelete = -1;
  deleteOpened = false;
  isSaved: boolean = true;

  invalidForm: boolean = false;
  errorMessage: string;

  constructor(
    private shortcutService: ShortcutLinksClient,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit(): void {
    this.shortcutService.get().subscribe(val => {
      this.shortcutLinks$ = of(val);
    });
  }

  updateShortcutLink(
    shortcutIdx: number,
    changedField: string,
    changedValue: string,
  ) {
    if (changedField === 'shortcutLink') {
      this.updateShortcutLinkUrl(changedValue.toString(), shortcutIdx);
    } else if (changedField === 'label') {
      this.updateLabel(changedValue, shortcutIdx);
    } else if (changedField === 'orderNumber') {
      this.updateOrderNumber(parseInt(changedValue), shortcutIdx);
    }
  }
  updateLabel(newLabel: string, shortcutIdx: number) {
    this.shortcutLinks$.subscribe(shortcutLinkArr => {
      shortcutLinkArr.forEach((shortcut, idx) => {
        if (idx === shortcutIdx) {
          shortcut.label = newLabel;
        }
      });

      const isValid = IsRequiredValidator.isRequired(
        shortcutLinkArr.map(x => x.label),
      );
      this.errorMessage = isValid.errorMessage;
      this.invalidForm = isValid.invalidForm;
    });
  }
  updateShortcutLinkUrl(shortcutLink: string, shortcutIdx: number) {
    this.shortcutLinks$.subscribe(shortcutLinkArr => {
      shortcutLinkArr.forEach((shortcut, idx) => {
        if (idx === shortcutIdx) {
          shortcut.shortcutLinkUrl = shortcutLink;
        }
      });
      const isValid = IsRequiredValidator.isRequired(
        shortcutLinkArr.map(x => x.shortcutLinkUrl),
      );
      this.errorMessage = isValid.errorMessage;
      this.invalidForm = isValid.invalidForm;

      if (shortcutLink && !shortcutLink.includes('https://')) {
        this.errorMessage = 'Invalid Link Format';
        this.invalidForm = true;
      } else {
        this.errorMessage = 'All Inputs are Required';
        this.invalidForm = false;
      }
    });
  }

  updateOrderNumber(orderNumber: number, shortcutIdx: number) {
    this.shortcutLinks$.subscribe(shortcutLinkArr => {
      shortcutLinkArr.forEach((shortcut, idx) => {
        if (idx === shortcutIdx) {
          shortcut.orderNumber = orderNumber;
        }
      });

      const isValid = OrderNumberValidator.validateOrderNumbers(
        shortcutLinkArr.map(x => x.orderNumber),
      );
      this.errorMessage = isValid.errorMessage;
      this.invalidForm = isValid.invalidForm;
    });
  }

  onAdd() {
    this.invalidForm = true;
    this.errorMessage = 'All Inputs are Required';
    const newShortCutLink: ShortcutLink = {
      id: 0,
      label: '',
      shortcutLinkUrl: '',
      orderNumber: null,
    };
    this.shortcutLinks$.subscribe(val => {
      val.push(newShortCutLink);
    });
  }

  deleteContact(idx: number) {
    this.indexToDelete = idx;
    this.deleteOpened = true;
  }

  cancelDeleteConfirmation() {
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.shortcutLinks$.subscribe(val => {
      val.splice(this.indexToDelete, 1);
    });
    this.indexToDelete = -1;
    this.deleteOpened = false;
    this.save();
  }

  save() {
    this.isSaved = false;
    this.feedbackService.beginLoading();
    this.shortcutLinks$
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(val => {
        const tempShortcutLinkArr: ShortcutLink[] = [];

        val.forEach(shortcut => {
          const newShortcut: ShortcutLink = {
            id: shortcut.id,
            label: shortcut.label,
            shortcutLinkUrl: shortcut.shortcutLinkUrl,
            orderNumber: shortcut.orderNumber,
          };
          tempShortcutLinkArr.push(newShortcut);
        });

        const formCheck = CompleteFormValidator.isCompleteForm(
          'shortcut',
          tempShortcutLinkArr,
        );
        if (formCheck.invalidForm === false) {
          this.shortcutService
            .updateShortcutLinks(tempShortcutLinkArr)
            .subscribe(
              next => {
                this.showSavedModal = true;
                this.isSaved = true;
                this.feedbackService.displaySuccess();
                // auto-close for 'Saved Modal'
                setTimeout(() => {
                  this.showSavedModal = false;
                }, 1500);
              },
              error => console.error('error updating contact list', error),
            );
        } else {
          this.invalidForm = formCheck.invalidForm;
          this.errorMessage = formCheck.errorMessage;
        }
      });
    this.isSaved = true;
  }
}
