<div class="card" [formGroup]="form">
  <div class="card-header">
    {{ title }}
  </div>

  <div class="card-block">
    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        controlName="firstName"
        label="* First Name"
      >
      </subs-input>

      <subs-input
        class="clr-col-5"
        controlName="middleName"
        labelStyle="width: 4rem;"
      >
      </subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        controlName="lastName"
        label="* Last Name"
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="nameSuffix"
        labelStyle="width: 4rem;"
      >
      </subs-input>
    </div>

    <div class="clr-row">
      <subs-input class="clr-col-7" controlName="phone"></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="fax"
        labelStyle="width: 4rem;"
      ></subs-input>
    </div>

    <subs-input controlName="emailAddress" label="* Email Address"></subs-input>

    <subs-address
      [form]="form.controls.address"
      [showCountryRequiredIndicator]="true"
    ></subs-address>
  </div>
</div>
