<div class="card" [formGroup]="form">
  <div class="card-header">
    Departmental Administrative Point of Contact of Collaborating Institution
  </div>
  <div class="card-block">
    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        label="* First name"
        controlName="firstName"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        label="* Last name"
        controlName="lastName"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        label="* Email address"
        controlName="emailAddress"
      ></subs-input>
    </div>
  </div>
</div>
