import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
} from 'rxjs/operators';

import { AlertOptions } from '../alerts/alert-options';
import { DuplicateInstitutionError } from '../alerts/institution-alerts/institution-alert-options';
import {
  Institution,
  InstitutionsClient,
  LookupItem,
  UtilityTableKeyEnum,
} from '../api.service';
import { AuthService } from '../auth.service';
import { FeedbackService } from '../form-layout/feedback.service';
import { MainLayoutService } from '../main-layout/main-layout.service';
import { FeatureService } from '../utilities/feature.service';
import { LookupItemsService } from '../utilities/lookup-items.service';

interface RequiredControls {
  controlName: string | undefined;
  validators: ValidatorFn[] | undefined;
}

@Component({
  selector: 'subs-institution-details',
  templateUrl: './institution-details.component.html',
  providers: [FeedbackService],
})
export class InstitutionDetailsComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private institutionsClient: InstitutionsClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private feedbackService: FeedbackService,
    private mainLayoutService: MainLayoutService,
    public lookupItemService: LookupItemsService,
    private authService: AuthService,
    private featureService: FeatureService,
  ) {
    this.mainLayoutService.toggleEditInstitutionSidebarVisibility(false);
    this.mainLayoutService.toggleAddInstitutionSidebarVisibility(false);
    this.saveConfirmationForm.controls.confirmation.valueChanges.subscribe(
      (result) => {
        this.saveConfirmationSubject.next(result);
      },
    );
  }
  header = 'Institution Details';
  ueiRequiredIndicator = '';
  dunsRequiredIndicator = '';
  finQues1RequiredIndicator = '';
  finQues2RequiredIndicator = '';
  userRole: string;
  status = 'IP';
  institutionTypes: LookupItem[] = [];
  subawardId: string;
  activeFeatureUei = false;
  activeFeatureDuns = true;
  returnToSearch = false;
  formSubmitted = false;
  alertSubject$ = this.feedbackService.alerts;
  alertDuplicatesSubject$ = new BehaviorSubject<
    Array<DuplicateInstitutionError>
  >(null);
  submitState$ = this.feedbackService.submitState;
  showUnsavedChangesModal = false;
  saveConfirmationSubject = new Subject<boolean>();
  saveConfirmationForm = this.fb.group({
    confirmation: [],
  });

  // dynamic Validators - on status 'Complete'
  requiredControlsMainForm: RequiredControls[] = [
    { controlName: 'institutionType', validators: [] },
    { controlName: 'riskCategory', validators: [] },
    { controlName: 'samGov', validators: [] },
    { controlName: 'foreign', validators: [] },
    { controlName: 'top5Q1', validators: [] },
    { controlName: 'top5Q2', validators: [] },
  ];

  requiredUei: RequiredControls = {
    controlName: 'uniqueEntityId',
    validators: [Validators.pattern('^[a-zA-Z0-9]*$')],
  };

  requiredDuns: RequiredControls = {
    controlName: 'dunsNumber',
    validators: [Validators.pattern('^[a-zA-Z0-9]*$')],
  };

  requiredControlsContactForm: RequiredControls[] = [
    { controlName: 'firstName', validators: [] },
    { controlName: 'lastName', validators: [] },
    { controlName: 'email', validators: [] },
  ];

  requiredControlsAddressForm: RequiredControls[] = [
    { controlName: 'addressLine1', validators: [] },
    { controlName: 'city', validators: [] },
    {
      controlName: 'countryCode',
      validators: [Validators.pattern('^(?!\\s*$).+')],
    },
  ];

  requiredControlsBasedOnCountry: RequiredControls[] = [
    { controlName: 'congressionalDistrict', validators: [] },
  ];

  institutionForm = this.fb.group({
    id: [],
    institutionName: [null, Validators.required],
    status: ['IP'],
    institutionType: [null],
    dunsNumber: [null],
    uniqueEntityId: [null],
    supplierId: [null, Validators.pattern('^[a-zA-Z0-9]*$')],
    congressionalDistrict: [null],
    samGov: [null],
    foreign: [null],
    isDeleted: [null],
    primaryInstitution: [true],
    top5Q1: [null],
    top5Q2: [null],
    parentDuns: [null, Validators.pattern('^[a-zA-Z0-9]*$')],
    parentCongressionalDistrict: [],
    employerIdNumber: [null],
    riskCategory: [null],
    centralOfficeEmail: [],
    comments: [],
    samGovFileId: [],
    samGovFileName: [],
    institutionAddress: this.createAddressGroup(),
    signatory: this.createContactGroup(),
    adminPointOfContact: this.createContactGroup(),
    financeContact: this.createFinanceContactGroup(),
    auditLogs: [],
    institutionOfficers: [],
  });

  submitState = this.feedbackService.submitState;
  canDelete = true;
  canSearch = true;
  showDeleteModal = false;
  showConversionModal = false;

  alertOptions: AlertOptions = {
    message: '',
    canBeClosed: true,
    type: 'danger',
  };

  ngOnInit() {
    this.userRole = this.authService.currentUser.role;
    this.lookupItemService
      .lookupItems(UtilityTableKeyEnum.InstitutionTypeLookup)
      .subscribe((value) => {
        this.institutionTypes = value;
      });
    this.featureService.featureEnabled('UEI').subscribe((feature) => {
      if (feature && feature.featureName) {
        if (feature.featureName.toLowerCase() === 'uei') {
          this.activeFeatureUei = feature.featureEnabled;
        }
      }
      if (this.activeFeatureUei) {
        this.ueiRequiredIndicator = '* ';
        this.requiredControlsMainForm.push(this.requiredUei);
      } else {
        this.ueiRequiredIndicator = '';
        this.requiredControlsMainForm = this.requiredControlsMainForm.filter(
          (x) => x.controlName !== 'uniqueEntityId',
        );
      }
    });

    this.featureService.featureEnabled('DUNS').subscribe((feature) => {
      if (feature && feature.featureName) {
        if (feature.featureName.toLowerCase() === 'duns') {
          this.activeFeatureDuns = feature.featureEnabled;
        }
      }
      if (this.activeFeatureDuns) {
        this.dunsRequiredIndicator = '* ';
        this.requiredControlsMainForm.push(this.requiredDuns);
      } else {
        this.dunsRequiredIndicator = '';
        this.requiredControlsMainForm = this.requiredControlsMainForm.filter(
          (x) => x.controlName !== 'dunsNumber',
        );
      }
    });

    if (this.institutionForm.controls.status) {
      this.institutionForm.controls.status.valueChanges.subscribe((value) => {
        this.status = value;
        if (value === 'C') {
          this.setRequiredControlsMainForm(this.requiredControlsMainForm);
          this.setRequiredControlsAddressForm(this.requiredControlsAddressForm);
          this.setRequiredControlsContactForm(this.requiredControlsContactForm);
          this.setRequiredIndicators();
        } else {
          this.resetRequiredControlsMainForm(this.requiredControlsMainForm);
          this.resetRequiredControlsAddressForm(
            this.requiredControlsAddressForm,
          );
          this.resetRequiredControlsContactForm(
            this.requiredControlsContactForm,
          );
          this.resetRequiredIndicators();
        }
      });
    }

    if (this.institutionForm.controls.foreign) {
      this.institutionForm.controls.foreign.valueChanges.subscribe((value) => {
        if (!value && this.status.toLowerCase() === 'c') {
          this.setRequiredControlsMainForm(this.requiredControlsBasedOnCountry);
        }
        if (value || this.status.toLowerCase() === 'ip') {
          this.resetRequiredControlsMainForm(
            this.requiredControlsBasedOnCountry,
          );
        }
      });
    }

    combineLatest(
      this.activatedRoute.params.pipe(distinctUntilChanged()),
      this.activatedRoute.queryParams.pipe(distinctUntilChanged()),
    ) // distinctUntilChanged to avoid triggering subscription twice if routeParams and queryParams changed at he same time
      .pipe(
        map(([params, queryParams]) => ({ params, queryParams })),
        mergeMap(({ params, queryParams }) => {
          const institutionId = params.id;
          this.subawardId = queryParams.subawardid;
          const observables: Observable<any>[] = [];

          if (institutionId) {
            this.mainLayoutService.setInstitutionId(institutionId);
            this.mainLayoutService.toggleEditInstitutionSidebarVisibility(true);
            this.mainLayoutService.toggleAddInstitutionSidebarVisibility(false);
            observables.push(this.institutionsClient.get(institutionId));
          } else {
            this.mainLayoutService.setInstitutionId(-1);
            this.mainLayoutService.toggleEditInstitutionSidebarVisibility(
              false,
            );
            this.mainLayoutService.toggleAddInstitutionSidebarVisibility(true);
            this.canDelete = false;
            observables.push(of(null));
          }
          return forkJoin(observables);
        }),
      )
      .subscribe(([institution]) => {
        this.setValueInstitutionForm(institution);
        this.institutionForm.statusChanges.subscribe((val) =>
          this.onFormValidation(val),
        );
        this.institutionForm.valueChanges.subscribe((value: any) => {
          if (this.institutionForm.dirty) {
            this.saveConfirmationForm.controls.confirmation.setValue(false);
          } else {
            this.saveConfirmationForm.controls.confirmation.setValue(true);
          }
        });
      });
  }

  samGovUploaded(samGovFile: any) {
    this.institutionForm.controls.samGovFileId.patchValue(samGovFile.fileId);
    this.institutionForm.controls.samGovFileName.patchValue(
      samGovFile.file.name,
    );
    this.save(this.institutionForm.controls.id.value);
  }

  private setRequiredIndicators() {
    this.finQues1RequiredIndicator = '* ';
    this.finQues2RequiredIndicator = '* ';
  }

  private resetRequiredIndicators() {
    this.finQues1RequiredIndicator = '';
    this.finQues2RequiredIndicator = '';
  }

  private setRequiredControlsMainForm(requiredControls: RequiredControls[]) {
    requiredControls.forEach((control) => {
      control.validators = control.validators.concat(Validators.required);

      this.institutionForm.controls[control.controlName].setValidators(
        control.validators,
      );
      this.institutionForm.controls[
        control.controlName
      ].updateValueAndValidity();
    });
    this.institutionForm.updateValueAndValidity();
  }

  private setRequiredControlsAddressForm(requiredControls: RequiredControls[]) {
    requiredControls.forEach((control) => {
      control.validators = control.validators.concat(Validators.required);

      this.institutionForm.controls.institutionAddress
        .get(control.controlName)
        .setValidators(control.validators);

      const addressControlName = 'address.' + control.controlName;

      this.institutionForm.controls.signatory
        .get(addressControlName)
        .setValidators(control.validators);

      this.institutionForm.controls.adminPointOfContact
        .get(addressControlName)
        .setValidators(control.validators);

      this.institutionForm.controls.financeContact
        .get(addressControlName)
        .setValidators(control.validators);
    });
    this.institutionForm.updateValueAndValidity();
  }

  private setRequiredControlsContactForm(requiredControls: RequiredControls[]) {
    requiredControls.forEach((control) => {
      control.validators = control.validators.concat(Validators.required);

      this.institutionForm.controls.signatory
        .get(control.controlName)
        .setValidators(control.validators);

      this.institutionForm.controls.adminPointOfContact
        .get(control.controlName)
        .setValidators(control.validators);

      this.institutionForm.controls.financeContact
        .get(control.controlName)
        .setValidators(control.validators);
    });

    this.institutionForm.updateValueAndValidity();
  }

  private resetRequiredControlsMainForm(requiredControls: RequiredControls[]) {
    requiredControls.forEach((control) => {
      control.validators = control.validators.filter(
        (val) => val.name !== 'required',
      );
      this.institutionForm.controls[control.controlName].setValidators(
        control.validators,
      );
      this.clearMainFormControlError(control.controlName);
    });
    this.institutionForm.updateValueAndValidity();
  }

  private resetRequiredControlsAddressForm(
    requiredControls: RequiredControls[],
  ) {
    requiredControls.forEach((control) => {
      control.validators = control.validators.filter(
        (val) => val.name !== 'required',
      );

      this.institutionForm.controls.institutionAddress
        .get(control.controlName)
        .setValidators(control.validators);

      const addressControlName = 'address.' + control.controlName;

      this.institutionForm.controls.signatory
        .get(addressControlName)
        .setValidators(control.validators);

      this.institutionForm.controls.adminPointOfContact
        .get(addressControlName)
        .setValidators(control.validators);

      this.institutionForm.controls.financeContact
        .get(addressControlName)
        .setValidators(control.validators);

      this.clearAddressFormControlError(control.controlName);
    });
    this.institutionForm.updateValueAndValidity();
  }

  private resetRequiredControlsContactForm(
    requiredControls: RequiredControls[],
  ) {
    requiredControls.forEach((control) => {
      control.validators = control.validators.filter(
        (val) => val.name !== 'required',
      );

      this.institutionForm.controls.signatory
        .get(control.controlName)
        .setValidators(control.validators);

      this.institutionForm.controls.adminPointOfContact
        .get(control.controlName)
        .setValidators(control.validators);

      this.institutionForm.controls.financeContact
        .get(control.controlName)
        .setValidators(control.validators);

      this.clearContactFormControlError(control.controlName);
    });
    this.institutionForm.updateValueAndValidity();
  }

  private clearMainFormControlError(value: string) {
    this.institutionForm.controls[value].updateValueAndValidity();
    this.institutionForm.controls[value].setErrors(null);
  }

  private clearContactFormControlError(value: string) {
    this.institutionForm.controls.signatory.get(value).setErrors(null);
    this.institutionForm.controls.signatory.get(value).updateValueAndValidity();

    this.institutionForm.controls.adminPointOfContact
      .get(value)
      .setErrors(null);
    this.institutionForm.controls.adminPointOfContact
      .get(value)
      .updateValueAndValidity();

    this.institutionForm.controls.financeContact.get(value).setErrors(null);
    this.institutionForm.controls.financeContact
      .get(value)
      .updateValueAndValidity();
  }

  private clearAddressFormControlError(value: string) {
    this.institutionForm.controls.institutionAddress.updateValueAndValidity();
    this.institutionForm.controls.institutionAddress.get(value).setErrors(null);

    const addressControlName = 'address.' + value;

    this.institutionForm.controls.signatory.updateValueAndValidity();
    this.institutionForm.controls.signatory
      .get(addressControlName)
      .setErrors(null);
    this.institutionForm.controls.signatory
      .get(addressControlName)
      .setErrors(null);
    this.institutionForm.controls.signatory
      .get(addressControlName)
      .setErrors(null);

    this.institutionForm.controls.adminPointOfContact.updateValueAndValidity();
    this.institutionForm.controls.adminPointOfContact
      .get(addressControlName)
      .setErrors(null);
    this.institutionForm.controls.adminPointOfContact
      .get(addressControlName)
      .setErrors(null);
    this.institutionForm.controls.adminPointOfContact
      .get(addressControlName)
      .setErrors(null);

    this.institutionForm.controls.financeContact.updateValueAndValidity();
    this.institutionForm.controls.financeContact
      .get(addressControlName)
      .setErrors(null);
    this.institutionForm.controls.financeContact
      .get(addressControlName)
      .setErrors(null);
    this.institutionForm.controls.financeContact
      .get(addressControlName)
      .setErrors(null);
  }

  onFormValidation(validity: string) {
    switch (validity) {
      case 'VALID':
        this.feedbackService.clearAlert();
        break;
      case 'INVALID':
        // covered by individual field error messages
        break;
    }
  }

  private setValueInstitutionForm(result: Institution) {
    if (result != null) {
      this.institutionForm.setValue(result);
    }
  }

  closeModal(val: boolean) {
    this.showDeleteModal = val;
  }

  closeConversion(val: boolean) {
    this.showConversionModal = false;
  }

  save(id?: number) {
    this.formSubmitted = true;
    if (this.institutionForm.invalid) {
      this.institutionForm.markAsTouched();
      if (this.institutionForm.controls.status) {
        if (this.institutionForm.controls.status.value.toLowerCase() === 'c') {
          this.feedbackService.alert(
            'Please complete the highlighted fields on the Institution record before setting the status to "Completed".',
          );
        } else {
          this.feedbackService.alert(
            'Please fix all field errors before saving the form.',
          );
        }
      }

      this.validateAllFormFields(this.institutionForm);

      // check state and zip code lists. This will display any existing error using feedbackService
      this.checkStatesAndZips();
      return;
    } else {
      this.feedbackService.clearAlert();
    }

    this.feedbackService.beginLoading();

    const value = this.institutionForm.value;

    if (id) {
      this.institutionsClient
        .update(id, value)
        .pipe(
          catchError((error) => {
            if (this.checkIfArrayOfErrors(error.error)) {
              const alerts: DuplicateInstitutionError[] = JSON.parse(
                error.error,
              );
              this.alertDuplicatesSubject$.next(alerts);
              this.duplicateInstitutionResponse();
            } else {
              this.alertDuplicatesSubject$.next(null);
              return throwError(error);
            }
          }),
          this.feedbackService.provideFeedback(),
        )
        .subscribe((result) => {
          this.successfulInstitutionResponse(result);
        });
    } else {
      this.institutionsClient
        .create(value)
        .pipe(
          catchError((error) => {
            if (this.checkIfArrayOfErrors(error.error)) {
              const alerts: DuplicateInstitutionError[] = JSON.parse(
                error.error,
              );
              this.alertDuplicatesSubject$.next(alerts);
              this.duplicateInstitutionResponse();
            } else {
              this.alertDuplicatesSubject$.next(null);
              return throwError(error);
            }
          }),
          this.feedbackService.provideFeedback(),
        )
        .subscribe((result) => {
          this.formSubmitted = false;
          this.successfulInstitutionResponse(result);
        });
    }
  }

  private checkStatesAndZips(): boolean {
    if (this.validateStates(this.institutionForm.get('institutionAddress'))) {
      return true; // has errors
    }
    if (this.validateStates(this.institutionForm.get('signatory.address'))) {
      return true; // has errors
    }
    if (
      this.validateStates(
        this.institutionForm.get('adminPointOfContact.address'),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateStates(this.institutionForm.get('financeContact.address'))
    ) {
      return true; // has errors
    }

    if (this.validateZipCodes(this.institutionForm.get('institutionAddress'))) {
      return true; // has errors
    }
    if (this.validateZipCodes(this.institutionForm.get('signatory.address'))) {
      return true; // has errors
    }
    if (
      this.validateZipCodes(this.institutionForm.get('financeContact.address'))
    ) {
      return true; // has errors
    }
    if (
      this.validateZipCodes(
        this.institutionForm.get('adminPointOfContact.address'),
      )
    ) {
      return true; // has errors
    }
    return false;
  }

  private validateStates(addressComponent): boolean {
    const countryList = addressComponent.get('countryCode');
    const stateList = addressComponent.get('state');

    if (stateList.hasError('required')) {
      const country = countryList.value;
      const provinceOrState = country === 'CAN' ? 'Province' : 'State';
      this.feedbackService.alert(
        'Please select a ' +
          provinceOrState +
          ' for selected country code: ' +
          country +
          ' before saving the form.',
      );
      return true;
    } else {
      return false;
    }
  }

  private validateZipCodes(addressComponent): boolean {
    const countryList = addressComponent.get('countryCode');
    const zipList = addressComponent.get('zip');

    if (zipList.hasError('required')) {
      const country = countryList.value;
      this.feedbackService.alert(
        'Please enter a zip code for selected country code: ' +
          country +
          ' before saving the form.',
      );
      return true;
    } else {
      return false;
    }
  }

  private checkIfArrayOfErrors(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  successfulInstitutionResponse(result: Institution) {
    this.institutionForm.markAsPristine();
    this.saveConfirmationForm.controls.confirmation.setValue(true);
    if (!this.returnToSearch) {
      this.router.navigateByUrl(this.setUrl(result));
    }
  }

  duplicateInstitutionResponse() {
    this.institutionForm.markAsPristine();
    this.feedbackService.alertDuplicates();
  }

  private setUrl(result: Institution) {
    return this.subawardId
      ? `subaward/${this.subawardId}?institutionid=${result.id}`
      : `institution/${result.id}`;
  }

  convertionSave(institution: Institution) {
    this.institutionForm.reset({});
    this.setValueInstitutionForm(institution);
    this.save(institution.id);
  }

  private createContactGroup(): UntypedFormGroup {
    return this.fb.group({
      title: [''],
      firstName: [''],
      middleName: [''],
      lastName: [''],
      nameSuffix: [''],
      phone: [''],
      fax: [''],
      email: [''],
      address: this.createAddressGroup(),
    });
  }

  private createFinanceContactGroup(): UntypedFormGroup {
    const financeGroup = this.createContactGroup();
    financeGroup.addControl('invoiceEmail', new UntypedFormControl(''));
    return financeGroup;
  }

  private createAddressGroup(): UntypedFormGroup {
    return this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      addressLine4: [''],
      city: [''],
      state: [''],
      zip: [''],
      countryCode: [''],
      countryName: [''],
    });
  }

  deptAdminUser() {
    return this.authService.hasPermission('DepartmentAdminGlobalAccess');
  }

  viewOnlyUser() {
    return this.authService.hasPermission('ReadOnlyGlobal');
  }

  redirectToSearchPage() {
    this.returnToSearch = true;
    this.router.navigate(['/find-institution'], {
      queryParams: { query: 'true' },
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.institutionForm.dirty) {
      this.mainLayoutService.toggleEditInstitutionSidebarVisibility(false);
      this.mainLayoutService.toggleAddInstitutionSidebarVisibility(false);
      return true;
    }
    this.showUnsavedChangesModal = true;
    return this.saveConfirmationSubject;
  }

  confirmSave() {
    this.save(this.institutionForm.controls.id.value);
    this.showUnsavedChangesModal = false;
  }

  discardChanges() {
    this.showUnsavedChangesModal = false;
    this.saveConfirmationForm.controls.confirmation.setValue(true);
  }

  cancelNavigation() {
    this.saveConfirmationForm.controls.confirmation.setValue(false);
    this.showUnsavedChangesModal = false;
  }

  private validateAllFormFields(
    formGroup: UntypedFormGroup | UntypedFormArray,
  ) {
    const keysArray =
      formGroup instanceof UntypedFormGroup
        ? Object.values(formGroup.controls)
        : formGroup.controls;
    keysArray.forEach((control) => {
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: false });
        control.updateValueAndValidity();
      } else if (
        control instanceof UntypedFormGroup ||
        control instanceof UntypedFormArray
      ) {
        this.validateAllFormFields(control);
      }
    });
    return formGroup.invalid;
  }

  isEditFromSubaward(): boolean {
    return !!this.subawardId;
  }

  cancelEdit() {
    this.router.navigateByUrl(`/subaward/${this.subawardId}`);
  }
}
