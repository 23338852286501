import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { CanDeactivateGuard } from './guard/can-deactivate.guard';
import { InstitutionDetailsComponent } from './institution-details/institution-details.component';
import { InstitutionSearchComponent } from './institution-search/institution-search.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { CentralOfficeWorklistComponent } from './reports/central-office-worklist/central-office-worklist.component';
import { DaWorklistComponent } from './reports/da-worklist/da-worklist.component';
import { AddProjectComponent } from './subawards/add-project/add-project.component';
import { EditProjectComponent } from './subawards/edit-project/edit-project.component';
import { SearchProjectComponent } from './subawards/search-project/search-project.component';
import { SelectAttachmentsComponent } from './subawards/select-attachments/select-attachments.component';
import { SubawardComponent } from './subawards/subaward/subaward.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AttachmentComponent } from './utilities/attachment/attachment.component';
import { MonitoringQuestionsComponent } from './utilities/monitoring-questions/monitoring-questions.component';
import { ReturnCodeComponent } from './utilities/return-code/return-code.component';
import { SearchAttachmentComponent } from './utilities/search-attachment/search-attachment.component';
import { WashuContactsComponent } from './utilities/washu-contacts/washu-contacts.component';
import { ReturnReportComponent } from './reports/return-report/return-report.component';
import { FfataReportComponent } from './reports/ffata-report/ffata-report.component';
import { SecurityComponent } from './utilities/security/security.component';
import { ContactOsrsComponent } from './contact-osrs/contact-osrs.component';
import { OutgoingActivityReportComponent } from './reports/outgoing-activity-report/outgoing-activity-report.component';
import { OutgoingSubsStatusComponent } from './reports/outgoing-subs-status/outgoing-subs-status.component';
import { CollaboratorsExecutedSubsComponent } from './reports/collaborators-executed-subs/collaborators-executed-subs.component';
import { SentDistFfataReportComponent } from './reports/sent-dist-ffata-report/sent-dist-ffata-report.component';
import { UtilityItemDetailsComponent } from './utilities/utility-item-details/utility-item-details.component';
import { AppUtilitySearchComponent } from './utilities/app-utility-search/app-utility-search.component';
import { OsrsReviewReportComponent } from './reports/osrs-review-report/osrs-review-report.component';
import { NewInstitutionReportComponent } from './reports/new-institution-report/new-institution-report.component';
import { InstitutionAuditReportComponent } from './reports/institution-audit-report/institution-audit-report.component';
import { CchRollupReportComponent } from './reports/cch-rollup-report/cch-rollup-report.component';
import { PermissionGuard } from './guard/permission.guard';
import { UserDashbaordComponent } from './main-layout/user-dashbaord/user-dashbaord.component';
import { ContactListComponent } from './utilities/contact-list/contact-list.component';
import { ShortcutLinksComponent } from './utilities/shortcut-links/shortcut-links.component';
import { TrainingComponent } from './utilities/training/training.component';
import { AnnouncementsComponent } from './utilities/announcements/announcements.component';
import { SubrecipientStatusComponent } from './utilities/subrecipient-status/subrecipient-status.component';

const routes: Routes = [
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: UserDashbaordComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: { permissions: [] },
      },
      {
        path: 'institution',
        component: InstitutionDetailsComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['AccessInstitutionAddPage'],
        },
      },
      {
        path: 'contact-list',
        component: ContactListComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessInstitutionMaintenancePage'],
        },
      },
      {
        path: 'shortcut-links',
        component: ShortcutLinksComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessInstitutionMaintenancePage'],
        },
      },
      {
        path: 'training',
        component: TrainingComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessInstitutionMaintenancePage'],
        },
      },
      {
        path: 'announcements',
        component: AnnouncementsComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessInstitutionMaintenancePage'],
        },
      },
      {
        path: 'find-institution',
        component: InstitutionSearchComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessInstitutionMaintenancePage'],
        },
      },
      {
        path: 'institution/:id',
        component: InstitutionDetailsComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['AccessInstitutionEditPage'],
        },
      },
      {
        path: 'subaward/project/:projectId',
        component: SubawardComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['SubawardCreate'],
        },
      },
      {
        path: 'subaward/changesToCurrentYear/:cloneId',
        component: SubawardComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['SubawardClone'],
        },
      },
      {
        path: 'subaward/:id',
        component: SubawardComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['SubawardRead', 'SubawardUpdate', 'SubawardDelete'],
        },
      },
      {
        path: 'subaward/:id/select-attachments',
        component: SelectAttachmentsComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['SubawardPopulateAttachment'],
        },
      },
      {
        path: 'subaward/:id/:tab',
        component: SubawardComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['SubawardRead', 'SubawardUpdate', 'SubawardDelete'],
        },
      },
      {
        path: 'project',
        component: AddProjectComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ProjectCreate'],
        },
      },
      {
        path: 'project/:id',
        component: EditProjectComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['ProjectRead', 'ProjectUpdate', 'ProjectDelete'],
        },
      },
      {
        path: 'find-subawards',
        component: SearchProjectComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ProjectRead'],
        },
      },
      {
        path: 'find-attachment',
        component: SearchAttachmentComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['AccessAttachmentFindPage'],
        },
      },
      {
        path: 'attachment',
        component: AttachmentComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['AccessAttachmentAddPage'],
        },
      },
      {
        path: 'attachment/:id',
        component: AttachmentComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['AccessAttachmentAddPage'],
        },
      },
      {
        path: 'return-code',
        component: ReturnCodeComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessSubReturnCodesPage'],
        },
      },
      {
        path: 'monitoring-questions',
        component: MonitoringQuestionsComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessSubMonitoringQuestionsPage'],
        },
      },
      {
        path: 'sub-status',
        component: SubrecipientStatusComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['UtilitySubStatusRead'],
        },
      },
      {
        path: 'da-worklist',
        component: DaWorklistComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'co-worklist',
        component: CentralOfficeWorklistComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'washington-university-contacts',
        component: WashuContactsComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessWashUContactsPage'],
        },
      },
      {
        path: 'cch-rollup-report',
        component: CchRollupReportComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'osrs-review-report',
        component: OsrsReviewReportComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'new-institution-report',
        component: NewInstitutionReportComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'institution-audit-report',
        component: InstitutionAuditReportComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'return-report',
        component: ReturnReportComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'subs-executed',
        component: SentDistFfataReportComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'collaborators-executed-subs',
        component: CollaboratorsExecutedSubsComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'ffata-report',
        component: FfataReportComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'outgoing-subs-status-report',
        component: OutgoingSubsStatusComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'roles-security',
        component: SecurityComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessSecurityPage'],
        },
      },
      {
        path: 'outgoing-activity-report',
        component: OutgoingActivityReportComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['ReportRead'],
        },
      },
      {
        path: 'find-app-utility',
        component: AppUtilitySearchComponent,
        canActivate: [PermissionGuard],
        data: {
          permissions: ['AccessUtilityMaintenancePage'],
        },
      },
      {
        path: 'app-utility/item',
        component: UtilityItemDetailsComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['AccessUtilityItemAddPage'],
        },
      },
      {
        path: 'app-utility/item/:id',
        component: UtilityItemDetailsComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permissions: ['AccessUtilityItemEditPage'],
        },
      },
    ],
  },
  {
    path: 'contact-osrs',
    component: ContactOsrsComponent,
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
