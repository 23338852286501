import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Announcement, AnnouncementsClient } from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { CompleteFormValidator } from 'src/app/validators/complete-form-validator/complete-form-validator';
import { IsRequiredValidator } from 'src/app/validators/is-required-validator/is-required-validator';
import { OrderNumberValidator } from 'src/app/validators/order-number-validator/order-number-validator';

@Component({
  selector: 'subs-announcements',
  templateUrl: './announcements.component.html',
  providers: [FeedbackService],
})
export class AnnouncementsComponent implements OnInit {
  submitState$ = this.feedbackService.submitState;
  showSavedModal = false;
  announcements$: Observable<Announcement[]>;
  indexToDelete = -1;
  deleteOpened = false;
  isSaved = true;

  invalidForm = false;
  errorMessage: string;

  constructor(
    private announcementsService: AnnouncementsClient,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit(): void {
    this.announcementsService.get().subscribe(val => {
      this.announcements$ = of(val.sort((a, b) => (a.id > b.id ? 1 : -1)));
    });
  }

  updateAnnouncement(
    shortcutIdx: number,
    changedField: string,
    changedValue: string,
  ) {
    if (changedField === 'announcement') {
      this.updateAnnouncements(changedValue, shortcutIdx);
    } else if (changedField === 'announcementLink') {
      this.updateAnnouncementLink(changedValue, shortcutIdx);
    } else if (changedField === 'date') {
      this.updateDate(changedValue, shortcutIdx);
    } else if (changedField === 'orderNumber') {
      this.updateOrderNumber(parseInt(changedValue), shortcutIdx);
    } else if (changedField === 'status') {
      this.updateStatus(changedValue, shortcutIdx);
    }
  }
  updateAnnouncements(announcement: string, announcementIdx: number) {
    this.announcements$.subscribe(announcementArr => {
      announcementArr.forEach((newAnnouncement, idx) => {
        if (idx === announcementIdx) {
          newAnnouncement.announcementText = announcement;
        }
      });

      const isValid = IsRequiredValidator.isRequired(
        announcementArr.map(x => x.announcementText),
      );
      this.errorMessage = isValid.errorMessage;
      this.invalidForm = isValid.invalidForm;
    });
  }
  updateAnnouncementLink(announcementLink: string, announcementIdx: number) {
    this.announcements$.subscribe(announcementArr => {
      announcementArr.forEach((newAnnouncement, idx) => {
        if (idx === announcementIdx) {
          newAnnouncement.announcementLink =
            announcementLink !== '' ? announcementLink : null;
        }
        if (announcementLink && !announcementLink.includes('https://')) {
          this.errorMessage = 'Invalid Link Format';
          this.invalidForm = true;
        } else {
          this.errorMessage = 'All Inputs are Required';
          this.invalidForm = false;
        }
      });
    });
  }
  updateStatus(status: string, announcementIdx: number) {
    if (status === 'Active' || status === 'Inactive') {
      this.announcements$.subscribe(announcementArr => {
        announcementArr.forEach((newAnnouncement, idx) => {
          if (idx === announcementIdx) {
            newAnnouncement.status = status;
          }
        });
      });
    }
  }
  updateDate(date: string, announcementIdx: number) {
    this.announcements$.subscribe(announcementArr => {
      announcementArr.forEach((newAnnouncement, idx) => {
        if (idx === announcementIdx) {
          // const announcementDate = new Date(date[3].replace(/-/g, '/'));
          const announcementDate = date
            .replace(/-/, '/') // replace 1st "-" with "/"
            .replace(/-/, '/'); // replace 2nd "-" with "/"
          newAnnouncement.date = new Date(announcementDate);
        }
      });
    });
  }
  updateOrderNumber(orderNumber: number, announcementIdx: number) {
    this.announcements$.subscribe(announcementArr => {
      announcementArr.forEach((newAnnouncement, idx) => {
        if (idx === announcementIdx) {
          newAnnouncement.orderNumber = orderNumber;
        }
      });

      const isValid = OrderNumberValidator.validateOrderNumbers(
        announcementArr.map(x => x.orderNumber),
      );
      this.errorMessage = isValid.errorMessage;
      this.invalidForm = isValid.invalidForm;
    });
  }

  onAdd() {
    this.invalidForm = true;
    this.errorMessage = 'All Inputs are Required';

    const newAnnouncement: Announcement = {
      id: 0,
      date: new Date(),
      announcementText: '',
      announcementLink: null,
      orderNumber: null,
      status: 'Active',
    };
    this.announcements$.subscribe(val => {
      val.push(newAnnouncement);
    });
  }

  deleteContact(idx: number) {
    this.indexToDelete = idx;
    this.deleteOpened = true;
  }

  cancelDeleteConfirmation() {
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.announcements$.subscribe(val => {
      val.splice(this.indexToDelete, 1);
    });
    this.indexToDelete = -1;
    this.deleteOpened = false;
    this.save();
  }

  save() {
    this.isSaved = false;
    this.feedbackService.beginLoading();
    this.announcements$
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(val => {
        const tempAnnouncementArr: Announcement[] = [];
        val.forEach(announcement => {
          const newAnnouncement: Announcement = {
            id: announcement.id,
            date: announcement.date,
            announcementText: announcement.announcementText,
            announcementLink: announcement.announcementLink,
            orderNumber: announcement.orderNumber,
            status: announcement.status,
          };
          tempAnnouncementArr.push(newAnnouncement);
        });

        const formCheck = CompleteFormValidator.isCompleteForm(
          'announcement',
          tempAnnouncementArr,
        );
        if (formCheck.invalidForm === false) {
          this.announcementsService
            .updateAnnouncements(tempAnnouncementArr)
            .subscribe(
              next => {
                this.showSavedModal = true;
                this.isSaved = true;
                this.feedbackService.displaySuccess();
                // auto-close for 'Saved Modal'
                setTimeout(() => {
                  this.showSavedModal = false;
                }, 1500);
              },
              error => console.error('error updating contact list', error),
            );
        } else {
          this.invalidForm = formCheck.invalidForm;
          this.errorMessage = formCheck.errorMessage;
        }
      });
    this.isSaved = true;
  }
}
