<div class="form-container">
  <form
    clrForm
    [formGroup]="form"
    style="
      flex-grow: 1;
      overflow-y: auto;
      padding-right: 24px;
    "
  >
    <div class="card">
      <div class="card-header">Sub Recipient Entry for {{ projectTitle }}</div>
    </div>
    <subs-checklist [checklist]="checklist"> </subs-checklist>

    <subs-washu-information
      data-test-id="washU-information"
      [form]="form.controls.washUInformation"
      [showSubawardId]="false"
      [showComplianceChecks]="false"
      [showGenerateAgreementNumber]="false"
      [validatingGrantWorkTag]="validatingGrantWorkTag"
      (grantWorkTagEntered)="grantWorkTagEntered.emit($event)"
      (poCoRequestIdEntered)="poCoRequestIdEntered.emit($event)"
    ></subs-washu-information>

    <subs-collaborator
      [displayAllComponents]="true"
      [isModifiedAgreement]="isModifiedAgreement"
      data-test-id="subrecipient-information"
      [form]="form.controls.subrecipientInformation"
      (financialEmailClicked)="this.financialEmailClicked.emit(true)"
      (institutionalInformationUpload)="
        this.institutionalInformationUpload.emit($event)
      "
      (sameAsGrantPeriodClicked)="this.sameAsGrantPeriodClicked.emit()"
      (sameAsSignatoryClicked)="this.sameAsSignatoryClicked.emit()"
      [subawardStatus]="subawardStatus"
      (onCollaboratorInstitutionSelect)="this.onCollaboratorInstitutionSelect.emit($event)"
    ></subs-collaborator>

    <subs-subrecipient-monitoring
      [subrecipientMonitoringQuestions]="
        form.controls.subrecipientMonitoringQuestions
      "
      *ngIf="questionIsVisible"
    >
    </subs-subrecipient-monitoring>
  </form>
</div>
