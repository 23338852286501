import { AbstractControl } from '@angular/forms';

export function proposalIdValidator(control: AbstractControl) {
  const value = control.value;
  const regexp = new RegExp(
    '^P?[a-zA-Z0-9]{2}-?[a-zA-Z0-9]{5}|P?[a-zA-Z0-9]{2}-?[a-zA-Z0-9]{5}-[a-zA-Z0-9]{2}|[a-zA-Z0-9]{6}|[a-zA-Z0-9]{2}-[a-zA-Z0-9]{5}$',
  );
  if (regexp.test(value)) {
    return null;
  }
  return { proposalId: true };
}
