import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainLayoutService {
  projectIdChange: BehaviorSubject<number> = new BehaviorSubject<number | -1>(
    -1,
  );
  subawardIdChange: BehaviorSubject<number> = new BehaviorSubject<number | -1>(
    -1,
  );
  institutionIdChange: BehaviorSubject<number> = new BehaviorSubject<
    number | -1
  >(-1);
  utilityItemIdChange: BehaviorSubject<number> = new BehaviorSubject<
    number | -1
  >(-1);
  projectSidebarVisibilityChange: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean | false>(false);
  editInstitutionSidebarVisibilityChange: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean | false>(false);
  addInstitutionSidebarVisibilityChange: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean | false>(false);
  addInstitutionActiveChange: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean | false
  >(false);
  editInstitutionActiveChange: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean | false
  >(false);
  addSubawardSidebarVisibilityChange: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean | false>(false);
  subawardSidebarVisibilityChange: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean | false>(false);
  addCurrentYearAsideVisibleChange: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean | false>(false);

  addUtilityItemVisibleChange = new BehaviorSubject<boolean | true>(null);
  editUtilityItemVisibleChange = new BehaviorSubject<boolean | false>(null);

  constructor() {}

  toggleProjectSidebarVisibility(value: boolean) {
    this.projectSidebarVisibilityChange.next(value);
  }

  toggleEditInstitutionSidebarVisibility(value: boolean) {
    this.editInstitutionSidebarVisibilityChange.next(value);
  }

  toggleAddInstitutionSidebarVisibility(value: boolean) {
    this.addInstitutionSidebarVisibilityChange.next(value);
  }

  toggleAddSubawardSidebarVisibility(value: boolean) {
    this.addSubawardSidebarVisibilityChange.next(value);
  }

  toggleSubawardSidebarVisibility(value: boolean) {
    this.subawardSidebarVisibilityChange.next(value);
  }

  toggleCurrentYearSidebarVisibility(value: boolean) {
    this.addCurrentYearAsideVisibleChange.next(value);
  }

  toggleSubawardSidebar(value: boolean) {
    this.subawardSidebarVisibilityChange.next(value);
    this.addSubawardSidebarVisibilityChange.next(value);
  }

  setProjectId(projectId: number) {
    this.projectIdChange.next(projectId);
  }

  setSubawardId(subawardId: number) {
    this.subawardIdChange.next(subawardId);
  }

  setInstitutionId(institutionId: number) {
    const id = +institutionId;
    const visibleAndActive = id !== -1;
    this.institutionIdChange.next(id);
    this.editInstitutionSidebarVisibilityChange.next(visibleAndActive);
    this.addInstitutionSidebarVisibilityChange.next(!visibleAndActive);
    this.addInstitutionActiveChange.next(!visibleAndActive);
    this.editInstitutionActiveChange.next(visibleAndActive);
  }

  setUtilityItemId(institutionId: number) {
    this.utilityItemIdChange.next(+institutionId);
  }

  // utility items visibility - side bar
  selectAddUtilityItemNavbar(value: boolean) {
    this.addUtilityItemVisibleChange.next(value);
  }

  selectEditUtilityItemNavbar(value: boolean) {
    this.editUtilityItemVisibleChange.next(value);
  }

  getAddUtilityItemNavbar() {
    return this.addUtilityItemVisibleChange.asObservable();
  }

  getEditUtilityItemNavbar() {
    return this.editUtilityItemVisibleChange.asObservable();
  }
}
