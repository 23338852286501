import { Component } from '@angular/core';
import { FeedbackService } from '../../form-layout/feedback.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  InstitutionReportClient,
  NewInstitutionReportResponse,
} from '../../api.service';
import { ExcelExportService } from '../excel-export.service';

@Component({
  selector: 'subs-new-institution-report',
  templateUrl: './new-institution-report.component.html',
  providers: [FeedbackService],
})
export class NewInstitutionReportComponent {
  submitState = this.feedbackService.submitState;
  alertSubject = this.feedbackService.alerts;

  searchForm = this.fb.group({
    dateFrom: ['', Validators.required],
    dateThru: ['', Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private newInstitutionReportClient: InstitutionReportClient,
    private excelExportService: ExcelExportService,
  ) {}

  search() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.newInstitutionReportClient
        .get(
          new Date(this.searchForm.controls.dateFrom.value),
          new Date(this.searchForm.controls.dateThru.value),
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async val => {
          await this.generateReport(val);
        });
    }
  }

  async generateReport(results: NewInstitutionReportResponse) {
    if (
      results.completedInstitutions.length === 0 &&
      results.newInstitutions.length === 0
    ) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }

    const columns = [
      {
        header: 'Institution ID',
        width: 20,
      },
      {
        header: 'DUNS',
        width: 20,
      },
      {
        header: 'UEI',
        width: 20,
      },
      {
        header: 'Institution Name',
        width: 40,
      },
      {
        header: 'Risk Category',
        width: 20,
      },
      {
        header: 'Supplier ID',
        width: 20,
      },
      {
        header: 'Foreign Institution',
        width: 20,
      },
      {
        header: 'Created By',
        width: 25,
      },
      {
        header: 'Created Date',
        width: 15,
      },
      {
        header: 'Institution Status',
        width: 20,
      },
    ];

    const data = this.generateReportData(results);

    const headers = [
      {
        title: 'New Institutions Created',
        newInstitutionsCount: results.newInstitutions.length,
        columns: [
          {
            value: 'Institution ID',
          },
          {
            value: 'DUNS',
          },
          {
            value: 'UEI',
          },
          {
            value: 'Institution Name',
          },
          {
            value: 'Risk Category',
          },
          {
            value: 'Supplier ID',
          },
          {
            value: 'Foreign Institution',
          },
          {
            value: 'Created By',
          },
          {
            value: 'Created Date',
          },
          {
            value: 'Institution Status',
          },
        ],
      },
      {
        title: 'New Institutions Completed',
        columns: [
          {
            value: 'Institution ID',
          },
          {
            value: 'DUNS',
          },
          {
            value: 'UEI',
          },
          {
            value: 'Institution Name',
          },
          {
            value: 'Risk Category',
          },
          {
            value: 'Supplier ID',
          },
          {
            value: 'Foreign Institution',
          },
          {
            value: 'Completed By',
          },
          {
            value: 'Completed Date',
          },
          {
            value: 'Institution Status',
          },
        ],
      },
      columns,
    ];

    await this.excelExportService.generateExcel(
      'New Institutions Report',
      'New Institutions Report for the period ' +
        this.searchForm.controls.dateFrom.value +
        ' - ' +
        this.searchForm.controls.dateThru.value,
      data,
      columns,
      {
        value:
          'Date Range: ' +
          this.searchForm.controls.dateFrom.value +
          ' - ' +
          this.searchForm.controls.dateThru.value,
      },
      this.addHeadersAndStyles,
      headers,
    );
  }

  private addHeadersAndStyles(worksheet, headers) {
    // New Institution Title
    worksheet.spliceRows(10, 0, [headers[0].title]);
    worksheet.mergeCells(10, 1, 10, headers[0].columns.length);
    worksheet.getCell('A10').alignment = { horizontal: 'center' };
    worksheet.spliceRows(11, 0, '');
    worksheet.spliceRows(headers[0].newInstitutionsCount + 13, 0, '', '');

    // Completed Institution Title
    worksheet.spliceRows(headers[0].newInstitutionsCount + 14, 0, [
      headers[1].title,
    ]);
    worksheet.mergeCells(
      headers[0].newInstitutionsCount + 14,
      1,
      headers[0].newInstitutionsCount + 14,
      headers[0].columns.length,
    );
    worksheet.getCell(
      'A' + (headers[0].newInstitutionsCount + 14),
    ).alignment = {
      horizontal: 'center',
    };

    // Completed Institution Header
    const gridHeader = [];
    headers[1].columns.forEach(d => {
      gridHeader.push(d.value);
    });
    worksheet.spliceRows(headers[0].newInstitutionsCount + 16, 0, gridHeader);
    worksheet.getRow(headers[0].newInstitutionsCount + 16).eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFE9F3FC',
        },
        bgColor: {
          argb: 'FFFFFFFF',
        },
      };
      cell.font = {
        color: {
          argb: '00000000',
        },
        bold: true,
      };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
    });
  }

  private generateReportData(results: NewInstitutionReportResponse) {
    const data = [];

    results.newInstitutions.forEach(e => {
      data.push([
        e.institutionId,
        e.dunsNumber,
        e.uniqueEntityId,
        e.institutionName,
        e.riskCategory,
        e.supplierId,
        e.foreign,
        e.createdBy,
        e.createdDate ? new Date(e.createdDate).toLocaleDateString() : '',
        e.status,
      ]);
    });

    results.completedInstitutions.forEach(e => {
      data.push([
        e.institutionId,
        e.dunsNumber,
        e.uniqueEntityId,
        e.institutionName,
        e.riskCategory,
        e.supplierId,
        e.foreign,
        e.createdBy,
        e.createdDate ? new Date(e.createdDate).toLocaleDateString() : '',
        e.status,
      ]);
    });
    return data;
  }

  clear() {
    this.searchForm.controls.dateFrom.setValue('');
    this.searchForm.controls.dateThru.setValue('');
    this.feedbackService.clearAlert();
  }
}
