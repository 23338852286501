import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  UntypedFormArray,
  FormGroupDirective,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DownloadService } from 'src/app/download.service';
import { AuthService } from 'src/app/auth.service';
import { NoaControlFactory } from '../noa-control-factory.service';

@Component({
  selector: 'subs-notice-of-award',
  templateUrl: './notice-of-award.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class NoticeOfAwardComponent implements OnInit {
  noticeOfAwards = new BehaviorSubject([]);
  fileNameToDelete = '';
  indexToDelete = -1;

  constructor(
    private formGroup: FormGroupDirective,
    private controlFactory: NoaControlFactory,
    private downloadService: DownloadService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.getFormArray().valueChanges.subscribe(this.noticeOfAwards);
  }

  fileUploaded({ fileName, fileId }: { fileName: string; fileId: string }) {
    const control = this.controlFactory.createNoaControl({
      id: null,
      selected: false,
      dateUploaded: new Date().toISOString(),
      uploadedBy: this.authService.currentUser.name,
      fileId,
      fileName,
    });

    this.getFormArray().push(control);
  }

  deleteAward(index: number) {
    this.indexToDelete = index;
    this.fileNameToDelete = this.getFormArray().value[index].fileName;
  }

  download(index: number) {
    const { fileId, fileName } = this.getFormArray().value[index];
    this.downloadService.downloadFile(fileId, fileName).subscribe();
  }

  cancelDeleteConfirmation() {
    this.fileNameToDelete = '';
    this.indexToDelete = -1;
  }

  confirmDelete() {
    this.getFormArray().removeAt(this.indexToDelete);
    this.fileNameToDelete = '';
    this.indexToDelete = -1;
  }

  private getFormArray(): UntypedFormArray {
    return this.formGroup.form.get('noticeOfAwards') as UntypedFormArray;
  }
}
