import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilesClient, FileResponse } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private fileClient: FilesClient) { }

  downloadFile(fileId: string, fileName: string): Observable<void> {
    return this.fileClient.get2(fileId, fileName).pipe(
      map(response => {
        const dataUrl = window.URL.createObjectURL(response.data);
        this.saveFile(dataUrl, fileName);
        window.URL.revokeObjectURL(dataUrl);
      }),
    );
  }

  private saveFile(dataUrl: string, fileName: string) {
    const anchor = document.createElement('a');
    anchor.href = dataUrl;
    anchor.download = fileName;
    anchor.click();
  }

  getFile(fileId: string, fileName: string): Observable<FileResponse> {
    return this.fileClient.get2(fileId, fileName);
  }
}
