import { Component, Input, OnInit } from '@angular/core';
import {
  LookupItem,
  LookupItemsResponse,
  UtilityTableKeyEnum,
} from '../../../../api.service';
import {
  ClrDatagridComparatorInterface,
  ClrDatagridSortOrder,
} from '@clr/angular';
import { AppLookupItemKeys } from '../../../../enums/app-lookup-item-keys.enum';
import { Router } from '@angular/router';
import { AppLookupTableKeys } from '../../../../enums/app-lookup-table-keys.enum';

class KeyComparator implements ClrDatagridComparatorInterface<LookupItem> {
  compare(a: LookupItem, b: LookupItem) {
    return +a.key - +b.key;
  }
}

@Component({
  selector: 'subs-app-utilities-grid',
  templateUrl: './app-utilities-grid.component.html',
  styleUrls: ['./app-utilities-grid.component.scss'],
})
export class AppUtilitiesGridComponent implements OnInit {
  public keyComparator = new KeyComparator();
  @Input() utilitiesSearchResults: LookupItemsResponse;
  appLookupItemKeys = AppLookupItemKeys;
  appLookupTableKeys = AppLookupTableKeys;
  ascSort = ClrDatagridSortOrder.ASC;
  constructor(private router: Router) {
    this.utilitiesSearchResults = {};
  }

  ngOnInit() {}

  navigateToUtilityItem(lookupItemId: number, utilityTableKey: string) {
    this.router.navigate(['app-utility/item/' + lookupItemId], {
      queryParams: { type: utilityTableKey },
    });
  }

  getItemKey(key: string, tableKey: UtilityTableKeyEnum) {
    const isUsedKey = this.isUsedKey(key);
    const isFeature = this.isFeature(tableKey.toString());
    if (isUsedKey && isFeature) {
      return this.appLookupItemKeys[key];
    }
    return key;
  }

  private isUsedKey(key: string) {
    return Object.keys(this.appLookupItemKeys).some(v => v === key);
  }

  private isFeature(key: string) {
    return Object.keys(this.appLookupTableKeys).some(v => v === key);
  }
}
