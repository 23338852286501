import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SubawardsService } from '../subawards.service';

@Component({
  selector: 'subs-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @Input() contactForm: UntypedFormGroup;
  @Input() checklist: UntypedFormGroup;
  @Input() projectTitle: string;
  @Input() centralOfficeEmail: UntypedFormControl;

  constructor(private subawardsService: SubawardsService) {}

  ngOnInit(): void {
    this.contactForm.valueChanges.subscribe(value => {
      this.subawardsService.setSubawardContact(this.contactForm.value);
    });
  }
}
