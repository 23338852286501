import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import {
  COWorklistClient,
  COWorklistViewModel,
  SubrecipientStatusVm,
} from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { ExcelExportService } from '../excel-export.service';
import { SubStatusService } from '../../utilities/subrecipient-status/sub-status.service';

@Component({
  selector: 'subs-central-office-worklist',
  templateUrl: './central-office-worklist.component.html',
  providers: [FeedbackService],
})
export class CentralOfficeWorklistComponent implements OnInit {
  submitState = this.feedbackService.submitState;
  userHasSearched = false;
  searchResult = Array<COWorklistViewModel>();
  filteredResults = Array<COWorklistViewModel>();
  searchResultCount = '';
  subIdPriority: number[] = [];
  activeStatusList: SubrecipientStatusVm[] = [];

  searchForm = this.fb.group({
    wUPILastName: '',
    wUPIFirstName: '',
    proposalId: [''],
    awardDept: '',
    grantWorkTag: [''],
    costCenterId: [''],
    issuingDept: [''],
    agreementNum: '',
    trackingNum: '',
    daysSinceSubmission: '',
    status: '',
    subrecipient: '',
    DAStatus: '',
    assignedGA: '',
    foreignEntity: '',
    showRepeatSubmissions: '',
  });

  alertSubject$ = this.feedbackService.alerts;

  shouldShowNoResultsMessage = this.submitState.pipe(
    map(
      (submitState) =>
        this.searchResult.length === 0 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  shouldShowMaxResultsMessage = this.submitState.pipe(
    map(
      (submitState) =>
        this.searchResult.length === 300 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  constructor(
    private fb: UntypedFormBuilder,
    private cOWorklistClient: COWorklistClient,
    private excelExportService: ExcelExportService,
    private feedbackService: FeedbackService,
    private subStatusService: SubStatusService,
  ) {
    this.subStatusService.ActiveStatuses.subscribe(async (value) => {
      if (value !== undefined && value !== null) {
        this.activeStatusList = value;
      }
    });
  }

  ngOnInit() {
    this.searchForm.controls.showRepeatSubmissions.valueChanges.subscribe(
      (value) => {
        if (value !== undefined && value !== null) {
          this.filterSearchResult(value);
        }
      },
    );
  }

  private filterSearchResult(value: any) {
    if (value) {
      this.filteredResults = this.searchResult.filter(
        (result) => result.repeatedSubmission === value,
      );
    } else {
      this.filteredResults = this.searchResult;
    }
  }

  search() {
    this.feedbackService.beginLoading();

    const showRepeatSubmissionsValue =
      this.searchForm.controls.showRepeatSubmissions.value === ''
        ? null
        : this.searchForm.controls.showRepeatSubmissions.value;

    this.cOWorklistClient
      .get(
        this.searchForm.controls.wUPILastName.value,
        this.searchForm.controls.wUPIFirstName.value,
        this.searchForm.controls.proposalId.value,
        this.searchForm.controls.awardDept.value,
        this.searchForm.controls.grantWorkTag.value,
        this.searchForm.controls.costCenterId.value,
        this.searchForm.controls.issuingDept.value,
        this.searchForm.controls.agreementNum.value,
        this.searchForm.controls.trackingNum.value,
        this.searchForm.controls.daysSinceSubmission.value,
        this.searchForm.controls.status.value,
        this.searchForm.controls.subrecipient.value,
        this.searchForm.controls.DAStatus.value === '' ? false : true,
        this.searchForm.controls.assignedGA.value,
        this.searchForm.controls.foreignEntity.value,
        showRepeatSubmissionsValue,
        true,
      )
      .pipe(
        tap(
          (val) => {
            this.searchResult = val;
            this.filteredResults = val;
            if (this.searchForm.controls.showRepeatSubmissions.value) {
              this.filterSearchResult(true);
            }
            this.searchResultCount = `${
              val.length === 0 ? 'No' : val.length
            } result${val.length === 1 ? '' : 's'}`;
            this.userHasSearched = true;
            this.subIdPriority = [];
          },
          (err) => {
            this.userHasSearched = false;
          },
        ),
        this.feedbackService.provideFeedback(),
      )
      .subscribe();
  }

  clear() {
    this.userHasSearched = false;
    this.searchForm.controls.wUPILastName.setValue('');
    this.searchForm.controls.wUPIFirstName.setValue('');
    this.searchForm.controls.proposalId.setValue('');
    this.searchForm.controls.grantWorkTag.setValue('');
    this.searchForm.controls.costCenterId.setValue('');
    this.searchForm.controls.issuingDept.setValue('');
    this.searchForm.controls.agreementNum.setValue('');
    this.searchForm.controls.trackingNum.setValue('');
    this.searchForm.controls.daysSinceSubmission.setValue('');
    this.searchForm.controls.status.setValue('');
    this.searchForm.controls.subrecipient.setValue('');
    this.searchForm.controls.awardDept.setValue('');
    this.searchForm.controls.DAStatus.setValue('');
    this.searchForm.controls.assignedGA.setValue('');
    this.searchForm.controls.foreignEntity.setValue('');
    this.searchForm.controls.showRepeatSubmissions.setValue('');
    this.searchResult = [];
    this.feedbackService.clearAlert();
    this.subIdPriority = [];
  }

  async downloadReport() {
    if (this.filteredResults.length <= 300) {
      await this.generateReport(this.filteredResults);
    } else {
      this.feedbackService.beginLoading();
      this.cOWorklistClient
        .get(
          this.searchForm.controls.wUPILastName.value,
          this.searchForm.controls.wUPIFirstName.value,
          this.searchForm.controls.proposalId.value,
          this.searchForm.controls.awardDept.value,
          this.searchForm.controls.grantWorkTag.value,
          this.searchForm.controls.costCenterId.value,
          this.searchForm.controls.issuingDept.value,
          this.searchForm.controls.agreementNum.value,
          this.searchForm.controls.trackingNum.value,
          this.searchForm.controls.daysSinceSubmission.value,
          this.searchForm.controls.status.value,
          this.searchForm.controls.subrecipient.value,
          this.searchForm.controls.DAStatus.value === '' ? false : true,
          this.searchForm.controls.assignedGA.value,
          this.searchForm.controls.foreignEntity.value,
          this.searchForm.controls.showRepeatSubmissions.value,
          false,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async (val) => {
          await this.generateReport(val);
        });
    }
  }

  private generateReportData(results: Array<COWorklistViewModel>) {
    const data = [];
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    results.forEach((e) => {
      data.push([
        this.subIdPriority.find((x) => x === e.subrecipientId) ? 'Y' : 'N',
        e.assignedGA,
        this.getStatusDescription(e.status),
        e.repeatedSubmission ? 'Yes' : 'No',
        e.lastStatusChangedDate
          ? new Date(e.lastStatusChangedDate).toLocaleDateString()
          : '',
        e.submittedDate ? new Date(e.submittedDate).toLocaleDateString() : '',
        e.subrecipient,
        e.foreignEntity,
        formatter.format(e.totalCostsThisAction),
        e.wupiLastName,
        e.wupiFirstName,
        e.proposalId,
        e.awardDept,
        e.issuingDept,
        e.grantWorkTag,
        e.costCenterId,
        e.awardYear,
        e.agreementNumber ? e.agreementNumber : '',
        e.trackingNumber ? e.trackingNumber : '',
      ]);
    });
    return data;
  }

  private getStatusDescription(statusCode: string) {
    const activeStatus = this.activeStatusList.find(
      (status) => status.statusCode === statusCode,
    );
    return activeStatus ? activeStatus.statusDescription : '';
  }

  async generateReport(results: Array<COWorklistViewModel>) {
    const columns = [
      {
        header: 'Priority',
        width: 12,
      },
      {
        header: 'OSRS GA',
        width: 12,
      },
      {
        header: 'Status',
        width: 30,
      },
      {
        header: 'Repeat Submission',
        width: 23,
      },
      {
        header: 'Last Status Changed Date',
        width: 23,
      },
      {
        header: 'Submitted Date',
        width: 20,
      },
      {
        header: 'Subrecipient',
        width: 30,
      },
      {
        header: 'Foreign Institution',
        width: 18,
      },
      {
        header: 'Total Costs this Action',
        width: 15,
      },
      {
        header: 'WU PI Last Name',
        width: 16,
      },
      {
        header: 'WU PI First Name',
        width: 16,
      },
      {
        header: 'Proposal Id',
        width: 15,
      },
      {
        header: 'Award Dept',
        width: 12,
      },
      {
        header: 'Issuing Dept',
        width: 12,
      },
      {
        header: 'Grant Worktag',
        width: 15,
      },
      {
        header: 'PI Cost Center',
        width: 15,
      },
      {
        header: 'Award Year',
        width: 15,
      },
      {
        header: 'Agreement Num',
        width: 19,
      },
      {
        header: 'Tracking Num',
        width: 15,
      },
    ];

    const data = this.generateReportData(results);

    await this.excelExportService.generateExcel(
      'Central Admin Worklist Report',
      'Central Admin Worklist Report',
      data,
      columns,
    );
  }

  onPriorityChange(value) {
    this.subIdPriority = value;
  }
}
