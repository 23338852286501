<subs-form-layout [formGroup]="form">
  <subs-form-content>
    <div class="card">
      <div class="card-header">
        Attachment Options
      </div>

      <div class="card-block">
        <div class="clr-row">
          <subs-select
            class="clr-col-lg-auto"
            labelLength="6"
            controlName="fed"
            label="Federal/Nonfederal"
          >
            <option value="" selected>&nbsp; </option>
            <option value="true" selected="selected">Federal</option>
            <option value="false">Nonfederal</option>
          </subs-select>

          <subs-select
            class="clr-col-lg-auto"
            labelLength="6"
            controlName="fdp"
            label="FDP/NonFDP"
          >
            <option value="" selected>&nbsp; </option>
            <option value="true" selected="selected">FDP</option>
            <option value="false">NonFDP</option>
          </subs-select>

          <subs-input
            class="clr-col-lg-auto"
            controlName="attachmentNum"
            label="Attachment Num"
            labelLength="6"
            maxLength="3"
            errorMessage="Attachment num is required"
          >
          </subs-input>
        </div>
        <div class="clr-row">
          <subs-checkbox
            class="clr-col-lg-auto"
            controlName="archive"
            label="Archive"
            labelLength="3"
          >
          </subs-checkbox>

          <subs-select
            class="clr-col-lg-auto"
            labelLength="6"
            controlName="unilateral"
            label="Unilateral/Bilateral"
          >
            <option value="" selected>&nbsp; </option>
            <option value="Unilateral" selected="selected">Unilateral</option>
            <option value="Bilateral">Bilateral</option>
          </subs-select>
        </div>
        <div class="clr-row">
          <subs-input
            class="clr-col"
            controlName="sponsor"
            label="Sponsor Name"
            labelLength="6"
            maxLength="80"
          >
          </subs-input>
        </div>
        <div class="clr-row">
          <subs-input
            class="clr-col"
            controlName="description"
            label="Description"
            labelLength="6"
            maxLength="128"
          >
          </subs-input>
        </div>
      </div>
    </div>

    <subs-card-file-upload
      id="attachment-file"
      title="Attachment File"
      fileNameControlName="attachmentFileName"
      fileIdControlName="attachmentFileId"
      dateControlName="attachmentFileModified"
      [customAlert]="requiredFileAlert$"
    ></subs-card-file-upload>

    <div class="card">
      <div class="card-header">
        Bookmarks
      </div>
      <subs-bookmarks-list
        [bookmarks]="bookmarks"
        (bookmarkAdded)="addLine($event)"
        (idxToRemove)="removeBookmark($event)"
      >
      </subs-bookmarks-list>
    </div>
  </subs-form-content>

  <subs-form-buttons>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="save().subscribe()"
      data-test-id="submit-attachment"
    >
      Save
    </button>
    <button class="btn btn-outline" (click)="redirectToSearchPage()">
      Return to Search
    </button>
    <button
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="saveAndCopy()"
      data-test-id="copy-attachment"
    >
      Copy
    </button>
    <button class="btn btn-outline" (click)="redirectToSearchPage()">
      Cancel
    </button>
  </subs-form-buttons>
</subs-form-layout>
<clr-modal
  [(clrModalOpen)]="showUnsavedChangesModal"
  [clrModalClosable]="false"
>
  <h3 class="modal-title">
    You have unsaved changes.
  </h3>
  <div class="modal-body">
    <subs-alert [alertBehavior]="alert$"></subs-alert>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="confirmSave()"
      data-test-id="confirm-save-before-navigate"
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="discardChanges()"
    >
      Discard
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="cancelNavigation()"
    >
      Cancel
    </button>
  </div>
</clr-modal>
