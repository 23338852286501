<div class="card">
  <div class="card-block" [formGroup]="form">
    <div class="clr-row">
      <subs-input
        class="clr-col-8"
        label="* Institution"
        controlName="institutionName"
        inputStyle="width:100%"
        maxLength="254"
        errorMessage="A value is required."
      ></subs-input>

      <subs-select class="clr-col-md-2" controlName="status" labelLength="4">
        <option value="IP">In Progress</option>
        <option value="C">Completed</option>
      </subs-select>

      <subs-input
        class="clr-col-2"
        labelLength="2"
        label="ID"
        controlName="id"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-item-lookup-select
        class="clr-col-8"
        [options]="options"
        controlName="institutionType"
        label="* Institution Type"
        labelLength="6"
        errorMessage="A selection is required."
      ></subs-item-lookup-select>

      <subs-select
        *subsPermission="['InstitutionUpdateAll']"
        class="clr-col-md-2"
        controlName="isDeleted"
        labelLength="4"
      >
        <option value="true">Yes</option>
        <option value="false">No</option>
      </subs-select>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col"
        [label]="dunsRequiredIndicator + 'DUNS number'"
        controlName="dunsNumber"
        labelLength="8"
        maxLength="13"
        errorMessage="A value is required. No special characters allowed."
      ></subs-input>

      <subs-input
        class="clr-col"
        [label]="ueiRequiredIndicator + 'UEI'"
        controlName="uniqueEntityId"
        labelLength="8"
        maxLength="12"
        errorMessage="A value is required. No special characters allowed."
      ></subs-input>

      <subs-select
        class="clr-col"
        label="* Risk Category"
        controlName="riskCategory"
        labelLength="6"
        errorMessage="A selection is required."
      >
        <option value=""></option>
        <option value="High">High</option>
        <option value="Medium">Medium</option>
        <option value="Low">Low</option>
      </subs-select>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col"
        label="EIN"
        controlName="employerIdNumber"
        labelLength="8"
        maxLength="12"
        errorMessage="A value is required. No special characters allowed."
      ></subs-input>

      <subs-input
        class="clr-col"
        label="Supplier ID"
        controlName="supplierId"
        labelLength="8"
        maxLength="12"
        errorMessage="No special characters allowed."
      ></subs-input>

      <subs-input
        class="clr-col"
        label="{{ congressionalDistrictLabel }}"
        controlName="congressionalDistrict"
        labelLength="6"
        maxLength="12"
        errorMessage="A value is required."
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-yes-no
        class="clr-col"
        label="* sam.gov Registered"
        controlName="samGov"
        errorMessage="{{ setErrorMessage('samGov') }}"
      >
      </subs-yes-no>

      <subs-yes-no
        class="clr-col"
        label="* Foreign institution"
        controlName="foreign"
        errorMessage="{{ setErrorMessage('foreign') }}"
      >
      </subs-yes-no>

      <subs-yes-no
        class="clr-col"
        label="* Primary Institution Record"
        controlName="primaryInstitution"
        [nullable]="false"
      >
      </subs-yes-no>
    </div>

    <div class="clr-row">
      <p class="clr-col-12 clr-col-md-8">
        {{ finQues1RequiredIndicator }}In your Business or organization's
        previous fiscal year, did your business or organization (including
        parent organization, all branches, and all affiliates worldwide) receive
        (1) 80 percent or more of your annual gross revenues in U.S. federal
        contracts, subcontracts, loans, grants, subgrants, and/or cooperative
        agreements; and (2) $25,000,000 or more in annual gross revenues from
        U.S. federal contracts, subcontracts, loans, grants, subgrants, and/or
        cooperative agreements?
      </p>

      <subs-yes-no
        class="clr-col-12 clr-col-md-4"
        controlName="top5Q1"
        hideLabel
        labelLength="1"
        errorMessage="{{ setErrorMessage('top5Q1') }}"
      >
      </subs-yes-no>
    </div>

    <div class="clr-row">
      <p class="clr-col-12 clr-col-md-8">
        {{ finQues2RequiredIndicator }}Does the public have access to
        information about the compensation of the senior executives in your
        business or organization (including parent organization, all branches,
        and all affiliates worldwide) through periodic reports filed under
        section 13(a) or 15(d) of the Securities Exchange Act of 1934 (15 U.S.C.
        78m(a), 78o(d)) or section 6104 of the Internal Revenue Code of 1986?
      </p>

      <subs-yes-no
        class="clr-col-12 clr-col-md-4"
        controlName="top5Q2"
        hideLabel
        labelLength="1"
        errorMessage="{{ setErrorMessage('top5Q2') }}"
      >
      </subs-yes-no>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-6"
        label="Parent DUNS"
        controlName="parentDuns"
        labelLength="6"
        maxLength="9"
        errorMessage="No special characters allowed."
      ></subs-input>

      <subs-input
        class="clr-col-6"
        label="Parent Congressional District"
        controlName="parentCongressionalDistrict"
        labelLength="14"
        maxLength="6"
      ></subs-input>
    </div>
    <br />
    <div class="clr-row">
      <div class="clr-col-6">
        <subs-card-file-upload
          id="sam-gov-upload"
          title="SAM.Gov Upload"
          fileNameControlName="samGovFileName"
          fileIdControlName="samGovFileId"
          buttonLabel="Upload"
          [callback]="callback"
          [acceptedMimeTypes]="['image/jpeg', 'image/png']"
          acceptedMimeTypesErrorMessage="SAM.Gov uploaded document must be an .jpeg or .png image."
        ></subs-card-file-upload>
      </div>

      <div class="clr-col-6">
        <subs-textarea
          class="clr-col"
          label="Comments"
          labelLength="4"
          controlName="comments"
          placeholder="Add a comment."
          errorMessage="Please insert a comment."
        >
        </subs-textarea>
      </div>
    </div>
  </div>
</div>
