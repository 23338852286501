<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <div class="clr-col-6">
      <subs-input
        label="Proposal ID"
        placeholder="ID"
        controlName="proposalId"
        labelLength="6"
        helperText="Will return projects that contain the entered ID."
      >
      </subs-input>

      <subs-input
        label="Grant Worktag"
        placeholder="Alphanumeric"
        controlName="grantWorkTag"
        labelLength="6"
        helperText="Will return projects that contain the entered Grant Worktag."
      >
      </subs-input>

      <subs-input
        label="Agency Award Number"
        placeholder="Number"
        controlName="awardNumber"
        labelLength="6"
        helperText="Will return projects that contain the entered number."
        errorMessage="Please enter a valid Award Number."
      >
      </subs-input>

      <subs-input
        label="WashU PI Last Name"
        placeholder="Name"
        controlName="pILastName"
        labelLength="6"
        helperText="Will return projects that contain the entered name."
      >
      </subs-input>

      <subs-input
        label="Sub PI Last Name"
        placeholder="Name"
        controlName="subPILastName"
        labelLength="6"
        helperText="Will return projects that contain the entered contact."
      >
      </subs-input>

      <subs-input
        label="Sub Agreement #"
        placeholder="Number"
        controlName="agreementNumber"
        labelLength="6"
        helperText="Will return projects that contain the entered Agreement Number."
        errorMessage="Please enter a valid Agreement Number."
      >
      </subs-input>

      <div class="clr-row">
        <div class="clr-col-6">
          <subs-select
            class="clr-col"
            controlName="riskCategory"
            labelLength="6"
          >
            <option value=""></option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
          </subs-select>
        </div>
        <div class="clr-col-6">
          <subs-select
            class="clr-col"
            controlName="foreignEntity"
            label="Foreign Institution"
            labelLength="6"
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </subs-select>
        </div>
      </div>

      <subs-subaward-status-select
        class="clr-col-lg-auto"
        labelLength="6"
        controlName="status"
        [includeEmptyStatus]="true"
        [statuses]="activeStatusList"
      >
      </subs-subaward-status-select>
    </div>

    <div class="clr-col-6">
      <subs-input
        label="Award Dept"
        labelLength="6"
        placeholder="Name"
        controlName="awardDepartment"
        helperText="Will return projects that contains the entered text."
      >
      </subs-input>

      <subs-input
        label="PO Number"
        labelLength="6"
        placeholder="Number"
        controlName="pONumber"
        helperText="Will return projects that contain the entered number."
        errorMessage="Please enter a valid PO Number."
      >
      </subs-input>

      <subs-input
        label="Subrecipient"
        labelLength="6"
        placeholder="Name"
        controlName="subrecipient"
        helperText="Will return projects that contain the entered subrecipient."
      >
      </subs-input>

      <subs-input
        label="WashU PI First Name"
        labelLength="6"
        placeholder="Name"
        controlName="pIFirstName"
        helperText="Will return projects that contain the entered name."
      >
      </subs-input>

      <subs-input
        label="Sub PI First Name"
        labelLength="6"
        placeholder="Name"
        controlName="subPIFirstName"
        helperText="Will return projects that contain the entered contact."
      >
      </subs-input>

      <subs-input
        label="PI Cost Center"
        labelLength="6"
        placeholder="Alphanumeric"
        controlName="costCenterId"
        helperText="Will return projects that contain the entered PI Cost Center associated with the Grant Worktag."
      >
      </subs-input>

      <subs-input
        label="Sponsor"
        labelLength="6"
        placeholder="Name"
        controlName="sponsor"
        helperText="Will return projects that contain the entered sponsor."
      >
      </subs-input>

      <div class="clr-col" labelLength="6"></div>

      <subs-country
        class="clr-col"
        [options]="options"
        controlName="country"
        label="Country"
        labelLength="6"
      ></subs-country>
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-6" style="margin-top: 24px">
      <subs-alert [alertBehavior]="alertSubject$"> </subs-alert>
      <button
        class="btn btn-primary"
        [clrLoading]="submitState | async"
        (click)="searchProjects()"
        data-test-id="search-project"
        [disabled]="!searchForm.valid"
      >
        Search
      </button>

      <button
        class="btn btn-outline"
        (click)="clear()"
        data-test-id="clear-project-search"
      >
        Clear
      </button>

      <button
        *ngIf="this.searchResult.length > 0"
        class="btn btn-outline"
        (click)="exportGrid()"
        data-test-id="clear-project-search"
      >
        Export
      </button>
    </div>
    <div
      class="clr-col-6"
      style="
        margin-top: 24px;
        padding-top: 24px;
        display: flex;
        justify-content: flex-end;
      "
    >
      <div *ngIf="searchResult && searchResult.length > 0" class="clr-col-3">
        <subs-static-text
          label="Results Found"
          labelLength="5"
          controlName="foundResults"
          maxLength="4"
        >
        </subs-static-text>
      </div>
      <div class="clr-col-3">
        <subs-input
          label="Results Search"
          labelLength="5"
          placeholder="300"
          controlName="maxResults"
          maxLength="4"
          errorMessage="Only positive numbers without leading zero allowed."
        >
        </subs-input>
      </div>
    </div>
  </div>

  <div
    *ngIf="shouldShowNoResultsMessage | async"
    class="alert alert-warning"
    role="alert"
  >
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
        </div>
        <span class="alert-text" data-test-id="alert-msg">
          There were no results for your search.
        </span>
      </div>
    </div>
  </div>
</form>

<subs-project-grid
  [projectSearchResults]="searchResult"
  [statuses]="activeStatusList"
  data-test-id="search-result-grid"
>
</subs-project-grid>
