import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../form-layout/feedback.service';
import {
  combineLatest,
  forkJoin,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import { AlertOptions } from '../../alerts/alert-options';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  LookupItem,
  LookupItemResponse,
  LookupItemsResponse,
  UtilityClient,
  UtilityTableKeyEnum,
} from '../../api.service';
import { AppUtilitySearchService } from '../app-utility-search/app-utility-search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainLayoutService } from '../../main-layout/main-layout.service';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
} from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { AppLookupTableKeys } from '../../enums/app-lookup-table-keys.enum';
import { FeatureService } from '../feature.service';
import { LookupItemsService } from '../lookup-items.service';

@Component({
  selector: 'subs-utility-item-details',
  templateUrl: './utility-item-details.component.html',
  styleUrls: ['./utility-item-details.component.scss'],
  providers: [FeedbackService],
})
export class UtilityItemDetailsComponent implements OnInit {
  utilityTableKeys = AppLookupTableKeys;
  utilityTableKey = '';
  utilityTableDisplayName = '';
  valueHelperText = '';
  userRolePermissions: LookupItemsResponse = {
    lookupItems: [],
    utilityTableKey: UtilityTableKeyEnum.RolePermissionLookup,
  };
  rolePermissions: string[] = [];
  permissions: string[] = [];
  userRole = false;
  returnToSearch = false;
  showUnsavedChangesModal = false;
  canDelete = true;
  canSearch = true;
  showDeleteModal = false;
  originalFormValue: any;
  alertSubject$ = this.feedbackService.alerts;
  submitState = this.feedbackService.submitState;
  submitState$ = this.feedbackService.submitState;
  saveConfirmationSubject = new Subject<boolean>();
  saveConfirmationForm = this.fb.group({
    confirmation: [],
  });

  form = this.fb.group({
    id: [],
    key: [],
    value: [],
  });

  selectPermissions = this.fb.array([]);

  alertOptions: AlertOptions = {
    message: '',
    canBeClosed: true,
    type: 'danger',
  };

  constructor(
    private fb: UntypedFormBuilder,
    private utilityClient: UtilityClient,
    private utilitySearchService: AppUtilitySearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private feedbackService: FeedbackService,
    private mainLayoutService: MainLayoutService,
    private lookupItemService: LookupItemsService,
    private featureService: FeatureService,
  ) {
    this.saveConfirmationForm.controls.confirmation.valueChanges.subscribe(
      result => {
        this.saveConfirmationSubject.next(result);
      },
    );
  }

  ngOnInit() {
    combineLatest(
      this.activatedRoute.params.pipe(distinctUntilChanged()),
      this.activatedRoute.queryParams.pipe(distinctUntilChanged()),
    ) // distinctUntilChanged to avoid triggering subscription twice if routeParams and queryParams changed at he same time
      .pipe(
        map(([params, queryParams]) => ({ params, queryParams })),
        mergeMap(({ params, queryParams }) => {
          const observables: Observable<any>[] = [];
          const utilityItemId = params.id;
          this.utilityTableKey = queryParams.type;
          this.userRole =
            this.utilityTableKey === UtilityTableKeyEnum.UserRoleLookup;

          this.utilityTableDisplayName = this.utilityTableKeys[
            this.utilityTableKey
          ];

          if (
            !utilityItemId &&
            this.utilityTableKey === UtilityTableKeyEnum.AppFeatureLookup
          ) {
            this.form.controls.value.patchValue('True');
          }

          if (this.utilityTableKey === UtilityTableKeyEnum.AppFeatureLookup) {
            this.valueHelperText = 'Item Value can only be "True" or "False"';
          }

          if (utilityItemId) {
            this.mainLayoutService.setUtilityItemId(utilityItemId);
            this.mainLayoutService.selectEditUtilityItemNavbar(true);
            this.mainLayoutService.selectAddUtilityItemNavbar(false);
            observables.push(
              this.utilityClient.get(utilityItemId, this.utilityTableKey),
            );
          } else {
            this.mainLayoutService.selectAddUtilityItemNavbar(true);
            this.mainLayoutService.selectEditUtilityItemNavbar(false);
            this.canDelete = false;
            observables.push(of(null));
          }
          return forkJoin(observables);
        }),
      )
      .subscribe(async ([response]) => {
        this.setFormValue(response);
        if (response) {
          this.rolePermissions = await response.lookupItem.value.split(',');
        }

        if (this.userRole) {
          this.lookupItemService
            .lookupItems(UtilityTableKeyEnum.RolePermissionLookup)
            .subscribe(value => {
              if (value) {
                this.userRolePermissions.lookupItems = value;
                this.createPermissionsForm(value);
              }
            });
        }

        this.originalFormValue = cloneDeep(this.form.value);
        this.form.statusChanges.subscribe(val => this.onFormValidation(val));
        this.form.valueChanges.subscribe((value: any) => {
          if (this.form.dirty) {
            this.saveConfirmationForm.controls.confirmation.setValue(false);
          } else {
            this.saveConfirmationForm.controls.confirmation.setValue(true);
          }
        });
      });

    this.selectPermissions.valueChanges.subscribe(value => {
      if (value) {
        const selectedPermissions: string[] = [];
        for (const item of value) {
          if (item.selected) {
            selectedPermissions.push(item.permissionKey);
          }
        }
        this.form.controls.value.patchValue(selectedPermissions.toString());
      }
    });
  }

  private createPermissionsForm(permissions: LookupItem[]) {
    this.selectPermissions.clear();

    for (const permission of permissions) {
      if (permission.id) {
        const control = this.fb.group({
          id: [{ disabled: true }],
          permissionKey: [],
          permissionValue: [],
          selected: [],
        });

        control.setValue({
          id: permission.id,
          permissionKey: permission.key,
          permissionValue: permission.value,
          selected: this.rolePermissions.includes(permission.key)
            ? true
            : false,
        });

        this.selectPermissions.push(control);
      }
    }
  }

  onFormValidation(validity: string) {
    switch (validity) {
      case 'VALID':
        this.feedbackService.clearAlert();
        break;
      case 'INVALID':
        // covered by individual field error messages
        break;
    }
  }

  private setFormValue(response: LookupItemResponse) {
    if (response != null) {
      this.form.patchValue(response.lookupItem);
    }
  }

  save(id?: number) {
    this.returnToSearch = true;
    if (this.form.invalid) {
      this.form.markAsTouched();
      this.feedbackService.alert(
        'Please fix all field errors before saving the form.',
      );
      this.validateAllFormFields(this.form);
      return;
    } else {
      this.feedbackService.clearAlert();
    }

    this.feedbackService.beginLoading();

    const value = this.form.value;

    if (id) {
      this.utilityClient
        .put(id, this.utilityTableKey, value)
        .pipe(
          catchError(error => {
            return throwError(error);
          }),
          this.feedbackService.provideFeedback(),
        )
        .subscribe(result => {
          this.successfulUtilityResponse(result);
        });
    } else {
      this.utilityClient
        .post(value, this.utilityTableKey)
        .pipe(
          catchError(error => {
            return throwError(error);
          }),
          this.feedbackService.provideFeedback(),
        )
        .subscribe(result => {
          this.successfulUtilityResponse(result);
        });
    }
  }

  redirectToSearchPage() {
    this.returnToSearch = true;
    this.router.navigate(['/find-app-utility'], {
      queryParams: { type: this.utilityTableKey },
    });
    this.deactivateSidebarItems();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.dirty) {
      this.deactivateSidebarItems();
      return true;
    }
    this.showUnsavedChangesModal = true;
    return this.saveConfirmationSubject;
  }

  cancelEdit() {
    this.form.patchValue(this.originalFormValue);
  }

  confirmSave() {
    this.returnToSearch = true;
    this.save(this.form.controls.id.value);
    this.showUnsavedChangesModal = false;
  }

  discardChanges() {
    this.showUnsavedChangesModal = false;
    this.saveConfirmationForm.controls.confirmation.setValue(true);
  }

  cancelNavigation() {
    this.saveConfirmationForm.controls.confirmation.setValue(false);
    this.showUnsavedChangesModal = false;
  }

  private validateAllFormFields(formGroup: UntypedFormGroup | UntypedFormArray) {
    const keysArray =
      formGroup instanceof UntypedFormGroup
        ? Object.values(formGroup.controls)
        : formGroup.controls;
    keysArray.forEach(control => {
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: false });
        control.updateValueAndValidity();
      } else if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        this.validateAllFormFields(control);
      }
    });
    return formGroup.invalid;
  }

  private successfulUtilityResponse(result: LookupItemResponse) {
    this.setFormValue(result);
    if (result.utilityTableKey === UtilityTableKeyEnum.AppFeatureLookup) {
      this.featureService.setFeatures(UtilityTableKeyEnum.AppFeatureLookup);
    } else {
      this.utilitySearchService.selectAppUtilityItems(result.utilityTableKey);
    }
    this.form.markAsPristine();
    this.saveConfirmationForm.controls.confirmation.setValue(true);
    if (this.returnToSearch) {
      this.router.navigate(['/find-app-utility'], {
        queryParams: { type: this.utilityTableKey, reload: true },
      });
    } else {
      this.router.navigate(['app-utility/item/' + result.lookupItem.id], {
        queryParams: { type: this.utilityTableKey },
      });
    }
  }

  delete() {
    this.utilityClient
      .delete(this.form.controls.id.value, this.utilityTableKey)
      .subscribe(
        result => {
          this.router.navigate(['/find-app-utility'], {
            queryParams: { type: this.utilityTableKey },
          });
          this.showDeleteModal = false;
        },
        error => {
          this.showDeleteModal = false;
          this.feedbackService.alert(error.error);
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        },
      );
  }

  deleteUtilityItem() {
    this.showDeleteModal = true;
  }

  private deactivateSidebarItems() {
    this.mainLayoutService.selectAddUtilityItemNavbar(false);
    this.mainLayoutService.selectEditUtilityItemNavbar(false);
  }
}
