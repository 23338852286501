<div class="card">
  <div class="card-header">
    Institution Address
  </div>
  <div class="card-block">
    <subs-address
      [form]="institutionAddress"
      [hideRequiredItems]="true"
    ></subs-address>
  </div>
</div>
<div class="card">
  <div class="card-header">
    OSRS Address
  </div>
  <div class="card-block">
    <subs-address
      [form]="osrsAddress"
      [hideRequiredItems]="true"
    ></subs-address>
  </div>
</div>
