import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  Input,
  OnChanges,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { AlertOptions } from 'src/app/alerts/alert-options';
import { API_BASE_URL, File as FileResponse } from 'src/app/api.service';
import { DownloadService } from 'src/app/download.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'subs-file-upload',
  templateUrl: './file-upload.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  providers: [DatePipe],
})
export class FileUploadComponent implements OnChanges, AfterViewInit {
  @Input() id: string;
  @Input() label: string;
  @Input() fileNameControlName: string;
  @Input() dateControlName: string;
  @Input() fileIdControlName: string;
  @Input() acceptedMimeTypes: string[];
  @Input() acceptedMimeTypesErrorMessage: string;
  @Input() customAlert: Subject<AlertOptions>;
  @Input() canEdit = true;
  @Input() fileInputIndex = -1;
  @Input() callback: Function;
  @Input() canceledCallback: Function;
  @Input() buttonLabel = 'Add';
  @Input() getConfirmation = false;
  @Input() confirmationMessage = 'Are you sure?';
  @Input() confirmationTitle = 'File Upload Confirmation';

  @ViewChild('fileInput') fileInput: ElementRef;

  uploadAlert$ = new Subject<AlertOptions>();
  uploadingFileName = '';
  showDeleteConfirmation = false;
  showUploadConfirmation = false;
  private filePlaceholder: File;

  ngOnChanges() {
    if (this.customAlert) {
      this.uploadAlert$ = this.customAlert;
    }
  }

  ngAfterViewInit() {
    // force onchange to be called, even if the user selects the same file after canceling the upload
    this.fileInput.nativeElement.onclick = function() {
      this.value = null;
    };
  }

  get form(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  get fileId(): string {
    return this.form.value[this.fileIdControlName];
  }

  get fileName(): string {
    return this.form.value[this.fileNameControlName];
  }

  get inputId(): string {
    return (
      this.fileIdControlName +
      'fileupload' +
      (this.fileInputIndex === -1 ? '' : this.fileInputIndex.toString())
    );
  }

  get date(): string {
    return this.form.value[this.dateControlName];
  }

  constructor(
    private controlContainer: ControlContainer,
    private httpClient: HttpClient,
    @Inject(API_BASE_URL) private baseUrl: string,
    private downloadService: DownloadService,
    private datePipe: DatePipe,
  ) {}

  upload(file: File, ignoreConfirmation: boolean = false) {
    const fileExtension = file.name.split('.').pop();
    if (fileExtension === 'doc' || fileExtension === 'docm') {
      this.uploadAlert$.next({
        type: 'danger',
        message: 'All Word documents should be in .docx format.',
      });
      return;
    }

    if (!ignoreConfirmation && this.getConfirmation) {
      this.filePlaceholder = file;
      this.showUploadConfirmation = true;
      return;
    }

    if (this.acceptedMimeTypes && !this.acceptedMimeTypes.includes(file.type)) {
      this.uploadAlert$.next({
        type: 'danger',
        message: this.acceptedMimeTypesErrorMessage,
      });

      return;
    }

    this.uploadAlert$.next(null);
    this.form.controls[this.fileIdControlName].setErrors(null);

    this.uploadingFileName = file.name;

    const data = new FormData();
    data.append('file', file, file.name);

    this.httpClient.post<FileResponse>(`${this.baseUrl}/files`, data).subscribe(
      response => {
        if (this.callback) {
          this.callback(file, response.id);
        }
        this.uploadingFileName = null;
        this.uploadAlert$.next(null);

        this.form.patchValue({
          [this.fileNameControlName]: file.name,
          [this.fileIdControlName]: response.id,
        });
        if (this.dateControlName) {
          const date = this.datePipe.transform(
            new Date(),
            'MM/dd/yyyy hh:mm aaa',
          );
          this.form.get(this.dateControlName).setValue(date);
        }
      },
      error => {
        this.uploadingFileName = null;

        this.uploadAlert$.next({
          type: 'danger',
          message: 'There was an error uploading the file',
        });
      },
    );
  }

  confirmUpload() {
    if (this.filePlaceholder) {
      this.showUploadConfirmation = false;
      this.upload(this.filePlaceholder, true); // ignoreConfirmation = true so we don't ask for confirmation again
      this.filePlaceholder = null;
    }
  }

  cancelUpload() {
    this.showUploadConfirmation = false;
    this.filePlaceholder = null;
    this.canceledCallback();
  }

  beginDelete() {
    this.showDeleteConfirmation = true;
  }

  cancelDelete() {
    this.showDeleteConfirmation = false;
  }

  delete() {
    if (this.callback) {
      this.callback(null);
    }

    this.uploadAlert$.next(null);

    this.form.patchValue({
      [this.fileNameControlName]: null,
      [this.fileIdControlName]: null,
      [this.dateControlName]: null,
    });

    if (this.dateControlName) {
      this.form.get(this.dateControlName).setValue(null);
    }

    if (this.fileIdControlName) {
      this.form.get(this.fileIdControlName).setValue(null);
    }

    if (this.fileNameControlName) {
      this.form.get(this.fileNameControlName).setValue(null);
    }

    this.showDeleteConfirmation = false;
  }

  download() {
    this.downloadService.downloadFile(this.fileId, this.fileName).subscribe(
      () => {
        this.uploadAlert$.next(null);
      },
      err => {
        this.uploadAlert$.next({
          type: 'danger',
          message: 'There was an error downloading the file',
        });
      },
    );
  }
}
