import { Injectable } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AlertOptions } from '../alerts/alert-options';

@Injectable()
export class FeedbackService {
  private alertSubject = new BehaviorSubject<AlertOptions>(null);
  private loadingSubject = new BehaviorSubject<ClrLoadingState>(
    ClrLoadingState.DEFAULT,
  );
  private pageLoadSubject = new BehaviorSubject<ClrLoadingState>(
    ClrLoadingState.LOADING,
  );

  get alerts(): Observable<AlertOptions> {
    return this.alertSubject.asObservable();
  }

  get submitState(): Observable<ClrLoadingState> {
    return this.loadingSubject.asObservable();
  }

  get pageLoadState(): Observable<ClrLoadingState> {
    return this.pageLoadSubject.asObservable();
  }

  alert(opts: AlertOptions | string | Error) {
    if (typeof opts === 'string') {
      opts = {
        type: 'danger',
        message: opts,
      } as AlertOptions;
    }

    if (opts instanceof Error) {
      opts = {
        type: 'danger',
        message: this.generateErrorMessage(opts),
      } as AlertOptions;
    }

    this.alertSubject.next(opts);
    this.loadingSubject.next(ClrLoadingState.ERROR);
  }

  alertDuplicates() {
    this.loadingSubject.next(ClrLoadingState.ERROR);
  }

  clearAlert() {
    this.alertSubject.next(null);
    this.loadingSubject.next(ClrLoadingState.DEFAULT);
  }

  displaySuccess() {
    this.alertSubject.next(null);
    this.loadingSubject.next(ClrLoadingState.SUCCESS);
  }

  beginLoading() {
    this.alertSubject.next(null);
    this.loadingSubject.next(ClrLoadingState.LOADING);
  }

  completeLoading() {
    this.loadingSubject.next(ClrLoadingState.DEFAULT);
  }

  provideFeedback() {
    return <T>(source: Observable<T>): Observable<T> =>
      source.pipe(
        tap(
          () => this.displaySuccess(),
          (error) => this.alert(this.generateErrorMessage(error)),
        ),
      );
  }

  providePageLoadFeedback() {
    return <T>(source: Observable<T>): Observable<T> =>
      source.pipe(
        tap(
          () => this.pageLoadSubject.next(ClrLoadingState.SUCCESS),
          (error) => this.pageLoadSubject.next(ClrLoadingState.ERROR),
        ),
      );
  }

  private generateErrorMessage(error: any): string {
    if (error.status === 401) {
      return 'Your session has expired. Please log out then log back in.';
    }

    if (error.status === 403) {
      return 'You don’t have permission to access/manage this page/record.';
    }

    if (error.error) {
      return error.error;
    }

    return 'There was an error processing your request.';
  }
}
