import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { AlertOptions } from 'src/app/alerts/alert-options';
import { API_BASE_URL, File as FileResponse } from 'src/app/api.service';

let nextId = 0;

@Component({
  selector: 'subs-upload-button',
  templateUrl: './upload-button.component.html',
})
export class UploadButtonComponent {
  loading = false;
  inputId = 'file-upload' + nextId++;

  @Input() id: string;
  @Input() disable = false;
  @Input() acceptedMimeTypes: string[];
  @Input() acceptedMimeTypesErrorMessage: string;
  @Output() uploading = new EventEmitter<{ fileName: string }>();
  @Output() uploaded = new EventEmitter<{ fileName: string; fileId: string }>();
  @Output() uploadError = new EventEmitter<AlertOptions>();

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(
    private httpClient: HttpClient,
    @Inject(API_BASE_URL) private baseUrl: string,
  ) {}

  upload(file: File) {
    if (this.acceptedMimeTypes && !this.acceptedMimeTypes.includes(file.type)) {
      this.uploadError.emit({
        type: 'danger',
        message: this.acceptedMimeTypesErrorMessage,
      });

      return;
    }

    this.uploading.emit({
      fileName: file.name,
    });

    this.loading = true;

    const data = new FormData();
    data.append('file', file, file.name);

    this.httpClient.post<FileResponse>(`${this.baseUrl}/files`, data).subscribe(
      response => {
        this.loading = false;
        this.uploadError.emit(null);

        this.uploaded.emit({
          fileName: file.name,
          fileId: response.id,
        });

        this.fileInput.nativeElement.value = null;
      },
      error => {
        this.loading = false;

        this.uploadError.emit({
          type: 'danger',
          message: 'There was an error uploading the file',
        });

        this.fileInput.nativeElement.value = null;
      },
    );
  }
}
