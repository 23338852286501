<clr-datagrid>
  <clr-dg-column [clrDgField]="'id'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Institution Id
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'institutionName'" [clrDgSortOrder]="ascSort"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Institution Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'status'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Status
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'primaryInstitution'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Primary Institution
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'supplierId'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Supplier ID
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'riskCategory'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Risk Category
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'countryName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Country
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'samGov'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      SAM.gov
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'foreign'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Foreign Institution
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'deleted'"
  ><ng-container *clrDgHideableColumn="{ hidden: false }">
    Deleted
  </ng-container></clr-dg-column
  >
  <clr-dg-row
    *clrDgItems="let result of institutionSearchResults"
    (click)="navigateToInstitution(result.id)"
  >
    <clr-dg-cell>{{ result.id }}</clr-dg-cell>
    <clr-dg-cell>{{ result.institutionName }}</clr-dg-cell>
    <clr-dg-cell>{{ institutionStatusName[result.status] }}</clr-dg-cell>
    <clr-dg-cell>{{ result.primaryInstitution ? 'Y' : 'N' }}</clr-dg-cell>
    <clr-dg-cell>{{ result.supplierId }}</clr-dg-cell>
    <clr-dg-cell>{{ result.riskCategory }}</clr-dg-cell>
    <clr-dg-cell>{{ result.institutionAddress.countryName }}</clr-dg-cell>
    <clr-dg-cell>{{ getSamGov(result.samGov) }}</clr-dg-cell>
    <clr-dg-cell>{{ getForeignInstitution(result.foreign) }}</clr-dg-cell>
    <clr-dg-cell>{{ getDeletedInstitution(result.isDeleted) }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer data-test-id="results-amount"
    >{{
      institutionSearchResults.length === 0
        ? 'No'
        : institutionSearchResults.length
    }}
    result{{ institutionSearchResults.length === 1 ? '' : 's' }}</clr-dg-footer
  >
</clr-datagrid>
