import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ReturnCode } from 'src/app/api.service';

@Injectable()
export class ReturnCodeControlFactory {
  constructor(private fb: UntypedFormBuilder) {}

  createReturnCodeControl(value: ReturnCode): UntypedFormGroup {
    const control = this.createGroup();
    control.setValue(value);
    return control;
  }

  createMany(count: number): UntypedFormGroup[] {
    const output = [];

    for (let i = 0; i < count; i++) {
      output.push(this.createGroup());
    }

    return output;
  }

  private createGroup() {
    return this.fb.group({
      id: [],
      code: ['', [Validators.required, RxwebValidators.unique()]],
      reason: ['', Validators.required],
    });
  }
}
