<clr-datagrid>
  <clr-dg-column
    [style.width.px]="130"
    [clrDgField]="'proposalId'"
    [clrDgSortOrder]="ascSort"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Proposal ID
    </ng-container></clr-dg-column
  >
  <clr-dg-column [style.width.px]="140" [clrDgField]="'investigatorLastName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      WashU PI Last Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'investigatorFirstName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      WashU PI First Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'issuingDept'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Award Dept
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'grantWorkTag'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Grant Worktag
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'costCenterId'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      PI Cost Center
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'awardYear'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Award Year
    </ng-container></clr-dg-column
  >
  <clr-dg-column [style.width.px]="135" [clrDgField]="'amendment'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Amendment
    </ng-container></clr-dg-column
  >
  <clr-dg-column [style.width.px]="150" [clrDgField]="'subrecipient'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Subrecipient
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'foreignEntity'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Foreign Institution
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'poNumber'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      PO Number
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'subFromDate'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Sub from Date
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'subThruDate'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Sub thru Date
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'totalCost'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Total Cost
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'subInvestigatorLastName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Sub PI Last Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'subInvestigatorFirstName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Sub PI First Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'agreementNum'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Agreement Num
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'trackingNumber'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Tracking Num
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'subStatus'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Sub Status
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'riskCategory'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Sub Risk Category
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'countryName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Country Name
    </ng-container></clr-dg-column
  >
  <clr-dg-row
    *clrDgItems="let result of projectSearchResults"
    (click)="navigateToProject(result.id)"
  >
    <clr-dg-cell>{{ result.proposalId }}</clr-dg-cell>
    <clr-dg-cell>{{ result.investigatorLastName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.investigatorFirstName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.issuingDept }}</clr-dg-cell>
    <clr-dg-cell>{{ result.grantWorkTag }}</clr-dg-cell>
    <clr-dg-cell>{{ result.costCenterId }}</clr-dg-cell>
    <clr-dg-cell>{{ result.awardYear }}</clr-dg-cell>
    <clr-dg-cell>{{ result.amendment }}</clr-dg-cell>
    <clr-dg-cell>{{ result.subrecipient }}</clr-dg-cell>
    <clr-dg-cell>{{ result.foreignEntity }}</clr-dg-cell>
    <clr-dg-cell>{{ result.poNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ result.subFromDate | date: 'shortDate' }}</clr-dg-cell>
    <clr-dg-cell>{{ result.subThruDate | date: 'shortDate' }}</clr-dg-cell>
    <clr-dg-cell>{{ result.totalCost | currency }}</clr-dg-cell>
    <clr-dg-cell>{{ result.subInvestigatorLastName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.subInvestigatorFirstName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.agreementNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ result.trackingNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ this.getStatus(result.subStatus) }}</clr-dg-cell>
    <clr-dg-cell>{{ result.riskCategory }}</clr-dg-cell>
    <clr-dg-cell>{{ result.countryName }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer data-test-id="results-amount"
    >{{
      projectSearchResults.length === 0 ? 'No' : projectSearchResults.length
    }}
    result{{ projectSearchResults.length === 1 ? '' : 's' }}</clr-dg-footer
  >
</clr-datagrid>
