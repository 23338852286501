<div class="card">
  <div class="card-header">Washington University Information</div>
  <div class="card-block" [formGroup]="form">
    <div *ngIf="showSubawardId" class="clr-row">
      <subs-static-text
        class="clr-col-md"
        label="Proposal ID"
        controlName="proposalId"
        labelLength="8"
      ></subs-static-text>

      <subs-static-text
        class="clr-col-md"
        label="Award Dept"
        controlName="awardDepartment"
        labelLength="6"
      ></subs-static-text>

      <subs-static-text
        class="clr-col-md"
        label="Subaward ID"
        controlName="subawardId"
        labelLength="6"
      ></subs-static-text>
    </div>

    <div *ngIf="!showSubawardId" class="clr-row">
      <subs-static-text
        class="clr-col-md-4"
        label="Proposal ID"
        controlName="proposalId"
        labelLength="8"
      ></subs-static-text>

      <subs-static-text
        class="clr-col-md"
        label="Award Dept"
        controlName="awardDepartment"
        labelLength="6"
      ></subs-static-text>
    </div>

    <div class="clr-row">
      <subs-static-text
        class="clr-col-4"
        label="Award Year"
        controlName="awardYear"
        labelLength="8"
      ></subs-static-text>

      <subs-input
        class="clr-col-4"
        label="Amendment"
        labelLength="6"
        controlName="amendment"
        inputStyle="width:50%"
      ></subs-input>

      <subs-subaward-status-select
        class="clr-col-4"
        controlName="status"
        labelLength="6"
        [statuses]="activeStatusList"
        [osrsDisabled]="!hasAccessToOsrsStatuses"
        [psoDisabled]="!hasAccessToOsrsStatuses"
        [disabled]="viewOnlyUser()"
      >
      </subs-subaward-status-select>
    </div>

    <div class="clr-row">
      <subs-static-text
        class="clr-col-12"
        label="Sponsor Name"
        controlName="sponsorName"
        labelLength="8"
      ></subs-static-text>
    </div>

    <div class="clr-row">
      <subs-static-text
        class="clr-col-12"
        labelLength="8"
        label="WU Principal Investigator"
        controlName="wuPrincipalInvestigator"
      ></subs-static-text>
    </div>

    <div class="clr-row">
      <subs-static-text
        class="clr-col-12"
        labelLength="8"
        label="Award Dept Name"
        controlName="awardDepartmentName"
      ></subs-static-text>
    </div>

    <div *ngIf="isActiveFeatureGrantWorkTag" class="card">
      <div class="card-header">Department Issuing Subaward</div>
      <div class="card-block">
        <div *ngIf="validatingGrantWorkTag" class="clr-row">
          <div class="clr-col-2"></div>
          <div class="clr-col-10">
            <clr-spinner clrInline> </clr-spinner>
            <span> Validating Grant Worktag </span>
          </div>
        </div>
        <div class="clr-row">
          <subs-input
            (focusout)="validateGrantWorkTag()"
            class="clr-col-6"
            label="Grant Worktag"
            controlName="grantWorkTag"
            maxLength="20"
            helperText="Enter Grant Worktag only"
          ></subs-input>

          <subs-input
            class="clr-col-6"
            label="Cost Center Name"
            controlName="costCenterName"
            maxLength="256"
          ></subs-input>
        </div>

        <div>
          <span style="font-size: 0.458rem; color: #737373">
            <em> of outgoing subaward </em></span
          >
        </div>

        <div class="clr-row">
          <subs-input
            class="clr-col-12"
            label="Cost Center"
            controlName="costCenterId"
            maxLength="20"
          ></subs-input>
        </div>

        <div class="clr-row">
          <subs-input
            class="clr-col-12"
            label="WashU PI's Name"
            controlName="principalInvestigatorName"
          ></subs-input>
        </div>
      </div>

      <div class="card-block">
        <div class="clr-row">
          <subs-input
            class="clr-col-4"
            label="WashU PI's Dept"
            controlName="issuingDepartment"
            maxLength="10"
          ></subs-input>

          <subs-input
            class="clr-col-4"
            label="Fund"
            controlName="fund"
            maxLength="7"
          ></subs-input>

          <div class="clr-col-4">
            <div class="clr-row">
              <subs-static-text
                class="clr-col-7"
                label="Sub Class"
                labelLength="3"
                controlName="subClass"
                maxLength="4"
              ></subs-static-text>
              <subs-input
                class="clr-col-5"
                labelLength="0"
                controlName="issuingFundSubClassBudgetObject"
                maxLength="3"
              ></subs-input>
            </div>
          </div>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-12"
            label="WashU PI's Dept Name"
            controlName="awardDepartmentName"
          ></subs-static-text>
        </div>
      </div>
    </div>

    <div *ngIf="!isActiveFeatureGrantWorkTag" class="card">
      <div class="card-header">Department Issuing Subaward</div>
      <div class="card-block">
        <div class="clr-row">
          <subs-input
            class="clr-col-4"
            label="WashU PI's Dept"
            controlName="issuingDepartment"
            maxLength="10"
          ></subs-input>

          <subs-input
            class="clr-col-4"
            label="Fund"
            controlName="fund"
            maxLength="7"
          ></subs-input>

          <div class="clr-col-4">
            <div class="clr-row">
              <subs-static-text
                class="clr-col-7"
                label="Sub Class"
                labelLength="3"
                controlName="subClass"
                maxLength="4"
              ></subs-static-text>
              <subs-input
                class="clr-col-5"
                labelLength="0"
                controlName="issuingFundSubClassBudgetObject"
                maxLength="3"
              ></subs-input>
            </div>
          </div>
        </div>
        <div class="clr-row">
          <subs-static-text
            class="clr-col-12"
            label="WashU PI's Dept Name"
            controlName="awardDepartmentName"
          ></subs-static-text>
        </div>
        <div class="clr-row">
          <subs-static-text
            class="clr-col-12"
            label="WashU PI's Name"
            controlName="principalInvestigatorName"
          ></subs-static-text>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">OSRS Only</div>
      <div class="card-block">
        <div class="clr-row">
          <subs-input
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col-4"
            label="WU Agreement Number"
            controlName="agreementNumber"
            maxLength="40"
          ></subs-input>
          <subs-static-text
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col-4"
            label="WU Agreement Number"
            controlName="agreementNumber"
            maxLength="40"
          ></subs-static-text>
          <subs-input
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col-4"
            label="WU Tracking Number"
            controlName="trackingNumber"
            maxLength="40"
          ></subs-input>
          <subs-static-text
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col-4"
            label="WU Tracking Number"
            controlName="trackingNumber"
            maxLength="40"
          ></subs-static-text>
          <subs-date
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            disabled
            class="clr-col"
            label="WU Signature Date"
            controlName="signatureDate"
          >
          </subs-date>
          <subs-static-text
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col"
            label="WU Signature Date"
            controlName="signatureDate"
          >
          </subs-static-text>
        </div>
        <div *ngIf="showGenerateAgreementNumber" class="clr-row">
          <div class="clr-col-4">
            <button
              class="btn btn-outline btn-sm"
              (click)="generateAgreementNumber()"
              [disabled]="!canGenerateNumber()"
            >
              Generate Agreement #
            </button>
          </div>
        </div>
        <div class="clr-row">
          <subs-select
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col-4"
            controlName="assignedGA"
            label="Current OSRS GA"
          >
            <option value=""></option>
            <option
              *ngFor="let name of this.assignedGAOptions"
              [value]="name"
              [selected]="name === form.controls.assignedGA.value"
            >
              {{ name }}
            </option>
          </subs-select>
          <subs-static-text
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col-4"
            controlName="assignedGA"
            label="Current OSRS GA"
          >
          </subs-static-text>
          <div class="clr-col-4">
            <div class="clr-col-10" style="float: left">
              <subs-static-text
                label="Previous OSRS GA"
                controlName="previousAssignedGA"
                maxLength="40"
              ></subs-static-text>
            </div>
            <div class="clr-col-2" style="float: left">
              <button
                *subsPermission="['OsrsAuthorityGlobalAccess']"
                type="button"
                class="btn btn-sm btn-icon"
                style="margin-top: 0.9rem; float: left"
                (click)="clearPreviousAssignedGA()"
              >
                <clr-icon shape="times" title="Clear"></clr-icon>
              </button>
            </div>
          </div>
          <subs-input
            *subsPermission="['OsrsAuthorityGlobalAccess']"
            class="clr-col-2"
            labelLength="0"
            placeholder="First Name"
            controlName="previousAssignedGAFirstName"
            maxLength="40"
          ></subs-input>
          <subs-input
            *subsPermission="['OsrsAuthorityGlobalAccess']"
            class="clr-col-2"
            labelLength="0"
            placeholder="Last Name"
            controlName="previousAssignedGALastName"
            maxLength="40"
          ></subs-input>
        </div>
        <div class="clr-row">
          <subs-input
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col-4"
            label="PO/CO Request ID"
            controlName="poCoDocId"
            maxLength="20"
            (focusout)="copyToSupplierContractId()"
          ></subs-input>
          <subs-static-text
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col-4"
            label="PO/CO Request ID"
            controlName="poCoDocId"
            maxLength="20"
          ></subs-static-text>
          <subs-input
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col-4"
            label="PO/Encumb ID"
            controlName="poCoEncumbId"
            maxLength="20"
          ></subs-input>
          <subs-static-text
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col-4"
            label="PO/Encumb ID"
            controlName="poCoEncumbId"
            maxLength="20"
          ></subs-static-text>
        </div>
        <div class="clr-row">
          <subs-input
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col-4"
            label="Supplier Contract ID"
            controlName="supplierContractId"
            maxLength="20"
          ></subs-input>
          <subs-static-text
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col-4"
            label="Supplier Contract ID"
            controlName="supplierContractId"
            maxLength="20"
          ></subs-static-text>
          <subs-select
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col-4"
            controlName="subcontractType"
            label="Subcontract Type *"
          >
            <option value=""></option>
            <option value="Fixed Price">Fixed Price</option>
            <option value="Cost Reimbursable">Cost Reimbursable</option>
          </subs-select>
          <subs-static-text
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col-4"
            controlName="subcontractType"
            label="Subcontract Type *"
          >
          </subs-static-text>
        </div>
        <div class="clr-row">
          <subs-yes-no
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col"
            controlName="farContract"
            label="FAR Contract *"
            labelMaxWidth="190px"
          >
          </subs-yes-no>
          <subs-yes-no
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col"
            controlName="farContract"
            label="FAR Contract *"
            labelMaxWidth="190px"
            [disabled]="true"
          >
          </subs-yes-no>
          <subs-yes-no
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col"
            controlName="otherTransactionalAgreement"
            label="OTA *"
            labelMaxWidth="190px"
          >
          </subs-yes-no>
          <subs-yes-no
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col"
            controlName="otherTransactionalAgreement"
            label="OTA *"
            labelMaxWidth="190px"
            [disabled]="true"
          >
          </subs-yes-no>
          <subs-yes-no
            *subsPermission="['WidgetOsrsOnlyUpdate']"
            class="clr-col"
            controlName="ffataReportable"
            label="FFATA Reportable *"
          >
          </subs-yes-no>
          <subs-yes-no
            *subsPermission="['WidgetOsrsOnlyRead']"
            class="clr-col"
            controlName="ffataReportable"
            label="FFATA Reportable *"
            [disabled]="true"
          >
          </subs-yes-no>
        </div>
      </div>
      <div *ngIf="showComplianceChecks" class="card">
        <div class="card-header">OSRS Compliance Checks</div>
        <div class="card-block">
          <div class="clr-row">
            <subs-checkbox
              class="clr-col-3"
              label="Debarment"
              labelLength="5"
              controlName="debarment"
            >
            </subs-checkbox>
            <subs-textarea
              class="clr-col"
              label="Comments"
              labelLength="4"
              [disabled]="!form.controls.debarment.value"
              controlName="debarmentComments"
              placeholder="Add a comment."
              errorMessage="Please insert a comment."
            >
            </subs-textarea>
          </div>
          <div class="clr-row">
            <subs-checkbox
              class="clr-col-3"
              label="Export Control"
              labelLength="5"
              controlName="exportControl"
            >
            </subs-checkbox>
            <subs-textarea
              class="clr-col"
              labelLength="4"
              label="Comments"
              [disabled]="!form.controls.exportControl.value"
              controlName="exportControlComments"
              placeholder="Add a comment."
              errorMessage="Please insert a comment."
            >
            </subs-textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
