<clr-modal [(clrModalOpen)]="isOpen">
  <h3 class="modal-title">
    You are about to delete
    {{
      InstitutionName === '' || InstitutionName === null ? 'an ' : ''
    }}institution {{ InstitutionName }}
  </h3>
  <div class="modal-body">
    <p>Are you sure you would like to delete it?</p>
    <subs-alert [alertBehavior]="alertSubject$"></subs-alert>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="isOpen = false">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      data-test-id="delete-institution"
      (click)="deleteInstitution()"
    >
      Delete
    </button>
  </div>
</clr-modal>
