import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Component({
  selector: 'subs-unauthorized',
  template: '',
})
export class UnauthorizedComponent implements OnInit {
  redirectUrl = new BehaviorSubject(null);

  constructor(private authService: AuthService, private route: ActivatedRoute) {
    this.route.queryParamMap
      .pipe(map(paramMap => paramMap.get('redirect')))
      .subscribe(this.redirectUrl);
  }

  ngOnInit(): void {
    this.login();
  }

  login(): void {
    const redirect = this.redirectUrl.getValue();
    this.authService.login(redirect);
  }
}
