<clr-datagrid>
  <clr-dg-column [clrDgField]="'id'">
    Institution Id
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'institutionName'" [clrDgSortOrder]="ascSort">
    Institution Name
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'status'">
    Status
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'dunsNumber'">
    Duns Number
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'uniqueEntityId'">
    UEI
  </clr-dg-column>
  <clr-dg-row
    data-test-id="collaborator-search-result-row"
    *clrDgItems="let result of institutionSearchResults"
    (click)="selectInstitution(result.id)"
  >
    <clr-dg-cell>{{ result.id }}</clr-dg-cell>
    <clr-dg-cell>{{ result.institutionName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.status }}</clr-dg-cell>
    <clr-dg-cell>{{ result.dunsNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ result.uniqueEntityId }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer data-test-id="institution-search-results-amount"
    >{{
      institutionSearchResults.length === 0
        ? 'No'
        : institutionSearchResults.length
    }}
    result{{ institutionSearchResults.length === 1 ? '' : 's' }}</clr-dg-footer
  >
</clr-datagrid>
