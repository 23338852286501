import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'subs-institution-address',
  templateUrl: './institution-address.component.html',
})
export class InstitutionAddressComponent {
  @Input() form: UntypedFormGroup;
  @Input() centralOfficeEmail: UntypedFormControl;
  constructor() {}
}
