import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'subs-user-dashbaord',
  templateUrl: './user-dashbaord.component.html',
  styleUrls: ['./user-dashbaord.component.scss']
})
export class UserDashbaordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
