import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import {
  Institution,
  InstitutionsClient,
  LookupItem,
  UtilityTableKeyEnum,
} from '../api.service';
import { FeedbackService } from '../form-layout/feedback.service';
import { dunsNumberValidator } from '../validators/duns-number-validator/duns-number-validator';
import { InstitutionSearchService } from './institution-search.service';
import { ExcelExportService } from '../reports/excel-export.service';
import { InstitutionStatusNamesEnum } from '../enums/institution-status-names.enum';
import { MainLayoutService } from '../main-layout/main-layout.service';
import { LookupItemsService } from '../utilities/lookup-items.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'subs-institution-search',
  templateUrl: './institution-search.component.html',
  styleUrls: ['./institution-search.component.scss'],
  providers: [FeedbackService],
})
export class InstitutionSearchComponent implements OnInit {
  alertSubject$ = this.feedbackService.alerts;
  submitState = this.feedbackService.submitState;
  showDeleted: boolean;
  userHasSearched = false;
  searchResult = Array<Institution>();
  searchResultCount = '';
  institutionStatusName = InstitutionStatusNamesEnum;
  options: LookupItem[] = [];

  shouldShowNoResultsMessage = this.submitState.pipe(
    map(
      submitState =>
        this.searchResult.length === 0 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  shouldShowMaxResultsMessage = this.feedbackService.submitState.pipe(
    map(
      submitState =>
        this.searchResult.length === 300 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private institutionsClient: InstitutionsClient,
    private institutionSearchService: InstitutionSearchService,
    private router: Router,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
    private excelExportService: ExcelExportService,
    private mainLayoutService: MainLayoutService,
    public lookupItemService: LookupItemsService,
  ) {
    this.mainLayoutService.toggleEditInstitutionSidebarVisibility(false);
    this.mainLayoutService.toggleAddInstitutionSidebarVisibility(false);
  }

  searchForm = this.fb.group({
    institutionName: '',
    dunsNumber: ['', dunsNumberValidator],
    uniqueEntityId: '',
    supplierId: '',
    riskCategory: '',
    country: '',
    samGov: '',
    foreignEntity: '',
    deleted: '',
  });

  ngOnInit(): void {
    if (this.authService.hasPermission('InstitutionUpdateAll')) {
      this.showDeleted = true;
    }
    this.lookupItemService
      .lookupItems(UtilityTableKeyEnum.CountryCodeLookup)
      .subscribe(value => {
        this.options = value;
      });
    this.route.queryParams.subscribe(params => {
      const populateFilters = params.query;
      if (populateFilters) {
        this.institutionSearchService.getSearchForm().subscribe(formValue => {
          if (formValue !== undefined && formValue !== null) {
            this.searchForm.setValue(formValue);
          }
        });
      }
    });
  }

  search() {
    this.institutionSearchService.select(this.searchForm.value);
    this.feedbackService.beginLoading();
    this.institutionsClient
      .search(
        this.searchForm.controls.dunsNumber.value,
        this.searchForm.controls.institutionName.value,
        undefined,
        this.searchForm.controls.uniqueEntityId.value,
        this.searchForm.controls.supplierId.value,
        this.searchForm.controls.riskCategory.value,
        this.searchForm.controls.country.value,
        this.searchForm.controls.samGov.value,
        this.searchForm.controls.foreignEntity.value,
        this.searchForm.controls.deleted.value,
        false,
        false,
        true,
      )
      .pipe(
        tap(val => {
          if (this.showDeleted) {
            this.searchResult = val;
          } else {
            this.searchResult = val.filter(i => i.isDeleted === false);
          }
          this.searchResultCount = `${
            this.searchResult.length === 0 ? 'No' : this.searchResult.length
          } result${val.length === 1 ? '' : 's'}`;
          this.userHasSearched = true;
        }),
        this.feedbackService.provideFeedback(),
      )
      .subscribe();
  }

  clear() {
    this.userHasSearched = false;
    this.searchForm.controls.institutionName.setValue('');
    this.searchForm.controls.dunsNumber.setValue('');
    this.searchForm.controls.uniqueEntityId.setValue('');
    this.searchForm.controls.supplierId.setValue('');
    this.searchForm.controls.riskCategory.setValue('');
    this.searchForm.controls.country.setValue('');
    this.searchForm.controls.samGov.setValue('');
    this.searchForm.controls.foreignEntity.setValue('');
    this.searchForm.controls.deleted.setValue('');
    this.searchResult = [];
    this.feedbackService.clearAlert();
  }

  addInstitution() {
    this.router.navigateByUrl('/institution');
  }

  private generateExportData(results: Array<Institution>) {
    const data = [];
    results.forEach(e => {
      data.push([
        e.id,
        e.institutionName ? e.institutionName : '',
        this.institutionStatusName[e.status],
        e.primaryInstitution ? 'Yes' : 'No',
        e.supplierId ? e.supplierId : '',
        e.riskCategory ? e.riskCategory : '',
        e.institutionAddress.countryName
          ? e.institutionAddress.countryName
          : '',
        e.samGov !== null ? this.getSamGov(e.samGov) : '',
        e.foreign != null ? this.getForeignEntity(e.foreign) : '',
        e.isDeleted != null ? this.getDeletedEntity(e.isDeleted) : '',
      ]);
    });
    return data;
  }

  getSamGov(samGov: boolean) {
    return samGov ? 'Yes' : 'No';
  }

  private getForeignEntity(foreign: boolean) {
    return foreign ? 'Yes' : 'No';
  }

  private getDeletedEntity(foreign: boolean) {
    return foreign ? 'Yes' : 'No';
  }

  async exportGrid() {
    if (this.searchResult.length < 300) {
      await this.generateReport(this.searchResult);
    } else {
      this.feedbackService.beginLoading();
      this.institutionsClient
        .search(
          this.searchForm.controls.dunsNumber.value,
          this.searchForm.controls.institutionName.value,
          undefined,
          this.searchForm.controls.uniqueEntityId.value,
          this.searchForm.controls.supplierId.value,
          this.searchForm.controls.riskCategory.value,
          this.searchForm.controls.country.value,
          this.searchForm.controls.samGov.value,
          this.searchForm.controls.foreignEntity.value,
          this.searchForm.controls.deleted.value,
          false,
          false,
          false,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async val => await this.generateReport(val));
    }
  }

  async generateReport(results: Array<Institution>) {
    const columns = [
      {
        header: 'Institution ID',
        width: 12,
      },
      {
        header: 'Institution Name',
        width: 55,
      },
      {
        header: 'Status',
        width: 16,
      },
      {
        header: 'Primary Institution',
        width: 17,
      },
      {
        header: 'Supplier ID',
        width: 20,
      },
      {
        header: 'Risk Category',
        width: 14,
      },
      {
        header: 'Country',
        width: 50,
      },
      {
        header: 'SAM.Gov',
        width: 10,
      },
      {
        header: 'Foreign Institution',
        width: 18,
      },
      {
        header: 'Deleted',
        width: 18,
      },
    ];

    const data = this.generateExportData(results);

    await this.excelExportService.generateExcel(
      'Institution Search ' + new Date().toLocaleString(),
      'Institution Search Result',
      data,
      columns,
    );
  }
}
