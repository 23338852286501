import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DelegatedLabeledComponent } from '../delegated-labeled.component';

@Component({
  selector: 'subs-currency-input',
  template: `
    <clr-input-container [style]="safeContainerStyle">
      <label class="clr-col-12 clr-col-md-auto" [style]="safeLabelStyle">{{
        label
      }}</label>

      <input
        clrInput
        class="clr-col-12 clr-col-md"
        [style]="safeInputStyle"
        [attr.data-test-id]="controlName"
        [name]="controlName"
        [formControlName]="controlName"
        [disabled]="disabled"
        [attr.maxLength]="maxLength"
        currencyMask
      />
      <clr-control-error>{{ errorMessage }}</clr-control-error>
    </clr-input-container>
  `,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class CurrencyInputComponent extends DelegatedLabeledComponent {
  @Input()
  maxLength?: number;

  @Input()
  errorMessage?: string;

  disabled: boolean;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }
}
