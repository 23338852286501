import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SubawardsService } from '../../../subawards.service';

@Component({
  selector: 'subs-awarded-budget-and-performance-period',
  templateUrl: './awarded-budget-and-performance-period.component.html',
})
export class AwardedBudgetAndPerformancePeriodComponent implements OnInit {
  showDescription: boolean;
  @Input() form: UntypedFormGroup;
  @Output() sameAsGrantPeriodClicked: EventEmitter<void> = new EventEmitter<
    void
  >();

  async copyGrantPeriod(checked: boolean) {
    this.sameAsGrantPeriodClicked.emit();
  }

  constructor(private subawardsService: SubawardsService) {}

  ngOnInit(): void {
    this.subawardsService.IndirectCostBaseMethod.subscribe(async value => {
      if (value) {
        this.showDescription = value.toLocaleLowerCase() === 'othr';
      }
    });
  }
}
