import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  SubrecipientStatusClient,
  SubrecipientStatusVm,
} from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class SubStatusService {
  private activeSubStatuses = new BehaviorSubject<
    Array<SubrecipientStatusVm> | []
  >(null);

  constructor(private subStatusClient: SubrecipientStatusClient) {}

  loadSubStatuses() {
    this.subStatusClient.getActiveStatuses().subscribe(result => {
      this.activeSubStatuses.next(result);
    });
  }

  get ActiveStatuses(): Observable<SubrecipientStatusVm[] | null> {
    return this.activeSubStatuses.asObservable();
  }
}
