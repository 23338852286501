<subs-select
  [controlName]="controlName"
  [label]="label"
  [labelLength]="labelLength"
>
  <option *ngFor="let item of this.options" [value]="item.key">{{
    item.value
  }}</option>
</subs-select>
<clr-control-error style="margin-left: 6rem;color: #a51417;">{{
  errorMessage
}}</clr-control-error>
