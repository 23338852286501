import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { AlertOptions } from 'src/app/alerts/alert-options';
import {
  SubrecipientQuestion,
  SubrecipientQuestionsClient,
} from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { markFormGroupTouched } from 'src/app/form-layout/markFormGroupTouched';

import { MonitoringQuestionFactory } from './monitoring-question-control-factory';

@Component({
  selector: 'subs-monitoring-questions',
  templateUrl: './monitoring-questions.component.html',
  providers: [FeedbackService],
})
export class MonitoringQuestionsComponent implements OnInit {
  question: UntypedFormGroup;
  alertOptions: AlertOptions = {
    message: '',
    canBeClosed: true,
    type: 'danger',
  };
  submitState = this.feedbackService.submitState;
  indexToDelete = -1;
  controlToDelete = '';
  deleteOpened = false;

  form = this.fb.group({
    questions: this.fb.array([]),
  });

  get questions(): UntypedFormArray {
    return this.form.get('questions') as UntypedFormArray;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private subrecipientQuestionClient: SubrecipientQuestionsClient,
    private controlFactory: MonitoringQuestionFactory,
    private feedbackService: FeedbackService,
    private chRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subrecipientQuestionClient
      .get()
      .pipe(tap(result => this.bindReturnCode(result)))
      .subscribe();
  }

  private bindReturnCode(questions: Partial<SubrecipientQuestion[]>) {
    this.questions.clear();
    this.controlFactory
      .createMany(questions.length)
      .forEach(control => this.questions.push(control));

    this.questions.reset(questions);
    this.chRef.detectChanges();
  }

  onAdd() {
    const sequenceNumbers = this.questions.value.map(o => o.sequenceNumber);
    const control = this.controlFactory.createControl({
      id: 0,
      question: '',
      isActive: true,
      sequenceNumber: Math.max(...sequenceNumbers) + 1,
    });
    this.questions.push(control);
  }

  save() {
    this.feedbackService.beginLoading();

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      this.feedbackService.alert(
        'Please fix all field errors before saving the form.',
      );
      return;
    }

    const value = this.questions.value;
    this.subrecipientQuestionClient
      .updateQuestions(value)
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(result => {
        this.bindReturnCode(result);
      });
  }

  deleteQuestion(index: number) {
    this.indexToDelete = index;
    this.controlToDelete = this.questions.value[index].sequenceNumber;
    this.deleteOpened = true;
  }

  cancelDeleteConfirmation() {
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.questions.removeAt(this.indexToDelete);
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }
}
