<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <subs-input
      class="clr-col-md"
      controlName="attachmentNum"
      label="Attachment Num"
      labelLenght="3"
      maxLength="3"
    ></subs-input>
    <subs-input
      class="clr-col-md"
      controlName="templateId"
      label="Template Id"
      labelLenght="3"
      errorMessage="Template Id must be a number"
    ></subs-input>
  </div>
  <div>
    <subs-input
      class="clr-col-md"
      controlName="description"
      label="Description"
      labelLenght="3"
      maxLength="128"
    ></subs-input>
  </div>
  <div class="clr-row">
    <div class="clr-col-6" style="margin-top:24px;">
      <subs-alert [alertBehavior]="alertSubject$"> </subs-alert>
      <button
        class="btn btn-primary"
        [clrLoading]="submitState | async"
        (click)="search()"
        data-test-id="search-attachment"
        [disabled]="!searchForm.valid"
      >
        Search
      </button>
      <button
        class="btn btn-outline"
        (click)="clear()"
        data-test-id="clear-attachment-search"
      >
        Clear
      </button>
      <button class="btn btn-outline" (click)="redirectToAddAttachment()">
        Add Attachment
      </button>
      <button
        *ngIf="this.searchResult.length > 0"
        class="btn btn-outline"
        (click)="exportGrid()"
        data-test-id="export-attachment-search"
      >
        Export
      </button>
    </div>
    <div
      class="clr-col-5"
      *ngIf="this.searchResult && this.searchResult.length >= 0"
    >
      <subs-checkbox
        label="Include Archived"
        controlName="includeArchived"
        class="clr-col"
        labelLength="5"
      >
      </subs-checkbox>
    </div>
    <div class="clr-col-1" style="margin-top:24px;padding-top:24px;">
      <span *ngIf="searchResult && searchResult.length >= 0">{{
        searchResultCount
      }}</span>
    </div>
  </div>
  <div
    *ngIf="shouldShowNoResultsMessage | async"
    class="alert alert-warning"
    role="alert"
  >
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
        </div>
        <span class="alert-text" data-test-id="alert-msg">
          There were no results for your search.
        </span>
      </div>
    </div>
  </div>

  <div
    *ngIf="shouldShowMaxResultsMessage | async"
    class="alert alert-warning"
    role="alert"
  >
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
        </div>
        <span class="alert-text" data-test-id="alert-msg">
          Only 300 attachments are being shown.
        </span>
      </div>
    </div>
  </div>
</form>
<subs-attachment-grid
  [attachmentSearchResults]="filteredResult"
  data-test-id="search-result-grid"
>
</subs-attachment-grid>
