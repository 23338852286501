<subs-fixed-header [header]="header"></subs-fixed-header>
<subs-form-layout [formGroup]="institutionForm">
  <subs-form-content>
    <subs-institution-header
      [form]="institutionForm"
      [options]="institutionTypes"
      [formSubmitted]="formSubmitted"
      [ueiRequiredIndicator]="ueiRequiredIndicator"
      [dunsRequiredIndicator]="dunsRequiredIndicator"
      [finQues1RequiredIndicator]="finQues1RequiredIndicator"
      [finQues2RequiredIndicator]="finQues2RequiredIndicator"
      (samGovUploaded)="samGovUploaded($event)"
    ></subs-institution-header>

    <subs-institution-address
      [centralOfficeEmail]="institutionForm.controls.centralOfficeEmail"
      [form]="institutionForm.controls.institutionAddress"
      data-test-id="institutionAddress"
    >
    </subs-institution-address>

    <subs-institution-contact
      title="Authorized Institutional Official (Signatory) of Collaborating Institution"
      [form]="institutionForm.controls.signatory"
      [instituteAddress]="institutionForm.controls.institutionAddress"
      data-test-id="signatory"
    ></subs-institution-contact>

    <subs-institution-contact
      title="Institutional Administrative Point of Contact of Collaborating Institution"
      [form]="institutionForm.controls.adminPointOfContact"
      [instituteAddress]="institutionForm.controls.institutionAddress"
      data-test-id="adminPointOfContact"
    >
    </subs-institution-contact>

    <subs-institution-contact
      title="Institutional Financial Contact of Collaborating Institution"
      [form]="institutionForm.controls.financeContact"
      [instituteAddress]="institutionForm.controls.institutionAddress"
      data-test-id="financeContact"
    ></subs-institution-contact>
  </subs-form-content>

  <subs-form-buttons>
    <div class="modal-body">
      <subs-institution-alert
        [duplicateInstitutionErrors]="alertDuplicatesSubject$"
      ></subs-institution-alert>
    </div>
    <button
      *ngIf="!viewOnlyUser()"
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="save(institutionForm.controls.id.value)"
      data-test-id="submit-institution"
    >
      Save
    </button>
    <button
      *ngIf="canSearch && !deptAdminUser() && !isEditFromSubaward()"
      class="btn btn-outline"
      (click)="redirectToSearchPage()"
    >
      Return to Search
    </button>
    <button
      class="btn btn-outline"
      *ngIf="
        institutionForm.controls.status.value == 'IP' && !isEditFromSubaward()
      "
      (click)="showConversionModal = true"
      data-test-id="btn-conversion-process"
    >
      Convert
    </button>
    <button
      *ngIf="canDelete && !viewOnlyUser() && !isEditFromSubaward()"
      class="btn btn-outline"
      (click)="showDeleteModal = true"
      data-test-id="delete-institution"
    >
      Delete
    </button>

    <button
      *ngIf="isEditFromSubaward()"
      class="btn btn-outline"
      (click)="cancelEdit()"
      data-test-id="cancel-edit-institution"
    >
      Cancel
    </button>
  </subs-form-buttons>
</subs-form-layout>

<subs-institution-conversion
  [form]="institutionForm"
  [isOpen]="showConversionModal"
  (wizardClosed)="closeConversion($event)"
  (wizardFinished)="convertionSave($event)"
>
</subs-institution-conversion>
<subs-delete-modal
  (modalClose)="closeModal($event)"
  [isOpen]="showDeleteModal"
  [Id]="institutionForm.controls.id.value"
  data-test-id="institution-delete-modal"
  [InstitutionName]="institutionForm.controls.institutionName.value"
>
</subs-delete-modal>
<clr-modal
  [(clrModalOpen)]="showUnsavedChangesModal"
  [clrModalClosable]="false"
>
  <h3 class="modal-title">
    You have unsaved changes.
  </h3>
  <div class="modal-body">
    <subs-alert [alertBehavior]="alertSubject$"></subs-alert>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="confirmSave()"
      data-test-id="confirm-save-before-navigate"
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="discardChanges()"
    >
      Discard
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="cancelNavigation()"
    >
      Cancel
    </button>
  </div>
</clr-modal>
