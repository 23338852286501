import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OfficerControlFactory } from './officer-control-factory/officer-control-factory';
import { InstitutionOfficerVm } from '../../api.service';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'subs-ffata',
  templateUrl: './ffata.component.html',
  styleUrls: ['./ffata.component.scss'],
})
export class FfataComponent implements OnInit {
  @Input()
  subawardForm: UntypedFormGroup;
  @Input()
  ffataDescription: UntypedFormControl;
  @Input()
  institutionOfficers: UntypedFormControl;

  officerCount = 0;
  disabled = false;
  showMaxOfficersInfo = false;
  deleteOpened = false;
  indexToDelete = -1;
  controlToDelete = '';
  officer: UntypedFormGroup;
  form = this.fb.group({
    officers: this.fb.array([]),
  });

  get officers(): UntypedFormArray {
    return this.form.get('officers') as UntypedFormArray;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private controlFactory: OfficerControlFactory,
    private chRef: ChangeDetectorRef,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    if (this.institutionOfficers && this.institutionOfficers.value) {
      this.setOfficers(this.institutionOfficers.value);
    }

    this.form.controls.officers.valueChanges.subscribe(value => {
      this.institutionOfficers.patchValue(value);
    });
  }

  private setOfficers(officers: Partial<InstitutionOfficerVm[]>) {
    this.officers.clear();
    this.controlFactory
      .createMany(officers.length)
      .forEach(control => this.officers.push(control));

    this.officers.reset(officers);
    this.chRef.detectChanges();

    this.officerCount = officers.length;
    this.setOfficerCountRules(this.officerCount);
  }

  onAdd() {
    if (this.officerCount <= 5) {
      const control = this.controlFactory.createControl({
        id: 0,
        institutionId: this.subawardForm.controls.subrecipientInformation.get(
          'institutionId',
        ).value,
        subrecipientId: this.subawardForm.controls.id.value,
        officerName: null,
        compensation: null,
        isActive: true,
        createdDate: new Date(),
        createdBy: this.authService.currentUser.name,
        modifiedDate: new Date(),
        modifiedBy: this.authService.currentUser.name,
      });
      this.officers.push(control);
    }

    this.officerCount++;
    this.setOfficerCountRules(this.officerCount);
  }

  private setOfficerCountRules(officerCount: number) {
    this.disabled = officerCount >= 5 ? true : false;
    this.showMaxOfficersInfo = officerCount >= 5 ? true : false;
  }

  deleteOfficer(index: number) {
    this.indexToDelete = index;
    this.controlToDelete = this.officers.value[index];
    this.deleteOpened = true;
  }

  cancelDeleteConfirmation() {
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.officers.removeAt(this.indexToDelete);
    this.indexToDelete = -1;
    this.deleteOpened = false;
    this.officerCount--;
    this.setOfficerCountRules(this.officerCount);
  }
}
