import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GetNoticeOfAward as NoticeOfAward } from 'src/app/api.service';

@Injectable()
export class NoaControlFactory {
  constructor(private fb: UntypedFormBuilder) {}

  createNoaControl(value: NoticeOfAward): UntypedFormGroup {
    const control = this.createGroup();
    control.setValue(value);
    return control;
  }

  createMany(count: number): UntypedFormGroup[] {
    const output = [];

    for (let i = 0; i < count; i++) {
      output.push(this.createGroup());
    }

    return output;
  }

  private createGroup() {
    return this.fb.group({
      id: [],
      selected: [],
      dateUploaded: [],
      uploadedBy: [],
      fileId: [],
      fileName: [],
    });
  }
}
