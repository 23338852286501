<div class="clr-row" style="height: 100%;">
  <div class="clr-col-12" style="height: 100%;">
    <div class="card  announcement-card">
      <div class="card-header">
        <div class="clr-row">
          <div class="clr-col-6">
            <h3>Announcements</h3>
          </div>
          <div class="clr-col-6" style="display: flex; justify-content: end">
            <button
              class="btn btn-sm btn-link"
              (click)="showAllAnnouncements()"
            >
              Show All
            </button>
          </div>
        </div>
      </div>

      <div class="card-block">
        <!-- Innner card -->
        <div
          class="card"
          *ngFor="
            let announcement of activeAnnouncements$ | async;
            let i = index
          "
        >
          <div class="card-block">
            <p class="card-text">
              <b>
                {{ announcement.date | date: 'MM/dd/yyyy'
                }}<span class="tab"></span>
              </b>
              {{ announcement.announcementText }}
            </p>
            <a
              *ngIf="announcement.announcementLink !== null"
              [href]="announcement.announcementLink"
              target="_blank"
              >{{ announcement.announcementLink }}</a
            >
          </div>
        </div>
        <!-- End of Inner Card -->
      </div>
    </div>
  </div>
  <clr-modal [(clrModalOpen)]="showAllOpened">
    <h3 class="modal-title">
      All Announcements
    </h3>
    <div class="modal-body">
      <div
        class="card"
        *ngFor="let announcement of allAnnouncements$ | async; let i = index"
      >
        <div class="card-block">
          <p class="card-text">
            <b
              >{{ announcement.date | date: 'MM/dd/yyyy'
              }}<span class="tab"></span
            ></b>
            <b>{{ announcement.status }} - </b>
            {{ announcement.announcementText }}
          </p>
          <a
            *ngIf="announcement.announcementLink !== null"
            [href]="announcement.announcementLink"
            target="_blank"
            >{{ announcement.announcementLink }}</a
          >
        </div>
      </div>
    </div>
  </clr-modal>
</div>
