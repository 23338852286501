import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  issuer: window.environment.securityIssuer,
  redirectUri: window.environment.redirectUri,
  postLogoutRedirectUri: window.location.origin,
  clientId: window.environment.securityClientId,
  responseType: 'code',
  scope: window.environment.securityScopes,
  showDebugInformation: true,
};
