import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Router } from '@angular/router';
import { Attachment } from 'src/app/api.service';
import { UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'subs-attachment-grid',
  templateUrl: './attachment-grid.component.html',
  styleUrls: ['./attachment-grid.component.scss'],
})
export class AttachmentGridComponent {
  @Input() attachmentSearchResults: Array<Attachment>;
  @Input() contractAttachments: UntypedFormArray;
  ascSort = ClrDatagridSortOrder.ASC;

  constructor(private router: Router) {
    this.attachmentSearchResults = [];
  }

  navigateToAttachment(id: number) {
    this.router.navigateByUrl(`/attachment/${id}`);
  }

  getAttachmentStatus(archived: boolean) {
    return archived === true ? 'Archived' : 'Active';
  }
}
