<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <subs-date
      controlName="dateFrom"
      labelLength="3"
      class="clr-col-4"
      errorMessage="Required field"
    >
    </subs-date>
    <subs-input
      class="clr-col-4"
      controlName="cchStart"
      label="Start CCH Rollup"
      labelLength="5"
      helperText="CCH Rollup is a numeric value."
    ></subs-input>
    <clr-control-error *ngIf="searchForm.controls.cchStart.invalid"
      >CCH Rollup must be a numeric value.</clr-control-error
    >
  </div>
  <div class="clr-row">
    <subs-date
      controlName="dateThru"
      labelLength="3"
      class="clr-col-4"
      errorMessage="Required field"
    >
    </subs-date>
    <subs-input
      class="clr-col-4"
      controlName="cchEnd"
      label="End CCH Rollup"
      labelLength="5"
      labelWidth="98px"
      helperText="CCH Rollup is a numeric value."
    ></subs-input>
    <clr-control-error *ngIf="searchForm.controls.cchEnd.invalid"
      >CCH Rollup must be a numeric value.</clr-control-error
    >
  </div>
  <div class="clr-row">
    <div class="clr-col-4"></div>
    <subs-input
      class="clr-col-4"
      controlName="fisLegacyDeptNumber"
      label="FIS Legacy System Dept. Number"
      labelLength="5"
      helperText="FIS department number associated with outgoing subaward."
    ></subs-input>
    <clr-control-error *ngIf="searchForm.controls.fisLegacyDeptNumber.invalid"
      >Department Number must be a numeric value.</clr-control-error
    >
  </div>
  <div style="margin-top: 24px">
    <subs-alert [alertBehavior]="alertSubject"> </subs-alert>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="search()"
      data-test-id="search-btn"
      [disabled]="!searchForm.valid"
    >
      Submit
    </button>

    <button class="btn btn-outline" (click)="clear()" data-test-id="clear-btn">
      Clear
    </button>
  </div>
</form>
