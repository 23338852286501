import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DelegatedLabeledComponent } from '../delegated-labeled.component';

@Component({
  selector: 'subs-static-text',
  template: `
    <clr-input-container [style]="safeContainerStyle">
      <label class="clr-col-12 clr-col-md-auto" [style]="safeLabelStyle">{{
        label
      }}</label>
      <input
        clrInput
        class="clr-col-12 clr-col-md"
        [style]="safeInputStyle"
        [attr.data-test-id]="controlName"
        [name]="controlName"
        [formControlName]="controlName"
        readonly="true"
      />
      <clr-control-helper *ngIf="helperText">{{
        helperText
      }}</clr-control-helper>
    </clr-input-container>
  `,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class StaticTextComponent extends DelegatedLabeledComponent {
  @Input()
  helperText?: string;
  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }
}
