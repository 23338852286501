import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AnnouncementsClient, Announcement } from 'src/app/api.service';

@Component({
  selector: 'subs-dashboard-announcements',
  templateUrl: './dashboard-announcements.component.html',
  styleUrls: ['./dashboard-announcements.component.scss'],
})
export class DashboardAnnouncementsComponent implements OnInit {
  activeAnnouncements$: Observable<Announcement[]>;
  allAnnouncements$: Observable<Announcement[]>;
  showAllOpened = false;
  dateString = '';
  constructor(private Announcementervice: AnnouncementsClient) {}

  ngOnInit() {
    this.getAnnouncments();
  }
  getAnnouncments() {
    this.Announcementervice.get().subscribe(val => {
      let announcmentArr: Announcement[] = [];
      val.forEach(announcement => {
        if (announcement.status === 'Active') {
          announcmentArr.push(announcement);
        }
      });

      this.activeAnnouncements$ = of(
        announcmentArr.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1)),
      );
    });
  }
  showAllAnnouncements() {
    this.showAllOpened = true;
    this.Announcementervice.get().subscribe(val => {
      let announcmentArr: Announcement[] = [];
      val.forEach(announcement => {
        announcmentArr.push(announcement);
      });

      this.allAnnouncements$ = of(
        announcmentArr.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1)),
      );
    });
  }
}

interface AnnouncementDate {
  announcment: Announcement;
  dateString: string;
}
