import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';

import { Employee } from '../employee.model';

@Component({
  selector: 'subs-employee-lookup-grid',
  templateUrl: './employee-lookup-grid.component.html',
  styleUrls: ['./employee-lookup-grid.component.scss'],
})
export class EmployeeLookupGridComponent {
  @Input() employeeLookupSearchResults: Employee[] = [];

  @Output() employeeSelected = new EventEmitter<Employee>();

  ascSort = ClrDatagridSortOrder.ASC;

  selectEmployee(employee: Employee) {
    this.employeeSelected.emit(employee);
  }
}
