import { Injectable } from '@angular/core';
import * as Excel from 'exceljs';
import {
  Subaward,
  SubrecipientWashUInformation,
  SubrecipientInformation,
  SubawardContact,
  SubrecipientPlaceOfPerformance,
  SubrecipientContact,
  InstitutionOfficerVm,
} from '../api.service';
import { DateConvert } from '../converters/date-convert/date-convert';

@Injectable()
export class ExcelReadService {
  constructor() {}

  public loadInsitutionalInformation(blob: Blob, callback) {
    if (blob !== null) {
      const wb = new Excel.Workbook();
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);

      reader.onload = () => {
        const buffer = reader.result as Buffer;
        wb.xlsx.load(buffer).then(workbook => {
          const sheet = workbook.getWorksheet(2);
          const subaward: Subaward = {
            washUInformation: this.getWashuInformation(sheet),
            subrecipientInformation: this.getSubrecipientInformation(sheet),
            ffataDescription: sheet
              .getRow(43)
              .getCell(1)
              .toString(),
            contact: this.getSubawardContact(sheet),
            institutionOfficers: this.getInstitutionOfficers(sheet),
          };
          callback(subaward);
        });
      };
    }
  }

  private getWashuInformation(
    sheet: Excel.Worksheet,
  ): SubrecipientWashUInformation {
    const washUInformation: SubrecipientWashUInformation = {
      amendment: Number(
        sheet
          .getRow(3)
          .getCell(4)
          .toString()
          .trim(),
      ),
      issuingDepartment: sheet
        .getRow(4)
        .getCell(4)
        .toString()
        .trim(),
      fund: sheet
        .getRow(2)
        .getCell(6)
        .toString()
        .trim(),
      issuingFundSubClassBudgetObject: sheet
        .getRow(3)
        .getCell(6)
        .toString()
        .trim(),
    };
    return washUInformation;
  }

  private getSubawardContact(sheet: Excel.Worksheet): SubawardContact {
    const contacts: SubawardContact = {
      principalInvestigator: this.getPrincipalInvestigator(sheet),
      signatory: this.getSignatory(sheet),
      institutionalAdmin: this.getInstitutionalAdmin(sheet),
      institutionalFinancial: this.getInstutionFinancial(sheet),
    };
    return contacts;
  }

  private getSubrecipientInformation(
    sheet: Excel.Worksheet,
  ): SubrecipientInformation {
    const subrecipientInformation: SubrecipientInformation = {
      placeOfPerformance: this.getPrimaryPlaceOfPerformance(sheet),
      budget: {
        estimatedProjectPeriodFromDate: DateConvert.convertDateToLocalCulture(
          new Date(
            sheet
              .getRow(141)
              .getCell(2)
              .toString(),
          )
            .toISOString()
            .slice(0, 10),
        ),
        estimatedProjectPeriodToDate: DateConvert.convertDateToLocalCulture(
          new Date(
            sheet
              .getRow(141)
              .getCell(3)
              .toString(),
          )
            .toISOString()
            .slice(0, 10),
        ),
        estimatedTotalFunding: sheet
          .getRow(142)
          .getCell(2)
          .toString(),
        costSharingAmount: sheet
          .getRow(144)
          .getCell(2)
          .toString(),
        fAndARate: sheet
          .getRow(145)
          .getCell(2)
          .toString(),
        indirectCostBaseMethod: sheet
          .getRow(146)
          .getCell(2)
          .toString(),
        directCost: sheet
          .getRow(147)
          .getCell(2)
          .toString(),
        indirectCost: sheet
          .getRow(148)
          .getCell(2)
          .toString(),
      },
      animalSubjects:
        sheet
          .getRow(149)
          .getCell(2)
          .toString() === 'Y',
      humanSubjects:
        sheet
          .getRow(150)
          .getCell(2)
          .toString() === 'Y',
      clinicalResearch:
        sheet
          .getRow(151)
          .getCell(2)
          .toString() === 'Y',
    };
    return subrecipientInformation;
  }

  private getPrimaryPlaceOfPerformance(
    sheet: Excel.Worksheet,
  ): SubrecipientPlaceOfPerformance {
    const placeOfPerformance: SubrecipientPlaceOfPerformance = {
      placeDiffersFromContactsPage:
        sheet
          .getRow(125)
          .getCell(2)
          .toString() === 'Y',
      institutionName: sheet
        .getRow(126)
        .getCell(2)
        .toString(),
      address: {
        addressLine1: sheet
          .getRow(127)
          .getCell(2)
          .toString(),
        addressLine2: sheet
          .getRow(128)
          .getCell(2)
          .toString(),
        addressLine3: sheet
          .getRow(129)
          .getCell(2)
          .toString(),
        addressLine4: sheet
          .getRow(130)
          .getCell(2)
          .toString(),
        city: sheet
          .getRow(131)
          .getCell(2)
          .toString(),
        state: sheet
          .getRow(132)
          .getCell(4)
          .toString(),
        zip: sheet
          .getRow(134)
          .getCell(2)
          .toString(),
        countryCode: sheet
          .getRow(135)
          .getCell(4)
          .toString()
          .trim(),
        congressionalDistrict: sheet
          .getRow(136)
          .getCell(2)
          .toString(),
      },
      phone: sheet
        .getRow(137)
        .getCell(2)
        .toString(),
      fax: sheet
        .getRow(138)
        .getCell(2)
        .toString(),
      emailAddress: sheet
        .getRow(139)
        .getCell(2)
        .toString(),
    };
    return placeOfPerformance;
  }

  private getPrincipalInvestigator(
    sheet: Excel.Worksheet,
  ): SubrecipientContact {
    const principalInvestigator: SubrecipientContact = {
      firstName: sheet
        .getRow(50)
        .getCell(2)
        .toString(),
      lastName: sheet
        .getRow(51)
        .getCell(2)
        .toString(),
      primaryDivision: sheet
        .getRow(52)
        .getCell(2)
        .toString(),
      phone: sheet
        .getRow(53)
        .getCell(2)
        .toString(),
      fax: sheet
        .getRow(54)
        .getCell(2)
        .toString(),
      emailAddress: sheet
        .getRow(55)
        .getCell(2)
        .toString(),
      address: {
        addressLine1: sheet
          .getRow(57)
          .getCell(2)
          .toString(),
        addressLine2: sheet
          .getRow(58)
          .getCell(2)
          .toString(),
        addressLine3: sheet
          .getRow(59)
          .getCell(2)
          .toString(),
        addressLine4: sheet
          .getRow(60)
          .getCell(2)
          .toString(),
        city: sheet
          .getRow(61)
          .getCell(2)
          .toString(),
        state: sheet
          .getRow(62)
          .getCell(4)
          .toString(),
        zip: sheet
          .getRow(64)
          .getCell(2)
          .toString(),
        countryCode: sheet
          .getRow(65)
          .getCell(4)
          .toString(),
      },
    };
    return principalInvestigator;
  }

  private getSignatory(sheet: Excel.Worksheet): SubrecipientContact {
    const signatory: SubrecipientContact = {
      firstName: sheet
        .getRow(67)
        .getCell(2)
        .toString(),
      middleName: sheet
        .getRow(68)
        .getCell(2)
        .toString(),
      lastName: sheet
        .getRow(69)
        .getCell(2)
        .toString(),
      nameSuffix: sheet
        .getRow(70)
        .getCell(2)
        .toString(),
      jobTitle: sheet
        .getRow(71)
        .getCell(2)
        .toString(),
      phone: sheet
        .getRow(72)
        .getCell(2)
        .toString(),
      fax: sheet
        .getRow(73)
        .getCell(2)
        .toString(),
      emailAddress: sheet
        .getRow(74)
        .getCell(2)
        .toString(),
      address: {
        addressLine1: sheet
          .getRow(76)
          .getCell(2)
          .toString(),
        addressLine2: sheet
          .getRow(77)
          .getCell(2)
          .toString(),
        addressLine3: sheet
          .getRow(78)
          .getCell(2)
          .toString(),
        addressLine4: sheet
          .getRow(79)
          .getCell(2)
          .toString(),
        city: sheet
          .getRow(80)
          .getCell(2)
          .toString(),
        state: sheet
          .getRow(81)
          .getCell(4)
          .toString(),
        zip: sheet
          .getRow(83)
          .getCell(2)
          .toString(),
        countryCode: sheet
          .getRow(84)
          .getCell(4)
          .toString(),
      },
    };
    return signatory;
  }

  private getInstitutionalAdmin(sheet: Excel.Worksheet): SubrecipientContact {
    const institutionalAdmin: SubrecipientContact = {
      firstName: sheet
        .getRow(86)
        .getCell(2)
        .toString(),
      middleName: sheet
        .getRow(87)
        .getCell(2)
        .toString(),
      lastName: sheet
        .getRow(88)
        .getCell(2)
        .toString(),
      nameSuffix: sheet
        .getRow(89)
        .getCell(2)
        .toString(),
      jobTitle: sheet
        .getRow(90)
        .getCell(2)
        .toString(),
      phone: sheet
        .getRow(91)
        .getCell(2)
        .toString(),
      fax: sheet
        .getRow(92)
        .getCell(2)
        .toString(),
      emailAddress: sheet
        .getRow(93)
        .getCell(2)
        .toString(),
      address: {
        addressLine1: sheet
          .getRow(95)
          .getCell(2)
          .toString(),
        addressLine2: sheet
          .getRow(96)
          .getCell(2)
          .toString(),
        addressLine3: sheet
          .getRow(97)
          .getCell(2)
          .toString(),
        addressLine4: sheet
          .getRow(98)
          .getCell(2)
          .toString(),
        city: sheet
          .getRow(99)
          .getCell(2)
          .toString(),
        state: sheet
          .getRow(100)
          .getCell(4)
          .toString(),
        zip: sheet
          .getRow(102)
          .getCell(2)
          .toString(),
        countryCode: sheet
          .getRow(103)
          .getCell(4)
          .toString(),
      },
    };
    return institutionalAdmin;
  }

  private getInstutionFinancial(sheet: Excel.Worksheet): SubrecipientContact {
    const institutionalFinancial: SubrecipientContact = {
      firstName: sheet
        .getRow(105)
        .getCell(2)
        .toString(),
      middleName: sheet
        .getRow(106)
        .getCell(2)
        .toString(),
      lastName: sheet
        .getRow(107)
        .getCell(2)
        .toString(),
      nameSuffix: sheet
        .getRow(108)
        .getCell(2)
        .toString(),
      jobTitle: sheet
        .getRow(109)
        .getCell(2)
        .toString(),
      phone: sheet
        .getRow(110)
        .getCell(2)
        .toString(),
      fax: sheet
        .getRow(111)
        .getCell(2)
        .toString(),
      emailAddress: sheet
        .getRow(112)
        .getCell(2)
        .toString(),
      invoiceEmail: sheet
        .getRow(113)
        .getCell(2)
        .toString(),
      address: {
        addressLine1: sheet
          .getRow(115)
          .getCell(2)
          .toString(),
        addressLine2: sheet
          .getRow(116)
          .getCell(2)
          .toString(),
        addressLine3: sheet
          .getRow(117)
          .getCell(2)
          .toString(),
        addressLine4: sheet
          .getRow(118)
          .getCell(2)
          .toString(),
        city: sheet
          .getRow(119)
          .getCell(2)
          .toString(),
        state: sheet
          .getRow(120)
          .getCell(4)
          .toString(),
        zip: sheet
          .getRow(122)
          .getCell(2)
          .toString(),
        countryCode: sheet
          .getRow(123)
          .getCell(4)
          .toString(),
      },
    };
    return institutionalFinancial;
  }

  private getInstitutionOfficers(
    sheet: Excel.Worksheet,
  ): InstitutionOfficerVm[] {
    const officers: InstitutionOfficerVm[] = [];

    if (
      sheet
        .getRow(22)
        .getCell(2)
        .toString() !== ''
    ) {
      const officer1: InstitutionOfficerVm = {
        officerName: sheet
          .getRow(22)
          .getCell(2)
          .toString(),
        compensation: +sheet
          .getRow(22)
          .getCell(3)
          .toString(),
      };
      officers.push(officer1);
    }

    if (
      sheet
        .getRow(23)
        .getCell(2)
        .toString() !== ''
    ) {
      const officer2: InstitutionOfficerVm = {
        officerName: sheet
          .getRow(23)
          .getCell(2)
          .toString(),
        compensation: +sheet
          .getRow(23)
          .getCell(3)
          .toString(),
      };
      officers.push(officer2);
    }

    if (
      sheet
        .getRow(24)
        .getCell(2)
        .toString() !== ''
    ) {
      const officer3: InstitutionOfficerVm = {
        officerName: sheet
          .getRow(24)
          .getCell(2)
          .toString(),
        compensation: +sheet
          .getRow(24)
          .getCell(3)
          .toString(),
      };
      officers.push(officer3);
    }

    if (
      sheet
        .getRow(25)
        .getCell(2)
        .toString() !== ''
    ) {
      const officer4: InstitutionOfficerVm = {
        officerName: sheet
          .getRow(25)
          .getCell(2)
          .toString(),
        compensation: +sheet
          .getRow(25)
          .getCell(3)
          .toString(),
      };
      officers.push(officer4);
    }

    if (
      sheet
        .getRow(26)
        .getCell(2)
        .toString() !== ''
    ) {
      const officer5: InstitutionOfficerVm = {
        officerName: sheet
          .getRow(26)
          .getCell(2)
          .toString(),
        compensation: +sheet
          .getRow(26)
          .getCell(3)
          .toString(),
      };
      officers.push(officer5);
    }

    return officers;
  }
}
