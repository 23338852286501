<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <div class="clr-col-6">
      <subs-input
        label="Institution Name"
        placeholder="Name"
        controlName="institutionName"
        labelLength="6"
        helperText="Will return institutions that contain the entered text."
      >
      </subs-input>

      <subs-input
        label="Duns Number"
        placeholder="Number"
        controlName="dunsNumber"
        labelLength="6"
        helperText="Will return institutions that start with the entered duns number (without hyphens).."
        errorMessage="Please enter the number without hyphens"
      >
      </subs-input>

      <subs-input
        label="UEI"
        placeholder="Number"
        controlName="uniqueEntityId"
        labelLength="6"
        helperText="Will return institutions that start with the entered unique entity identifier."
      >
      </subs-input>

      <subs-input
        label="Supplier ID"
        placeholder="Supplier ID"
        controlName="supplierId"
        labelLength="6"
        helperText="Will return institutions that start with the entered supplier id."
      >
      </subs-input>
    </div>

    <div class="clr-col-6">
      <subs-country
        [options]="options"
        controlName="country"
        label="Country"
        labelLength="6"
        data-test-id="countryNameTest"
      ></subs-country>

      <div class="clr-row">
        <div class="clr-col-6 select-margin-top">
          <subs-select
            controlName="riskCategory"
            labelLength="6"
            data-test-id="riskCategoryTest"
          >
            <option value=""></option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
          </subs-select>
        </div>
        <div class="clr-col-6 select-margin-top">
          <subs-select
            controlName="foreignEntity"
            label="Foreign Institution"
            labelLength="6"
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </subs-select>
        </div>
      </div>

      <div class="clr-row">
        <div class="clr-col-6 select-margin-top">
          <subs-select
            controlName="samGov"
            label="SAM.gov"
            labelLength="6"
            data-test-id="samGovTest"
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </subs-select>
        </div>
      </div>
      <div *subsPermission="['InstitutionUpdateAll']" class="clr-row">
        <div class="clr-col-6 select-margin-top">
          <subs-select
            controlName="deleted"
            label="Deleted"
            labelLength="6"
            data-test-id="deletedTest"
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </subs-select>
        </div>
      </div>
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-11" style="margin-top:24px;">
      <subs-alert [alertBehavior]="alertSubject$"> </subs-alert>
      <button
        class="btn btn-primary"
        [clrLoading]="submitState | async"
        (click)="search()"
        data-test-id="search-institution"
      >
        Search
      </button>

      <button
        class="btn btn-outline"
        (click)="clear()"
        data-test-id="clear-institution-search"
      >
        Clear
      </button>

      <button
        *subsPermission="['AccessInstitutionAddPage']"
        class="btn btn-outline"
        (click)="addInstitution()"
        data-test-id="add-institution"
      >
        Add Institution
      </button>

      <button
        *ngIf="searchResult.length"
        class="btn btn-outline"
        [clrLoading]="submitState | async"
        (click)="exportGrid()"
        data-test-id="export-grid-btn"
      >
        Export
      </button>
    </div>
    <div class="clr-col-1" style="margin-top:24px;padding-top:24px;">
      <span *ngIf="searchResult && searchResult.length >= 0">{{
        searchResultCount
      }}</span>
    </div>
  </div>

  <div *ngIf="shouldShowNoResultsMessage | async">
    There were no results for your search.
  </div>

  <div
    *ngIf="shouldShowMaxResultsMessage | async"
    class="alert alert-warning"
    role="alert"
  >
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
        </div>
        <span class="alert-text" data-test-id="alert-msg">
          Only 300 institutions are being shown. Export to see the whole list
        </span>
      </div>
    </div>
  </div>
</form>

<subs-institutions-grid
  [institutionSearchResults]="searchResult"
  data-test-id="search-result-grid"
>
</subs-institutions-grid>
