import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LookupItem, Subaward } from '../../api.service';

@Component({
  selector: 'subs-institution-header',
  templateUrl: './institution-header.component.html',
})
export class InstitutionHeaderComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() formSubmitted: boolean;
  @Input() ueiRequiredIndicator: string;
  @Input() dunsRequiredIndicator: string;
  @Input() finQues1RequiredIndicator: string;
  @Input() finQues2RequiredIndicator: string;
  @Input() options: LookupItem[];

  @Output() samGovUploaded: EventEmitter<any> = new EventEmitter<File>();

  congressionalDistrictLabel = '* Congressional district';

  constructor() {}

  ngOnInit() {
    this.form.controls.foreign.valueChanges.subscribe(value => {
      this.setForeignInstitutionDependantLabels(value);
    });
  }

  callback = (file: File, fileId: string) => {
    if (file) {
      this.samGovUploaded.emit({ file, fileId });
    } else {
      this.form.markAsDirty(); // SAM.gov image deleted
    }
  };

  private setForeignInstitutionDependantLabels(value: any) {
    this.congressionalDistrictLabel = value
      ? 'Congressional district'
      : '* Congressional district';
  }

  setErrorMessage(value: string) {
    const invalidControl = this.form.controls[value].invalid;
    return invalidControl && this.formSubmitted ? 'A value is required.' : '';
  }
}
