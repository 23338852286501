import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubrecipientContractAttachment } from 'src/app/api.service';

@Injectable()
export class ContractAttachmentControlFactory {
  constructor(private fb: UntypedFormBuilder) {}

  createContractAttachmentControl(
    value: SubrecipientContractAttachment,
  ): UntypedFormGroup {
    const control = this.createGroup();
    control.setValue(value);
    return control;
  }

  createMany(count: number): UntypedFormGroup[] {
    const output = [];

    for (let i = 0; i < count; i++) {
      output.push(this.createGroup());
    }

    return output;
  }

  private createGroup() {
    return this.fb.group({
      id: [],
      fileName: [],
      fileId: [],
      fullAgreement: [],
      source: [],
      signaturePage: [],
      signed: [],
      sequence: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[1-9]{0,1}[0-9].[0-9]{1}$'),
        ]),
      ],
      created: [],
      unilateral: [],
      utilitiesAttachmentTemplateId: [],
    });
  }
}
