import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { AlertOptions } from '../../alerts/alert-options';
import {
  LookupItem,
  ProjectSearchClient,
  ProjectSearchResult,
  SubrecipientStatusVm,
  UtilityTableKeyEnum,
} from '../../api.service';
import { ExcelExportService } from 'src/app/reports/excel-export.service';
import { LookupItemsService } from '../../utilities/lookup-items.service';
import { SearchProjectService } from './search-project.service';
import { Location } from '@angular/common';
import { SubStatusService } from '../../utilities/subrecipient-status/sub-status.service';

@Component({
  selector: 'subs-search-project',
  templateUrl: './search-project.component.html',
  styleUrls: ['./search-project.component.scss'],
  providers: [FeedbackService],
})
export class SearchProjectComponent implements OnInit {
  submitState = this.feedbackService.submitState;
  userHasSearched = false;
  searchResult = Array<ProjectSearchResult>();
  searchResultCount = '';
  options: LookupItem[] = [];
  activeStatusList: SubrecipientStatusVm[] = [];

  searchForm = this.fb.group({
    proposalId: [''],
    awardDepartment: '',
    grantWorkTag: [''],
    costCenterId: [''],
    pONumber: [''],
    awardNumber: [''],
    pILastName: '',
    pIFirstName: '',
    subPIFirstName: '',
    subPILastName: '',
    sponsor: '',
    subrecipient: '',
    agreementNumber: '',
    status: '',
    riskCategory: '',
    country: '',
    foreignEntity: '',
    maxResults: [
      '300',
      [Validators.required, Validators.pattern('^[1-9]\\d*$')],
    ],
    foundResults: ['', { disabled: true }],
  });

  alertOptions: AlertOptions = {
    message: 'There was an error processing your request.',
    canBeClosed: true,
    type: 'danger',
  };

  alertSubject$ = this.feedbackService.alerts;

  shouldShowNoResultsMessage = this.feedbackService.submitState.pipe(
    map(
      (submitState) =>
        this.searchResult.length === 0 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private projectSearchClient: ProjectSearchClient,
    private feedbackService: FeedbackService,
    private excelExportService: ExcelExportService,
    public lookupItemService: LookupItemsService,
    private searchProjectService: SearchProjectService,
    private subStatusService: SubStatusService,
  ) {}

  ngOnInit(): void {
    this.subStatusService.ActiveStatuses.subscribe(async (value) => {
      if (value !== undefined && value !== null) {
        this.activeStatusList = value;
      }
    });
    this.lookupItemService
      .lookupItems(UtilityTableKeyEnum.CountryCodeLookup)
      .subscribe((value) => {
        this.options = value;
      });
    this.searchForm.valueChanges.subscribe((formValue) => {
      if (
        formValue !== undefined &&
        formValue !== null &&
        this.searchForm.touched
      ) {
        this.searchProjectService.setSearchFormValue(formValue);
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params.query) {
        this.search();
        this.location.replaceState(`/find-subawards`);
      }
    });
  }

  search() {
    this.feedbackService.beginLoading();
    this.searchProjectService
      .getSearchFormValue()
      .subscribe((searchFormValue) => {
        if (searchFormValue !== undefined && searchFormValue !== null) {
          if (!isEqual(searchFormValue, this.searchForm.value)) {
            this.searchForm.setValue(searchFormValue);
            this.searchProjects();
          }
        }
      });
  }

  searchProjects() {
    this.feedbackService.beginLoading();
    this.projectSearchClient
      .get(
        this.searchForm.controls.proposalId.value,
        this.searchForm.controls.awardDepartment.value,
        this.searchForm.controls.grantWorkTag.value,
        this.searchForm.controls.costCenterId.value,
        this.searchForm.controls.pONumber.value,
        this.searchForm.controls.awardNumber.value,
        this.searchForm.controls.subrecipient.value,
        this.searchForm.controls.pILastName.value,
        this.searchForm.controls.pIFirstName.value,
        this.searchForm.controls.agreementNumber.value,
        this.searchForm.controls.status.value,
        this.searchForm.controls.subPILastName.value,
        this.searchForm.controls.subPIFirstName.value,
        this.searchForm.controls.sponsor.value,
        this.searchForm.controls.riskCategory.value,
        this.searchForm.controls.country.value,
        this.searchForm.controls.foreignEntity.value,
        this.searchForm.controls.maxResults.value,
      )
      .pipe(
        tap(
          (val) => {
            this.searchResult = val;
            const foundProjects = val.length > 0 ? val.length : 0;
            this.searchForm.controls.foundResults.setValue(foundProjects);
            this.searchResultCount = `${
              val.length === 0 ? 'No' : val.length
            } result${val.length === 1 ? '' : 's'}`;
            this.userHasSearched = true;
          },
          (err) => {
            this.userHasSearched = false;
          },
        ),
        this.feedbackService.provideFeedback(),
      )
      .subscribe();
  }

  clear() {
    this.userHasSearched = false;
    this.searchForm.controls.proposalId.setValue('');
    this.searchForm.controls.awardDepartment.setValue('');
    this.searchForm.controls.grantWorkTag.setValue('');
    this.searchForm.controls.costCenterId.setValue('');
    this.searchForm.controls.pONumber.setValue('');
    this.searchForm.controls.awardNumber.setValue('');
    this.searchForm.controls.pIFirstName.setValue('');
    this.searchForm.controls.pILastName.setValue('');
    this.searchForm.controls.subPIFirstName.setValue('');
    this.searchForm.controls.subPILastName.setValue('');
    this.searchForm.controls.subrecipient.setValue('');
    this.searchForm.controls.agreementNumber.setValue('');
    this.searchForm.controls.status.setValue('');
    this.searchForm.controls.sponsor.setValue('');
    this.searchForm.controls.riskCategory.setValue('');
    this.searchForm.controls.country.setValue('');
    this.searchForm.controls.foreignEntity.setValue('');
    this.searchForm.controls.foundResults.setValue('');
    this.searchResult = [];
    this.feedbackService.clearAlert();
    this.location.replaceState(`/find-subawards`);
  }

  private generateExportData(results: Array<ProjectSearchResult>) {
    const data = [];
    results.forEach((e) => {
      data.push([
        e.subAwardId,
        e.proposalId,
        e.investigatorLastName,
        e.investigatorFirstName,
        e.issuingDept,
        e.grantWorkTag,
        e.costCenterId,
        e.awardYear,
        e.amendment,
        e.subrecipient,
        e.foreignEntity,
        e.poNumber ? e.poNumber : '',
        e.subFromDate ? e.subFromDate : '',
        e.subThruDate ? e.subThruDate : '',
        '$' + e.totalCost,
        e.subInvestigatorLastName,
        e.subInvestigatorFirstName,
        e.agreementNumber ? e.agreementNumber : '',
        e.trackingNumber ? e.trackingNumber : '',
        this.getStatusDescription(e.subStatus),
        e.riskCategory,
        e.countryName,
      ]);
    });
    return data;
  }

  private getStatusDescription(statusCode: string) {
    const activeStatus = this.activeStatusList.find(
      (status) => status.statusCode === statusCode,
    );
    return activeStatus ? activeStatus.statusDescription : '';
  }

  async exportGrid() {
    const columns = [
      {
        header: 'Sub ID',
        width: 10,
      },
      {
        header: 'Proposal ID',
        width: 12,
      },
      {
        header: 'WashU PI Last Name',
        width: 20,
      },
      {
        header: 'WashU PI First Name',
        width: 20,
      },
      {
        header: 'Award Dept',
        width: 15,
      },
      {
        header: 'Grant Worktag',
        width: 15,
      },
      {
        header: 'PI Cost Center',
        width: 15,
      },
      {
        header: 'Award Year',
        width: 12,
      },
      {
        header: 'Amendment',
        width: 12,
      },
      {
        header: 'Subrecipient',
        width: 55,
      },
      {
        header: 'Foreign Institution',
        width: 18,
      },
      {
        header: 'PO Number',
        width: 15,
      },
      {
        header: 'Sub From Date',
        width: 20,
      },
      {
        header: 'Sub Thru Date',
        width: 20,
      },
      {
        header: 'Total Cost',
        width: 15,
      },
      {
        header: 'Sub PI Last Name',
        width: 20,
      },
      {
        header: 'Sub PI First Name',
        width: 20,
      },
      {
        header: 'Agreement Num',
        width: 20,
      },
      {
        header: 'Tracking Num',
        width: 15,
      },
      {
        header: 'Sub Status',
        width: 20,
      },
      {
        header: 'Sub Risk Category',
        width: 18,
      },
      {
        header: 'Country',
        width: 50,
      },
    ];

    const data = this.generateExportData(this.searchResult);

    await this.excelExportService.generateExcel(
      'Project Search ' + new Date().toLocaleString(),
      'Project Search Result',
      data,
      columns,
    );
  }
}
