import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagridSortOrder } from '@clr/angular';
import { SubawardsClient, SubrecipientStatusVm } from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { AlertOptions } from '../../../alerts/alert-options';
import { SubawardSummary } from '../../../api.service';

@Component({
  selector: 'subs-subawards-grid',
  templateUrl: './subawards-grid.component.html',
  styleUrls: ['./subawards-grid.component.scss'],
  providers: [FeedbackService],
})
export class SubawardsGridComponent {
  @Input() subawardSummaryResults: Array<SubawardSummary>;
  @Input() statuses: SubrecipientStatusVm[] = [];
  ascSort = ClrDatagridSortOrder.ASC;
  collaboratorIdToDelete: number | undefined;
  collaboratorNameToDelete: string | undefined;
  alertSubject$ = this.feedbackService.alerts;
  submitState = this.feedbackService.submitState;

  alertOptions: AlertOptions = {
    message: 'There was en error deleting the selected collaborator.',
    canBeClosed: true,
    type: 'danger',
  };

  constructor(
    private router: Router,
    private subawardsClient: SubawardsClient,
    private feedbackService: FeedbackService,
  ) {
    this.subawardSummaryResults = [];
  }

  setCollaboratorToDelete(id, collaboratorNameToDelete, event: any) {
    this.collaboratorIdToDelete = id;
    this.collaboratorNameToDelete = collaboratorNameToDelete;
    event.stopPropagation();
  }

  navigateToSubaward(id) {
    this.router.navigateByUrl('/subaward/' + id);
  }

  getStatus(statusCode: string) {
    const activeStatus = this.statuses.find(
      status => status.statusCode === statusCode,
    );
    return activeStatus ? activeStatus.statusDescription : '';
  }

  cancelDeleteConfirmation() {
    this.collaboratorIdToDelete = null;
  }

  confirmDelete() {
    this.feedbackService.beginLoading();

    this.subawardsClient
      .delete(this.collaboratorIdToDelete)
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(() => {
        const index = this.subawardSummaryResults.findIndex(
          x => x.id === this.collaboratorIdToDelete,
        );
        if (index > -1) {
          this.subawardSummaryResults.splice(index, 1);
        }

        this.collaboratorIdToDelete = null;
      });
  }
}
