<clr-alert
  [clrAlertType]="alertOptions.type"
  [(clrAlertClosed)]="alertOptions.isClosed"
  [clrAlertClosable]="alertOptions.canBeClosed || true"
>
  <clr-alert-item>
    <span class="alert-text" data-test-id="alert-message">
      {{ alertOptions.message }}
    </span>
  </clr-alert-item>
</clr-alert>
