<div class="form-container">
  <form
    clrForm
    [formGroup]="form"
    style="
      flex-grow: 1;
      overflow-y: scroll;
      margin: -24px -24px 0 0;
      padding: 24px 24px 24px 0;
    "
  >
    <div class="card">
      <div class="card-header">
        Washington University Contacts
      </div>
      <div class="card-block">
        <div class="clr-row">
          <subs-static-text
            class="clr-col"
            controlName="id"
            label="Institution ID"
          ></subs-static-text>
          <clr-input-container class="clr-col">
            <label class="clr-col-12 clr-col-md-auto">Institution Status</label>
            <input
              clrInput
              class="clr-col-12 clr-col-md"
              [value]="
                this.form.controls.status.value === 'C'
                  ? 'Complete'
                  : this.form.controls.status.value
              "
              readonly="true"
            />
          </clr-input-container>
        </div>
        <div class="clr-row">
          <subs-static-text
            class="clr-col"
            controlName="institutionName"
            label="Institution"
          ></subs-static-text>
        </div>
      </div>
    </div>
    <subs-university-addresses
      [institutionAddress]="form.controls.institutionAddress"
      [osrsAddress]="form.controls.osrsAddress"
    >
    </subs-university-addresses>
    <div class="card">
      <div class="card-header">
        Contact Information
      </div>
      <div class="card-block">
        <div *ngFor="let contact of contacts.controls; index as i">
          <subs-university-contact
            [form]="contacts.controls[i]"
            [contactIndex]="i"
          >
          </subs-university-contact>
        </div>
        <button
          class="btn btn-outline"
          style="margin-top:20px;"
          [clrLoading]="submitState | async"
          (click)="addContact()"
          data-test-id="add-contact-btn"
        >
          Add Contact
        </button>
      </div>
    </div>
  </form>
  <div class="card form-footer">
    <subs-alert [alertBehavior]="alertSubject$"> </subs-alert>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="save()"
      data-test-id="submit-washu-save"
    >
      Save
    </button>
  </div>
</div>
