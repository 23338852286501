import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { AlertOptions } from 'src/app/alerts/alert-options';
import {
  DAWorklistClient,
  DAWorklistViewModel,
  SubrecipientStatusVm,
} from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { ExcelExportService } from '../excel-export.service';
import { SubStatusService } from '../../utilities/subrecipient-status/sub-status.service';

@Component({
  selector: 'subs-da-worklist',
  templateUrl: './da-worklist.component.html',
  providers: [FeedbackService],
})
export class DaWorklistComponent {
  submitState = this.feedbackService.submitState;
  userHasSearched = false;
  searchResult = Array<DAWorklistViewModel>();
  activeStatusList: SubrecipientStatusVm[] = [];
  searchResultCount = '';
  yearList: number[] = [];
  yearMonthError = '';

  months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];

  searchForm = this.fb.group({
    wUPILastName: '',
    proposalId: [''],
    grantWorkTag: [''],
    costCenterId: [''],
    sponsorName: '',
    subPILastName: '',
    wUPIFirstName: '',
    awardDept: '',
    poNumber: [''],
    status: '',
    subrecipient: '',
    OSRSStatus: '',
    assignedGA: '',
    foreignEntity: '',
    budgetYear: [''],
    budgetMonth: [''],
  });

  alertOptions: AlertOptions = {
    message: 'There was an error processing your request.',
    canBeClosed: true,
    type: 'danger',
  };

  alertSubject$ = this.feedbackService.alerts;

  shouldShowNoResultsMessage = this.feedbackService.submitState.pipe(
    map(
      (submitState) =>
        this.searchResult.length === 0 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  shouldShowMaxResultsMessage = this.feedbackService.submitState.pipe(
    map(
      (submitState) =>
        this.searchResult.length === 300 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  constructor(
    private fb: UntypedFormBuilder,
    private daworkClient: DAWorklistClient,
    private excelExportService: ExcelExportService,
    private feedbackService: FeedbackService,
    private subStatusService: SubStatusService,
  ) {
    this.populateYearList();
    this.subStatusService.ActiveStatuses.subscribe(async (value) => {
      if (value !== undefined && value !== null) {
        this.activeStatusList = value;
      }
    });
    this.searchForm.valueChanges.subscribe(() => {
      this.validateYearMonthSelection();
    });
  }

  private populateYearList() {
    const startYear = 2012;
    const currentYear = new Date().getFullYear();
    this.yearList = [];
    for (let year = startYear; year <= currentYear + 3; year++) {
      this.yearList.push(year);
    }
  }

  validateYearMonthSelection() {
    const year = this.searchForm.controls.budgetYear.value;
    const month = this.searchForm.controls.budgetMonth.value;

    if (!year && month) {
      this.yearMonthError = 'Must select year if month selected';
      this.searchForm.controls.budgetYear.setErrors({ required: true });
    } else {
      this.yearMonthError = '';
      this.searchForm.controls.budgetYear.setErrors(null);
    }
  }

  search() {
    this.feedbackService.beginLoading();
    this.daworkClient
      .get(
        this.searchForm.controls.wUPILastName.value,
        this.searchForm.controls.wUPIFirstName.value,
        this.searchForm.controls.sponsorName.value,
        this.searchForm.controls.proposalId.value,
        this.searchForm.controls.awardDept.value,
        this.searchForm.controls.grantWorkTag.value,
        this.searchForm.controls.costCenterId.value,
        this.searchForm.controls.poNumber.value,
        this.searchForm.controls.status.value,
        this.searchForm.controls.subrecipient.value,
        this.searchForm.controls.subPILastName.value,
        this.searchForm.controls.OSRSStatus.value === '' ? false : true,
        this.searchForm.controls.assignedGA.value,
        this.searchForm.controls.foreignEntity.value,
        this.searchForm.controls.budgetYear.value,
        this.searchForm.controls.budgetMonth.value,
        true,
      )
      .pipe(
        tap(
          (val) => {
            this.searchResult = val;
            this.searchResultCount = `${
              val.length === 0 ? 'No' : val.length
            } result${val.length === 1 ? '' : 's'}`;
            this.userHasSearched = true;
          },
          (err) => {
            this.userHasSearched = false;
          },
        ),
        this.feedbackService.provideFeedback(),
      )
      .subscribe();
  }

  clear() {
    this.userHasSearched = false;
    this.searchForm.controls.wUPILastName.setValue('');
    this.searchForm.controls.wUPIFirstName.setValue('');
    this.searchForm.controls.sponsorName.setValue('');
    this.searchForm.controls.proposalId.setValue('');
    this.searchForm.controls.awardDept.setValue('');
    this.searchForm.controls.grantWorkTag.setValue('');
    this.searchForm.controls.costCenterId.setValue('');
    this.searchForm.controls.poNumber.setValue('');
    this.searchForm.controls.status.setValue('');
    this.searchForm.controls.subrecipient.setValue('');
    this.searchForm.controls.subPILastName.setValue('');
    this.searchForm.controls.OSRSStatus.setValue('');
    this.searchForm.controls.assignedGA.setValue('');
    this.searchForm.controls.foreignEntity.setValue('');
    this.searchForm.controls.budgetYear.setValue('');
    this.searchForm.controls.budgetMonth.setValue('');
    this.searchResult = [];
    this.feedbackService.clearAlert();
  }

  async downloadReport() {
    if (this.searchResult.length < 300) {
      await this.generateReport(this.searchResult);
    } else {
      this.feedbackService.beginLoading();
      this.daworkClient
        .get(
          this.searchForm.controls.wUPILastName.value,
          this.searchForm.controls.wUPIFirstName.value,
          this.searchForm.controls.sponsorName.value,
          this.searchForm.controls.proposalId.value,
          this.searchForm.controls.awardDept.value,
          this.searchForm.controls.grantWorkTag.value,
          this.searchForm.controls.costCenterId.value,
          this.searchForm.controls.poNumber.value,
          this.searchForm.controls.status.value,
          this.searchForm.controls.subrecipient.value,
          this.searchForm.controls.subPILastName.value,
          this.searchForm.controls.OSRSStatus.value === '' ? false : true,
          this.searchForm.controls.assignedGA.value,
          this.searchForm.controls.foreignEntity.value,
          this.searchForm.controls.budgetYear.value,
          this.searchForm.controls.budgetMonth.value,
          false,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async (val) => await this.generateReport(val));
    }
  }

  private generateReportData(results: Array<DAWorklistViewModel>) {
    const data = [];
    results.forEach((e) => {
      data.push([
        e.proposalId ? e.proposalId : '',
        e.assignedGA ? e.assignedGA : '',
        e.wupiLastName ? e.wupiLastName : '',
        e.wupiFirstName ? e.wupiFirstName : '',
        e.sponsorName ? e.sponsorName : '',
        e.grantWorkTag ? e.grantWorkTag : '',
        e.costCenterId ? e.costCenterId : '',
        e.poNumber ? e.poNumber : '',
        e.awardYear ? e.awardYear : '',
        e.returnedDate ? new Date(e.budgetThrough).toLocaleDateString() : '',
        e.subrecipient ? e.subrecipient : '',
        e.foreignEntity ? e.foreignEntity : '',
        e.subPILastName ? e.subPILastName : '',
        this.getStatusDescription(e.status),
        e.submittedDate ? new Date(e.submittedDate).toLocaleDateString() : '',
        e.returnedDate ? new Date(e.returnedDate).toLocaleDateString() : '',
        e.awardDept ? e.awardDept : '',
        e.agreementNumber ? e.agreementNumber : '',
        e.trackingNumber ? e.trackingNumber : '',
      ]);
    });
    return data;
  }

  private getStatusDescription(statusCode: string) {
    const activeStatus = this.activeStatusList.find(
      (status) => status.statusCode === statusCode,
    );
    return activeStatus ? activeStatus.statusDescription : '';
  }

  async generateReport(results: Array<DAWorklistViewModel>) {
    const columns = [
      {
        header: 'Proposal ID',
        width: 12,
      },
      {
        header: 'OSRS GA',
        width: 12,
      },
      {
        header: 'WU PI Last Name',
        width: 16,
      },
      {
        header: 'WU PI First Name',
        width: 16,
      },
      {
        header: 'Sponsor Name',
        width: 40,
      },
      {
        header: 'Grant Worktag',
        width: 15,
      },
      {
        header: 'PI Cost Center',
        width: 15,
      },
      {
        header: 'PO Number',
        width: 15,
      },
      {
        header: 'Award Year',
        width: 12,
      },
      {
        header: 'Current Budget to Date',
        width: 28,
      },
      {
        header: 'Subrecipient',
        width: 80,
      },
      {
        header: 'Foreign Institution',
        width: 18,
      },
      {
        header: 'Sub PI Last Name',
        width: 16,
      },
      {
        header: 'Status',
        width: 30,
      },
      {
        header: 'Submitted',
        width: 19,
      },
      {
        header: 'Returned',
        width: 19,
      },
      {
        header: 'Award Dept',
        width: 15,
      },
      {
        header: 'Agreement Num',
        width: 20,
      },
      {
        header: 'Tracking Num',
        width: 15,
      },
    ];

    const data = this.generateReportData(results);

    await this.excelExportService.generateExcel(
      'DA_Worklist',
      'DA Worklist Report',
      data,
      columns,
    );
  }
}
