import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LookupItem, UtilityClient, UtilityTableKeyEnum } from '../api.service';
import { ClrLoadingState } from '@clr/angular';

@Injectable({
  providedIn: 'root',
})
export class LookupItemsService {
  private countryCodesSubject = new BehaviorSubject<LookupItem[]>([]);
  private stateCodesSubject = new BehaviorSubject<LookupItem[]>([]);
  private institutionTypesSubject = new BehaviorSubject<LookupItem[]>([]);
  private userRoleSubject = new BehaviorSubject<LookupItem[]>([]);
  private rolePermissionSubject = new BehaviorSubject<LookupItem[]>([]);
  private loadingStateSubject = new BehaviorSubject<ClrLoadingState>(
    ClrLoadingState.DEFAULT,
  );

  constructor(private utilityClient: UtilityClient) {}

  get loadingState(): Observable<ClrLoadingState> {
    return this.loadingStateSubject.asObservable();
  }

  lookupItems(lookupItemsKey: string): Observable<LookupItem[]> {
    switch (lookupItemsKey) {
      case UtilityTableKeyEnum.CountryCodeLookup:
        return this.countryCodesSubject.asObservable();
        break;
      case UtilityTableKeyEnum.StateCodeLookup:
        return this.stateCodesSubject.asObservable();
        break;
      case UtilityTableKeyEnum.InstitutionTypeLookup:
        return this.institutionTypesSubject.asObservable();
        break;
      case UtilityTableKeyEnum.UserRoleLookup:
        return this.userRoleSubject.asObservable();
        break;
      case UtilityTableKeyEnum.RolePermissionLookup:
        return this.rolePermissionSubject.asObservable();
        break;
      default:
        return null;
    }
  }

  loadLookupItems(lookupItemsKey: string) {
    this.loadingStateSubject.next(ClrLoadingState.LOADING);
    this.utilityClient.getAllLookupItems(lookupItemsKey).subscribe(
      result => {
        const items = result.lookupItems;
        if (lookupItemsKey === UtilityTableKeyEnum.CountryCodeLookup) {
          items.sort((a: LookupItem, b: LookupItem) =>
            a.value.localeCompare(b.value),
          );
          const usaOption = {
            value: 'U.S.A.',
            key: 'USA',
          };
          items.unshift(usaOption);
        }
        const firstOption = {
          value: '',
          key: '',
        };
        items.unshift(firstOption);

        switch (lookupItemsKey) {
          case UtilityTableKeyEnum.CountryCodeLookup:
            this.countryCodesSubject.next(items);
            break;
          case UtilityTableKeyEnum.StateCodeLookup:
            this.stateCodesSubject.next(items);
            break;
          case UtilityTableKeyEnum.InstitutionTypeLookup:
            items.sort((a, b) => {
              const key1 = +a.key;
              const key2 = +b.key;
              return key1 < key2 ? -1 : key1 > key2 ? 1 : 0;
            });
            this.institutionTypesSubject.next(items);
            break;
          case UtilityTableKeyEnum.UserRoleLookup:
            items.shift();
            this.userRoleSubject.next(items);
            break;
          case UtilityTableKeyEnum.RolePermissionLookup:
            this.rolePermissionSubject.next(items);
            break;
        }
        this.loadingStateSubject.next(ClrLoadingState.SUCCESS);
      },
      err => {
        this.loadingStateSubject.next(ClrLoadingState.ERROR);
      },
    );
  }
}
