import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DelegatedLabeledComponent } from '../delegated-labeled.component';

@Component({
  selector: 'subs-textarea',
  template: `
    <clr-textarea-container>
      <label class="clr-col-12 clr-col-md-auto" [style]="safeLabelStyle">{{
        label
      }}</label>

      <textarea
        clrTextarea
        class="clr-col-12 clr-col-md"
        [style]="safeInputStyle"
        [attr.data-test-id]="controlName"
        [name]="controlName"
        [formControlName]="controlName"
        [attr.disabled]="disabled ? 'disabled' : null"
        [attr.maxLength]="maxLength"
        [placeholder]="placeholder || ''"
      ></textarea>
      <clr-control-error>{{ errorMessage }}</clr-control-error>
    </clr-textarea-container>
  `,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class TextareaComponent extends DelegatedLabeledComponent {
  @Input()
  maxLength?: number;

  @Input()
  errorMessage?: string;

  @Input()
  placeholder?: string;

  @Input()
  disabled?: boolean;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }
}
