<clr-modal [(clrModalOpen)]="isModalOpen" [clrModalSize]="'lg'">
  <h3 class="modal-title">
    Look Up Employee
  </h3>

  <div class="modal-body">
    <form clrForm [formGroup]="searchForm">
      <div class="clr-row" style="margin-top:0;">
        <subs-input
          *ngIf="allowSearchByEmployeeId"
          class="clr-col"
          label="Employee ID"
          controlName="employeeId"
          labelLength="4"
          helperText="Exact match. Last name and first name are ignored if employee id is provided."
        ></subs-input>
      </div>

      <div class="clr-row">
        <subs-input
          class="clr-col"
          controlName="lastName"
          labelLength="4"
          maxLength="30"
          helperText="Starts with the entered text. Enter minimum of 2 characters."
          errorMessage="Enter minimum of 2 characters."
        ></subs-input>

        <subs-input
          class="clr-col"
          controlName="firstName"
          labelLength="4"
          maxLength="30"
          helperText="Starts with the entered text. Enter minimum of 2 characters."
          errorMessage="Enter minimum of 2 characters."
        ></subs-input>
      </div>
      <div
        *ngIf="shouldShowMaxResultsMessage | async"
        class="alert alert-warning"
        role="alert"
      >
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <clr-icon
                class="alert-icon"
                shape="exclamation-triangle"
              ></clr-icon>
            </div>
            <span class="alert-text" data-test-id="alert-msg">
              Only the first 500 results can be displayed
            </span>
          </div>
        </div>
      </div>
      <div
        *ngIf="shouldShowNoResultsMessage | async"
        class="alert alert-warning"
        role="alert"
      >
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <clr-icon
                class="alert-icon"
                shape="exclamation-triangle"
              ></clr-icon>
            </div>
            <span class="alert-text" data-test-id="alert-msg">
              There were no results for your search.
            </span>
          </div>
        </div>
      </div>
    </form>

    <subs-employee-lookup-grid
      [employeeLookupSearchResults]="employeeLookupSearchResults | async"
      (employeeSelected)="selectEmployee($event)"
      data-test-id="search-result-grid"
    >
    </subs-employee-lookup-grid>
  </div>

  <div class="modal-footer">
    <subs-alert
      [alertBehavior]="alertSubject$"
      style="margin-top: -0.25rem; flex: 1;"
    >
    </subs-alert>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="search()"
      [disabled]="!this.searchForm.valid"
      data-test-id="search-employee"
    >
      Search
    </button>

    <button
      class="btn btn-outline"
      (click)="clear()"
      data-test-id="clear-employee-search"
    >
      Clear
    </button>
    <button type="button" class="btn btn-outline" (click)="isModalOpen = false">
      Cancel
    </button>
  </div>
</clr-modal>
