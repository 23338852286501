import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DelegatedLabeledComponent } from '../delegated-labeled.component';

@Component({
  selector: 'subs-radio',
  template: `
    <clr-radio-container [style]="safeContainerStyle">
      <label class="clr-col-12 clr-col-md-auto" [style]="safeLabelStyle">{{
        label
      }}</label>
      <clr-radio-wrapper>
        <input
          class="clr-col-12 clr-col-md"
          type="radio"
          clrRadio
          [name]="controlName"
          [value]="value"
          [attr.disabled]="disabled"
          [formControlName]="controlName"
          [style]="safeInputStyle"
          [attr.data-test-id]="controlName"
        />
      </clr-radio-wrapper>
    </clr-radio-container>
  `,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class RadioComponent extends DelegatedLabeledComponent {
  @Input() disabled: boolean;
  @Input() value: string;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }
}
