import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ShortcutLink, ShortcutLinksClient } from 'src/app/api.service';

@Component({
  selector: 'subs-dashboard-weblinks',
  templateUrl: './dashboard-weblinks.component.html',
  styleUrls: ['./dashboard-weblinks.component.scss'],
})
export class DashboardWeblinksComponent implements OnInit {
  shortcutLinks$: Observable<ShortcutLink[]>;

  constructor(private shortcutService: ShortcutLinksClient) {}

  ngOnInit() {
    this.shortcutService.get().subscribe(val => {
      this.shortcutLinks$ = of(
        val.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1)),
      );
    });
  }
}
