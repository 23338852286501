import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Training, TrainingsClient } from 'src/app/api.service';
import { DownloadService } from 'src/app/download.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { TrainingService } from 'src/app/utilities/training/training.service';
import { OrderNumberValidator } from '../../validators/order-number-validator/order-number-validator';

@Component({
  selector: 'subs-training',
  templateUrl: './training.component.html',
  providers: [FeedbackService],
})
export class TrainingComponent implements OnInit {
  submitState$ = this.feedbackService.submitState;
  showSavedModal = false;
  deleteOpened = false;
  selectedId = -1;
  trainingViews$: Observable<Training[]>;
  isSaved = true;
  fileToSave: File;

  invalidOrderNum = false;
  orderErrorMessage: string;
  invalidLabel = false;
  labelErrorMessage: string;
  invalidFile = false;
  fileErrorMessage: string;

  constructor(
    private trainingService: TrainingsClient,
    private trainingUploadService: TrainingService,
    private downloadService: DownloadService,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit(): void {
    this.getTrainings();
  }

  getTrainings() {
    this.trainingService.getAll().subscribe(val => {
      const sortedArr = val.sort((a, b) =>
        a.orderNumber > b.orderNumber ? 1 : -1,
      );
      this.trainingViews$ = of(sortedArr);
    });
  }
  setFileToSave(event) {
    if (event.target.files[0] !== undefined) {
      this.fileToSave = event.target.files[0];
      this.checkForFile(this.fileToSave);
    }
  }
  saveTraining(
    id: number,
    index: number,
    label: string,
    trainingFile: any,
    orderNumber: number,
  ) {
    if (id !== 0) {
      this.updateTraining(id, label, trainingFile, orderNumber);
    } else {
      this.checkOrderNumber();
      this.checklabel(label);
      this.checkForFile(trainingFile);
      if (this.invalidOrderNum === false && this.invalidLabel === false) {
        if (this.invalidFile === false) {
          this.trainingUploadService
            .uploadTrainingFile(trainingFile)
            .subscribe(fileToSave => {
              const newTraining: Training = {
                id,
                label,
                trainingFileId: fileToSave.id,
                fileName: this.fileToSave.name,
                orderNumber,
              };

              this.trainingService
                .updateTraining(newTraining)
                .pipe(this.feedbackService.provideFeedback())
                .subscribe(val => {
                  this.feedbackService.beginLoading();
                  this.getTrainings();
                  this.showSavedModal = true;
                  this.feedbackService.displaySuccess();
                  this.fileToSave = undefined;
                  // auto-close for 'Saved Modal'
                  setTimeout(() => {
                    this.showSavedModal = false;
                  }, 2000);
                });
            });
        }
      }
    }
  }

  deleteTraining(id: number) {
    this.selectedId = id;
    this.deleteOpened = true;
  }

  cancelDeleteConfirmation() {
    this.selectedId = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.deleteOpened = false;
    this.feedbackService.beginLoading();
    this.trainingService
      .deleteTraining(this.selectedId)
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(val => {
        this.getTrainings();
        this.showSavedModal = true;
        this.feedbackService.displaySuccess();
        // auto-close for 'Saved Modal'
        setTimeout(() => {
          this.showSavedModal = false;
        }, 2000);
      });
  }

  downloadFile(fileId: string, fileName: string) {
    this.downloadService.downloadFile(fileId, fileName).subscribe(result => {});
  }

  onAdd() {
    const newTraining: Training = {
      id: 0,
      label: '',
      trainingFileId: null,
      fileName: null,
      orderNumber: null,
    };
    this.trainingViews$.subscribe(val => {
      val.push(newTraining);
    });
  }

  updateTraining(
    id: number,
    label: string,
    trainingFile: any,
    orderNumber: number,
  ) {
    this.checkOrderNumber();
    this.checklabel(label);
    if (this.invalidOrderNum === false && this.invalidLabel === false) {
      this.trainingViews$.subscribe(views => {
        views.forEach(training => {
          if (training.id === id) {
            if (trainingFile === undefined) {
              const newTraining: Training = {
                id,
                label,
                trainingFileId: training.trainingFileId,
                fileName: training.fileName,
                orderNumber,
              };

              this.trainingService
                .updateTraining(newTraining)
                .pipe(this.feedbackService.provideFeedback())
                .subscribe(val => {
                  this.feedbackService.beginLoading();
                  this.getTrainings();
                  this.showSavedModal = true;
                  this.feedbackService.displaySuccess();
                  this.fileToSave = undefined;
                  // auto-close for 'Saved Modal'
                  setTimeout(() => {
                    this.showSavedModal = false;
                  }, 2000);
                });
            } else {
              this.trainingUploadService
                .uploadTrainingFile(trainingFile)
                .subscribe(fileToSave => {
                  const newTraining: Training = {
                    id,
                    label,
                    trainingFileId: fileToSave.id,
                    fileName: this.fileToSave.name,
                    orderNumber,
                  };

                  this.trainingService
                    .updateTraining(newTraining)
                    .pipe(this.feedbackService.provideFeedback())
                    .subscribe(val => {
                      this.feedbackService.beginLoading();
                      this.getTrainings();
                      this.showSavedModal = true;
                      this.feedbackService.displaySuccess();
                      // auto-close for 'Saved Modal'
                      setTimeout(() => {
                        this.showSavedModal = false;
                      }, 2000);
                    });
                });
            }
          }
        });
      });
    }
  }

  checkOrderNumber() {
    this.invalidOrderNum = false;
    this.orderErrorMessage = '';

    this.trainingViews$.subscribe(items => {
      const isValid = OrderNumberValidator.validateOrderNumbers(
        items.map(x => x.orderNumber),
      );
      this.invalidOrderNum = isValid.invalidForm;
      this.orderErrorMessage = isValid.errorMessage;
    });
  }

  checklabel(label: string) {
    this.invalidLabel = false;
    this.labelErrorMessage = '';
    if (label === '') {
      this.labelErrorMessage = 'A Title is Missing';
      this.invalidLabel = true;
    }
  }
  checkForFile(file: any) {
    this.invalidFile = false;
    this.fileErrorMessage = '';

    if (file === undefined) {
      this.invalidFile = true;
      this.fileErrorMessage = 'A File is Missing';
    }
  }

  updateCollection(index: number, field: string, value: string) {
    this.trainingViews$.subscribe(items => {
      items.forEach((item, idx) => {
        if (idx === index) {
          switch (field) {
            case 'title':
              items[idx].label = value;
              break;
            case 'orderNumber':
              items[idx].orderNumber = +value;
              break;
          }
        }
      });
    });
  }
}
