<clr-wizard #wizard [(clrWizardOpen)]="isOpen" (clrWizardOnFinish)="doFinish()" data-test-id="wizard-modal">
  <clr-wizard-title>Conversion Process</clr-wizard-title>

  <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
  <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
  <clr-wizard-button [type]="'next'" data-test-id="btn-next">Next</clr-wizard-button>
  <clr-wizard-button [type]="'finish'" data-test-id="btn-finish">Finish</clr-wizard-button>

  <clr-wizard-page clrWizardPagePreventDefault="true" (clrWizardPageOnCommit)="onCommit()"
    (clrWizardPageOnCancel)="doCancel()" [clrWizardPageNextDisabled]="!formData.valid" (keydown.enter)="onCommit()">
    <ng-template clrPageTitle>Insert DUNS#</ng-template>

    <clr-spinner *ngIf="loadingFlag">
      Loading
    </clr-spinner>
    <clr-alert *ngIf="errorFlag" [clrAlertType]="'alert-danger'" [clrCloseButtonAriaLabel]="'Close Answer alert'">
      <clr-alert-item>
        DUNS Number Does Not Exist.
      </clr-alert-item>
    </clr-alert>

    <form clrForm #myForm="ngForm" [formGroup]="formData" [class.hide]="loadingFlag">
      <clr-input-container>
        <label>DUNS #</label>
        <input clrInput required formControlName="dunsNumber" data-test-id="conversion-duns-number" />
        <clr-control-error>DUNS# should be a number</clr-control-error>
      </clr-input-container>
    </form>
  </clr-wizard-page>
  <clr-wizard-page (clrWizardPageOnCancel)="doCancel()">
    <ng-template clrPageTitle>Check Information</ng-template>
    <form clrForm [formGroup]="formData">
      <clr-input-container>
        <label>Institution ID</label>
        <input clrInput readonly formControlName="id" />
      </clr-input-container>
      <clr-input-container>
        <label>Institution Name</label>
        <input clrInput readonly formControlName="institutionName" size="40" />
      </clr-input-container>
      <clr-input-container>
        <label>Status</label>
        <input clrInput readonly formControlName="status" />
      </clr-input-container>
      <div class="clr-form-control-disabled">
        <clr-checkbox-container clrInline>
          <clr-checkbox-wrapper>
            <label>Primary Institution Record</label>
            <input type="checkbox" clrCheckbox data-test-id="primaryInstitution" formControlName="primaryInstitution"
              disabled />
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
      </div>
    </form>
  </clr-wizard-page>
</clr-wizard>
