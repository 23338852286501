import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubrecipientMonitoringQuestion } from 'src/app/api.service';

@Injectable()
export class MonitoringQuestionControlFactory {
  constructor(private fb: UntypedFormBuilder) {}

  createMonitoringQuestionControl(
    value: SubrecipientMonitoringQuestion,
  ): UntypedFormGroup {
    const control = this.createGroup();
    control.setValue(value);
    return control;
  }

  createMany(count: number): UntypedFormGroup[] {
    const output = [];

    for (let i = 0; i < count; i++) {
      output.push(this.createGroup());
    }

    return output;
  }

  private createGroup() {
    return this.fb.group({
      id: [],
      sequence: [],
      answer: [],
      question: [],
    });
  }
}
