import { Component } from '@angular/core';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  CollaboratorsExecutedSubsClient,
  CollaboratorsExecutedSubsViewModel,
} from 'src/app/api.service';
import { ExcelExportService } from '../excel-export.service';

@Component({
  selector: 'subs-collaborators-executed-subs',
  templateUrl: './collaborators-executed-subs.component.html',
  providers: [FeedbackService],
})
export class CollaboratorsExecutedSubsComponent {
  alertSubject$ = this.feedbackService.alerts;
  submitState = this.feedbackService.submitState;

  searchForm = this.fb.group({
    dateFrom: ['', Validators.required],
    dateThru: ['', Validators.required],
    location: [''],
    sponsor: [''],
    collaborator: [''],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private reportService: CollaboratorsExecutedSubsClient,
    private excelExportService: ExcelExportService,
  ) {}

  clear() {
    this.searchForm.controls.dateFrom.setValue('');
    this.searchForm.controls.dateThru.setValue('');
    this.feedbackService.clearAlert();
  }

  search() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.reportService
        .get(
          new Date(this.searchForm.controls.dateFrom.value),
          new Date(this.searchForm.controls.dateThru.value),
          this.searchForm.controls.location.value,
          this.searchForm.controls.sponsor.value,
          this.searchForm.controls.collaborator.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async val => await this.generateReport(val));
    }
  }

  async generateReport(results: Array<CollaboratorsExecutedSubsViewModel>) {
    if (results.length === 0) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }
    const columns = [
      {
        header: 'WashU Agreement Number',
        width: 23,
      },
      {
        header: 'Institution Country',
        width: 22,
      },
      {
        header: 'Sponsor Award #',
        width: 22,
      },
      {
        header: 'Sponsor Name',
        width: 55,
      },
      {
        header: 'Project Title',
        width: 100,
      },
      {
        header: 'WashU PI Name',
        width: 25,
      },
      {
        header: 'Collaborator Name',
        width: 62,
      },
      {
        header: 'Collaborator PI Name',
        width: 27,
      },
      {
        header: 'Collaborator PI Email Address',
        width: 34,
      },
      {
        header: 'Total Funded Amount',
        width: 20,
      },
      {
        header: 'Distribution Count',
        width: 15,
      },
      {
        header: 'Sub Distrib Date',
        width: 15,
      },
    ];

    const data = this.generateReportData(results);
    const totalsObj = this.generateTotals(results);

    await this.excelExportService.generateExcel(
      'Collaborators Executed Subs',
      'Collaborators Executed Subs',
      data,
      columns,
      {
        value: `Date Range ${this.searchForm.controls.dateFrom.value} - ${this.searchForm.controls.dateThru.value},
        Sponsor: ${this.searchForm.controls.sponsor.value},
        Collaborator: ${this.searchForm.controls.collaborator.value},
        Institution Location: ${this.searchForm.controls.location.value}`,
      },
      this.addSubtotals,
      totalsObj,
    );
  }

  private generateReportData(
    results: Array<CollaboratorsExecutedSubsViewModel>,
  ) {
    const data = [];
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    results.forEach(e => {
      data.push([
        e.washUAgreementNumber,
        e.institutionCountry,
        e.sponsorAwardNumber,
        e.sponsorName,
        e.projectTitle,
        e.washUPIName,
        e.collaboratorName,
        e.collaboratorPIName,
        e.collaboratorPIEmailAddress,
        formatter.format(e.totalFoundedAmount),
        e.distributedSubAwardCount,
        new Date(e.subDistributionDate).toLocaleDateString(),
      ]);
    });
    return data;
  }

  private generateTotals(
    results: Array<CollaboratorsExecutedSubsViewModel>,
  ): any[] {
    const totalsObj = [];

    let pivotValue = results[0].washUAgreementNumber;
    let start = 0;
    const offset = 11;
    let subtotalDistributions = 0;
    let totalFoundedAmount = 0;
    let uniqueCountryCodes = 0;

    const groupedByAgreementNumber = this.groupBy(
      results,
      'washUAgreementNumber',
    );

    const groupedByCountryCode = this.groupBy(results, 'collaboratorPICountry');

    const uniqueCountries = Object.keys(groupedByCountryCode).map(k => {
      return { collaboratorPICountry: k, count: groupedByCountryCode[k] };
    });

    uniqueCountryCodes = uniqueCountries.length;

    const uniqueAgreementNumberRecords: any[] = [];
    Object.entries(groupedByAgreementNumber).forEach(([key, value]) => {
      uniqueAgreementNumberRecords.push(value[0]);
    });

    totalFoundedAmount = uniqueAgreementNumberRecords.reduce((x, y) => ({
      totalFoundedAmount: x.totalFoundedAmount + y.totalFoundedAmount,
    })).totalFoundedAmount;

    results.forEach((e, i) => {
      if (e.washUAgreementNumber !== pivotValue) {
        pivotValue = e.washUAgreementNumber;
        totalsObj.push([
          start + offset,
          i + offset - 1,
          subtotalDistributions,
          totalFoundedAmount,
          uniqueCountryCodes,
        ]);
        start = i;
        subtotalDistributions = 0;
      }
      if (e.subDistributionDate) {
        subtotalDistributions += 1;
      }
    });

    totalsObj.push([
      start + offset,
      results.length + offset - 1,
      subtotalDistributions,
      totalFoundedAmount,
      uniqueCountryCodes,
      uniqueAgreementNumberRecords.length,
    ]);

    return totalsObj;
  }

  private groupBy(
    results: Array<CollaboratorsExecutedSubsViewModel>,
    key: string,
  ) {
    return results.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  private addSubtotals(worksheet, totalsObj) {
    let pivot = 0;
    let totalSub = 0;
    let totalFunded = '$0';
    let totalCountries = 0;
    let uniqueDistributions = 0;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    totalsObj.forEach(e => {
      totalFunded = formatter.format(e[3]);
      totalCountries = e[4];
      uniqueDistributions = e[5];

      worksheet.mergeCells('A' + (e[0] + pivot) + ':A' + (e[1] + pivot));
      worksheet.mergeCells('B' + (e[0] + pivot) + ':B' + (e[1] + pivot));
      worksheet.mergeCells('C' + (e[0] + pivot) + ':C' + (e[1] + pivot));
      worksheet.mergeCells('D' + (e[0] + pivot) + ':D' + (e[1] + pivot));
      worksheet.mergeCells('E' + (e[0] + pivot) + ':E' + (e[1] + pivot));
      worksheet.mergeCells('F' + (e[0] + pivot) + ':F' + (e[1] + pivot));
      worksheet.mergeCells('G' + (e[0] + pivot) + ':G' + (e[1] + pivot));
      worksheet.mergeCells('H' + (e[0] + pivot) + ':H' + (e[1] + pivot));
      worksheet.mergeCells('I' + (e[0] + pivot) + ':I' + (e[1] + pivot));
      worksheet.mergeCells('J' + (e[0] + pivot) + ':J' + (e[1] + pivot));
      worksheet.mergeCells('K' + (e[0] + pivot) + ':K' + (e[1] + pivot));
      worksheet.spliceRows(e[1] + pivot + 1, 0, ['']);

      const subRow = worksheet.getRow(e[1] + pivot + 1);
      subRow.font = {
        size: 12,
        bold: true,
      };

      subRow.eachCell(cell => {
        cell.alignment = { vertical: 'top', horizontal: 'right' };
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });

      worksheet.mergeCells(e[1] + pivot + 1, 1, e[1] + pivot + 1, 12);
      totalSub = totalSub + e[2];

      pivot++;
    });

    const emptyRow = worksheet.addRow(['']);
    worksheet.mergeCells(emptyRow.number, 1, emptyRow.number, 12);
    emptyRow.font = {
      size: 14,
      bold: true,
    };
    emptyRow.eachCell(cell => {
      cell.alignment = { vertical: 'top', horizontal: 'right' };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
    });

    const uniqueCountriesRow = worksheet.addRow([
      'Unique Countries: ' + totalCountries,
    ]);
    worksheet.mergeCells(
      uniqueCountriesRow.number,
      1,
      uniqueCountriesRow.number,
      12,
    );
    uniqueCountriesRow.font = {
      size: 14,
      bold: true,
    };
    uniqueCountriesRow.eachCell(cell => {
      cell.alignment = { vertical: 'top', horizontal: 'right' };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
    });

    const uniqueDistributionsRow = worksheet.addRow([
      'Unique Distributions: ' + uniqueDistributions,
    ]);
    worksheet.mergeCells(
      uniqueDistributionsRow.number,
      1,
      uniqueDistributionsRow.number,
      12,
    );
    uniqueDistributionsRow.font = {
      size: 14,
      bold: true,
    };
    uniqueDistributionsRow.eachCell(cell => {
      cell.alignment = { vertical: 'top', horizontal: 'right' };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
    });

    const totalRow = worksheet.addRow(['Total Distributions: ' + totalSub]);
    worksheet.mergeCells(totalRow.number, 1, totalRow.number, 12);
    totalRow.font = {
      size: 14,
      bold: true,
    };
    totalRow.eachCell(cell => {
      cell.alignment = { vertical: 'top', horizontal: 'right' };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
    });

    const totalFundedRow = worksheet.addRow(['Total Funded: ' + totalFunded]);
    worksheet.mergeCells(totalFundedRow.number, 1, totalFundedRow.number, 12);
    totalFundedRow.font = {
      size: 14,
      bold: true,
    };
    totalFundedRow.eachCell(cell => {
      cell.alignment = { vertical: 'top', horizontal: 'right' };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
    });
  }
}
