<div class="card">
  <div class="card-header">
    {{ title }}
  </div>

  <div class="card-block">
    <subs-file-upload
      label="File name"
      [id]="id"
      [fileNameControlName]="fileNameControlName"
      [fileIdControlName]="fileIdControlName"
      [dateControlName]="dateControlName"
      [acceptedMimeTypes]="acceptedMimeTypes"
      [acceptedMimeTypesErrorMessage]="acceptedMimeTypesErrorMessage"
      [customAlert]="customAlert"
      [canEdit]="canEdit != null ? canEdit : true"
      [callback]="callback"
      [canceledCallback]="canceledCallback"
      [buttonLabel]="buttonLabel"
      [getConfirmation]="getConfirmation"
      [confirmationMessage]="confirmationMessage"
      [confirmationTitle]="confirmationTitle"
    ></subs-file-upload>
  </div>
</div>
