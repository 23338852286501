import { Component } from '@angular/core';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ExcelExportService } from '../excel-export.service';
import {
  OutgoingSubsStatusViewModel,
  OutgoingSubsStatusReportClient,
} from 'src/app/api.service';
@Component({
  selector: 'subs-outgoing-subs-status-report',
  templateUrl: './outgoing-subs-status.component.html',
  providers: [FeedbackService],
})
export class OutgoingSubsStatusComponent {
  submitState = this.feedbackService.submitState;

  searchForm = this.fb.group({
    dateFrom: ['', Validators.required],
    dateThru: ['', Validators.required],
    assignedGA: '',
  });

  alertSubject = this.feedbackService.alerts;

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private outgoingSubsStatusClient: OutgoingSubsStatusReportClient,
    private excelExportService: ExcelExportService,
  ) {}

  search() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.outgoingSubsStatusClient
        .get(
          new Date(this.searchForm.controls.dateFrom.value),
          new Date(this.searchForm.controls.dateThru.value),
          this.searchForm.controls.assignedGA.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async (val) => await this.generateReport(val));
    }
  }

  private generateReportData(results: Array<OutgoingSubsStatusViewModel>) {
    const data = [];
    let submittedToOsrsSum = 0;
    let wipSum = 0;
    let returnedSum = 0;
    let sentToSubSum = 0;
    let inactivatedAndVoidedSum = 0;
    let distributedSum = 0;
    let deletedSubs = 0;

    results.forEach((e) => {
      submittedToOsrsSum += e.submittedToOSRS;
      wipSum += e.workInProgress;
      returnedSum += e.subsReturned;
      sentToSubSum += e.sentToSub;
      inactivatedAndVoidedSum += e.inactivatedAndVoided;
      distributedSum += e.distributed;
      deletedSubs = e.deletedSubs;

      data.push([
        new Date(e.dateFrom).toLocaleDateString() +
          ' - ' +
          new Date(e.dateTo).toLocaleDateString(),
        e.submittedToOSRS,
        e.workInProgress,
        e.subsReturned,
        e.sentToSub,
        e.inactivatedAndVoided,
        e.distributed,
        '',
      ]);
    });

    data.push([
      'Total',
      submittedToOsrsSum,
      wipSum,
      returnedSum,
      sentToSubSum,
      inactivatedAndVoidedSum,
      distributedSum,
      deletedSubs,
    ]);

    return data;
  }

  async generateReport(results: Array<OutgoingSubsStatusViewModel>) {
    if (results.length === 0) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }
    const columns = [
      {
        header: 'Report Period',
        width: 30,
      },
      {
        header: 'Submitted to OSRS',
        width: 22,
      },
      {
        header: 'Work In Process',
        width: 19,
      },
      {
        header: 'Subs Returned',
        width: 20,
      },
      {
        header: 'Sent to Sub',
        width: 20,
      },
      {
        header: 'Inactivated/Voided',
        width: 25,
      },
      {
        header: 'Distributed',
        width: 11,
      },
      {
        header: 'Deleted',
        width: 11,
      },
    ];

    const data = this.generateReportData(results);

    await this.excelExportService.generateExcel(
      'Outgoing SUBS Weekly Activity Report',
      'Outgoing SUBS Weekly Activity Report',
      data,
      columns,
      {
        value: `Date Range ${this.searchForm.controls.dateFrom.value} - ${this.searchForm.controls.dateThru.value} 
        OSRS GA: ${this.searchForm.controls.assignedGA.value}`,
      },
    );
  }

  clear() {
    this.searchForm.controls.dateFrom.setValue('');
    this.searchForm.controls.dateThru.setValue('');
    this.searchForm.controls.assignedGA.setValue('');
    this.feedbackService.clearAlert();
  }
}
