import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'subs-institution-contact',
  templateUrl: './institution-contact.component.html',
})
export class InstitutionContactComponent {
  @Input() title: string;
  @Input() form: UntypedFormGroup;
  @Input() instituteAddress: UntypedFormGroup;

  constructor() {}

  copyAddress() {
    const instituteAddress = this.instituteAddress.value;
    this.form.controls.address.setValue(instituteAddress);
  }
}
