<clr-datagrid>
  <clr-dg-column [clrDgField]="'key'" [clrDgSortBy]="keyComparator"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Key
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'value'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Value
    </ng-container></clr-dg-column
  >
  <clr-dg-row
    *clrDgItems="let result of utilitiesSearchResults.lookupItems"
    (click)="
      navigateToUtilityItem(result.id, utilitiesSearchResults.utilityTableKey)
    "
  >
    <clr-dg-cell>{{
      getItemKey(result.key, utilitiesSearchResults.utilityTableKey)
    }}</clr-dg-cell>
    <clr-dg-cell class="break-all">{{ result.value }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer data-test-id="results-amount"
    >{{
      utilitiesSearchResults.lookupItems.length === 0
        ? 'No'
        : utilitiesSearchResults.lookupItems.length
    }}
    result{{
      utilitiesSearchResults.lookupItems.length === 1 ? '' : 's'
    }}</clr-dg-footer
  >
</clr-datagrid>
