import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { FFATAReportClient } from 'src/app/api.service';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';

@Component({
  selector: 'subs-ffata-report',
  templateUrl: './ffata-report.component.html',
  providers: [FeedbackService],
})
export class FfataReportComponent implements OnInit {
  submitState = this.feedbackService.submitState;

  searchForm = this.fb.group({
    month: ['', [Validators.required]],
    year: ['', [Validators.required]],
  });

  alertSubject = this.feedbackService.alerts;
  years: number[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private ffataReportClient: FFATAReportClient,
  ) {}

  ngOnInit(): void {
    const y = new Date().getFullYear() + 1; // adding +1 to handle a new FY in the middle of a calendar year
    for (let i = 0; i + 2012 <= y; i++) {
      this.years.push(i + 2012);
    }
  }

  contractReport() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.ffataReportClient
        .getContractReport(
          this.searchForm.controls.month.value,
          this.searchForm.controls.year.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(response => {
          const dataUrl = window.URL.createObjectURL(response.data);
          this.saveFile(dataUrl, 'FFATA Contract Report.csv');
          window.URL.revokeObjectURL(dataUrl);
        });
    }
  }

  grantReport() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.ffataReportClient
        .getGrantReport(
          this.searchForm.controls.month.value,
          this.searchForm.controls.year.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(response => {
          const dataUrl = window.URL.createObjectURL(response.data);
          this.saveFile(dataUrl, 'FFATA Grant Report.csv');
          window.URL.revokeObjectURL(dataUrl);
        });
    }
  }

  private saveFile(dataUrl: string, fileName: string) {
    const anchor = document.createElement('a');
    anchor.href = dataUrl;
    anchor.download = fileName;
    anchor.click();
  }

  clear() {
    this.searchForm.controls.year.setValue('');
    this.searchForm.controls.month.setValue('');
    this.feedbackService.clearAlert();
  }
}
