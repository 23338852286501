import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { SubrecipientStatusVm } from 'src/app/api.service';

@Injectable()
export class SubStatusControlFactory {
  constructor(private fb: UntypedFormBuilder) {}

  createSubStatusControl(value: SubrecipientStatusVm): UntypedFormGroup {
    const control = this.createGroup();
    control.setValue(value);
    return control;
  }

  createMany(count: number): UntypedFormGroup[] {
    const output = [];
    for (let i = 0; i < count; i++) {
      output.push(this.createGroup());
    }
    return output;
  }

  private createGroup() {
    return this.fb.group({
      id: [],
      statusCode: ['', [Validators.required, RxwebValidators.unique()]],
      statusDescription: ['', Validators.required],
      statusActionSource: ['', Validators.required],
      statusType: ['', Validators.required],
      isActive: ['', Validators.required],
      createdBy: '',
      modifiedBy: '',
      createdDate: '',
      modifiedDate: '',
    });
  }
}
