import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { SubrecipientComment } from '../../../api.service';

@Component({
  selector: 'subs-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss'],
  providers: [DatePipe],
})
export class CommentCardComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder, private datePipe: DatePipe) {}

  @Input() comment: SubrecipientComment;

  form = this.fb.group({
    returnCode: [],
    comment: [],
    created: [],
    createdBy: [],
  });

  ngOnInit() {
    this.form.setValue({
      returnCode: this.comment.returnCode,
      comment: this.comment.comment,
      created: this.datePipe.transform(
        this.comment.created,
        'MM/dd/yyyy hh:mm aaa',
      ),
      createdBy: this.comment.createdBy,
    });
  }
}
