import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../auth.service';

@Directive({
  selector: '[subsPermission]',
})
export class PermissionsDirective {
  constructor(
    private readonly authService: AuthService,
    private readonly viewRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
  ) {}

  @Input()
  set subsPermission(permissions: string[]) {
    if (!permissions || permissions.length === 0) {
      this.viewRef.createEmbeddedView(this.templateRef);
      return;
    }
    const hasPermissions = this.authService.hasPermission(...permissions);
    if (hasPermissions) {
      this.viewRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewRef.clear();
    }
  }
}
