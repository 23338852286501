import { Component } from '@angular/core';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ExcelExportService } from '../excel-export.service';
import { ReturnReportClient, ReturnReportViewModel } from 'src/app/api.service';
@Component({
  selector: 'subs-return-report',
  templateUrl: './return-report.component.html',
  providers: [FeedbackService],
})
export class ReturnReportComponent {
  submitState = this.feedbackService.submitState;

  searchForm = this.fb.group({
    dateFrom: ['', Validators.required],
    dateThru: ['', Validators.required],
    assignedGA: '',
  });

  alertSubject = this.feedbackService.alerts;

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private returnReportClient: ReturnReportClient,
    private excelExportService: ExcelExportService,
  ) {}

  search() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.returnReportClient
        .get(
          new Date(this.searchForm.controls.dateFrom.value),
          new Date(this.searchForm.controls.dateThru.value),
          this.searchForm.controls.assignedGA.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async (val) => await this.generateReport(val));
    }
  }

  private generateReportData(results: Array<ReturnReportViewModel>) {
    const data = [];
    results.forEach((e) => {
      data.push([
        e.returnCode,
        e.returnReason,
        e.subrecipient,
        e.assignedGA,
        e.washUPI,
        e.deptAdmin,
        e.proposalId,
        e.agreementNumber,
        e.oboDate ? new Date(e.oboDate).toLocaleDateString() : '',
        e.rdaDate ? new Date(e.rdaDate).toLocaleDateString() : '',
        e.daysAtOSRSDept,
        this.getSODate(e.osrsDate, e.rdaDate),
        e.daysAtDept,
        this.getSTSDate(e.stsDate, e.rdaDate),
        e.daysBtwnSTSandOBO,
      ]);
    });
    return data;
  }

  private getSODate(osrsDate: Date, rdaDate: Date) {
    if (!rdaDate) {
      return '';
    } else {
      return osrsDate
        ? new Date(osrsDate).toLocaleDateString()
        : `Not resubmitted to OSRS by ${new Date().toLocaleDateString()}`;
    }
  }

  private getSTSDate(stsDate: Date, rdaDate: Date) {
    if (!rdaDate) {
      return '';
    } else {
      return stsDate
        ? new Date(stsDate).toLocaleDateString()
        : `Not Sent to Sub by ${new Date().toLocaleDateString()}`;
    }
  }

  private generateMergedCellsArray(
    results: Array<ReturnReportViewModel>,
  ): any[] {
    const numberArray = [];
    var pivotValue = results[0].returnCode;
    var start = 0;
    const offset = 11;
    let totalSubs = 0;

    results.forEach((e, i) => {
      if (e.returnCode !== pivotValue) {
        pivotValue = e.returnCode;
        numberArray.push([start + offset, i + offset - 1, totalSubs]);
        start = i;
        totalSubs = 0;
      }
      if (e.subrecipientId && e.subrecipientId > 0) {
        totalSubs += 1;
      }
    });

    numberArray.push([start + offset, results.length + offset - 1, totalSubs]);
    return numberArray;
  }

  async generateReport(results: Array<ReturnReportViewModel>) {
    if (results.length === 0) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }
    const columns = [
      {
        header: 'Return Code',
        width: 12,
      },
      {
        header: 'Return Reason',
        width: 19,
      },
      {
        header: 'Subrecipient',
        width: 70,
      },
      {
        header: 'OSRS GA',
        width: 12,
      },
      {
        header: 'WashU PI',
        width: 27,
      },
      {
        header: 'Dept Admin',
        width: 27,
      },
      {
        header: 'Proposal ID',
        width: 15,
      },
      {
        header: 'Subaward #',
        width: 20,
      },
      {
        header: 'Opened by OSRS',
        width: 18,
      },
      {
        header: 'OSRS Returns to Dept. Date',
        width: 25,
      },
      {
        header: '# of Days at OSRS',
        width: 30,
      },
      {
        header: 'Dept Resubmits to OSRS Date',
        width: 40,
      },
      {
        header: '# of Days at Dept',
        width: 30,
      },
      {
        header: 'Sent to Sub',
        width: 30,
      },
      {
        header: '# of Days btwn OBO/STS',
        width: 35,
      },
    ];

    const data = this.generateReportData(results);
    const arrNum = this.generateMergedCellsArray(results);

    await this.excelExportService.generateExcel(
      'Return Report',
      'Subaward Return to Dept. Report',
      data,
      columns,
      {
        value:
          'Date Range: ' +
          this.searchForm.controls.dateFrom.value +
          ' - ' +
          this.searchForm.controls.dateThru.value,
      },
      this.addStylesAndSubtotals,
      arrNum,
    );
  }

  private addStylesAndSubtotals(worksheet, arrNum) {
    var pivot = 0;
    var totalSub = 0;

    arrNum.forEach((e) => {
      worksheet.mergeCells('A' + (e[0] + pivot) + ':A' + (e[1] + pivot));
      worksheet.mergeCells('B' + (e[0] + pivot) + ':B' + (e[1] + pivot));
      worksheet.spliceRows(e[1] + pivot + 1, 0, ['SUBS RETURNED : ' + e[2]]);

      const subRow = worksheet.getRow(e[1] + pivot + 1);
      subRow.font = {
        size: 12,
        bold: true,
      };
      subRow.eachCell((cell) => {
        cell.alignment = { vertical: 'top', horizontal: 'right' };
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });

      worksheet.mergeCells(e[1] + pivot + 1, 1, e[1] + pivot + 1, 9);
      totalSub = totalSub + e[2];

      pivot++;
    });

    const totalRow = worksheet.addRow(['TOTAL SUBS RETURNED: ' + totalSub]);
    worksheet.mergeCells(totalRow.number, 1, totalRow.number, 9);
    totalRow.font = {
      size: 14,
      bold: true,
    };
    totalRow.eachCell((cell) => {
      cell.alignment = { vertical: 'top', horizontal: 'right' };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
    });
  }

  clear() {
    this.searchForm.controls.dateFrom.setValue('');
    this.searchForm.controls.dateThru.setValue('');
    this.searchForm.controls.assignedGA.setValue('');
    this.feedbackService.clearAlert();
  }
}
