import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FeedbackService } from '../../form-layout/feedback.service';
import {
  InstitutionAuditReportClient,
  InstitutionAuditReportResponse,
} from '../../api.service';
import { ExcelExportService } from '../excel-export.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'subs-institution-audit-report',
  templateUrl: './institution-audit-report.component.html',
  providers: [FeedbackService, DatePipe],
})
export class InstitutionAuditReportComponent {
  submitState = this.feedbackService.submitState;
  alertSubject = this.feedbackService.alerts;

  searchForm = this.fb.group({
    dateFrom: ['', Validators.required],
    dateThru: '',
    institutionName: '',
    dunsNumber: '',
    uniqueEntityId: '',
    updatedBy: '',
    institutionsCreated: [true],
    institutionsCompleted: [true],
    institutionAudit: [true],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private feedbackService: FeedbackService,
    private institutionAuditReportClient: InstitutionAuditReportClient,
    private excelExportService: ExcelExportService,
  ) {}

  search() {
    this.feedbackService.beginLoading();

    if (!this.hasOptionSelected()) {
      this.feedbackService.alert(
        'Report Option is required. Please select at least one Report Option to run report.',
      );
      return;
    }

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      if (!this.searchForm.controls.dateThru.value) {
        const endDate = new Date();
        this.searchForm.controls.dateThru.patchValue(
          this.datePipe.transform(endDate, 'MM/dd/yyyy'),
        );
      }
      this.institutionAuditReportClient
        .get(
          new Date(this.searchForm.controls.dateFrom.value),
          new Date(this.searchForm.controls.dateThru.value),
          this.searchForm.controls.institutionName.value,
          this.searchForm.controls.dunsNumber.value,
          this.searchForm.controls.uniqueEntityId.value,
          this.searchForm.controls.updatedBy.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async (val) => {
          await this.generateReport(val);
        });
    }
  }

  private hasOptionSelected() {
    if (
      !this.searchForm.controls.institutionsCreated.value &&
      !this.searchForm.controls.institutionsCompleted.value &&
      !this.searchForm.controls.institutionAudit.value
    ) {
      return false;
    } else {
      return true;
    }
  }

  async generateReport(results: InstitutionAuditReportResponse) {
    if (
      results.completedInstitutions.length === 0 &&
      results.newInstitutions.length === 0 &&
      results.institutionAuditLogs.length === 0
    ) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }

    const columns = [
      {
        header: 'Institution ID',
        width: 20,
      },
      {
        header: 'DUNS',
        width: 20,
      },
      {
        header: 'UEI',
        width: 20,
      },
      {
        header: 'Institution Name',
        width: 40,
      },
      {
        header: 'Risk Category',
        width: 20,
      },
      {
        header: 'Supplier ID',
        width: 20,
      },
      {
        header: 'Foreign Institution',
        width: 20,
      },
      {
        header: 'Created By',
        width: 25,
      },
      {
        header: 'Created Date',
        width: 15,
      },
      {
        header: 'Institution Status',
        width: 20,
      },
    ];

    const data = this.generateReportData(results);

    const headers = [];

    if (this.searchForm.controls.institutionsCreated.value) {
      headers.push({
        title: 'New Institutions Created',
        sort: 1,
        count: results.newInstitutions.length,
        columns: [
          {
            value: 'Institution ID',
          },
          {
            value: 'DUNS',
          },
          {
            value: 'UEI',
          },
          {
            value: 'Institution Name',
          },
          {
            value: 'Risk Category',
          },
          {
            value: 'Supplier ID',
          },
          {
            value: 'Foreign Institution',
          },
          {
            value: 'Created By',
          },
          {
            value: 'Created Date',
          },
          {
            value: 'Institution Status',
          },
        ],
      });
    }

    if (this.searchForm.controls.institutionsCompleted.value) {
      headers.push({
        title: 'New Institutions Completed',
        sort: 2,
        count: results.completedInstitutions.length,
        columns: [
          {
            value: 'Institution ID',
          },
          {
            value: 'DUNS',
          },
          {
            value: 'UEI',
          },
          {
            value: 'Institution Name',
          },
          {
            value: 'Risk Category',
          },
          {
            value: 'Supplier ID',
          },
          {
            value: 'Foreign Institution',
          },
          {
            value: 'Completed By',
          },
          {
            value: 'Completed Date',
          },
          {
            value: 'Institution Status',
          },
        ],
      });
    }

    if (this.searchForm.controls.institutionAudit.value) {
      headers.push({
        title: 'Institution Audit History',
        sort: 3,
        count: results.institutionAuditLogs.length,
        columns: [
          {
            value: 'Institution ID',
          },
          {
            value: 'DUNS',
          },
          {
            value: 'UEI',
          },
          {
            value: 'Institution Name',
          },
          {
            value: 'Risk Category',
          },
          {
            value: 'Supplier ID',
          },
          {
            value: 'Foreign Institution',
          },
          {
            value: 'Edited By',
          },
          {
            value: 'Edited Date',
          },
          {
            value: 'Institution Status',
          },
          {
            value: 'Field Name',
          },
          {
            value: 'Entity Type',
          },
          {
            value: 'Contact Type',
          },
          {
            value: 'Field Old Value',
          },
          {
            value: 'Field New Value',
          },
        ],
      });
    }

    headers.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    headers.push(columns);

    const additionalColumns = [
      {
        header: 'Field Name',
        width: 20,
      },
      {
        header: 'Entity Type',
        width: 20,
      },
      {
        header: 'Contact Type',
        width: 20,
      },
      {
        header: 'Field Old Value',
        width: 20,
      },
      {
        header: 'Field New Value',
        width: 20,
      },
    ];

    if (headers[0].title === 'Institution Audit History') {
      additionalColumns.forEach((column) => {
        columns.push(column);
      });
    }

    await this.excelExportService.generateExcel(
      'Institutions Audit Report',
      'Institutions Audit Report for the period ' +
        this.searchForm.controls.dateFrom.value +
        ' - ' +
        this.searchForm.controls.dateThru.value,
      data,
      columns,
      {
        value: `Date Range: ${this.searchForm.controls.dateFrom.value} - ${this.searchForm.controls.dateThru.value},
        Institution Name: ${this.searchForm.controls.institutionName.value},
        DUNS: ${this.searchForm.controls.dunsNumber.value},
        UEI: ${this.searchForm.controls.uniqueEntityId.value},
        Updated By: ${this.searchForm.controls.updatedBy.value}`,
      },
      this.addHeadersAndStyles,
      headers,
    );
  }

  private addHeadersAndStyles(worksheet, headers) {
    if (headers.length === 4) {
      worksheet.columns.forEach((column) => {
        column.width = 20;
      });
      // Header/Title 1
      worksheet.spliceRows(10, 0, [headers[0].title]);
      worksheet.mergeCells(10, 1, 10, headers[0].columns.length);
      worksheet.getCell('A10').alignment = { horizontal: 'center' };
      worksheet.spliceRows(11, 0, '');
      worksheet.spliceRows(headers[0].count + 13, 0, '', '');

      // Title 2
      worksheet.spliceRows(headers[0].count + 14, 0, [headers[1].title]);
      worksheet.mergeCells(
        headers[0].count + 14,
        1,
        headers[0].count + 14,
        headers[0].columns.length,
      );
      worksheet.getCell('A' + (headers[0].count + 14)).alignment = {
        horizontal: 'center',
      };

      // Header 2
      const gridHeader = [];

      headers[1].columns.forEach((d) => {
        gridHeader.push(d.value);
      });

      worksheet.spliceRows(headers[0].count + 16, 0, gridHeader);
      worksheet.getRow(headers[0].count + 16).eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FFE9F3FC',
          },
          bgColor: {
            argb: 'FFFFFFFF',
          },
        };
        cell.font = {
          color: {
            argb: '00000000',
          },
          bold: true,
        };
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });

      // Title 3
      worksheet.spliceRows(headers[0].count + headers[1].count + 17, 0, '', '');
      worksheet.spliceRows(headers[0].count + headers[1].count + 18, 0, [
        headers[2].title,
      ]);
      worksheet.mergeCells(
        headers[0].count + headers[1].count + 18,
        1,
        headers[0].count + headers[1].count + 18,
        headers[2].columns.length,
      );
      worksheet.getCell(
        'A' + (headers[0].count + headers[1].count + 18),
      ).alignment = {
        horizontal: 'center',
      };

      // Header 3
      const gridHeader1 = [];

      headers[2].columns.forEach((d) => {
        gridHeader1.push(d.value);
      });

      worksheet.spliceRows(
        headers[0].count + headers[1].count + 20,
        0,
        gridHeader1,
      );
      worksheet
        .getRow(headers[0].count + headers[1].count + 20)
        .eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FFE9F3FC',
            },
            bgColor: {
              argb: 'FFFFFFFF',
            },
          };
          cell.font = {
            color: {
              argb: '00000000',
            },
            bold: true,
          };
          cell.border = {
            top: {
              style: 'thin',
            },
            left: {
              style: 'thin',
            },
            bottom: {
              style: 'thin',
            },
            right: {
              style: 'thin',
            },
          };
        });
    }

    if (headers.length === 3) {
      if (headers[1].title === 'Institution Audit History') {
        worksheet.columns.forEach((column) => {
          column.width = 20;
        });
      }
      // Header/Title 1
      worksheet.spliceRows(10, 0, [headers[0].title]);
      worksheet.mergeCells(10, 1, 10, headers[0].columns.length);
      worksheet.getCell('A10').alignment = { horizontal: 'center' };
      worksheet.spliceRows(11, 0, '');
      worksheet.spliceRows(headers[0].count + 13, 0, '', '');

      // Title 2
      worksheet.spliceRows(headers[0].count + 14, 0, [headers[1].title]);
      worksheet.mergeCells(
        headers[0].count + 14,
        1,
        headers[0].count + 14,
        headers[0].columns.length,
      );
      worksheet.getCell('A' + (headers[0].count + 14)).alignment = {
        horizontal: 'center',
      };

      // Header 2
      const gridHeader = [];

      headers[1].columns.forEach((d) => {
        gridHeader.push(d.value);
      });

      worksheet.spliceRows(headers[0].count + 16, 0, gridHeader);
      worksheet.getRow(headers[0].count + 16).eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FFE9F3FC',
          },
          bgColor: {
            argb: 'FFFFFFFF',
          },
        };
        cell.font = {
          color: {
            argb: '00000000',
          },
          bold: true,
        };
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });
    }

    if (headers.length === 2) {
      worksheet.spliceRows(10, 0, [headers[0].title]);
      worksheet.mergeCells(10, 1, 10, headers[0].columns.length);
      worksheet.getCell('A10').alignment = { horizontal: 'center' };
      worksheet.spliceRows(11, 0, '');
    }
  }

  private generateReportData(results: InstitutionAuditReportResponse) {
    const data = [];

    if (this.searchForm.controls.institutionsCreated.value) {
      results.newInstitutions.forEach((e) => {
        data.push([
          e.institutionId,
          e.dunsNumber,
          e.uniqueEntityId,
          e.institutionName,
          e.riskCategory,
          e.supplierId,
          e.foreign,
          e.createdBy,
          e.createdDate ? new Date(e.createdDate).toLocaleDateString() : '',
          e.status,
        ]);
      });
    }

    if (this.searchForm.controls.institutionsCompleted.value) {
      results.completedInstitutions.forEach((e) => {
        data.push([
          e.institutionId,
          e.dunsNumber,
          e.uniqueEntityId,
          e.institutionName,
          e.riskCategory,
          e.supplierId,
          e.foreign,
          e.createdBy,
          e.createdDate ? new Date(e.createdDate).toLocaleDateString() : '',
          e.status,
        ]);
      });
    }

    if (this.searchForm.controls.institutionAudit.value) {
      results.institutionAuditLogs.forEach((e) => {
        data.push([
          e.institutionId,
          e.dunsNumber,
          e.uniqueEntityId,
          e.institutionName,
          e.riskCategory,
          e.supplierId,
          e.foreign,
          e.createdBy,
          e.createdDate ? new Date(e.createdDate).toLocaleDateString() : '',
          e.status,
          e.fieldName,
          e.entityType,
          e.contactType,
          e.fieldOldValue,
          e.fieldNewValue,
        ]);
      });
    }

    return data;
  }

  clear() {
    this.searchForm.controls.dateFrom.setValue('');
    this.searchForm.controls.dateThru.setValue('');
    this.searchForm.controls.institutionName.setValue('');
    this.searchForm.controls.dunsNumber.setValue('');
    this.searchForm.controls.uniqueEntityId.setValue('');
    this.searchForm.controls.updatedBy.setValue('');
    this.searchForm.controls.institutionsCreated.setValue('');
    this.searchForm.controls.institutionsCompleted.setValue('');
    this.searchForm.controls.institutionAudit.setValue('');
    this.feedbackService.clearAlert();
  }
}
