<div class="card" style="padding-right: 20px">
  <div class="card-header">Fully Executed Agreement</div>

  <div class="clr-row">
    <div class="clr-col-6">
      <div class="card-block" [formGroup]="feaDocuments">
        <subs-card-file-upload
          id="fea-upload"
          title="Upload FEA File"
          fileNameControlName="fileName"
          fileIdControlName="fileId"
          dateControlName="dateUploaded"
          [acceptedMimeTypes]="['application/pdf']"
          [canEdit]="canEdit"
          acceptedMimeTypesErrorMessage="The Fully Executed Agreement must be a PDF document."
          [callback]="callback"
          [canceledCallback]="canceledCallback"
          [getConfirmation]="true"
          confirmationMessage="Are you sure you wish to upload the FEA? If yes, the PO notifications will be sent and the Subaward Record will be Saved."
          confirmationTitle="Upload Fully Executed Agreement"
        >
        </subs-card-file-upload>
      </div>
    </div>
    <div *ngIf="showResendEmails()" class="clr-col-6 card-block">
      <div class="card">
        <div class="card-header">Resend FEA Emails</div>
        <div class="card-block">
          <div class="clr-row">
            <button
              class="btn btn-outline btn-sm"
              *ngIf="!internalDistributionSent"
              (click)="sendInternalDistributionEmail()"
            >
              Send Internal Distribution Email
            </button>
            <button
              class="btn btn-outline btn-sm"
              *ngIf="!externalPONotificationSent"
              (click)="sendExternalPONotificationEmail()"
            >
              Send External PO Notification Email
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
