<subs-select *ngIf="controlName !== 'role'"
             [controlName]="controlName"
             [label]="label"
             [labelLength]="labelLength"
>
  <option *ngFor="let item of this.options" [value]="item.value">{{
    item.value
    }}</option>
</subs-select>
<subs-select *ngIf="controlName === 'role'"
  [controlName]="controlName"
  [label]="label"
  [labelLength]="labelLength"
>
  <option *ngFor="let item of this.options" [value]="item.key">{{
    item.key
    }}</option>
</subs-select>
