import { Component, OnInit, Input } from '@angular/core';
import { SubawardsClient } from 'src/app/api.service';

@Component({
  selector: 'subs-assigned-ga',
  templateUrl: './assigned-ga-component.html',
})
export class AssignedGAComponent implements OnInit {
  claimingGAList: Array<string> = [];
  @Input() controlName: string;
  @Input() label: string;

  constructor(private subawardsClient: SubawardsClient) {}

  ngOnInit(): void {
    this.fillGASearchList();
  }

  fillGASearchList() {
    this.subawardsClient.getClaimingGAList().subscribe(val => {
      val.forEach(x => {
        this.claimingGAList.push(x);
      });
    });
  }
}
