<div class="clr-file-wrapper" style="margin-top: 0;">
  <label [for]="inputId" class="clr-control-label" style="padding-left:0;">
    <span class="btn btn-sm" [class.disabled]="disable || loading">
      <span *ngIf="loading" class="spinner spinner-inline"></span>
      <ng-content *ngIf="!loading"></ng-content>
    </span>
  </label>

  <input
    #fileInput
    [id]="inputId"
    type="file"
    class="clr-file"
    (change)="upload($event.target.files[0])"
    [disabled]="disable || loading"
    [attr.data-test-id]="id"
  />
</div>
