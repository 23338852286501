import { Component } from '@angular/core';
import { FeedbackService } from '../../form-layout/feedback.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  OsrsReviewReportClient,
  OsrsReviewReportResponse,
  OsrsReviewReportViewModel,
} from '../../api.service';
import { ExcelExportService } from '../excel-export.service';

interface SummaryRows {
  label: string | undefined;
  value: number | undefined;
}

@Component({
  selector: 'subs-osrs-review-report',
  templateUrl: './osrs-review-report.component.html',
  providers: [FeedbackService],
})
export class OsrsReviewReportComponent {
  submitState = this.feedbackService.submitState;
  alertSubject = this.feedbackService.alerts;

  searchForm = this.fb.group({
    dateFrom: ['', Validators.required],
    dateThru: ['', Validators.required],
    assignedGA: '',
  });

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private osrsReviewReportClient: OsrsReviewReportClient,
    private excelExportService: ExcelExportService,
  ) {}

  search() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.osrsReviewReportClient
        .get(
          new Date(this.searchForm.controls.dateFrom.value),
          new Date(this.searchForm.controls.dateThru.value),
          this.searchForm.controls.assignedGA.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async val => await this.generateReport(val));
    }
  }

  async generateReport(results: OsrsReviewReportResponse) {
    if (results.subs.length === 0) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }
    const columns = [
      {
        header: 'Subrecipient',
        width: 60,
      },
      {
        header: 'OSRS GA',
        width: 20,
      },
      {
        header: 'WashU PI',
        width: 20,
      },
      {
        header: 'Dept Admin',
        width: 27,
      },
      {
        header: 'Subaward Number',
        width: 18,
      },
      {
        header: 'OSRS Not Approved Date',
        width: 21,
      },
      {
        header: 'GA Resubmits for Approval Date',
        width: 27,
      },
      {
        header: '# of Days in OSRS Review',
        width: 21,
      },
    ];

    const data = this.generateReportData(results.subs);
    const arrSummary = this.generateSummaryRowsArray(results);

    await this.excelExportService.generateExcel(
      'OSRS Review Report',
      'OSRS Review Report',
      data,
      columns,
      {
        value:
          'Date Range: ' +
          this.searchForm.controls.dateFrom.value +
          ' - ' +
          this.searchForm.controls.dateThru.value,
      },
      this.addStylesAndSummaryRows,
      arrSummary,
    );
  }

  private generateReportData(results: Array<OsrsReviewReportViewModel>) {
    const data = [];
    results.forEach(e => {
      data.push([
        e.subrecipient,
        e.assignedGA,
        e.washUPI,
        e.deptAdmin,
        e.subawardNumber,
        e.osrsNotApprovedDate
          ? new Date(e.osrsNotApprovedDate).toLocaleDateString()
          : '',
        e.pendingApprovalDate
          ? new Date(e.pendingApprovalDate).toLocaleDateString()
          : '',
        e.daysAtOsrsReview,
      ]);
    });
    return data;
  }

  private generateSummaryRowsArray(results: OsrsReviewReportResponse) {
    const summaryRowsArray: SummaryRows[] = [
      { label: 'TOTAL SUBS RETURNED: ', value: results.totalSubsCount },
      {
        label: 'Average # of Days per Review: ',
        value: Math.round(results.avgDaysAtOsrsReview * 10) / 10,
      },
    ];
    return summaryRowsArray;
  }

  private addStylesAndSummaryRows(worksheet, arrSummary) {
    arrSummary.forEach(e => {
      const row = worksheet.addRow([e.label + e.value]);
      worksheet.mergeCells(row.number, 1, row.number, 8);
      row.font = {
        size: 14,
        bold: true,
      };

      row.eachCell(cell => {
        cell.alignment = { vertical: 'top', horizontal: 'right' };
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });
    });
  }

  clear() {
    this.searchForm.controls.dateFrom.setValue('');
    this.searchForm.controls.dateThru.setValue('');
    this.searchForm.controls.assignedGA.setValue('');
    this.feedbackService.clearAlert();
  }
}
