import { Input, OnInit, Directive } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { capitalCase } from 'change-case';

@Directive()
export abstract class DelegatedLabeledComponent implements OnInit {
  @Input()
  set inputStyle(value: string) {
    this.safeInputStyle = this.sanitizer.bypassSecurityTrustStyle(value);
  }

  @Input()
  set labelLength(value: number) {
    if (value <= 0) {
      this.safeLabelStyle = this.sanitizer.bypassSecurityTrustStyle(
        `display: none;`,
      );
    } else {
      this.safeLabelStyle = this.sanitizer.bypassSecurityTrustStyle(
        `width: ${value}rem;`,
      );
    }
  }

  @Input()
  controlName: string;

  @Input()
  label: string;

  @Input()
  set containerStyle(value: string) {
    this.safeContainerStyle = this.sanitizer.bypassSecurityTrustStyle(value);
  }

  safeInputStyle: SafeStyle;
  safeLabelStyle: SafeStyle;
  safeContainerStyle: SafeStyle;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (!this.safeInputStyle) {
      this.safeInputStyle = this.sanitizer.bypassSecurityTrustStyle(
        'width:100%',
      );
    }

    if (!this.label) {
      this.label = capitalCase(this.controlName);
    }

    if (this.controlName === 'role') {
      this.safeContainerStyle = this.sanitizer.bypassSecurityTrustStyle(
        'margin-top:0px;',
      );
    }
  }
}
