import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'subs-contact-osrs',
  templateUrl: './contact-osrs.component.html',
})
export class ContactOsrsComponent {
  userName = this.authService.user.pipe(map(user => user && user.name));

  constructor(private authService: AuthService) {}

  async logout() {
    await this.authService.logout();
  }
}
