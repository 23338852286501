import { Component, Input } from '@angular/core';
import { LookupItem } from '../../api.service';

@Component({
  selector: 'subs-state-province',
  templateUrl: './state-province.component.html',
})
export class StateProvinceComponent {
  @Input() controlName: string;
  @Input() label: string;
  @Input() labelLength: string;
  @Input() options: LookupItem[];
  @Input() errorMessage?: string;

  @Input()
  includeCongressionalDistrict: boolean;

  requiredIndicator = '* ';

  @Input()
  set hideRequiredItems(val: boolean) {
    if (val) {
      this.requiredIndicator = '';
    }
  }
  constructor() {}
}
