<div class="clr-row user-dashboard" id="user-dashboard">
  <div id="home-top" class="clr-col-12 home-top">
    <div class="clr-row">
      <div id="home-announcements" class="clr-col-9 home-announcements">
        <subs-dashboard-announcements></subs-dashboard-announcements>
      </div>
      <div id="home-logo" class="clr-col-3 home-logo">
        <div class="clr-row">
          <div class="clr-col-12 img-div">
            <img src="assets/subsLogo.png" class="img-styling" />
          </div>
          <div id="home-weblinks" class="clr-col-12">
            <subs-dashboard-weblinks></subs-dashboard-weblinks>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="home-bottom" class="clr-col-12 home-bottom">
    <div class="clr-row">
      <div class="clr-col-3 home-contacts">
        <subs-dashboard-contacts></subs-dashboard-contacts>
      </div>
      <div class="clr-col-9 home-training">
        <subs-dashboard-training></subs-dashboard-training>
      </div>
    </div>
  </div>
</div>
