import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DelegatedLabeledComponent } from '../delegated-labeled.component';

@Component({
  selector: 'subs-input',
  template: `
    <clr-input-container [style]="safeContainerStyle">
      <label
        class="clr-col-12 clr-col-md-auto"
        [style]="safeLabelStyle"
        [ngStyle]="labelStyle"
      >
        {{ label }}
      </label>

      <input
        clrInput
        class="clr-col-12 clr-col-md"
        [style]="safeInputStyle"
        [attr.data-test-id]="controlName"
        [name]="controlName"
        [formControlName]="controlName"
        [disabled]="disabled"
        [attr.maxLength]="maxLength"
        [placeholder]="placeholder || ''"
      />
      <clr-control-helper *ngIf="helperText">{{
        helperText
      }}</clr-control-helper>
      <clr-control-error>{{ errorMessage }}</clr-control-error>
    </clr-input-container>
  `,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class InputComponent extends DelegatedLabeledComponent {
  @Input()
  maxLength?: number;

  @Input()
  errorMessage?: string;

  @Input()
  helperText?: string;

  @Input()
  placeholder?: string;

  @Input()
  disabled: boolean;

  @Input()
  labelWidth?: string;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  get labelStyle(): { [key: string]: string } {
    return {
      ...(this.labelWidth && { width: this.labelWidth }),
    };
  }
}
