import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'subs-principal-investigator',
  templateUrl: './principal-investigator.component.html',
})
export class PrincipalInvestigatorComponent {
  @Input() form: UntypedFormGroup;
}
