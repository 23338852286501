import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class SearchAttachmentService {
  private searchFormValue = new BehaviorSubject<UntypedFormGroup>(null);

  constructor() {}

  setSearchFormValue(formValue: UntypedFormGroup) {
    this.searchFormValue.next(formValue);
  }

  getSearchFormValue() {
    return this.searchFormValue.asObservable();
  }
}
