<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <subs-date
      controlName="dateFrom"
      labelLength="4"
      class="clr-col"
      errorMessage="Required field"
    >
    </subs-date>
    <subs-date
      controlName="dateThru"
      labelLength="4"
      class="clr-col"
      errorMessage="Required field"
    >
    </subs-date>
  </div>
  <div class="clr-row">
    <subs-input
      class="clr-col"
      controlName="sponsor"
      labelLength="4"
      placeholder="sponsor1, sponsor2"
      maxLength="80"
      helperText="Use commas to search for multiple Sponsors."
    ></subs-input>
    <subs-input
      class="clr-col"
      controlName="collaborator"
      labelLength="4"
      placeholder="collaborator1, collaborator2"
      maxLength="80"
      helperText="Use commas to search for multiple Collaborators."
    ></subs-input>
  </div>
  <div class="clr-row">
    <subs-select
      controlName="location"
      class="clr-col"
      label="Institution Location"
      labelLength="4"
    >
      <option value="" selected>&nbsp; </option>
      <option value="Foreign">Foreign</option>
      <option value="USA">U.S.</option>
    </subs-select>
  </div>
  <div style="margin-top:24px;">
    <subs-alert [alertBehavior]="alertSubject$"> </subs-alert>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="search()"
      data-test-id="search-btn"
      [disabled]="!searchForm.valid"
    >
      Submit
    </button>

    <button class="btn btn-outline" (click)="clear()" data-test-id="clear-btn">
      Clear
    </button>
  </div>
</form>
