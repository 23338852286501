<subs-form-layout [formGroup]="form">
  <subs-form-content>
    <div class="card">
      <div class="card-header">
        Sub Monitoring Questions Table
      </div>

      <div class="card-block">
        <div class="clr-row">
          <button
            class="clr-col-lg-auto"
            class="btn btn-sm"
            (click)="onAdd()"
            aria-label="Add Sub Question"
            title="Add Sub Question"
            data-test-id="add-button"
            style="margin: 0 0 0 0.5rem;"
          >
            Add
          </button>
        </div>
      </div>

      <div
        *ngFor="let question of questions.controls; let i = index"
        class="card-block"
        [formGroup]="question"
      >
        <div class="clr-row">
          <subs-input
            class="clr-col"
            controlName="sequenceNumber"
            errorMessage="A unique Sequence number is required"
            label="Sequence"
          >
          </subs-input>
          <subs-yes-no
            class="clr-col"
            labelLength="7"
            controlName="isActive"
            label="Is this question active?"
            [nullable]="false"
          >
          </subs-yes-no>
        </div>
        <div class="clr-row">
          <subs-textarea
            class="clr-col-10"
            label="Question"
            controlName="question"
            placeholder="Add a question."
            errorMessage="Please insert a question"
          >
          </subs-textarea>
          <div class="clr-col-2 clr-align-self-end">
            <button
              class="btn btn-sm"
              (click)="deleteQuestion(i)"
              data-test-id="delete"
            >
              Delete
            </button>
          </div>
        </div>
        <clr-modal [(clrModalOpen)]="deleteOpened">
          <h3 class="modal-title">
            Confirm Delete?
          </h3>

          <div class="modal-body">
            <p>
              Are you sure you would like to delete this question?
            </p>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline"
              (click)="cancelDeleteConfirmation()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="confirmDelete()"
              data-test-id="confirm-delete"
            >
              Delete
            </button>
          </div>
        </clr-modal>
      </div>
    </div>
  </subs-form-content>

  <subs-form-buttons>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="save()"
      data-test-id="submit-button"
    >
      Save
    </button>
  </subs-form-buttons>
</subs-form-layout>
