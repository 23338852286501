import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagridSortOrder } from '@clr/angular';
import { ProjectSearchResult, SubrecipientStatusVm } from '../../../api.service';

@Component({
  selector: 'subs-project-grid',
  templateUrl: './project-grid.component.html',
  styleUrls: ['./project-grid.component.scss'],
})
export class ProjectGridComponent {
  @Input() projectSearchResults: Array<ProjectSearchResult>;
  @Input() statuses: SubrecipientStatusVm[] = [];
  ascSort = ClrDatagridSortOrder.ASC;

  constructor(private router: Router) {
    this.projectSearchResults = [];
  }

  navigateToProject(id) {
    this.router.navigateByUrl('/project/' + id);
  }

  getStatus(statusCode: string) {
    const activeStatus = this.statuses.find(
      status => status.statusCode === statusCode,
    );
    return activeStatus ? activeStatus.statusDescription : '';
  }
}
