import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'subs-generic-readonly-contact',
  templateUrl: './generic-readonly-contact.component.html',
})
export class GenericReadonlyContactComponent {
  @Input() form: UntypedFormGroup;
  @Input() title: string;
}
