import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DelegatedLabeledComponent } from '../delegated-labeled.component';

@Component({
  selector: 'subs-date',
  template: `
    <clr-date-container>
      <label
        [for]="controlName"
        class="clr-col-12 clr-col-md-auto"
        [style]="safeLabelStyle"
        >{{ label }}
        <a
          *ngIf="tooltipText"
          role="tooltip"
          aria-haspopup="true"
          [class]="tooltipClass"
        >
          <clr-icon
            shape="info-circle"
            size="22"
            style="padding-bottom: 0.2em;"
          ></clr-icon>
          <span class="tooltip-content"> {{ tooltipText }} </span>
        </a>
      </label>

      <input
        clrDate
        type="date"
        class="clr-col-12 clr-col-md"
        [style]="safeInputStyle"
        [id]="controlName"
        [attr.data-test-id]="controlName"
        [name]="controlName"
        [formControlName]="controlName"
        [attr.disabled]="disabled === true ? 'disabled' : null"
      />
    </clr-date-container>
  `,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DateComponent extends DelegatedLabeledComponent {
  @Input() disabled = null;
  @Input() tooltipText: string;
  @Input() tooltipClass: string;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }
}
