import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../form-layout/feedback.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  SubrecipientStatusClient,
  SubrecipientStatusVm,
} from '../../api.service';
import { AlertOptions } from '../../alerts/alert-options';
import { tap } from 'rxjs/operators';
import { SubStatusControlFactory } from './sub-status-control-factory';
import { markFormGroupTouched } from '../../form-layout/markFormGroupTouched';
import { SubStatusService } from './sub-status.service';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'subs-subrecipient-status',
  templateUrl: './subrecipient-status.component.html',
  providers: [FeedbackService],
})
export class SubrecipientStatusComponent implements OnInit {
  indexToDelete = -1;
  deleteOpened = false;

  alertOptions: AlertOptions = {
    message: '',
    canBeClosed: true,
    type: 'danger',
  };
  submitState = this.feedbackService.submitState;

  subStatus: UntypedFormGroup;
  form: UntypedFormGroup;
  statuses: UntypedFormArray;

  constructor(
    private chRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private subStatusClient: SubrecipientStatusClient,
    private subStatusService: SubStatusService,
    private controlFactory: SubStatusControlFactory,
    private feedbackService: FeedbackService,
  ) {
    this.form = new UntypedFormGroup({
      statuses: this.fb.array([]),
    });

    this.statuses = this.form.controls.statuses as UntypedFormArray;
  }

  ngOnInit(): void {
    this.subStatusClient
      .getAllStatuses()
      .pipe(tap(result => this.bindSubStatus(result)))
      .subscribe();
  }

  private bindSubStatus(statuses: Partial<SubrecipientStatusVm[]>) {
    if (statuses) {
      this.statuses.clear();
      this.controlFactory
        .createMany(statuses.length)
        .forEach(control => this.statuses.push(control));

      this.statuses.reset(statuses);
      this.chRef.detectChanges();
    }
  }

  onAdd() {
    const control = this.controlFactory.createSubStatusControl({
      id: 0,
      statusCode: '',
      statusDescription: '',
      statusType: '',
      statusActionSource: 'Manual',
      isActive: true,
      createdBy: '',
      modifiedBy: '',
      createdDate: new Date(),
      modifiedDate: new Date(),
    });
    this.statuses.push(control);
  }

  save() {
    this.feedbackService.beginLoading();
    this.removeEmptyControls();

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      this.feedbackService.alert(
        'Please fix all field errors before saving the form.',
      );
      return;
    }

    const value = this.statuses.value;

    this.subStatusClient
      .updateSubrecipientStatuses(value)
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(result => {
        this.bindSubStatus(result);
        this.subStatusService.loadSubStatuses();
      });
  }

  removeEmptyControls() {
    const indexesToDelete: number[] = [];

    this.statuses.controls.forEach((c, i) => {
      if (c.value.statusCode === '' && c.value.statusDescription === '') {
        indexesToDelete.push(i);
      }
    });

    indexesToDelete.sort((a, b) => {
      return b - a;
    });
    indexesToDelete.forEach(i => {
      this.statuses.removeAt(i);
    });
  }

  systemStatus(item: any) {
    return item.statusActionSource === 'System' && item.id !== 0;
  }

  canAddSubStatus() {
    return this.authService.hasPermission('UtilitySubStatusCreate');
  }

  canUpdateSubStatus() {
    return this.authService.hasPermission('UtilitySubStatusUpdate');
  }
}
