import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { AlertOptions } from 'src/app/alerts/alert-options';
import { ReturnCode, ReturnCodesClient } from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { markFormGroupTouched } from 'src/app/form-layout/markFormGroupTouched';

import { ReturnCodeControlFactory } from './return-code-control-factory';

@Component({
  selector: 'subs-return-code',
  templateUrl: './return-code.component.html',
  providers: [FeedbackService],
})
export class ReturnCodeComponent implements OnInit {
  idxToRemove: number;
  returnCode: UntypedFormGroup;

  alertOptions: AlertOptions = {
    message: '',
    canBeClosed: true,
    type: 'danger',
  };
  submitState = this.feedbackService.submitState;

  form = this.fb.group({
    returnCodes: this.fb.array([]),
  });

  get returnCodes(): UntypedFormArray {
    return this.form.get('returnCodes') as UntypedFormArray;
  }

  indexToDelete = -1;
  returnCodeToDelete = '';
  deleteOpened = false;

  constructor(
    private chRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private returnCodeClient: ReturnCodesClient,
    private controlFactory: ReturnCodeControlFactory,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit(): void {
    this.returnCodeClient
      .get()
      .pipe(tap(result => this.bindReturnCode(result)))
      .subscribe();
  }

  onAdd() {
    const control = this.controlFactory.createReturnCodeControl({
      id: 0,
      code: '',
      reason: '',
    });
    this.returnCodes.push(control);
  }

  private bindReturnCode(returnCodes: Partial<ReturnCode[]>) {
    this.returnCodes.clear();
    this.controlFactory
      .createMany(returnCodes.length)
      .forEach(control => this.returnCodes.push(control));

    this.returnCodes.reset(returnCodes);
    this.chRef.detectChanges();
  }

  deleteReturnCode(index: number) {
    this.indexToDelete = index;
    this.returnCodeToDelete = this.returnCodes.value[index].code;
    this.deleteOpened = true;
  }

  cancelDeleteConfirmation() {
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.returnCodes.removeAt(this.indexToDelete);
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  returnCodeDisplayName() {
    if (this.returnCodeToDelete !== '' && this.returnCodeToDelete !== null) {
      return this.returnCodeToDelete;
    } else {
      return `return code ${this.indexToDelete + 1}`;
    }
  }

  removeEmptyControls() {
    const indexesToDelete: number[] = [];

    this.returnCodes.controls.forEach((c, i) => {
      if (c.value.code === '' && c.value.reason === '') {
        indexesToDelete.push(i);
      }
    });

    indexesToDelete.sort((a, b) => {
      return b - a;
    });
    indexesToDelete.forEach(i => {
      this.returnCodes.removeAt(i);
    });
  }

  save() {
    this.feedbackService.beginLoading();
    this.removeEmptyControls();

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      this.feedbackService.alert(
        'Please fix all field errors before saving the form.',
      );
      return;
    }

    const value = this.returnCodes.value;

    this.returnCodeClient
      .updateReturnCodes(value)
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(result => {
        this.bindReturnCode(result);
      });
  }
}
