<div class="form-container">
  <form
    clrForm
    [formGroup]="subawardForm"
    style="
      flex-grow: 1;
      overflow-y: hidden;
      margin-right: -1rem;
      padding-bottom: 0;
      padding-right: 0;
    "
  >
    <clr-tabs>
      <clr-tab>
        <button clrTabLink (click)="setActiveTab('details')" data-test-id="subaward-details-tab">
          Subaward Details
        </button>
        <ng-template [(clrIfActive)]="activeTab.details">
          <clr-tab-content>
            <subs-subaward-details
              [form]="subawardForm"
              [checklist]="checklist"
              [projectTitle]="projectTitle"
              [subawardStatus]="
                this.subawardForm.controls.washUInformation.get('status').value
              "
              [questionIsVisible]="questionIsVisible"
              [isModifiedAgreement]="isModifiedAgreement"
              [validatingGrantWorkTag]="validatingGrantWorkTag"
              (financialEmailClicked)="sendFinancialRiskEmail()"
              (institutionalInformationUpload)="
                this.loadInstitutionalInformation($event)
              "
              (sameAsGrantPeriodClicked)="sameAsGrantPeriod()"
              (sameAsSignatoryClicked)="copySignatory()"
              (grantWorkTagEntered)="validateGrantWorkTag($event)"
              (poCoRequestIdEntered)="copyPoCoRequestId($event)"
              (onCollaboratorInstitutionSelect)="
                this.onCollaboratorInstitutionSelect($event)
              "
            >
            </subs-subaward-details>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="setActiveTab('contacts')" data-test-id="subaward-cotacts-tab">
          Subaward Contacts
        </button>
        <ng-template [(clrIfActive)]="activeTab.contacts">
          <clr-tab-content>
            <subs-contact
              [contactForm]="subawardForm.controls.contact"
              [checklist]="checklist"
              [projectTitle]="projectTitle"
              [centralOfficeEmail]="
                subawardForm.controls.subrecipientInformation.get(
                  'centralOfficeEmail'
                )
              "
            ></subs-contact>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="setActiveTab('additionalDocuments')" data-test-id="additional-documents-tab">
          Additional Documents
        </button>
        <ng-template [(clrIfActive)]="activeTab.additionalDocuments">
          <clr-tab-content>
            <subs-additional-document
              [checklist]="checklist"
              [projectTitle]="projectTitle"
              [additionalDocuments]="additionalDocuments"
              [feaDocuments]="subawardForm.controls.fullyExecutedAgreement"
              (additionalDocument)="addAdditionalDocument($event)"
              (idxToRemove)="removeAdditionalDocument($event)"
            >
            </subs-additional-document>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="setActiveTab('comments')" data-test-id="subaward-comments-tab">
          Comments
        </button>
        <ng-template [(clrIfActive)]="activeTab.comments">
          <clr-tab-content>
            <subs-comment
              [form]="subawardForm.controls.comments"
              [checklist]="checklist"
              [projectTitle]="projectTitle"
            >
            </subs-comment>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="setActiveTab('auditHistory')" data-test-id="subaward-audit-history-tab">
          Audit
        </button>
        <ng-template [(clrIfActive)]="activeTab.auditHistory">
          <clr-tab-content>
            <subs-audit-history
              [form]="subawardForm.controls.auditLogs"
            ></subs-audit-history>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="setActiveTab('ffata')" data-test-id="subaward-ffata-tab">FFATA</button>
        <ng-template [(clrIfActive)]="activeTab.ffata">
          <clr-tab-content>
            <subs-ffata
              [ffataDescription]="subawardForm.controls.ffataDescription"
              [institutionOfficers]="subawardForm.controls.institutionOfficers"
              [subawardForm]="this.subawardForm"
            >
            </subs-ffata>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab *subsPermission="['TabSubawardAttachmentsRead']">
        <button clrTabLink (click)="setActiveTab('subAttachments')" data-test-id="subaward-attachments-tab">
          Subaward Attachments
        </button>
        <ng-template [(clrIfActive)]="activeTab.subAttachments">
          <clr-tab-content>
            <subs-subaward-attachments
              [form]="subawardForm"
              [checklist]="checklist"
              [projectTitle]="projectTitle"
              [approved]="this.subawardForm.controls.approved.value"
              [feaFileUploaded]="feaFileUploaded"
              [internalDistributionSent]="
                this.subawardForm.controls.internalDistributionSent.value
              "
              [unilateralModSent]="
                this.subawardForm.controls.unilateralModSent.value
              "
              [externalPONotificationSent]="
                this.subawardForm.controls.poNotificationSent.value
              "
              [contractAttachments]="contractAttachments"
              [fea]="subawardForm.controls.fullyExecutedAgreement"
              (contractAttachment)="addContractAttachment($event)"
              (idxToRemove)="removeContractAttachment($event)"
              (onNotApproved)="setStatusAndRedirectToProjectSummary()"
              (onSequenceErrorFixed)="saveSubaward()"
              (attachmentSelected)="selectAttachments()"
              (financialEmailClicked)="sendFinancialRiskEmail()"
              (signAgreementClicked)="signAgreement($event)"
              (routeForSignatureClicked)="routeForSignature()"
              (returnedToDA)="save()"
              (generateFullAgreementPDF)="generateFullAgreementPDF()"
              (sendSubSignatureEmail)="sendSubrecipientSignatureEmail()"
              (sendReturnedToDAEmail)="sendReturnedToDAEmail()"
              (feaUploaded)="feaUploaded($event)"
              (sendBilateralOrUnilateralEmail)="
                sendBilateralOrUnilateralEmail()
              "
              (resendInternalDistributionEmail)="
                resendFEAInternalDistributionEmail($event)
              "
              (resendSubUnilateralModEmail)="resendSubUnilateralModEmail()"
              (resendPurchaseOrderNotificationEmail)="
                resendPurchaseOrderNotificationEmail()
              "
            >
            </subs-subaward-attachments>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab *subsPermission="['TabFcoiRead']">
        <button clrTabLink (click)="setActiveTab('fcoi')" data-test-id="subaward-fcoi-tab">
          FCOI Compliance
        </button>
        <ng-template [(clrIfActive)]="activeTab.fcoi">
          <clr-tab-content>
            <subs-fcoi
              [form]="subawardForm.controls.washUInformation"
              (fcoiEmailClicked)="sendFcoiEmail()"
            >
            </subs-fcoi>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
    </clr-tabs>
  </form>
  <div class="card form-footer">
    <subs-alert [alertBehavior]="alertSubject$"> </subs-alert>
    <subs-alert
      *ngIf="this.showCollaboratorInstitutionAlert"
      [alertBehavior]="collaboratorInstituteAlertOptions"
    >
    </subs-alert>
    <button
      *ngIf="!viewOnlyUser()"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="save()"
      data-test-id="submit-subaward-save"
      [disabled]="disableButtons | async"
    >
      Save
    </button>
    <button
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="returnToProjectSummary()"
      data-test-id="return-to-project-summary"
    >
      Return to Project Summary
    </button>
    <button
      *subsPermission="['AccessButtonSubmitToOsrs']"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="submitToOsrs()"
      data-test-id="submit-to-osrs"
      [disabled]="disableSubmitToOsrs | async"
    >
      Submit to OSRS
    </button>
    <button
      [disabled]="inactiveStatus"
      *ngIf="canChangesToCurrentYear()"
      class="btn btn-outline"
      (click)="changesToCurrentYear()"
      data-test-id="changes-to-current-year"
    >
      Changes to current Year
    </button>

    <button
      *ngIf="!viewOnlyUser()"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="requestCollaboratorInformation()"
      data-test-id="submit-collaborator-information-request"
    >
      Request Collaborator Information
    </button>
  </div>
</div>

<clr-modal
  [(clrModalOpen)]="showUnsavedChangesModal"
  [clrModalClosable]="false"
>
  <h3 class="modal-title">You have unsaved changes.</h3>
  <div class="modal-body">
    <subs-alert [alertBehavior]="alertSubject$"></subs-alert>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="confirmSave()"
      data-test-id="confirm-save-before-navigate"
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="discardChanges()"
    >
      Discard
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="cancelNavigation()"
    >
      Cancel
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="canSubmitToOsrsModal" [clrModalClosable]="false">
  <h3 class="modal-title">You have unsaved changes.</h3>
  <div class="modal-body">
    <subs-alert [alertBehavior]="alertSubject$"></subs-alert>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="confirmSubawardSave()"
      data-test-id="confirm-save-before-navigate"
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="discardSubawardChanges()"
    >
      Discard
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="cancelSubawardNavigation()"
    >
      Cancel
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showSubmitToOsrsModal" [clrModalClosable]="false">
  <h3 class="modal-title">Reminder</h3>
  <div class="modal-body">
    <p>
      By clicking the "Submit" button, you acknowledge that the Collaborator
      Institution Information spreadsheet has been reviewed and the records
      updated with any new information.
    </p>
    <p>Click "Cancel" to return to the submission.</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="confirmSubmitToOsrs()"
      data-test-id="confirm-save-before-navigate"
    >
      Submit
    </button>
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelSubmitToOsrs()"
    >
      Cancel
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEmailConfirmation" [clrModalClosable]="false">
  <h3 class="modal-title">
    {{ emailResult$ | async }}
  </h3>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeEmailConfirmationModal()"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showNoAttachmentsModal" [clrModalClosable]="true">
  <h3 class="modal-title">Generate Full Agreement PDF</h3>

  <div class="modal-body">
    Cannot generate the agreement PDF because no attachments have been selected.
    Click on the "Select Attachments" button and select some attachments before
    clicking the "Generate Full Agreement PDF" button.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showNoAttachmentsModal = false"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [(clrModalOpen)]="showFullAgreementProgress"
  [clrModalClosable]="false"
>
  <h3 class="modal-title">Generate Full Agreement PDF</h3>

  <div class="modal-body">
    <span class="spinner spinner-inline"> In progress... </span>
    &nbsp; {{ generateAgreementStatusMessage | async }}
  </div>

  <div class="modal-footer"></div>
</clr-modal>

<clr-modal
  [(clrModalOpen)]="showGenerateAgreementError"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Generate Full Agreement PDF</h3>

  <div class="modal-body">
    <div>
      {{ generateAgreementStatusMessage | async }}
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="showGenerateAgreementError = false"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal [clrModalOpen]="showAgreementSignedModal" [clrModalClosable]="true">
  <h3 class="modal-title">Agreement previously signed.</h3>

  <div class="modal-body">
    This agreement already has a signature on it and may not be signed again.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showAgreementSignedModal = false"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showSignatureNotFoundModal | async"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">No Signature on File</h3>

  <div class="modal-body">
    Cannot sign the document because you do not have a signature on file.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="showSignatureNotFoundModal.next(false)"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showDuplicatedBookmarkModal | async"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Duplicated Bookmark</h3>

  <div class="modal-body">
    {{ duplicatedBookmarkMessage }}
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="showDuplicatedBookmarkModal.next(false)"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showSignedSuccessModal | async"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Success</h3>

  <div class="modal-body">The agreement was signed successfully.</div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="showSignedSuccessModal.next(false)"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [(clrModalOpen)]="showConfirmationExcelImport"
  [clrModalClosable]="false"
>
  <h3 class="modal-title">
    Do you want to override the subaward record values with the spreadsheet
    information?
  </h3>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="confirmExcelImport()"
      data-test-id="confirm-excel-import"
    >
      Upload and Overwrite
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="discardExcelImport()"
    >
      Upload Only
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="discardExcelUpload()"
    >
      Cancel
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showScopeOfWorkNotAnsweredModal"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Has the scope of work changed?</h3>

  <div class="modal-body">
    Please answer the question about the scope of work prior to submitting to
    OSRS
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showScopeOfWorkNotAnsweredModal = false"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showFundChangedNotAnsweredModal"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Has the fund changed from previous year?</h3>

  <div class="modal-body">
    Please answer the question about whether the fund has changed from the
    previous year prior to submitting to OSRS
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showFundChangedNotAnsweredModal = false"
      data-test-id="alert-message-fund-changed"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showDescriptionofActionModal"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Description of Action</h3>
  <div class="modal-body">
    Please enter a Description of the Action prior to submitting to OSRS.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showDescriptionofActionModal = false"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showMonitoringQuestionsModal"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Sub Monitoring Questions</h3>
  <div class="modal-body">
    Please answer all Sub Monitoring Questions prior to submitting to OSRS.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showMonitoringQuestionsModal = false"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showMonitoringQuestionsReminderModal"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Sub Monitoring Questions</h3>
  <div class="modal-body">
    You have answered NO to one or more of the monitoring questions. Please
    contact OSRS at wucontracts&#64;wusm.wustl.edu to discuss subrecipient
    performance.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showMonitoringQuestionsReminderModal = false"
    >
      Ok
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showCongressionalDistrictModal"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Congressional District is not required here</h3>
  <div class="modal-body">
    FYI - In the Collaborator Primary Place of Performance section, the
    Congressional District is NOT required for Submitting to OSRS when the
    Country selected is not U.S.A.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showCongressionalDistrictModal = false"
      data-test-id="congressional-district-modal"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showRouteForSignatureClickedModal"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">Route For Signature - Status Changed</h3>
  <div class="modal-body">
    The SubAward Status has been updated to 'M.Pending Approval'.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showRouteForSignatureClickedModal = false"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal [clrModalOpen]="showSubAwardSavedModal" [clrModalClosable]="true">
  <h3 class="modal-title">SubAward Saved</h3>
  <div class="modal-body">Changes to the SubAward have been saved.</div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showSubAwardSavedModal = false"
      data-test-id="subaward-saved-modal"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal
  *ngIf="supplierContractIdWarningMessage.length > 0"
  [clrModalOpen]="showSupplierContractIdModal"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">
    {{ supplierContractIdWarningMessage[0].warningMessageTitle }}
  </h3>

  <div class="modal-body">
    {{ supplierContractIdWarningMessage[0].warningMessageBody }}
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="clearSupplierContractIdWarningMessage()"
    >
      OK
    </button>
  </div>
</clr-modal>

<clr-modal
  *ngIf="grantWorkTagWarningMessage.length > 0"
  [clrModalOpen]="showGrantWorkTagModal"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">
    {{ grantWorkTagWarningMessage[0].warningMessageTitle }}
  </h3>

  <div *ngFor="let warning of grantWorkTagWarningMessage" class="modal-body">
    {{ warning.warningMessageBody }}
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="clearGrantWorkTagWarningMessage()"
    >
      OK
    </button>
  </div>
</clr-modal>

<clr-modal [clrModalOpen]="showDMSPModal" [clrModalClosable]="true">
  <h3 class="modal-title">
    Reminder: Data Management Sharing Plan Requirement
  </h3>

  <div class="modal-body">
    You answered "Yes" to the Data Management Sharing Plan (DMSP) Requirement;
    please upload the DMSP document.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showDMSPModal = false"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal [clrModalOpen]="showPsoStatusChangeModal" [clrModalClosable]="true">
  <h3 class="modal-title">Reminder</h3>

  <div class="modal-body">
    All Required Items are now completed. Status changed to “D. Pending
    Submission to OSRS.
    <br />
    Reminder: You must select the “Submit to OSRS” button to route record for
    further processing.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showPsoStatusChangeModal = false"
    >
      Close
    </button>
  </div>
</clr-modal>

<clr-modal [clrModalOpen]="showIpStatusChangeModal" [clrModalClosable]="true">
  <h3 class="modal-title">Reminder</h3>

  <div class="modal-body">
    One or more Required Items missing. The status changed back to "A. In
    Progress".
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showIpStatusChangeModal = false"
    >
      Close
    </button>
  </div>
</clr-modal>
