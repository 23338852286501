import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'subs-university-addresses',
  templateUrl: './university-addresses.component.html',
})
export class UniversityAddressesComponent {
  @Input()
  osrsAddress: UntypedFormGroup;

  @Input()
  institutionAddress: UntypedFormGroup;

  constructor() {}
}
