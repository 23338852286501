import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubrecipientQuestion } from 'src/app/api.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Injectable()
export class MonitoringQuestionFactory {
  constructor(private fb: UntypedFormBuilder) {}

  createControl(value: SubrecipientQuestion): UntypedFormGroup {
    const control = this.createGroup();
    control.setValue(value);
    return control;
  }

  createMany(count: number): UntypedFormGroup[] {
    const output = [];

    for (let i = 0; i < count; i++) {
      output.push(this.createGroup());
    }

    return output;
  }

  private createGroup() {
    return this.fb.group({
      id: [],
      sequenceNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(/^-?([1-9]\d*)?$/),
          RxwebValidators.unique(),
        ],
      ],
      question: ['', Validators.required],
      isActive: ['', Validators.required],
    });
  }
}
