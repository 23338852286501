import { Component, Input, TemplateRef } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DelegatedLabeledComponent } from '../delegated-labeled.component';

@Component({
  selector: 'subs-yes-no',
  template: `
    <label
      *ngIf="!isLabelHidden"
      class="clr-col-12 clr-col-md-auto clr-control-label"
      [class.clr-col-md-6]="!safeLabelStyle"
      [class.clr-col-md-auto]="!!safeLabelStyle"
      [style]="safeLabelStyle"
      [ngStyle]="{ 'max-width': labelMaxWidth }"
      [attr.disabled]="disabled === true ? 'disabled' : null"
      >{{ label }}
      <ng-container *ngIf="tooltip">
        <ng-container *ngTemplateOutlet="tooltip"></ng-container> </ng-container
    ></label>
    <div
      clr-inline
      class="clr-control-container clr-control-inline"
      [class.clr-col-12]="!isLabelHidden"
      [class.clr-col-md-6]="!isLabelHidden"
    >
      <clr-radio-wrapper
        (click)="resetToNull($event, true)"
        [attr.data-test-id]="controlName + '-yes-wrapper'"
        [attr.disabled]="disabled === true ? 'disabled' : null"
      >
        <input
          type="radio"
          clrRadio
          [formControlName]="controlName"
          [value]="true"
          [name]="controlName"
          [attr.data-test-id]="controlName + '-yes'"
          [attr.disabled]="disabled === true ? 'disabled' : null"
        />
        <label>Yes</label>
      </clr-radio-wrapper>

      <clr-radio-wrapper
        (click)="resetToNull($event, false)"
        [attr.data-test-id]="controlName + '-no-wrapper'"
        [attr.disabled]="disabled === true ? 'disabled' : null"
      >
        <input
          type="radio"
          clrRadio
          [formControlName]="controlName"
          [value]="false"
          [name]="controlName"
          [attr.data-test-id]="controlName + '-no'"
          [attr.disabled]="disabled === true ? 'disabled' : null"
        />
        <label>No</label>
      </clr-radio-wrapper>
      <clr-control-error style="color: #a51417">{{
        errorMessage
      }}</clr-control-error>
    </div>
  `,
  host: {
    '[class.clr-form-control]': 'true',
  },
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class YesNoComponent extends DelegatedLabeledComponent {
  protected hideLabelField: boolean;

  @Input()
  set hideLabel(value: string) {
    this.hideLabelField = !value || value === '';
  }

  public get isLabelHidden(): boolean {
    return this.hideLabelField;
  }

  @Input()
  disabled: boolean;

  @Input()
  nullable: boolean;

  @Input()
  errorMessage?: string;

  @Input()
  tooltip: TemplateRef<any>;

  @Input()
  labelMaxWidth: string;

  constructor(
    sanitizer: DomSanitizer,
    private container: ControlContainer,
  ) {
    super(sanitizer);
    this.nullable = true;
  }

  resetToNull(e, condition) {
    if (
      this.container.control.value[this.controlName] === condition &&
      !this.disabled &&
      this.nullable
    ) {
      this.container.control.get(this.controlName).setValue(null);
      e.preventDefault();
      return false;
    }
  }
}
