<div class="card">
  <div class="card-header">
    Submonitoring Questions
  </div>
  <div class="card-block">
    <div
      class="clr-row"
      *ngFor="
        let subrecipientMonitoringQuestion of subrecipientMonitoringQuestions.controls;
        let i = index
      "
      [formGroup]="subrecipientMonitoringQuestion"
    >
      <label
        class="clr-col-2 clr-control-label"
        style="margin-top:1rem; padding-top:4px;"
      >
        Question {{ i + 1 }}
      </label>

      <div class="clr-col-8 clr-form-control">
        {{ subrecipientMonitoringQuestion.value.question }}
      </div>

      <subs-yes-no controlName="answer" hideLabel class="clr-col-2">
      </subs-yes-no>
    </div>
  </div>
</div>
