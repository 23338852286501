<clr-datagrid>
  <clr-dg-column
    [style.width.px]="130"
    [clrDgField]="'priority'"
    [clrDgSortOrder]="ascSort"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Priority
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'assignedGA'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      OSRS GA
    </ng-container></clr-dg-column
  >
  <clr-dg-column [style.width.px]="140" [clrDgField]="'status'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Current Status
    </ng-container></clr-dg-column
  >
  <clr-dg-column [style.width.px]="140" [clrDgField]="'repeatedSubmission'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Repeat Submission
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'lastStatusChangeDate'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Last Status Change Date
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'submittedDate'" [style.width.px]="150"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Submitted to OSRS Date
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'subrecipient'" [style.width.px]="150"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Subrecipient
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'foreignEntity'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Foreign Institution
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'totalCostsThisAction'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Total Costs This Action
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'wUPILastName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      WU PI Last Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [style.width.px]="150" [clrDgField]="'wUPIFirstName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      WU PI First Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'proposalId'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Proposal ID
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'awardDept'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Award Dept
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'issuingDept'" [style.width.px]="150"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Issuing Dept
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'grantWorkTag'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Grant Worktag
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'costCenterId'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      PI Cost Center
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'awardYear'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Award Year
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'agreementNumber'" [style.width.px]="150"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Agreement Num
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'trackingNumber'" [style.width.px]="150"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Tracking Num
    </ng-container></clr-dg-column
  >

  <clr-dg-row *clrDgItems="let result of searchResults">
    <clr-dg-cell
      style="
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <input
        type="checkbox"
        [(ngModel)]="result.priority"
        (change)="onToggle()"
      />
    </clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.assignedGA
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      this.getStatus(result.status)
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.repeatedSubmission ? 'Yes' : 'No'
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.lastStatusChangedDate | date: 'shortDate'
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.submittedDate | date: 'shortDate'
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.subrecipient
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.foreignEntity
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">
      {{ result.totalCostsThisAction | currency }}
    </clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.wupiLastName
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.wupiFirstName
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.proposalId
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.awardDept
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.issuingDept
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.grantWorkTag
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.costCenterId
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.awardYear
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.agreementNumber
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToSub(result.subrecipientId)">{{
      result.trackingNumber
    }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer data-test-id="results-amount"
    >{{ searchResults.length === 0 ? 'No' : searchResults.length }} result{{
      searchResults.length === 1 ? '' : 's'
    }}</clr-dg-footer
  >
</clr-datagrid>
