<clr-main-container>
  <clr-header class="header-6">
    <div class="branding">
      <a href="/" class="nav-link">
        <img
          src="assets/1linerev(RGB)1000-01.png"
          style="height:4em; margin-right: 1em;"
        />
      </a>
    </div>

    <div class="header-actions">
      <clr-dropdown>
        <button
          class="nav-text"
          clrDropdownTrigger
          aria-label="open user profile"
        >
          {{ userName | async }}
          <clr-icon shape="caret down"></clr-icon>
        </button>

        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <button clrDropdownItem (click)="logout()">Log out</button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </clr-header>

  <div class="content-area">
    <p style="margin-left: 2em;">
      Contact OSRS for access by emailing
      <a href="mailto:wucontracts@wusm.wustl.edu">wucontracts&#64;wusm.wustl.edu</a>
    </p>
  </div>
</clr-main-container>
