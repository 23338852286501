<div class="clr-row">
  <subs-static-text
    class="clr-col-md"
    [controlName]="fileNameControlName"
    label="{{ label }}"
  ></subs-static-text>
  <subs-static-text
    *ngIf="dateControlName"
    class="clr-col-md"
    [controlName]="dateControlName"
    label="Date Uploaded"
    labelLength="6"
  ></subs-static-text>
</div>

<subs-alert [alertBehavior]="uploadAlert$"> </subs-alert>

<div style="margin-top:24px;">
  <div class="clr-row" *ngIf="!uploadingFileName">
    <div class="clr-file-wrapper" style="margin-top: 0;">
      <label [for]="inputId" class="clr-control-label clr-col-12 clr-col-md-2"
        ><span class="btn btn-sm" [class.disabled]="!!fileId || !canEdit">{{
          buttonLabel
        }}</span></label
      >
      <input
        #fileInput
        type="file"
        [id]="inputId"
        [attr.data-test-id]="id"
        class="clr-file"
        (change)="upload($event.target.files[0])"
        [disabled]="!!fileId || !canEdit"
      />
    </div>

    <button
      class="btn btn-sm"
      (click)="beginDelete()"
      [disabled]="!fileId || !canEdit"
    >
      Delete
    </button>

    <button class="btn btn-sm" (click)="download()" [disabled]="!fileId">
      Download
    </button>
  </div>

  <div *ngIf="!!uploadingFileName">
    <clr-spinner clrInline>
      Uploading
    </clr-spinner>
    <span> Uploading {{ uploadingFileName }} </span>
  </div>
</div>

<clr-modal [(clrModalOpen)]="showDeleteConfirmation">
  <h3 class="modal-title">
    Confirm Delete?
  </h3>

  <div class="modal-body">
    <p>
      Are you sure you want to delete <strong>{{ fileName }}</strong
      >?
    </p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="cancelDelete()">
      Cancel
    </button>

    <button type="button" class="btn btn-primary" (click)="delete()">
      Delete
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showUploadConfirmation" [clrModalClosable]="false">
  <h3 class="modal-title">
    {{ confirmationTitle }}
  </h3>
  <div class="modal-body">
    {{ confirmationMessage }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="confirmUpload()">
      Yes
    </button>
    <button type="button" class="btn btn-outline" (click)="cancelUpload()">
      Cancel
    </button>
  </div>
</clr-modal>
