<div class="form-container">
  <div
    style="
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 24px;"
  >
    <div class="card">
      <div class="card-header">
        Audit Logs
      </div>
      <div class="card-block">
        <div *ngFor="let log of auditLogs | async" class="card-block">
          <subs-audit-log-card [auditLog]="log"> </subs-audit-log-card>
        </div>
      </div>
    </div>
  </div>
</div>
