<subs-form-layout>
  <subs-form-content>
    <div class="card" style="margin-top: 0px">
      <div class="card-header">
        Select Attachments
      </div>
    </div>

    <form clrForm style="margin: 0; padding: 0;">
      <div class="card">
        <div class="card-header">
          Project Information
        </div>

        <div class="card-block" [formGroup]="projectInformation">
          <div class="clr-row">
            <subs-static-text
              controlName="agreementNumber"
              label="WU agreement number"
              labelLength="8"
              class="clr-col-4"
            ></subs-static-text>

            <subs-static-text
              controlName="proposalId"
              label="Proposal ID"
              labelLength="6"
              class="clr-col-4"
            ></subs-static-text>

            <subs-static-text
              controlName="awardDepartment"
              label="Department"
              labelLength="6"
              class="clr-col-4"
            ></subs-static-text>
          </div>

          <div class="clr-row">
            <subs-static-text
              controlName="awardYear"
              labelLength="8"
              class="clr-col-4"
            ></subs-static-text>

            <subs-static-text
              controlName="amendment"
              labelLength="6"
              class="clr-col-4"
            ></subs-static-text>
          </div>

          <subs-static-text controlName="sponsorName"></subs-static-text>

          <div class="clr-row">
            <subs-static-text
              controlName="investigatorName"
              class="clr-col-6"
            ></subs-static-text>

            <subs-static-text
              controlName="investigatorDepartmentName"
              labelLength="12"
              class="clr-col-12 clr-col-lg-6"
            ></subs-static-text>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          Institution Information
        </div>
        <div class="card-block" [formGroup]="institutionInformation">
          <div class="clr-row">
            <subs-static-text
              controlName="institutionId"
              label="Institution ID"
              class="clr-col-4"
              labelLength="6"
            ></subs-static-text>

            <subs-static-text
              controlName="institution"
              class="clr-col"
              labelLength="6"
            ></subs-static-text>
          </div>

          <div class="clr-row">
            <subs-static-text
              controlName="fund"
              class="clr-col-4"
              labelLength="6"
            ></subs-static-text>

            <subs-static-text
              controlName="subClass"
              class="clr-col-4"
              labelLength="6"
            ></subs-static-text>
          </div>

          <div class="clr-row">
            <subs-static-text
              controlName="dunsNumber"
              class="clr-col-4"
              labelLength="6"
            ></subs-static-text>

            <subs-static-text
              controlName="congDist"
              class="clr-col"
            ></subs-static-text>

            <subs-static-text
              controlName="ein"
              class="clr-col"
            ></subs-static-text>
          </div>

          <div class="clr-row">
            <subs-static-text
              controlName="foreign"
              label="Foreign institution?"
              labelLength="6"
              class="clr-col-4"
            ></subs-static-text>

            <subs-static-text
              controlName="ccr"
              label="Reg. in CCR?"
              labelLength="6"
              class="clr-col-4"
            ></subs-static-text>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          Include Notice of Award
        </div>

        <div class="card-block" [formGroup]="includeNOA">
          <subs-checkbox
            controlName="includeNOA"
            label="Include NOA?"
          ></subs-checkbox>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          Select Face Page Template
        </div>

        <div class="card-block" [formGroup]="selectedFacePage">
          <clr-datagrid data-test-id="face-page-grid">
            <clr-dg-column
              style="
                min-width:40px;
                width:40px;
              "
            >
              <ng-container> </ng-container>
            </clr-dg-column>

            <clr-dg-column>
              <ng-container>
                Description
              </ng-container>
            </clr-dg-column>

            <clr-dg-row
              *ngFor="let facePage of facePages | async; let i = index"
            >
              <clr-dg-cell
                style="
                  min-width:40px;
                  width:40px;
                  padding-top: 0;
                  padding-bottom: 0;
                  display: flex;
                  align-items: center;
                "
              >
                <input
                  type="radio"
                  clrRadio
                  [value]="facePage.id"
                  formControlName="selectedFacePage"
                  data-test-id="selectFacePage"
                />
              </clr-dg-cell>

              <clr-dg-cell> {{ facePage.description }}</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer
              >{{ (facePages | async).length }} face pages</clr-dg-footer
            >
          </clr-datagrid>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          Select Attachments
        </div>

        <div class="card-block">
          <clr-datagrid data-test-id="attachments-grid">
            <clr-dg-column
              style="
                min-width:40px;
                width:40px;
              "
            >
              <ng-container> </ng-container>
            </clr-dg-column>

            <clr-dg-column style="width:96px;">
              <ng-container>
                Att #
              </ng-container>
            </clr-dg-column>

            <clr-dg-column>
              <ng-container>
                Description
              </ng-container>
            </clr-dg-column>

            <clr-dg-row
              *ngFor="
                let attachments of selectAttachments.controls;
                let i = index
              "
              [formGroup]="attachments"
            >
              <clr-dg-cell
                style="
                  min-width:40px;
                  padding-top: 0;
                  padding-bottom: 0;
                  display: flex;
                  align-items: center;
                "
              >
                <input
                  type="checkbox"
                  clrCheckbox
                  name="selected"
                  formControlName="selected"
                  data-test-id="selected"
                  [value]="true"
                />
              </clr-dg-cell>

              <clr-dg-cell>
                {{ attachments.controls.attachmentNumber.value }}
              </clr-dg-cell>

              <clr-dg-cell>
                {{ attachments.controls.description.value }}
              </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer
              >{{ selectAttachments.length }} attachments</clr-dg-footer
            >
          </clr-datagrid>
        </div>
      </div>
    </form>
  </subs-form-content>

  <subs-form-buttons>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="save()"
      data-test-id="save"
      [disabled]="disableButtons | async"
    >
      Generate Agreement
    </button>

    <button
      class="btn btn-outline"
      (click)="cancel()"
      data-test-id="cancel"
      [disabled]="disableButtons | async"
    >
      Cancel
    </button>
  </subs-form-buttons>
</subs-form-layout>

<clr-modal
  [(clrModalOpen)]="uploadComplete"
  [clrModalClosable]="false"
  [clrModalSize]="'xl'"
>
  <h3 class="modal-title">
    Summary
  </h3>

  <div class="modal-body">
    <div *ngFor="let failedAtt of this.getFailedAttachments()">
      <p>
        <strong
          >{{ failedAtt.attachmentNum + ' ' + failedAtt.description }}:</strong
        >
        Failed to Load
      </p>
    </div>
    <div *ngFor="let result of this.successSummary">
      <p
        *ngIf="
          result.emptyBookmarks.length == 0 &&
          result.invalidBookmarks.length == 0
        "
      >
        <strong>{{ result.attachmentName }}:</strong> Completed Successfully
      </p>
      <div
        *ngIf="
          result.emptyBookmarks.length != 0 ||
          result.invalidBookmarks.length != 0
        "
      >
        <p>
          <strong>{{ result.attachmentName }}:</strong>
        </p>
        <p *ngIf="result.emptyBookmarks.length != 0">
          <strong>Bookmarks with no data:</strong>
          {{ result.emptyBookmarks.join(', ') }}
        </p>
        <p *ngIf="result.invalidBookmarks.length != 0">
          <strong>Invalid Bookmarks:</strong>
          {{ result.invalidBookmarks.join(', ') }}
        </p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="navigateToSub()"
      data-test-id="confirm-delete"
    >
      Continue
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="showDuplicatedBookmarkModal | async"
  [clrModalClosable]="true"
>
  <h3 class="modal-title">
    Duplicated Bookmark
  </h3>

  <div class="modal-body">
    {{ duplicatedBookmarkMessage }}
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="showDuplicatedBookmarkModal.next(false)"
    >
      Close
    </button>
  </div>
</clr-modal>
