import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { InstitutionsClient } from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';

import { AlertOptions } from '../../alerts/alert-options';

@Component({
  selector: 'subs-delete-modal',
  templateUrl: './delete-modal.component.html',
  providers: [FeedbackService],
})
export class DeleteModalComponent {
  submitState = this.feedbackService.submitState;
  isModalOpen: boolean;
  deleteErrorModalOpen = false;

  @Input('isOpen')
  set isOpen(state: boolean) {
    if (this.isModalOpen !== state) {
      this.isModalOpen = state;
      this.modalClose.emit(state);
    }
  }

  get isOpen(): boolean {
    return this.isModalOpen;
  }

  @Output() modalClose = new EventEmitter<boolean>();
  @Input() Id: number;
  @Input() InstitutionName: string;

  alertOptions: AlertOptions = {
    message: 'There was en error deleting the selected institution.',
    canBeClosed: true,
    type: 'danger',
  };

  alertSubject$ = this.feedbackService.alerts;

  constructor(
    private router: Router,
    private institutionsClient: InstitutionsClient,
    private feedbackService: FeedbackService,
  ) {}

  deleteInstitution() {
    this.feedbackService.beginLoading();
    this.institutionsClient
      .delete(this.Id)
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(() => {
        this.router.navigateByUrl('/find-institution');
      });
  }
}
