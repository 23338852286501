<form clrForm [formGroup]="form">
  <div class="clr-row">
    <subs-static-text
      class="clr-col-md"
      controlName="createdBy"
      labelLength="4"
    ></subs-static-text>
    <subs-static-text
      class="clr-col-md"
      controlName="created"
      labelLength="4"
    ></subs-static-text>
  </div>
  <div class="clr-row">
    <subs-static-text
      class="clr-col-md"
      controlName="action"
      labelLength="4"
    ></subs-static-text>
  </div>
  <div class="clr-row">
    <div class="clr-col">
      <p>
        {{ form.controls.description.value }}
      </p>
    </div>
  </div>
</form>
