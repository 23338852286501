import { Component, Inject, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DuplicateInstitutionError } from './institution-alert-options';
import { API_BASE_URL } from '../../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'subs-institution-alert',
  templateUrl: './institution-alert.component.html',
})
export class InstitutionAlertComponent implements OnInit {
  @Input() duplicateInstitutionErrors: Observable<DuplicateInstitutionError[]>;

  constructor(private router: Router) {
    this.duplicateInstitutionErrors = null;
  }

  ngOnInit() {
    this.duplicateInstitutionErrors.subscribe(options => {
      if (options != null && options.length > 0) {
        options.forEach((error, i) => {
          error.canBeClosed = true;
          error.isClosed = false;
        });
      }
    });
  }

  visitInstitution(institutionId: string) {
    this.duplicateInstitutionErrors = null;
    this.router.navigateByUrl('/institution/' + institutionId);
  }
}
