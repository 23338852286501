import { Component, Input } from '@angular/core';
import { FormGroup, UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'subs-subrecipient-monitoring',
  templateUrl: './subrecipient-monitoring.component.html',
  styleUrls: ['./subrecipient-monitoring.component.scss'],
})
export class SubrecipientMonitoringComponent {
  @Input() subrecipientMonitoringQuestions: UntypedFormArray;
  @Input() awardYear: number;
  constructor() {}
}
