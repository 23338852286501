<div class="card" clrForm [formGroup]="form">
  <div class="card-block">
    <div class="clr-row">
      <subs-input
        class="clr-col-4"
        controlName="contactType"
        errorMessage="Required. Must be unique."
        maxLength="3"
      ></subs-input>
      <subs-input
        class="clr-col"
        controlName="description"
        maxLength="256"
      ></subs-input>
    </div>
    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        controlName="title"
        maxLength="46"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        controlName="firstName"
        maxLength="30"
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="middleName"
        labelLength="4"
        maxLength="30"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        controlName="lastName"
        maxLength="30"
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="nameSuffix"
        labelLength="4"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        controlName="phone"
        maxLength="30"
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="fax"
        labelLength="4"
        maxLength="30"
      ></subs-input>
    </div>

    <subs-input controlName="email" maxLength="70"></subs-input>

    <subs-address
      [form]="form.controls.address"
      [hideRequiredItems]="true"
    ></subs-address>
  </div>
</div>
