import { ClrLoadingState } from '@clr/angular';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FeedbackService } from '../form-layout/feedback.service';
import { AlertOptions } from './alert-options';

export function displayApiErrors(
  alert: Subject<AlertOptions> | FeedbackService,
  submitState$: Subject<ClrLoadingState>,
) {
  return <T>(source: Observable<T>): Observable<T> =>
    source.pipe(
      tap(
        () => {
          submitState$.next(ClrLoadingState.SUCCESS);

          if (alert instanceof FeedbackService) {
            alert.clearAlert();
          } else {
            alert.next(null);
          }
        },
        error => {
          submitState$.next(ClrLoadingState.ERROR);

          if (alert instanceof FeedbackService) {
            alert.alert({
              type: 'danger',
              message: 'There was an error processing your request.',
            });
          } else {
            alert.next({
              type: 'danger',
              message: 'There was an error processing your request.',
            });
          }
        },
      ),
    );
}

export function displayErrors(alert$: Subject<AlertOptions>) {
  return <T>(source: Observable<T>): Observable<T> =>
    source.pipe(
      tap(
        () => {
          alert$.next(null);
        },
        error => {
          alert$.next({
            type: 'danger',
            message: error.message,
          });
        },
      ),
    );
}
