import { Component, OnInit, Input } from '@angular/core';
import { Institution } from 'src/app/api.service';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Router } from '@angular/router';
import { InstitutionStatusNamesEnum } from '../../enums/institution-status-names.enum';

@Component({
  selector: 'subs-institutions-grid',
  templateUrl: './institutions-grid.component.html',
  styleUrls: ['./institutions-grid.component.scss'],
})
export class InstitutionsGridComponent implements OnInit {
  @Input() institutionSearchResults: Array<Institution>;
  institutionStatusName = InstitutionStatusNamesEnum;
  ascSort = ClrDatagridSortOrder.ASC;
  constructor(private router: Router) {
    this.institutionSearchResults = [];
  }

  ngOnInit() {}

  navigateToInstitution(id) {
    this.router.navigateByUrl('/institution/' + id);
  }

  getSamGov(samGov: boolean) {
    let value = '';
    if (samGov !== null) {
      value = samGov ? 'Yes' : 'No';
    }
    return value;
  }

  getForeignInstitution(foreign: boolean) {
    let value = '';
    if (foreign !== null) {
      value = foreign ? 'Yes' : 'No';
    }
    return value;
  }

  getDeletedInstitution(isDeleted: boolean) {
    let value = '';
    if (isDeleted !== null) {
      value = isDeleted ? 'Yes' : 'No';
    }
    return value;
  }
}
