<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <div class="clr-col">
      <subs-date controlName="dateFrom" class="clr-col"> </subs-date>
      <subs-date controlName="dateThru" class="clr-col"> </subs-date>
    </div>
  </div>

  <div style="margin-top:24px;">
    <subs-alert [alertBehavior]="alertSubject"> </subs-alert>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="search()"
      data-test-id="search-btn"
      [disabled]="!searchForm.valid"
    >
      Submit
    </button>

    <button class="btn btn-outline" (click)="clear()" data-test-id="clear-btn">
      Clear
    </button>
  </div>
</form>
