import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Params,
  Router,
} from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AlertOptions } from 'src/app/alerts/alert-options';
import { displayErrors } from 'src/app/alerts/display-error';
import {
  AdditionalYearsClient,
  GetAwardYear,
  GetProject as Project,
  ProjectsClient,
  SubawardSummary,
  SubrecipientStatusVm,
} from 'src/app/api.service';
import { AuthService } from 'src/app/auth.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { markFormGroupTouched } from 'src/app/form-layout/markFormGroupTouched';
import { ExcelExportService } from 'src/app/reports/excel-export.service';

import { MainLayoutService } from '../../main-layout/main-layout.service';
import { proposalIdValidator } from '../../validators/proposal-id-validator/proposal-id-validator';
import { DateConvert } from '../../converters/date-convert/date-convert';
import { RequiredItemsChecklistService } from '../subaward/required-items-checklist.service';
import { EmployeeLookupComponent } from './employee-lookup/employee-lookup.component';
import { NoaControlFactory } from './noa-control-factory.service';
import { SubStatusService } from '../../utilities/subrecipient-status/sub-status.service';

@Component({
  selector: 'subs-edit-project',
  templateUrl: './edit-project.component.html',
  providers: [FeedbackService],
})
export class EditProjectComponent implements OnInit {
  headerBasic = 'Project Summary';
  headerFull = '';
  formLevelAlerts$ = new BehaviorSubject<AlertOptions>(null);
  saveConfirmation$ = new Subject<boolean>();
  submitState$ = this.feedbackService.submitState;
  alert$ = this.feedbackService.alerts;
  returnToSearch = false;
  showUnsavedChangesModal = false;
  showDeleteModal = false;
  canDeleteYear = false;
  awardYears: GetAwardYear[];
  activeStatusList: SubrecipientStatusVm[] = [];
  awardYear: number;
  canAddYear = false;
  showAddYearModal = false;
  userRole: string;
  viewOnlyUser = false;
  helperText = '';

  @ViewChild(EmployeeLookupComponent, { static: true })
  employeeLookup!: EmployeeLookupComponent;

  saveConfirmationForm = this.fb.group({
    confirmation: [],
  });

  form = this.fb.group({
    id: [],
    projectGroupId: [],
    subrecipients: [],
    proposalId: ['', Validators.required],
    awardNumber: [],
    awardYear: [],
    awardDepartment: [],
    stimulusAwarded: [],
    researchAndDevelopment: [],
    sponsorType: [],
    sponsorName: [],
    originatingSponsor: [],
    projectTitle: [],
    grantFromDate: [],
    grantThruDate: [],
    investigatorEmployeeId: [],
    investigatorFirstName: [],
    investigatorLastName: [],
    investigatorEmail: [],
    investigatorCampusCode: [],
    investigatorDept: [],
    investigatorDeptName: [],
    investigatorCampusBox: [],
    adminPhone: [],
    adminFax: [],
    adminEmail: [],
    adminEmployeeId: [],
    adminFirstName: [],
    adminLastName: [],
    federalAwardId: [],
    noticeOfAwards: this.fb.array([]),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private apiClient: ProjectsClient,
    private mainLayoutService: MainLayoutService,
    private noaFactory: NoaControlFactory,
    private feedbackService: FeedbackService,
    private checklistService: RequiredItemsChecklistService,
    private additionalYearsClient: AdditionalYearsClient,
    private excelExportService: ExcelExportService,
    private authService: AuthService,
    private subStatusService: SubStatusService,
  ) {
    router.events.forEach(event => {
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.mainLayoutService.toggleProjectSidebarVisibility(false);
      }
    });
    this.saveConfirmationForm.controls.confirmation.valueChanges.subscribe(
      result => {
        this.saveConfirmation$.next(result);
      },
    );
  }

  ngOnInit() {
    this.subStatusService.ActiveStatuses.subscribe(async value => {
      if (value !== undefined && value !== null) {
        this.activeStatusList = value;
      }
    });
    this.form.valueChanges.subscribe((value: any) => {
      if (this.form.dirty) {
        this.saveConfirmationForm.controls.confirmation.setValue(false);
      } else {
        this.saveConfirmationForm.controls.confirmation.setValue(true);
      }
    });

    this.form.get('awardYear').valueChanges.subscribe(value => {
      if (this.awardYears) {
        this.setProjectSummaryFixedHeader(value, this.awardYears.length);
      }
    });
    this.form.get('proposalId').valueChanges.subscribe(value => {
      const invalidProposalId = proposalIdValidator(
        this.form.controls.proposalId,
      );
      if (invalidProposalId) {
        this.helperText =
          'The Proposal ID is required and shall use one of the following formats: PXX-XXXXX, XX-XXXXX, PXX-XXXXX-XX or XXXXXX';
      } else {
        this.helperText = '';
      }
    });
    this.getAllParameters()
      .pipe(
        switchMap(params => this.getProject(params)),
        tap(proposalId => this.bindProject(proposalId)),
        displayErrors(this.formLevelAlerts$),
      )
      .subscribe(
        result => {
          this.setProjectSummaryFixedHeader(
            result.awardYear,
            result.awardYears.length,
          );
        },
        error => {
          this.feedbackService.alert(error);
          displayErrors(this.formLevelAlerts$);
        },
        () => {},
      );

    this.userRole = this.authService.currentUser.role;
    this.viewOnlyUser = this.authService.hasPermission('ReadOnlyGlobal');
  }

  private setProjectSummaryFixedHeader(
    selectedAwardYear: number,
    totalAwardYears: number,
  ) {
    if (selectedAwardYear && totalAwardYears) {
      const headerAwardYear = ` - Award Year ${selectedAwardYear} of ${totalAwardYears}`;
      this.headerFull = this.headerBasic.concat(headerAwardYear);
    }
  }

  addAdditionalYear() {
    this.showAddYearModal = true;
  }

  deleteProjectYear() {
    this.showDeleteModal = true;
  }

  discardAdditionalYear() {
    this.showAddYearModal = false;
  }

  confirmAdditionalYear() {
    const projectViewModel = {
      ...this.form.value,
      grantFromDate: DateConvert.convertDateToInvariantCulture(
        this.form.value.grantFromDate,
      ),
      grantThruDate: DateConvert.convertDateToInvariantCulture(
        this.form.value.grantThruDate,
      ),
    };

    this.feedbackService.beginLoading();
    this.additionalYearsClient
      .put(projectViewModel.id)
      .pipe(
        this.feedbackService.provideFeedback(),
        tap(project => this.navigateToProject(project.id)),
      )
      .subscribe(() => {
        this.showAddYearModal = false;
      });
  }

  public navigateToProject(id: number): void {
    this.router.navigateByUrl(`/project/${id}`);
  }

  save() {
    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      this.feedbackService.alert(
        'Please fix all field errors before saving the form.',
      );
      return;
    }

    const projectViewModel = {
      ...this.form.value,
      grantFromDate: DateConvert.convertDateToInvariantCulture(
        this.form.value.grantFromDate,
      ),
      grantThruDate: DateConvert.convertDateToInvariantCulture(
        this.form.value.grantThruDate,
      ),
    };

    this.checklistService.setNoticeOfAward(projectViewModel.noticeOfAwards);

    this.feedbackService.beginLoading();

    this.apiClient
      .put(projectViewModel.id, projectViewModel)
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(result => {
        this.saveConfirmationForm.controls.confirmation.setValue(true);
        this.form.markAsPristine();
        if (result) {
          if (this.returnToSearch) {
            this.router.navigate(['/find-subawards'], {
              queryParams: { query: true },
            });
          }
        }
      });
  }

  private getAllParameters(): Observable<{ [key: string]: string }> {
    return combineLatest(this.route.params, this.route.queryParams).pipe(
      map(([routeParams, queryParams]) => ({
        ...queryParams,
        ...routeParams,
      })),
    );
  }

  private getProject(params: Params): Observable<Partial<Project>> {
    const idString = params.id;
    const projectId = parseInt(idString, 10);

    if (isNaN(projectId)) {
      throw new Error('The project id was in an incorrect format');
    }

    this.mainLayoutService.toggleProjectSidebarVisibility(true);
    this.mainLayoutService.setProjectId(projectId);
    return this.apiClient.get(projectId);
  }

  private bindProject(project: Partial<Project>) {
    const projectModel = {
      ...project,
      grantFromDate: DateConvert.convertDateToLocalCulture(
        project.grantFromDate,
      ),
      grantThruDate: DateConvert.convertDateToLocalCulture(
        project.grantThruDate,
      ),
    };

    const noaControl = this.form.get('noticeOfAwards') as UntypedFormArray;
    noaControl.clear();
    this.noaFactory
      .createMany(project.noticeOfAwards.length)
      .forEach(control => noaControl.push(control));

    this.form.reset(projectModel);
    this.awardYears = projectModel.awardYears;
    this.awardYear = projectModel.awardYear;
    this.canAddYear =
      Math.max.apply(
        Math,
        this.awardYears.map(o => o.awardYear),
      ) === projectModel.awardYear;
    this.canDeleteYear = this.canAddYear;
    this.checklistService.setNoticeOfAward(projectModel.noticeOfAwards);
  }

  canAddCollaborator() {
    return !!this.form.controls.id.value;
  }

  addCollaborator() {
    this.router.navigateByUrl(
      '/subaward/project/' + this.form.controls.id.value,
    );
  }

  closeModal(val: boolean) {
    this.showDeleteModal = val;
  }

  delete() {
    this.apiClient.delete(this.form.controls.id.value).subscribe(result => {
      const pos = this.awardYears
        .map(a => a.awardYear)
        .indexOf(this.form.controls.awardYear.value);
      if (pos > 0) {
        this.router.navigateByUrl(
          '/project/' + this.awardYears[pos - 1].projectId,
        );
      } else if (pos === 0 && this.awardYears.length > 1) {
        this.router.navigateByUrl(
          '/project/' + this.awardYears[pos + 1].projectId,
        );
      } else {
        this.router.navigateByUrl('/find-subawards');
      }
      this.showDeleteModal = false;
    });
  }

  canDeleteProjectYear() {
    return (
      this.canDeleteYear &&
      this.authService.hasPermission('AccessButtonDeleteProjectYear')
    );
  }

  private generateExportData(results: Array<SubawardSummary>) {
    const data = [];
    results.forEach(e => {
      data.push([
        e.issuingDepartment ? e.issuingDepartment : '',
        e.institutionName ? e.institutionName : '',
        e.grantWorkTag ? e.grantWorkTag : '',
        e.submittedDate ? e.submittedDate : '',
        e.amendmentNumber,
        e.agreementNumber ? e.agreementNumber : '',
        e.trackingNumber ? e.trackingNumber : '',
        this.getStatusDescription(e.status),
      ]);
    });
    return data;
  }

  private getStatusDescription(statusCode: string) {
    const activeStatus = this.activeStatusList.find(
      status => status.statusCode === statusCode,
    );
    return activeStatus ? activeStatus.statusDescription : '';
  }

  async exportSubawardsList() {
    const columns = [
      {
        header: 'Issuing Dept',
        width: 15,
      },
      {
        header: 'Institution Name',
        width: 55,
      },
      {
        header: 'Grant Worktag',
        width: 15,
      },
      {
        header: 'Submitted to OSRS',
        width: 19,
      },
      {
        header: 'Amendment',
        width: 15,
      },
      {
        header: 'Agreement Num',
        width: 22,
      },
      {
        header: 'Tracking Num',
        width: 22,
      },
      {
        header: 'Status',
        width: 20,
      },
    ];

    const data = this.generateExportData(
      this.form.controls.subrecipients.value,
    );

    const title =
      'Subawards List for Proposal ID ' +
      this.form.controls.proposalId.value +
      ' Award Year ' +
      this.form.controls.awardYear.value;

    await this.excelExportService.generateExcel(
      title + ' ' + new Date().toLocaleString(),
      title,
      data,
      columns,
    );
  }

  lookupDepartmentAdmin() {
    this.employeeLookup.lookupEmployee().subscribe(employee => {
      this.form.patchValue({
        adminPhone: employee.phone,
        adminFax: '',
        adminEmail: employee.commonEmail,
        adminEmployeeId: employee.employeeId,
        adminFirstName: employee.firstName,
        adminLastName: employee.lastName,
      });
      this.form.markAsDirty();
    });
  }

  lookupPrincipalInvestigator() {
    this.employeeLookup.lookupEmployee().subscribe(employee => {
      this.form.patchValue({
        investigatorEmployeeId: employee.employeeId,
        investigatorFirstName: employee.firstName,
        investigatorLastName: employee.lastName,
        investigatorEmail: employee.commonEmail,
        investigatorDept: employee.primaryDepartment,
        investigatorDeptName: '',
        investigatorCampusCode: employee.campusCode,
        investigatorCampusBox: employee.campusBox,
      });
      this.form.markAsDirty();
    });
  }

  redirectToSearchPage() {
    this.returnToSearch = true;
    this.router.navigate(['/find-subawards'], { queryParams: { query: true } });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.dirty) {
      return true;
    }
    this.showUnsavedChangesModal = true;
    return this.saveConfirmation$;
  }

  confirmSave() {
    this.showUnsavedChangesModal = false;
    this.save();
  }

  discardChanges() {
    this.showUnsavedChangesModal = false;
    this.saveConfirmationForm.controls.confirmation.setValue(true);
  }

  cancelNavigation() {
    this.saveConfirmationForm.controls.confirmation.setValue(false);
    this.showUnsavedChangesModal = false;
  }
}
