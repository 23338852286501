import { Component, Input } from '@angular/core';
import { LookupItem } from '../../api.service';

@Component({
  selector: 'subs-country',
  templateUrl: './country.component.html',
})
export class CountryComponent {
  @Input() controlName: string;
  @Input() label: string;
  @Input() labelLength: string;
  @Input() options: LookupItem[];
  @Input() errorMessage: string;

  constructor() {}
}
