import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DelegatedLabeledComponent } from '../delegated-labeled.component';

@Component({
  selector: 'subs-select',
  template: `
    <clr-select-container [style]="safeContainerStyle">
      <label
        *ngIf="label !== 'hideLabel'"
        class="clr-col-12 clr-col-md-auto"
        [style]="safeLabelStyle"
      >
        {{ label }}
      </label>

      <select
        clrSelect
        class="clr-col-12 clr-col-md"
        [attr.data-test-id]="controlName"
        [name]="controlName"
        [formControlName]="controlName"
        [attr.disabled]="disabled === true ? 'disabled' : null"
      >
        <ng-content></ng-content>
      </select>
      <clr-control-error>{{ errorMessage }}</clr-control-error>
    </clr-select-container>
  `,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class SelectComponent extends DelegatedLabeledComponent {
  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }
  @Input()
  errorMessage?: string;
  @Input()
  disabled?: boolean;
  @Input()
  initializedValue?: string;
}
