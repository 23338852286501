export class OrderNumberValidator {
  static validateOrderNumbers(orderNumberArr: number[]) {
    let invalidForm = false;
    let errorMessage = '';

    const checkIfOrderNumIsDuplicated = this.isOrderNumberDuplicated(
      orderNumberArr,
    );
    const checkIfOrderNumIsZero = this.isOrderNumberZero(orderNumberArr);
    if (
      checkIfOrderNumIsDuplicated === true ||
      checkIfOrderNumIsZero === true
    ) {
      invalidForm = true;
      errorMessage =
        'Order Number is required, must be unique, and must not be 0';
    }

    return {
      invalidForm,
      errorMessage,
    };
  }
  static isOrderNumberDuplicated(orderNumberArr: number[]) {
    const isDuplicated = orderNumberArr.some(element => {
      if (
        orderNumberArr.indexOf(element) !== orderNumberArr.lastIndexOf(element)
      ) {
        return true;
      }

      return false;
    });
    return isDuplicated;
  }

  static isOrderNumberZero(orderNumberArr: number[]) {
    const hasZero = orderNumberArr.includes(0);
    const hasNaN = orderNumberArr.includes(NaN);

    if (hasZero === true || hasNaN === true) {
      return true;
    }
    return false;
  }
}
