<subs-form-layout>
  <subs-form-content>
    <div class="card">
      <div class="card-header">
        Shortcut Links
      </div>
      <div class="card-block">
        <div class="clr-row">
          <button
            class="clr-col-lg-auto"
            class="btn btn-sm"
            (click)="onAdd()"
            aria-label="Add Return Code"
            title="Add Return Code"
            data-test-id="addLine-button"
            style="margin: 0 0 0 0.5rem;"
          >
            Add
          </button>
        </div>
        <clr-datagrid data-test-id="return-code-grid">
          <clr-dg-column>Label</clr-dg-column>
          <clr-dg-column>Link</clr-dg-column>
          <clr-dg-column>Order Number</clr-dg-column>
          <clr-dg-row
            *ngFor="let shortcutLink of shortcutLinks$ | async; let i = index"
          >
            <clr-dg-cell>
              <div class="clr-control-container" style="width: 100%;">
                <div class="clr-input-wrapper" style="width: 100%;">
                  <input
                    #label
                    type="text"
                    (change)="updateShortcutLink(i, 'label', label.value)"
                    [value]="shortcutLink.label"
                    class="link-input"
                  />
                </div>
              </div>
            </clr-dg-cell>
            <clr-dg-cell>
              <div class="clr-control-container" style="width: 100%;">
                <div class="clr-input-wrapper" style="width: 100%;">
                  <input
                    #shortcut
                    type="text"
                    placeholder="Link Format: https://www.google.com"
                    (change)="
                      updateShortcutLink(i, 'shortcutLink', shortcut.value)
                    "
                    [value]="shortcutLink.shortcutLinkUrl"
                    class="link-input"
                  />
                </div>
              </div>
            </clr-dg-cell>
            <clr-dg-cell>
              <clr-input-container style="margin-top: 0">
                <input
                  type="number"
                  #orderNumber
                  (change)="
                    updateShortcutLink(i, 'orderNumber', orderNumber.value)
                  "
                  clrInput
                  [value]="shortcutLink.orderNumber"
                />
              </clr-input-container>
            </clr-dg-cell>

            <clr-dg-action-overflow>
              <button
                class="action-item"
                (click)="deleteContact(i)"
                clrPopoverCloseButton
                data-test-id="delete"
              >
                Delete
              </button>
            </clr-dg-action-overflow>
          </clr-dg-row>
        </clr-datagrid>
        <clr-modal [(clrModalOpen)]="deleteOpened">
          <h3 class="modal-title">
            Confirm Delete?
          </h3>

          <div class="modal-body">
            <p>
              Are you sure you would like to delete?
            </p>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline"
              (click)="cancelDeleteConfirmation()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="confirmDelete()"
              data-test-id="confirm-delete"
            >
              Delete
            </button>
          </div>
        </clr-modal>
      </div>
      <label *ngIf="invalidForm === true" style="color: red; margin-left: 2%;">
        *{{ errorMessage }}!
      </label>
    </div>
  </subs-form-content>

  <subs-form-buttons>
    <div style="display: flex;">
      <button
        class="btn btn-primary"
        (click)="save()"
        [disabled]="isSaved === false || invalidForm === true"
      >
        Save
      </button>
      <span
        *ngIf="isSaved === false"
        class="spinner spinner-md"
        style="margin-top: 5px;"
      ></span>
    </div>
  </subs-form-buttons>
</subs-form-layout>

<clr-modal [clrModalOpen]="showSavedModal" [clrModalClosable]="true">
  <h3 class="modal-title">
    Successfully Saved
  </h3>
  <div class="modal-body">
    Changes to the Shortcut Links were successfully saved.
  </div>
  <div class="modal-footer"></div>
</clr-modal>
