import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  Input,
  SimpleChange,
  OnChanges,
} from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { AlertOptions } from 'src/app/alerts/alert-options';
import { API_BASE_URL, File as FileResponse } from 'src/app/api.service';

@Component({
  selector: 'subs-card-file-upload',
  templateUrl: './card-file-upload.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class CardFileUploadComponent {
  @Input() title: string;
  @Input() id: string;
  @Input() fileNameControlName: string;
  @Input() fileIdControlName: string;
  @Input() dateControlName: string;
  @Input() acceptedMimeTypes: string[];
  @Input() acceptedMimeTypesErrorMessage: string;
  @Input() customAlert: Subject<AlertOptions>;
  @Input() canEdit?: boolean;
  @Input() callback: Function;
  @Input() canceledCallback: Function;
  @Input() buttonLabel: string = 'Add';
  @Input() getConfirmation = false;
  @Input() confirmationMessage = 'Are you sure?';
  @Input() confirmationTitle = 'File Upload Confirmation';
}
