import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import {
  SubrecipientComment,
  ReturnCodesClient,
  ReturnCode,
} from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';

import { AlertOptions } from '../../../alerts/alert-options';
import { Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'subs-return-comment',
  templateUrl: './return-comment.component.html',
  providers: [FeedbackService],
})
export class ReturnCommentComponent implements OnInit {
  submitState = this.feedbackService.submitState;
  isModalOpen: boolean;

  @Input('isOpen')
  set isOpen(state: boolean) {
    if (this.isModalOpen !== state) {
      this.isModalOpen = state;
      this.returnCommentModalClose.emit(state);
    }
  }

  get isOpen(): boolean {
    return this.isModalOpen;
  }

  @Output() returnCommentModalClose = new EventEmitter<boolean>();
  @Output() returnToDA = new EventEmitter<boolean>();
  @Input() Id: number;
  @Input() InstitutionName: string;
  @Input() commentsFormControl: UntypedFormControl;
  returnCodes: ReturnCode[];
  alertOptions: AlertOptions = {
    message: 'Please select a return code and enter a comment.',
    canBeClosed: true,
    type: 'danger',
  };

  warningOptions: AlertOptions = {
    message: 'Returning to DA will save the Subaward Record.',
    canBeClosed: false,
    type: 'warning',
  };
  warningSubject$ = new BehaviorSubject<AlertOptions>(this.warningOptions);
  alertSubject$ = this.feedbackService.alerts;

  returnCommentForm = this.fb.group({
    returnCode: ['', [Validators.required]],
    comment: ['', [Validators.required]],
  });

  constructor(
    private feedbackService: FeedbackService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private returnCodesCLient: ReturnCodesClient,
  ) {}

  ngOnInit() {
    this.returnCodesCLient.get().subscribe(val => {
      this.returnCodes = val;
    });
  }

  save() {
    if (this.returnCommentForm.invalid) {
      this.feedbackService.alert(this.alertOptions);
      return;
    }

    this.feedbackService.beginLoading();

    const commentsViewModel: SubrecipientComment = {
      ...this.returnCommentForm.value,
      created: new Date(),
      createdBy: this.authService.currentUser.name,
      subrecipientId: null,
    };

    if (!this.commentsFormControl.value) {
      this.commentsFormControl.setValue([commentsViewModel]);
    } else {
      const newValue = this.commentsFormControl.value;
      newValue.unshift(commentsViewModel);
      this.commentsFormControl.setValue(newValue);
    }

    this.returnCommentForm.reset();
    this.feedbackService.displaySuccess();
    this.isModalOpen = false;
    this.returnToDA.emit(true);
    this.returnCommentModalClose.emit(false);
  }
}
