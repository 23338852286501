<subs-form-layout [formGroup]="form">
  <subs-form-content>
    <div class="card">
      <div class="card-header">
        Sub Statuses
      </div>
      <div class="card-block">
        <div class="clr-row">
          <button
            class="clr-col-lg-auto"
            class="btn btn-sm"
            (click)="onAdd()"
            [disabled]="!canAddSubStatus()"
            aria-label="Add Status"
            title="Add Status"
            data-test-id="addLine-button"
            style="margin: 0 0 0 0.5rem;"
          >
            Add
          </button>
        </div>

        <clr-datagrid *ngIf="statuses" data-test-id="sub-status-grid">
          <clr-dg-column class="custom-width"></clr-dg-column>
          <clr-dg-column>Status Code</clr-dg-column>
          <clr-dg-column>Status Action Source</clr-dg-column>
          <clr-dg-column>Status Description</clr-dg-column>
          <clr-dg-column>Status Type</clr-dg-column>
          <clr-dg-column>Active</clr-dg-column>
          <clr-dg-row
            *ngFor="let subStatus of statuses.controls; let i = index"
            [formGroup]="subStatus"
          >
            <clr-dg-cell class="custom-width">{{ i + 1 }}</clr-dg-cell>

            <clr-dg-cell *ngIf="systemStatus(subStatus.value)">
              <subs-static-text
                labelLength="0"
                controlName="statusCode"
                maxLength="64"
                containerStyle="margin: 0"
                errorMessage="Status Code is needed and must be unique."
              ></subs-static-text
            ></clr-dg-cell>

            <clr-dg-cell *ngIf="!systemStatus(subStatus.value)">
              <subs-input
                labelLength="0"
                controlName="statusCode"
                maxLength="64"
                containerStyle="margin: 0"
                errorMessage="Status Code is needed and must be unique."
              ></subs-input
            ></clr-dg-cell>

            <clr-dg-cell>
              <subs-select
                containerStyle="margin-top: 0"
                labelLength="0"
                controlName="statusActionSource"
              >
                <option value="Manual">Manual</option>
                <option value="System">System</option>
              </subs-select>
            </clr-dg-cell>

            <clr-dg-cell>
              <subs-input
                labelLength="0"
                maxLength="128"
                controlName="statusDescription"
                containerStyle="margin: 0"
                errorMessage="Status Description is needed."
              ></subs-input>
            </clr-dg-cell>

            <clr-dg-cell>
              <subs-select
                containerStyle="margin-top: 0"
                labelLength="0"
                controlName="statusType"
              >
                <option value="DA Status">DA Status</option>
                <option value="OSRS Status">OSRS Status</option>
                <option value="OSRS & DA Status">OSRS & DA Status</option>
              </subs-select>
            </clr-dg-cell>

            <clr-dg-cell>
              <subs-select
                containerStyle="margin-top: 0"
                labelLength="0"
                controlName="isActive"
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </subs-select>
            </clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>

        <div class="clr-row">
          <button
            class="clr-col-lg-auto"
            class="btn btn-sm"
            (click)="onAdd()"
            [disabled]="!canAddSubStatus()"
            aria-label="Add Status"
            title="Add Status"
            data-test-id="addLine-button"
            style="margin: 0.5rem 0 0 0.5rem;"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </subs-form-content>

  <subs-form-buttons>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="save()"
      [disabled]="!canUpdateSubStatus()"
      data-test-id="submit-sub-status"
    >
      Save
    </button>
  </subs-form-buttons>
</subs-form-layout>
