<subs-form-layout>
  <subs-form-content>
    <div class="card">
      <div class="card-header">
        Training
      </div>
      <div class="card-block">
        <div class="clr-row">
          <button
            class="clr-col-lg-auto"
            class="btn btn-sm"
            (click)="onAdd()"
            aria-label="Add Return Code"
            title="Add Return Code"
            data-test-id="addLine-button"
            style="margin: 0 0 0 0.5rem;"
          >
            Add
          </button>
        </div>
        <!-- training Cards -->
        <div
          class="card"
          *ngFor="let training of trainingViews$ | async; let i = index"
          style="width: 100%;"
        >
          <div class="card-body">
            <div class="clr-row" style="padding-bottom: 2%;">
              <div class="clr-col-6">
                <label
                  for="label"
                  class="clr-control-label"
                  style="padding-left: 2%; padding-top: 2%;"
                  >Title</label
                >

                <div
                  class="clr-input-wrapper"
                  style="width: 50%; padding-bottom: 5%; padding-left: 2%;"
                >
                  <input
                    id="label"
                    #label
                    (change)="updateCollection(i, 'title', label.value)"
                    type="text"
                    placeholder="Insert Title Here"
                    clrInput
                    [value]="training.label"
                  />
                </div>
              </div>
              <div class="clr-col-6">
                <label
                  for="orderNumber"
                  class="clr-control-label"
                  style="padding-top: 2%;"
                  >Order Number</label
                >
                <clr-input-container style="width: 50%; padding-bottom: 5%;">
                  <input
                    id="orderNumber"
                    type="number"
                    #orderNumber
                    (change)="
                      updateCollection(i, 'orderNumber', orderNumber.value)
                    "
                    placeholder="Insert Order Number Here"
                    clrInput
                    [value]="training.orderNumber"
                    [style.width.px]="200"
                  />
                </clr-input-container>
              </div>
            </div>
            <div
              class="clr-input-wrapper"
              style="width: 50%; padding-bottom: 5%; padding-left: 2%;"
            >
              <input
                id="files"
                type="file"
                [ngStyle]="{
                  display: training.fileName ? 'none' : 'block'
                }"
                (change)="setFileToSave($event)"
              />
              <label *ngIf="training.fileName" for="files" disabled>
                {{ training.fileName }}
              </label>
              <button
                *ngIf="training.fileName"
                (click)="
                  downloadFile(training.trainingFileId, training.fileName)
                "
                style="margin-left: 2%;    border: none; background: transparent;"
              >
                <clr-icon shape="download"></clr-icon>
              </button>
            </div>
          </div>
          <div class="card-footer" style="display: flex; justify-content: end">
            <button
              (click)="
                saveTraining(
                  training.id,
                  i,
                  label.value,
                  this.fileToSave,
                  +orderNumber.value || null
                )
              "
              class="btn btn-sm btn-link"
            >
              Save
            </button>
            <button
              (click)="deleteTraining(training.id)"
              class="btn btn-sm btn-link"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <label
        *ngIf="
          invalidOrderNum === true ||
          invalidLabel === true ||
          invalidFile === true
        "
        style="color: red; margin-left: 2%;"
      >
        *{{
          invalidFile === true
            ? fileErrorMessage
            : invalidOrderNum === true
            ? orderErrorMessage
            : invalidLabel === true
            ? labelErrorMessage
            : ''
        }}!
      </label>
      <div class="clr-row">
        <button
          class="clr-col-lg-auto"
          class="btn btn-sm"
          (click)="onAdd()"
          aria-label="Add Return Code"
          title="Add Return Code"
          data-test-id="addLine-button"
          style="margin: 0 0 0.5rem 1rem;"
        >
          Add
        </button>
      </div>
    </div>
  </subs-form-content>
</subs-form-layout>

<clr-modal [clrModalOpen]="showSavedModal" [clrModalClosable]="true">
  <h3 class="modal-title">
    Successfully Saved
  </h3>
  <div class="modal-body">
    Changes to the Training Materials were successfully saved.
  </div>
  <div class="modal-footer"></div>
</clr-modal>

<clr-modal [(clrModalOpen)]="deleteOpened">
  <h3 class="modal-title">
    Confirm Delete?
  </h3>

  <div class="modal-body">
    <p>
      Are you sure you would like to delete?
    </p>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelDeleteConfirmation()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="confirmDelete()"
      data-test-id="confirm-delete"
    >
      Delete
    </button>
  </div>
</clr-modal>
