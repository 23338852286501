<div class="form-container">
  <div
    style="
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 24px;"
  >
    <div class="card">
      <div class="card-header">
        FFATA Project Description
      </div>
      <div class="card-block">
        <div class="clr-row">
          <div class="text-area-container">
            <subs-textarea
              class="clr-col"
              labelLength="0"
              controlName="ffataDescription"
              placeholder="FFATA Project Description."
            >
            </subs-textarea>
          </div>
        </div>
      </div>
    </div>
    <subs-form-layout [formGroup]="form">
      <subs-form-content>
        <div class="card">
          <div class="card-header">
            Top 5 Compensated Officers
          </div>

          <div class="card-block">
            <div class="clr-row">
              <div>
                <button
                  class="clr-col-lg-auto"
                  class="btn btn-sm"
                  (click)="onAdd()"
                  [disabled]="disabled"
                  aria-label="Add Compensated Officer"
                  title="Add Compensated Officer"
                  data-test-id="add-button"
                  style="margin: 0 0 0 0.5rem;"
                >
                  Add Compensated Officer
                </button>
              </div>
              <div *ngIf="showMaxOfficersInfo" style="margin-left:25px;">
                <span class="label label-warning"
                  >Maximum five officers allowed.</span
                >
              </div>
            </div>
          </div>

          <div
            *ngFor="let officer of officers.controls; let i = index"
            class="card-block"
            [formGroup]="officer"
          >
            <div class="clr-row">
              <subs-input
                class="clr-col"
                controlName="officerName"
                errorMessage="A Compensated Officer Name is required"
                label="Officer's Name"
              >
              </subs-input>
              <subs-currency-input
                class="clr-col"
                controlName="compensation"
                errorMessage="A Compensation amount is required"
                label="Compensation"
              >
              </subs-currency-input>
              <div class="clr-col-2 clr-align-self-end">
                <button
                  class="btn btn-sm"
                  (click)="deleteOfficer(i)"
                  data-test-id="delete"
                >
                  Delete
                </button>
              </div>
            </div>
            <clr-modal [(clrModalOpen)]="deleteOpened">
              <h3 class="modal-title">
                Confirm Delete?
              </h3>

              <div class="modal-body">
                <p>
                  Are you sure you would like to delete this officer?
                </p>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline"
                  (click)="cancelDeleteConfirmation()"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="confirmDelete()"
                  data-test-id="confirm-delete"
                >
                  Delete
                </button>
              </div>
            </clr-modal>
          </div>
        </div>
      </subs-form-content>
    </subs-form-layout>
  </div>
</div>
