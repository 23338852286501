<div class="card" *ngIf="isAllowed()">
  <div class="card-header">
    Risk Notification Email - Financial Risk Survey Needed
  </div>
  <div class="card-block">
    <button class="btn btn-sm" (click)="sendFinancialRiskEmail()">
      Request SPA Send Survey to SUB
    </button>
  </div>
</div>
