import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Bookmark } from 'src/app/api.service';

@Injectable()
export class BookmarkControlFactory {
  constructor(private fb: UntypedFormBuilder) {}

  createBookmarkControl(value: Bookmark): UntypedFormGroup {
    const control = this.createGroup();
    control.setValue(value);
    return control;
  }

  createMany(count: number): UntypedFormGroup[] {
    const output = [];

    for (let i = 0; i < count; i++) {
      output.push(this.createGroup());
    }

    return output;
  }

  private createGroup() {
    return this.fb.group({
      id: [],
      attachmentId: [],
      name: [],
      fieldType: [],
      tableName: [],
      fieldName: [],
      contactType: [],
    });
  }
}
