import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Training, TrainingsClient } from 'src/app/api.service';
import { DownloadService } from 'src/app/download.service';

@Component({
  selector: 'subs-dashboard-training',
  templateUrl: './dashboard-training.component.html',
  styleUrls: ['./dashboard-training.component.scss'],
})
export class DashboardTrainingComponent implements OnInit {
  trainingViews$: Observable<Training[]>;

  constructor(
    private trainingService: TrainingsClient,
    private downloadService: DownloadService,
  ) {}
  ngOnInit() {
    this.trainingService.getAll().subscribe(val => {
      const sortedArr = val.sort((a, b) =>
        a.orderNumber > b.orderNumber ? 1 : -1,
      );
      this.trainingViews$ = of(sortedArr);
    });
  }
  download(fileId: string, fileName: string) {
    this.downloadService.downloadFile(fileId, fileName).subscribe(result => {});
  }
}
