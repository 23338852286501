import { Component, Input } from '@angular/core';
import { LookupItem } from '../../api.service';

@Component({
  selector: 'subs-item-lookup-select',
  templateUrl: './item-lookup-select.component.html',
})
export class ItemLookupSelectComponent {
  @Input() controlName: string;
  @Input() label: string;
  @Input() labelLength: string;
  @Input() options: LookupItem[];
  @Input() errorMessage: string;

  constructor() {}
}
