<div class="card" [formGroup]="form">
  <div class="card-header">
    {{ title }}
  </div>

  <div class="card-block">
    <div class="clr-row">
      <subs-input
        class="clr-col-5"
        controlName="title"
        maxLength="46"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        label="* First Name"
        controlName="firstName"
        maxLength="30"
        errorMessage="A value is required."
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="middleName"
        labelLength="4"
        maxLength="30"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        label="* Last Name"
        controlName="lastName"
        maxLength="30"
        errorMessage="A value is required."
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="nameSuffix"
        labelLength="4"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        controlName="phone"
        maxLength="30"
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="fax"
        labelLength="4"
        maxLength="30"
      ></subs-input>
    </div>
    <div class="clr-row">
      <subs-input
        class="clr-col"
        label="* Email"
        controlName="email"
        maxLength="70"
        errorMessage="A value is required."
      ></subs-input>

      <subs-input
        *ngIf="this.form.contains('invoiceEmail')"
        class="clr-col-5"
        labelLength="4"
        controlName="invoiceEmail"
        maxLength="70"
      ></subs-input>
    </div>

    <button
      class="btn btn-outline"
      style="margin-top:48px;"
      (click)="copyAddress()"
    >
      Same Address as Institution
    </button>
    <subs-address [form]="form.controls.address"></subs-address>
  </div>
</div>
