<div class="card" [formGroup]="form">
  <div class="card-header">
    Collaborator Primary Place of Performance
  </div>

  <div class="card-block">
    <div class="clr-row">
      <subs-input
        class="clr-col"
        label="* Name"
        controlName="institutionName"
        maxLength="254"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        controlName="phone"
        maxLength="30"
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="fax"
        labelStyle="width: 4rem;"
        maxLength="30"
      ></subs-input>
    </div>

    <subs-input controlName="emailAddress"></subs-input>

    <div class="clr-row">
      <button
        type="button"
        class="btn btn-outline"
        style="margin-top: 0.8rem; max-width:75%;"
        (click)="copySignatory()"
      >
        Same as Signatory Contact Information on Subaward Contacts page
      </button>
    </div>

    <subs-address
      data-test-id="performance-address"
      [form]="form.controls.address"
      [includeCongressionalDistrict]="true"
      [showCountryRequiredIndicator]="true"
    ></subs-address>
  </div>
</div>
