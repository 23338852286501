import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { Institution, InstitutionsClient } from '../../../../api.service';
import { dunsNumberValidator } from '../../../../validators/duns-number-validator/duns-number-validator';
import { AuthService } from '../../../../auth.service';

@Component({
  selector: 'subs-collaborator-institution-search',
  templateUrl: './collaborator-institution-search.component.html',
  providers: [FeedbackService],
})
export class CollaboratorInstitutionSearchComponent {
  submitState = this.feedbackService.submitState;
  userHasSearched = false;
  isModalOpen: boolean;
  searchResult = Array<Institution>();

  @Input('isOpen')
  set isOpen(state: boolean) {
    if (this.isModalOpen !== state) {
      this.isModalOpen = state;
      this.modalClose.emit(state);
    }
  }

  get isOpen(): boolean {
    return this.isModalOpen;
  }

  @Output() modalClose = new EventEmitter<boolean>();

  searchForm = this.fb.group({
    institutionName: '',
    dunsNumber: ['', dunsNumberValidator],
    uniqueEntityId: '',
  });

  alertSubject$ = this.feedbackService.alerts;

  shouldShowNoResultsMessage = this.shouldShowMessage(0);

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private institutionsClient: InstitutionsClient,
    private feedbackService: FeedbackService,
  ) {}

  search() {
    this.feedbackService.beginLoading();
    this.institutionsClient
      .search(
        this.searchForm.controls.dunsNumber.value,
        this.searchForm.controls.institutionName.value,
        undefined,
        this.searchForm.controls.uniqueEntityId.value,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        false,
      )
      .pipe(
        tap(
          val => {
            this.searchResult = val.filter(i => i.isDeleted === false);
            this.userHasSearched = true;
          },
          err => {
            this.userHasSearched = false;
          },
        ),
        this.feedbackService.provideFeedback(),
      )
      .subscribe();
  }

  clear() {
    this.userHasSearched = false;
    this.searchForm.controls.institutionName.setValue('');
    this.searchForm.controls.dunsNumber.setValue('');
    this.searchForm.controls.uniqueEntityId.setValue('');
    this.searchResult = [];
    this.feedbackService.clearAlert();
  }

  shouldShowMessage(length: number) {
    return this.feedbackService.submitState.pipe(
      map(
        submitState =>
          this.searchResult.length === length &&
          this.userHasSearched &&
          submitState !== 1,
      ),
    );
  }
}
