import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { COWorklistViewModel, SubrecipientStatusVm } from 'src/app/api.service';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'subs-co-worklist-result-grid',
  templateUrl: './co-worklist-result-grid.component.html',
  styleUrls: ['./co-worklist-result-grid.component.scss'],
})
export class CoWorklistResultGridComponent {
  @Input() searchResults: Array<COWorklistViewModel>;
  @Input() statuses: SubrecipientStatusVm[] = [];
  ascSort = ClrDatagridSortOrder.ASC;
  @Output() toggle = new EventEmitter<any[]>();
  selectedValues: number[];

  constructor(private router: Router, private fb: UntypedFormBuilder) {
    this.searchResults = [];
  }

  onToggle() {
    const checkedOptions = this.searchResults.filter(x => x.priority);
    this.selectedValues = checkedOptions.map(x => x.subrecipientId);
    this.toggle.emit(checkedOptions.map(x => x.subrecipientId));
  }

  navigateToSub(id) {
    this.router.navigateByUrl('/subaward/' + id);
  }

  getStatus(statusCode: string) {
    const activeStatus = this.statuses.find(
      status => status.statusCode === statusCode,
    );
    return activeStatus ? activeStatus.statusDescription : '';
  }
}
