<clr-datagrid data-test-id="employee-lookup-results">
  <clr-dg-column [clrDgField]="'employeeId'">
    Empl ID
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'lastName'" [clrDgSortOrder]="ascSort">
    Last Name
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'firstName'">
    First Name
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'primaryDepartment'">
    Primary Department
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'commonEmail'">
    Common Email
  </clr-dg-column>
  <clr-dg-row
    data-test-id="employee-lookup-search-result-row"
    *clrDgItems="let result of employeeLookupSearchResults"
    (click)="selectEmployee(result)"
  >
    <clr-dg-cell>{{ result.employeeId }}</clr-dg-cell>
    <clr-dg-cell>{{ result.lastName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.firstName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.primaryDepartment }}</clr-dg-cell>
    <clr-dg-cell>{{ result.commonEmail }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer
    data-test-id="employee-lookup-results-amount"
    *ngIf="employeeLookupSearchResults"
    >{{
      employeeLookupSearchResults.length === 0
        ? 'No'
        : employeeLookupSearchResults.length
    }}
    result{{
      employeeLookupSearchResults.length === 1 ? '' : 's'
    }}</clr-dg-footer
  >
</clr-datagrid>
