import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClrForm } from '@clr/angular';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  GetProject as Project,
  ProjectsClient,
  ProposalClient,
  ProposalModel,
} from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { proposalIdValidator } from 'src/app/validators/proposal-id-validator/proposal-id-validator';

@Component({
  selector: 'subs-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  providers: [FeedbackService],
})
export class AddProjectComponent implements OnInit {
  helperText = '';
  addProjectForm = this.fb.group({
    proposalId: ['', Validators.required],
  });

  submitState$ = this.feedbackService.submitState;
  alert$ = this.feedbackService.alerts;

  @ViewChild(ClrForm, { static: true }) clrForm;

  constructor(
    private fb: UntypedFormBuilder,
    private projectsClient: ProjectsClient,
    private proposalClient: ProposalClient,
    private router: Router,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit() {
    this.addProjectForm.get('proposalId').valueChanges.subscribe(value => {
      const invalidProposalId = proposalIdValidator(
        this.addProjectForm.controls.proposalId,
      );
      if (invalidProposalId) {
        this.helperText =
          'The Proposal ID is required and shall use one of the following formats: PXX-XXXXX, XX-XXXXX, PXX-XXXXX-XX or XXXXXX';
      } else {
        this.helperText = '';
      }
    });
  }

  addProject() {
    if (this.addProjectForm.invalid) {
      this.clrForm.markAsTouched();
      return;
    }

    const value = this.addProjectForm.value;
    const proposalId = value.proposalId;

    this.feedbackService.beginLoading();

    this.getProjectsByProposalId(proposalId)
      .pipe(
        switchMap(projects => this.showErrorIfExists(projects)),
        switchMap(() => this.getProposal(proposalId)),
        switchMap(proposal => this.createProject(proposal)),
        tap(project => this.navigateToProject(project)),
      )
      .subscribe();
  }

  private getProjectsByProposalId(proposalId: any) {
    return this.projectsClient
      .getAll(proposalId)
      .pipe(this.feedbackService.provideFeedback());
  }

  private showErrorIfExists(projects: Project[]): Observable<Project[]> {
    if (projects.length === 0) {
      return of(projects);
    }

    this.feedbackService.alert(
      'A project with the proposal id already exists.',
    );

    return EMPTY;
  }

  private createProject(proposal: ProposalModel): Observable<Project> {
    return this.projectsClient
      .post({
        proposalId: proposal.proposalId,
        awardYear: 1,
        noticeOfAwards: [],
        awardNumber: proposal.awardNumber,
        awardDepartment: proposal.awardDepartment,
        sponsorType: this.mapSponsorType(proposal.sponsorType),
        sponsorName: proposal.sponsorName,
        originatingSponsor: proposal.originatingSponsor,
        projectTitle: proposal.projectTitle,
        investigatorEmployeeId: proposal.investigatorEmployeeId,
        investigatorFirstName: proposal.investigatorFirstName,
        investigatorLastName: proposal.investigatorLastName,
        investigatorEmail: proposal.investigatorEmail,
        investigatorCampusCode: proposal.investigatorCampusCode,
        investigatorDept: proposal.investigatorDept,
        investigatorCampusBox: proposal.investigatorCampusBox,
        investigatorDeptName: proposal.investigatorDeptName,
      })

      .pipe(this.feedbackService.provideFeedback());
  }

  private mapSponsorType(rmsSponsorCode: string): string {
    const sponsorTypeMap = {
      CU: 'HE',
      FF: 'FG',
      GP: 'LG',
      NI: 'IN',
      NF: 'IT',
      100: 'VH',
      101: 'OT',
      105: 'FD',
      112: 'SG',
    };

    return sponsorTypeMap[rmsSponsorCode];
  }

  private navigateToProject(project: Project): void {
    this.router.navigateByUrl(`/project/${project.id}`);
  }

  private getProposal(proposalId: string): Observable<ProposalModel> {
    this.feedbackService.beginLoading();

    return this.proposalClient
      .getProposal(proposalId)
      .pipe(
        this.displayErrorIfProposalDoesntExist(proposalId),
        this.feedbackService.provideFeedback(),
      );
  }

  private displayErrorIfProposalDoesntExist(proposalId: string) {
    return catchError(err => {
      if (err && err.status === 404) {
        this.feedbackService.alert(
          `A proposal with the id ${proposalId} does not exist`,
        );
        return EMPTY;
      } else {
        throw err;
      }
    });
  }
}
