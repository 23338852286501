<div *ngFor="let alert of duplicateInstitutionErrors | async">
  <clr-alert
    [clrAlertType]="'danger'"
    [(clrAlertClosed)]="alert.isClosed"
    [clrAlertClosable]="alert.canBeClosed || true"
  >
    <clr-alert-item>
      <span class="alert-text" data-test-id="alert-message">
        Cannot use {{ alert.propertyValue }} for {{ alert.propertyName }} as it
        is already used in the Institution Record, {{ alert.institutionName }}.
      </span>
      <div class="alert-actions">
        <a
          routerLink=""
          class="alert-action"
          (click)="visitInstitution(alert.institutionId)"
          >{{ alert.institutionName }}</a
        >
      </div>
    </clr-alert-item>
  </clr-alert>
</div>
