import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  ContactList,
  ContactListClient,
  UserContact,
  UserProfilesClient,
  UserProfileViewModel,
} from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { CompleteFormValidator } from 'src/app/validators/complete-form-validator/complete-form-validator';
import { IsRequiredValidator } from 'src/app/validators/is-required-validator/is-required-validator';
import { OrderNumberValidator } from '../../validators/order-number-validator/order-number-validator';

@Component({
  selector: 'subs-contact-list',
  templateUrl: './contact-list.component.html',
  providers: [FeedbackService],
})
export class ContactListComponent implements OnInit {
  submitState$ = this.feedbackService.submitState;
  contactList$: Observable<UserContact[]>;
  showSavedModal = false;
  indexToDelete = -1;
  deleteOpened = false;
  users: UserProfileViewModel[];
  fullUserList: UserProfileViewModel[] = [];
  isSaved: boolean = true;

  invalidForm: boolean = false;
  errorMessage: string;

  constructor(
    private contactService: ContactListClient,
    private userProfileClient: UserProfilesClient,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit(): void {
    this.contactService.get().subscribe(userContactArr => {
      this.contactList$ = of(userContactArr);
      this.getUsersList();
    });
  }

  updateContact(
    contactIdx: number,
    changedField: string,
    changedValue: string,
  ) {
    if (changedField === 'contactName') {
      this.updateContactNameAndEmail(changedValue.toString(), contactIdx);
    } else if (changedField === 'contactTitle') {
      this.updateTitle(changedValue.toString(), contactIdx);
    } else if (changedField === 'orderNumber') {
      this.updateOrderNumber(parseInt(changedValue), contactIdx);
    }

    this.getUsersList();
  }
  updateContactNameAndEmail(contactName: string, contactIdx: number) {
    const newContactEmail = this.users.find(
      contact => contact.employeeName === contactName,
    ).email;
    this.contactList$.subscribe(contactListArr => {
      contactListArr.forEach((contact, idx) => {
        if (idx === contactIdx) {
          contact.name = contactName;
          contact.email = newContactEmail;
        }
      });
    });
  }
  updateOrderNumber(orderNumber: number, contactIdx: number) {
    this.contactList$.subscribe(contactListArr => {
      contactListArr.forEach((contact, idx) => {
        if (idx === contactIdx) {
          contact.orderNumber = orderNumber;
        }
      });
      const isValid = OrderNumberValidator.validateOrderNumbers(
        contactListArr.map(x => x.orderNumber),
      );
      this.errorMessage = isValid.errorMessage;
      this.invalidForm = isValid.invalidForm;
    });
  }
  updateTitle(contactTitle: string, contactIdx: number) {
    this.contactList$.subscribe(contactListArr => {
      contactListArr.forEach((contact, idx) => {
        if (idx === contactIdx) {
          contact.title = contactTitle;
        }
      });

      const isValid = IsRequiredValidator.isRequired(
        contactListArr.map(x => x.title),
      );
      this.errorMessage = isValid.errorMessage;
      this.invalidForm = isValid.invalidForm;
    });
  }
  getUsersList() {
    this.userProfileClient.get().subscribe(val => {
      const tempUserArr: UserProfileViewModel[] = [];

      val.forEach(user => {
        if (
          user.isActive === true &&
          user.employeeName !== null &&
          (user.role === 'OSRS GA' ||
            user.role === 'OSRS GA Admin' ||
            user.role === 'OSRS Authority')
        ) {
          tempUserArr.push(user);
          this.fullUserList.push(user);
        }
      });

      this.contactList$.subscribe(val => {
        if (val.length !== 0) {
          val.forEach((contact, idx) => {
            tempUserArr.forEach((user, i) => {
              if (contact.name === user.employeeName) {
                tempUserArr.splice(i, 1);
              }
            });
          });
        }
      });

      this.users = tempUserArr;
    });
  }

  onAdd() {
    this.invalidForm = true;
    this.errorMessage = 'All Inputs are Required';
    const newContact: UserContact = {
      id: 0,
      name: '',
      email: '',
      title: '',
      orderNumber: null,
    };
    this.contactList$.subscribe(val => {
      val.push(newContact);
    });
  }

  deleteContact(idx: number) {
    this.indexToDelete = idx;
    this.deleteOpened = true;
  }

  cancelDeleteConfirmation() {
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.contactList$.subscribe(val => {
      val.splice(this.indexToDelete, 1);
      this.getUsersList();
    });
    this.indexToDelete = -1;
    this.deleteOpened = false;
    this.save();
  }

  save() {
    this.isSaved = false;
    this.feedbackService.beginLoading();
    this.contactList$
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(val => {
        const tempContactListArr: ContactList[] = [];

        val.forEach(contact => {
          const userProfile = this.fullUserList.find(
            user => user.employeeName === contact.name,
          );
          const contactToUpdate: ContactList = {
            id: contact.id,
            userProfileId: userProfile.id.toString(),
            title: contact.title,
            orderNumber: contact.orderNumber,
          };
          tempContactListArr.push(contactToUpdate);
        });

        const formCheck = CompleteFormValidator.isCompleteForm(
          'contact',
          tempContactListArr,
        );

        if (formCheck.invalidForm === false) {
          this.contactService.updateContacts(tempContactListArr).subscribe(
            next => {
              this.showSavedModal = true;
              this.isSaved = true;
              this.feedbackService.displaySuccess();
              // auto-close for 'Saved Modal'
              setTimeout(() => {
                this.showSavedModal = false;
              }, 1500);
            },
            error => console.error('error updating contact list', error),
          );
        } else {
          this.invalidForm = formCheck.invalidForm;
          this.errorMessage = formCheck.errorMessage;
        }
      });
    this.isSaved = true;
  }
}
