import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SubawardsService } from '../subawards.service';
import { File } from '../../api.service';

@Component({
  selector: 'subs-fully-executed-agreement',
  templateUrl: './fully-executed-agreement.component.html',
})
export class FullyExecutedAgreementComponent {
  @Input() feaDocuments: UntypedFormGroup;
  @Input() canEdit: boolean;
  @Input() hasFeaFile: boolean;
  @Input() internalDistributionSent: boolean;
  @Input() externalPONotificationSent: boolean;

  @Output() agreementUploaded: EventEmitter<any> = new EventEmitter<File>();
  @Output() uploadCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() resendInternalDistributionEmail: EventEmitter<
    boolean
  > = new EventEmitter<boolean>();
  @Output() resendExternalPONotificationEmail: EventEmitter<
    void
  > = new EventEmitter<void>();

  constructor(private subawardsService: SubawardsService) {}

  callback = (file: File, fileId: string) => {
    if (file) {
      this.agreementUploaded.emit({ file, fileId });
      this.hasFeaFile = true;
    } else {
      this.hasFeaFile = false;
      this.subawardsService.setFeaUploaded(false);
      this.subawardsService.setPoNotificationSent(false);
      this.subawardsService.setInternalDistributionSent(false);
    }
  };

  canceledCallback = () => {
    this.uploadCanceled.emit();
  };

  showResendEmails() {
    return (
      this.hasFeaFile === true &&
      (!this.internalDistributionSent || !this.externalPONotificationSent)
    );
  }

  sendInternalDistributionEmail() {
    this.resendInternalDistributionEmail.emit(true);
  }

  sendExternalPONotificationEmail() {
    this.resendExternalPONotificationEmail.emit();
  }
}
