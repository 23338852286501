import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class InstitutionSearchService {
  private searchFormValue = new BehaviorSubject<UntypedFormGroup>(null);

  constructor() {}

  select(formValue: UntypedFormGroup) {
    this.searchFormValue.next(formValue);
  }

  getSearchForm() {
    return this.searchFormValue.asObservable();
  }
}
