<div [formGroup]="form">
  <subs-static-text
    label="* Address 1"
    controlName="addressLine1"
    maxLength="80"
  ></subs-static-text>

  <subs-static-text
    label="Address 2"
    controlName="addressLine2"
    maxLength="80"
  ></subs-static-text>

  <subs-static-text
    label="Address 3"
    controlName="addressLine3"
    maxLength="80"
  ></subs-static-text>

  <subs-static-text
    label="Address 4"
    controlName="addressLine4"
    maxLength="80"
  ></subs-static-text>

  <div class="clr-row">
    <subs-static-text
      class="clr-col-lg-7"
      controlName="city"
      label="* City"
      maxLength="55"
    ></subs-static-text>

    <subs-static-text
      class="clr-col-lg-auto"
      labelLength="2"
      label="* State"
      controlName="state"
    >
    </subs-static-text>

    <subs-static-text
      class="clr-col-lg"
      label="* Zip code + 4"
      controlName="zip"
      labelStyle="width:4rem;"
      maxLength="30"
    ></subs-static-text>
  </div>

  <div class="clr-row">
    <subs-static-text class="clr-col" label="* Country" controlName="country">
    </subs-static-text>

    <subs-static-text
      *ngIf="includeCongressionalDistrict"
      class="clr-col"
      label="* Congressional District"
      controlName="congressionalDistrict"
      labelStyle="width:4rem;"
      maxLength="30"
    ></subs-static-text>
  </div>
</div>
