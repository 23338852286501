import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { authCodeFlowConfig } from './auth.config';
import {
  RolePermissionsViewModel,
  UserProfilesClient,
  UserProfileUpdate,
} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject = new BehaviorSubject<User>(null);

  user = this.userSubject.asObservable();
  private accessToken: any;

  get currentUser(): User {
    return this.userSubject.value;
  }

  constructor(
    private oauthService: OAuthService,
    private jwtHelperService: JwtHelperService,
    private injector: Injector,
    private userProfilesClient: UserProfilesClient,
  ) {}

  async initialize(): Promise<void> {
    this.oauthService.configure(authCodeFlowConfig);

    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events
      .pipe(filter(event => event.type === 'token_refresh_error'))
      .subscribe(err => this.retryTokenRefresh(err));

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => {
        this.handleAccessToken();
        const user = this.createUser(
          this.oauthService.getIdentityClaims(),
          this.accessToken.email,
        );
        if (user && user.wuPeopleId) {
          const request: UserProfileUpdate = {
            employeeName: user.name,
            email: user.email,
            wustlId: user.wuPeopleId,
          };
          this.userProfilesClient.updateProfileFromToken(request).subscribe(
            next => console.log('User profile updated.'),
            error =>
              console.error('error updating user profile from token', error),
          );
        }
      });

    try {
      const success = await this.oauthService.loadDiscoveryDocumentAndTryLogin();

      if (success === false) {
        this.userSubject.next(null);
        return;
      }

      const identityClaims = this.oauthService.getIdentityClaims();

      if (identityClaims) {
        if (!this.accessToken && this.accessToken !== null) {
          this.handleAccessToken();
        }
        let rolePermissions = await this.userProfilesClient
          .getCurrentUserRolePermissions()
          .toPromise()
          .catch(error => {
            console.log(error);
            this.oauthService.logOut();
          });

        rolePermissions = rolePermissions as RolePermissionsViewModel;
        identityClaims['role'] = rolePermissions.role;
        identityClaims['permissions'] = rolePermissions.permissions;
      }

      const email = this.accessToken ? this.accessToken.email : '';
      const currentUser = this.createUser(identityClaims, email);
      this.userSubject.next(currentUser);

      if (currentUser && this.oauthService.state) {
        await this.oauthService.refreshToken();

        const router = this.injector.get(Router);
        await router.navigateByUrl(decodeURIComponent(this.oauthService.state));
      }
    } catch (err) {
      if (
        !err.type ||
        err.type !== 'code_error' ||
        !err.params ||
        !err.params.error ||
        err.params.error !== 'access_denied'
      ) {
        throw err;
      }

      this.userSubject.next(null);
      return;
    }
  }

  private handleAccessToken() {
    this.accessToken = this.jwtHelperService.decodeToken(
      this.oauthService.getAccessToken(),
    );
  }

  login(redirectUrl: string): void {
    this.oauthService.initLoginFlow(redirectUrl);
  }

  async logout(): Promise<void> {
    await this.oauthService.revokeTokenAndLogout().then();
  }

  public hasPermission(...permissions: any[]): boolean {
    const authUser = this.userSubject.getValue();
    if (!permissions || permissions.length === 0) {
      return true;
    }

    if (
      !authUser ||
      !authUser.permissions ||
      authUser.permissions.length === 0
    ) {
      return false;
    }

    return permissions.some(p => authUser.permissions.includes(p));
  }

  private createUser(claims: any, email: string): User | null {
    if (!claims) {
      return null;
    }

    return {
      name: claims.name,
      wuPeopleId: claims.sub,
      email,
      role: claims.role,
      permissions: claims.permissions,
    };
  }

  private retryTokenRefresh(err: any) {
    if (
      !(err instanceof OAuthErrorEvent) ||
      !(err.reason instanceof HttpErrorResponse) ||
      err.reason.status !== 400
    ) {
      setTimeout(() => this.oauthService.refreshToken(), 1000);
    }
  }
}

export interface User {
  name: string;
  wuPeopleId: number;
  email: string;
  role: string;
  permissions: string[];
}
