<div class="card">
  <div class="card-header">
    Add New Project
  </div>
  <div class="card-block">
    <form clrForm [formGroup]="addProjectForm" class="clr-row">
      <clr-input-container class="clr-col-lg-6">
        <label class="clr-col-12 clr-col-md-auto" style="width: 4rem;">
          Proposal ID
        </label>
        <input
          clrInput
          class="clr-col-12 clr-col-md"
          style="width: 100%"
          data-test-id="proposalId"
          name="proposalId"
          formControlName="proposalId"
          placeholder="Proposal ID"
        />
        <clr-control-error></clr-control-error>
        <clr-control-helper>{{ helperText }}</clr-control-helper>
      </clr-input-container>

      <p class="clr-col-12">
        To enter a new project that has NOT previously had a subaward entered
        into the SUBS System, enter the Proposal ID in the field above and click
        the ADD Button. The Proposal IDs that can be added in SUBS must be in
        one of the following statuses in RMS:
      </p>
      <p class="clr-col-12"></p>

      <ul style="padding-left:50px">
        <li>Approved for Submission</li>
        <li>Submitted to Sponsor</li>
        <li>JIT Requested</li>
        <li>In AT Status</li>
        <li>Full Review Complete</li>
        <li>WU SOM Complete</li>
        <li>Award Changes Needed</li>
        <li>Award Completed</li>
      </ul>

      <p class="clr-col-12">
        If making changes to an existing subaward, please navigate to the Find
        Projects option in the left side menu to retrieve the existing subaward
        information. Please contact the Office of Sponsored Research Services
        (OSRS) if you have questions concerning the SUBS System.
      </p>

      <div class="clr-col-12" style="margin-top:24px;">
        <subs-alert [alertBehavior]="alert$"> </subs-alert>

        <button
          class="btn btn-primary"
          [clrLoading]="submitState$ | async"
          (click)="addProject()"
          data-test-id="add-project"
        >
          Add
        </button>
      </div>
    </form>
  </div>
</div>
