<div class="clr-row" style="height: 100%;">
  <div class="clr-col-12" style="height: 100%">
    <div class="card  contacts-card">
      <h3 class="clr-col-6">Contacts</h3>
      <div class="card-block">
        <!-- Inner contact card -->
        <div class="clr-row" *ngFor="let contact of contacts">
          <div class="clr-col-12">
            <div
              id="contact-list"
              class="card contact-list"
              style="display: flex; flex-direction: column; max-height: 100px;"
            >
              <label
                id="contact-name"
                class="card-block name-style"
                style="margin-bottom: 0;"
                >{{ contact.name }}</label
              >
              <button
                id="contact-email"
                (click)="openEmail(contact.email)"
                class="btn btn-link"
                style="text-transform: lowercase; margin-bottom: 0; text-align: left;"
              >
                {{ contact.email }}
              </button>
              <label
                id="contact-title"
                class="contact-title"
                style="margin-bottom: 0;"
                >{{ contact.title }}</label
              >
            </div>
            <hr />
          </div>
        </div>
        <!-- End of Inner contact Card -->
      </div>
    </div>
  </div>
</div>


