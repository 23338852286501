import { Component, Input } from '@angular/core';
import { DAWorklistViewModel, SubrecipientStatusVm } from 'src/app/api.service';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'subs-da-worklist-result-grid',
  templateUrl: './da-worklist-result-grid.component.html',
  styleUrls: ['./da-worklist-result-grid.component.scss'],
})
export class DaWorklistResultGridComponent {
  @Input() searchResults: Array<DAWorklistViewModel>;
  @Input() statuses: SubrecipientStatusVm[] = [];
  ascSort = ClrDatagridSortOrder.ASC;

  constructor(private router: Router) {
    this.searchResults = [];
  }

  navigateToSub(id) {
    this.router.navigateByUrl('/subaward/' + id);
  }

  getStatus(statusCode: string) {
    const activeStatus = this.statuses.find(
      status => status.statusCode === statusCode,
    );
    return activeStatus ? activeStatus.statusDescription : '';
  }
}
