<div class="clr-row" style="height: 100%;">
  <div class="clr-col-12" style="height: 100%">
    <div class="card  web-links-card">
      <h3 class="clr-col-6">Shortcut Links</h3>
      <div class="card-block">
        <div *ngFor="let shortcut of shortcutLinks$ | async">
          <a [href]="shortcut.shortcutLinkUrl" target="_blank">{{
            shortcut.label
          }}</a>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
