import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { LookupItem, UtilityClient, UtilityTableLookupVm } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class AppUtilitySearchService {
  private searchFormValue = new BehaviorSubject<UntypedFormGroup>(null);
  private appUtilityItems = new BehaviorSubject<Array<LookupItem> | []>(null);
  private appUtilityTables = new BehaviorSubject<Array<UtilityTableLookupVm> | []>(null);


  constructor(private utilityClient: UtilityClient) {}

  select(formValue: UntypedFormGroup) {
    this.searchFormValue.next(formValue);
  }

  getSearchForm() {
    return this.searchFormValue.asObservable();
  }

  selectAppUtilityItems(utilityTableKey: string) {
    this.utilityClient.getAllLookupItems(utilityTableKey).subscribe(result => {
      this.appUtilityItems.next(result.lookupItems);
    });
  }

  getAppUtilityItems() {
    return this.appUtilityItems.asObservable();
  }

  selectAppUtilityTables() {
    this.utilityClient.getAllUtilityLookupTables().subscribe(result => {
      this.appUtilityTables.next(result);
    });
  }

  getAppUtilityTables() {
    return this.appUtilityTables.asObservable();
  }
}
