import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AlertOptions } from 'src/app/alerts/alert-options';
import { Attachment, AttachmentsClient } from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { markFormGroupTouched } from 'src/app/form-layout/markFormGroupTouched';
import { BookmarkControlFactory } from './bookmarks-list/bookmark-control-factory';
import { LocalTimePipe } from '../../components/local-time/local-time.pipe';
@Component({
  selector: 'subs-attachment',
  templateUrl: './attachment.component.html',
  providers: [FeedbackService, LocalTimePipe],
})
export class AttachmentComponent implements OnInit {
  returnToSearch = false;
  showUnsavedChangesModal = false;
  existParam = false;
  currentFileId = '';

  requiredFileAlert$ = new Subject<AlertOptions>();
  saveConfirmation$ = new Subject<boolean>();
  submitState$ = this.feedbackService.submitState;
  alert$ = this.feedbackService.alerts;

  saveConfirmationForm = this.fb.group({
    confirmation: [],
  });

  form = this.fb.group({
    id: [],
    fed: [],
    fdp: [],
    archive: [false],
    unilateral: [],
    sponsor: [],
    attachmentNum: ['', Validators.required],
    description: [],
    attachmentFileName: [],
    attachmentFileId: ['', Validators.required],
    attachmentFileModified: [],
    bookmarks: this.fb.array([]),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private attachmentClient: AttachmentsClient,
    private route: ActivatedRoute,
    private router: Router,
    private bookmarkControlFactory: BookmarkControlFactory,
    private feedbackService: FeedbackService,
    private localDatePipe: LocalTimePipe,
  ) {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.existParam = true;
      }
    });
    this.saveConfirmationForm.controls.confirmation.valueChanges.subscribe(
      result => {
        this.saveConfirmation$.next(result);
      },
    );
  }

  ngOnInit(): void {
    if (this.existParam) {
      this.getAllParameters()
        .pipe(
          switchMap(params => this.getAttachment(params)),
          tap(id => this.bindAttachment(id)),
        )
        .subscribe();
    }

    this.form.valueChanges.subscribe((value: any) => {
      if (this.form.dirty) {
        this.saveConfirmationForm.controls.confirmation.setValue(false);
      } else {
        this.saveConfirmationForm.controls.confirmation.setValue(true);
      }
    });

    this.form.controls.attachmentFileModified.valueChanges.subscribe(value => {
      if (value) {
        // load existing attachment with a file OR re-upload
        if (value.indexOf('T') !== -1) {
          this.form.controls.attachmentFileModified.patchValue(
            this.localDatePipe.transform(
              this.form.controls.attachmentFileModified.value,
            ),
            {
              onlySelf: true,
              emitEvent: false,
            },
          );
        } else {
          // add new attachment / file
          this.form.controls.attachmentFileModified.patchValue(
            this.localDatePipe.transform(new Date()),
            {
              onlySelf: true,
              emitEvent: false,
            },
          );
        }
      }
    });
  }

  private getAllParameters(): Observable<{ [key: string]: string }> {
    return combineLatest(this.route.params, this.route.queryParams).pipe(
      map(([routeParams, queryParams]) => ({
        ...queryParams,
        ...routeParams,
      })),
    );
  }

  private getAttachment(params: Params): Observable<Partial<Attachment>> {
    const idString = params.id;
    const attachmentId = parseInt(idString, 10);

    if (isNaN(attachmentId)) {
      throw new Error('The attachment id was in an incorrect format');
    }

    return this.attachmentClient.get(attachmentId);
  }

  private bindAttachment(attachment: Partial<Attachment>) {
    const attachmentModel = {
      ...attachment,
    };

    this.bookmarks.clear();
    this.bookmarkControlFactory
      .createMany(attachmentModel.bookmarks.length)
      .forEach(control => this.bookmarks.push(control));

    this.form.reset(attachmentModel);
  }

  get bookmarks(): UntypedFormArray {
    return this.form.get('bookmarks') as UntypedFormArray;
  }

  public addLine(bookmark: UntypedFormGroup): void {
    this.bookmarks.push(bookmark);
  }

  public removeBookmark(index: number): void {
    this.bookmarks.removeAt(index);
  }

  redirectToSearchPage() {
    this.returnToSearch = true;
    this.router.navigate(['/find-attachment'], {
      queryParams: { query: true },
    });
  }

  saveAndCopy() {
    this.save()
      .pipe(mergeMap(attachment => this.copy()))
      .subscribe();
  }

  copy(): Observable<Attachment> {
    if (this.form.invalid) {
      return EMPTY;
    }

    this.feedbackService.beginLoading();

    return this.attachmentClient.copy(this.form.controls.id.value).pipe(
      this.feedbackService.provideFeedback(),
      tap(result => {
        this.router.navigateByUrl(`attachment/${result.id}`);
      }),
    );
  }

  save(): Observable<Attachment> {
    this.feedbackService.beginLoading();

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      this.feedbackService.alert(
        'Please fix all field errors before saving the form.',
      );
      if (this.form.controls.attachmentFileId.invalid) {
        this.requiredFileAlert$.next({
          type: 'danger',
          message: 'You have to upload an attachment.',
        });
      }
      return;
    }

    this.feedbackService.beginLoading();

    const value = this.form.value;
    const id = this.form.controls.id.value;
    if (id) {
      return this.attachmentClient.update(id, value).pipe(
        this.feedbackService.provideFeedback(),
        tap(() => {
          this.saveConfirmationForm.controls.confirmation.setValue(true);
          this.form.markAsPristine();
        }),
      );
    } else {
      return this.attachmentClient.post(value).pipe(
        this.feedbackService.provideFeedback(),
        tap(result => {
          this.saveConfirmationForm.controls.confirmation.setValue(true);
          this.form.markAsPristine();
          this.router.navigateByUrl(`attachment/${result.id}`);
        }),
      );
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.dirty) {
      return true;
    }
    this.showUnsavedChangesModal = true;
    return this.saveConfirmation$;
  }

  confirmSave() {
    this.showUnsavedChangesModal = false;
    this.save().subscribe(result => {
      if (result) {
        if (this.returnToSearch) {
          this.router.navigate(['/find-attachment'], {
            queryParams: { query: true },
          });
        }
      }
    });
  }

  discardChanges() {
    this.showUnsavedChangesModal = false;
    this.saveConfirmationForm.controls.confirmation.setValue(true);
  }

  cancelNavigation() {
    this.saveConfirmationForm.controls.confirmation.setValue(false);
    this.showUnsavedChangesModal = false;
  }
}
