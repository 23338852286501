import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EmployeeLookupComponent } from '../../edit-project/employee-lookup/employee-lookup.component';

@Component({
  selector: 'subs-washu-contact',
  templateUrl: './washu-contact.component.html',
})
export class WashuContactComponent {
  @Input() form: UntypedFormGroup;
  @Input() title: string;
  @Input() isPrincipalInvestigator: boolean;
  DeptName: string;

  @ViewChild(EmployeeLookupComponent, { static: true })
  employeeLookup!: EmployeeLookupComponent;

  lookupEmployee() {
    this.employeeLookup.lookupEmployee().subscribe(employee => {
      this.form.patchValue({
        firstName: employee.firstName,
        lastName: employee.lastName,
        phone: employee.phone,
        emailAddress: employee.commonEmail,
        fax: '',
        campusCD: employee.campusCode,
        campusBox: employee.campusBox,
        principalInvestigatorDepartment: employee.primaryDepartment,
      });
    });
  }
}
