import { Announcement, ShortcutLink, UserContact } from 'src/app/api.service';

export class CompleteFormValidator {
  static isCompleteForm(
    formName: string,
    form: Announcement[] | ShortcutLink[] | UserContact[],
  ) {
    let invalidForm = false;
    let errorMessage = '';

    switch (formName) {
      case 'announcement':
        const announcementForm = this.validateAnnouncmentForm(
          form as Announcement[],
        );
        if (announcementForm.map(x => x.invalidForm).includes(true)) {
          (invalidForm = true), (errorMessage = 'Form Is Invalid');
        }
      case 'contact':
        const contactForm = this.validateContactForm(form as UserContact[]);
        if (contactForm.map(x => x.invalidForm).includes(true)) {
          (invalidForm = true), (errorMessage = 'Form Is Invalid');
        }
      case 'shortcut':
        const shortcutForm = this.validateShortcutForm(form as ShortcutLink[]);
        if (shortcutForm.map(x => x.invalidForm).includes(true)) {
          (invalidForm = true), (errorMessage = 'Form Is Invalid');
        }
    }
    return {
      invalidForm,
      errorMessage,
    };
  }

  static validateAnnouncmentForm(form: Announcement[]) {
    const result = form.map(val => {
      if (
        val.announcementText === '' ||
        val.date.toString() === '' ||
        val.orderNumber === (0 || null) ||
        val.orderNumber.toString() === 'NaN'
      ) {
        return {
          invalidForm: true,
          errorMessage: 'Form Is Invalid',
        };
      } else {
        return {
          invalidForm: false,
          errorMessage: '',
        };
      }
    });

    return result;
  }
  static validateContactForm(form: UserContact[]) {
    const result = form.map(val => {
      if (
        val.userProfileId === (0 || null) ||
        val.title === '' ||
        val.orderNumber === (0 || null) ||
        val.orderNumber.toString() === 'NaN'
      ) {
        return {
          invalidForm: true,
          errorMessage: 'Form Is Invalid',
        };
      } else {
        return {
          invalidForm: false,
          errorMessage: '',
        };
      }
    });

    return result;
  }
  static validateShortcutForm(form: ShortcutLink[]) {
    const result = form.map(val => {
      if (
        val.label === '' ||
        val.shortcutLinkUrl === '' ||
        val.orderNumber === (0 || null) ||
        val.orderNumber.toString() === 'NaN'
      ) {
        return {
          invalidForm: true,
          errorMessage: 'Form Is Invalid',
        };
      } else {
        return {
          invalidForm: false,
          errorMessage: '',
        };
      }
    });

    return result;
  }
}
