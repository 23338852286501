<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <subs-date
      controlName="dateFrom"
      labelLength="4"
      class="clr-col"
      errorMessage="Required field"
    >
    </subs-date>
    <subs-date
      controlName="dateThru"
      labelLength="4"
      class="clr-col"
      errorMessage="Required field"
    >
    </subs-date>
  </div>
  <div class="clr-row">
    <subs-assigned-ga controlName="assignedGA" label="OSRS GA">
    </subs-assigned-ga>
    <subs-select controlName="reportType" class="clr-col" labelLength="4">
      <option value="sent" selected>Sent</option>
      <option value="dist">Distributed</option>
    </subs-select>
  </div>
  <div class="clr-row">
    <subs-checkbox
      class="clr-col"
      controlName="summaryOnly"
      labelLength="4"
      label="Summary Only?"
    ></subs-checkbox>
  </div>
  <div style="margin-top:24px;">
    <subs-alert [alertBehavior]="alertSubject$"> </subs-alert>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="search()"
      data-test-id="search-btn"
      [disabled]="!searchForm.valid"
    >
      Submit
    </button>

    <button class="btn btn-outline" (click)="clear()" data-test-id="clear-btn">
      Clear
    </button>
  </div>
</form>
