import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ControlContainer,
  UntypedFormArray,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { DownloadService } from 'src/app/download.service';
import { AdditionalDocumentControlFactory } from './additional-document-control-factory';

@Component({
  selector: 'subs-additional-document',
  templateUrl: './additional-document.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class AdditionalDocumentComponent {
  @Input() additionalDocuments: UntypedFormArray;
  @Output() additionalDocument: EventEmitter<UntypedFormGroup> = new EventEmitter<
    UntypedFormGroup
  >();
  @Output() idxToRemove: EventEmitter<number> = new EventEmitter<number>();
  @Input() feaDocuments: UntypedFormGroup;
  @Input() checklist: UntypedFormGroup;
  @Input() projectTitle: string;

  wordDocumentExtensionError = false;
  indexToDelete = -1;
  fileNameToDelete = '';
  deleteOpened = false;

  constructor(
    private controlFactory: AdditionalDocumentControlFactory,
    private downloadService: DownloadService,
  ) {}

  fileUploaded({ fileName, fileId }: { fileName: string; fileId: string }) {
    const fileExtension = fileName.split('.').pop();
    if (fileExtension === 'doc' || fileExtension === 'docm') {
      this.wordDocumentExtensionError = true;
      return;
    }
    const control = this.controlFactory.createAdditionalDocumentControl({
      id: null,
      dateUploaded: new Date().toISOString(),
      included: false,
      fileId,
      fileName,
    });

    this.additionalDocument.emit(control);
  }

  resetErrors() {
    this.wordDocumentExtensionError = false;
  }

  download(index: number) {
    const { fileId, fileName } = this.additionalDocuments.value[index];
    this.downloadService.downloadFile(fileId, fileName).subscribe();
  }

  downloadFEA() {
    const { fileId, fileName } = this.feaDocuments.value;
    this.downloadService.downloadFile(fileId, fileName).subscribe();
  }

  deleteAdditionalDocument(index: number) {
    this.indexToDelete = index;
    this.fileNameToDelete = this.additionalDocuments.value[index].name;
    this.deleteOpened = true;
  }

  cancelDeleteConfirmation() {
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.idxToRemove.emit(this.indexToDelete);
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  canInclude(fileExt: string) {
    if (!fileExt) {
      return true;
    }
    const acceptedExtensions = ['pdf', 'docx'];
    const extension = fileExt.split('.').pop();
    const validExtension = acceptedExtensions.includes(extension) ? null : true;
    return validExtension;
  }
}
