import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'subs-readonly-address',
  templateUrl: './readonly-address.component.html',
})
export class ReadonlyAddressComponent {
  @Input() form: UntypedFormGroup;
  @Input() includeCongressionalDistrict: boolean;
  constructor() {}
}
