import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { InstitutionOfficerVm } from '../../../api.service';

@Injectable()
export class OfficerControlFactory {
  constructor(private fb: UntypedFormBuilder) {}

  createControl(value: InstitutionOfficerVm): UntypedFormGroup {
    const control = this.createGroup();
    control.setValue(value);
    return control;
  }

  private createGroup() {
    return this.fb.group({
      id: [],
      institutionId: ['', Validators.required],
      subrecipientId: ['', Validators.required],
      officerName: ['', Validators.required],
      compensation: ['', Validators.required],
      isActive: [true, Validators.required],
      createdDate: [new Date(), Validators.required],
      createdBy: ['', Validators.required],
      modifiedDate: [new Date(), Validators.required],
      modifiedBy: ['', Validators.required],
    });
  }

  createMany(count: number): UntypedFormGroup[] {
    const output = [];

    for (let i = 0; i < count; i++) {
      output.push(this.createGroup());
    }

    return output;
  }
}
