<clr-datagrid>
  <clr-dg-column
    [clrDgField]="'id'"
    [clrDgSortOrder]="ascSort"
    [style.width.px]="110"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Template Id
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'attachmentNum'" [style.width.px]="130"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Attachment Num
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'unilateral'" [style.width.px]="130"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Unilateral / Bilateral
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'sponsor'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Sponsor Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'attachmentFileName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      File Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'description'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Description
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'created'"
  ><ng-container *clrDgHideableColumn="{ hidden: false }">
    Created
  </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'modified'"
  ><ng-container *clrDgHideableColumn="{ hidden: false }">
    Last Modified
  </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'includeArchived'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Archived
    </ng-container></clr-dg-column
  >
  <clr-dg-row *clrDgItems="let result of attachmentSearchResults">
    <clr-dg-cell (click)="navigateToAttachment(result.id)">{{
      result.id
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToAttachment(result.id)">{{
      result.attachmentNum
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToAttachment(result.id)">{{
      result.unilateral
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToAttachment(result.id)">{{
      result.sponsor
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToAttachment(result.id)">{{
      result.attachmentFileName
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToAttachment(result.id)">{{
      result.description
    }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToAttachment(result.id)">{{
      result.created.toString() | date: 'shortDate'
      }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToAttachment(result.id)">{{
      result.modified.toString() | date: 'shortDate'
      }}</clr-dg-cell>
    <clr-dg-cell (click)="navigateToAttachment(result.id)">{{
      getAttachmentStatus(result.archive)
    }}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer data-test-id="results-amount"
    >{{
      attachmentSearchResults.length === 0
        ? 'No'
        : attachmentSearchResults.length
    }}
    result{{ attachmentSearchResults.length === 1 ? '' : 's' }}</clr-dg-footer
  >
</clr-datagrid>
