import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Institution } from 'src/app/api.service';
import { ClrDatagridSortOrder } from '@clr/angular';
import { CollaboratorInstitutionSearchService } from '../collaborator-institution-search.service';

@Component({
  selector: 'subs-collaborator-institution-grid',
  templateUrl: './collaborator-institution-grid.component.html',
  styleUrls: ['./collaborator-institution-grid.component.scss'],
})
export class CollaboratorInstitutionGridComponent {
  @Input() institutionSearchResults: Array<Institution>;
  @Output() institutionSelected = new EventEmitter<boolean>();
  ascSort = ClrDatagridSortOrder.ASC;

  constructor(private searchService: CollaboratorInstitutionSearchService) {
    this.institutionSearchResults = [];
  }

  selectInstitution(id) {
    const inst = this.institutionSearchResults.find(i => i.id === id);
    this.searchService.select(inst);
    this.institutionSelected.emit(true);
  }
}
