import { Component, OnInit } from '@angular/core';
import {
  LookupItemsResponse,
  UtilityClient,
  UtilityTableKeyEnum,
  UtilityTableLookupVm,
} from '../../api.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedbackService } from '../../form-layout/feedback.service';
import { AppUtilitySearchService } from './app-utility-search.service';
import { map, tap } from 'rxjs/operators';
import { LookupItemsService } from '../lookup-items.service';

@Component({
  selector: 'subs-app-utility-search',
  templateUrl: './app-utility-search.component.html',
  styleUrls: ['./app-utility-search.component.scss'],
  providers: [FeedbackService],
})
export class AppUtilitySearchComponent implements OnInit {
  alertSubject$ = this.feedbackService.alerts;
  submitState = this.feedbackService.submitState;
  userHasSearched = false;
  loadingUtilityTables = false;
  utilityTables = Array<UtilityTableLookupVm>();
  searchResult: LookupItemsResponse = {
    lookupItems: [],
  };

  shouldShowNoResultsMessage = this.submitState.pipe(
    map(
      submitState =>
        this.searchResult.lookupItems.length === 0 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  constructor(
    private fb: UntypedFormBuilder,
    private utilityClient: UtilityClient,
    private utilitySearchService: AppUtilitySearchService,
    private router: Router,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
    public lookupItemService: LookupItemsService,
  ) {}

  searchForm = this.fb.group({
    utilityKey: [''],
  });

  ngOnInit() {
    this.utilitySearchService.getAppUtilityTables().subscribe(utilityTables => {
      this.utilityTables = utilityTables;
    });
    this.route.queryParams.subscribe(params => {
      const utilityTableKey = params.type;
      const reloadData = params.reload;
      if (utilityTableKey) {
        this.searchForm.controls.utilityKey.patchValue(utilityTableKey);
        this.utilitySearchService.select(this.searchForm);
        this.getUtilityItems(utilityTableKey);
        if (reloadData) {
          this.lookupItemService.loadLookupItems(utilityTableKey);
        }
      }
    });
  }

  search() {
    this.utilitySearchService.select(this.searchForm);
    this.feedbackService.beginLoading();
    this.getUtilityItems(this.searchForm.controls.utilityKey.value);
  }

  private getUtilityItems(utilityTableKey: string) {
    this.utilityClient
      .getAllLookupItems(utilityTableKey)
      .pipe(
        tap(result => {
          if (utilityTableKey === UtilityTableKeyEnum.InstitutionTypeLookup) {
            result.lookupItems.sort((a, b) => {
              const key1 = +a.key;
              const key2 = +b.key;
              return key1 < key2 ? -1 : key1 > key2 ? 1 : 0;
            });
          }
          this.searchResult = result;
          this.userHasSearched = true;
        }),
        this.feedbackService.provideFeedback(),
      )
      .subscribe();
  }

  clear() {
    this.searchResult.lookupItems = [];
    this.searchResult.utilityTableKey = null;
  }

  addUtilityItem(value: string) {
    this.router.navigate(['app-utility/item'], {
      queryParams: { type: value },
    });
  }
}
