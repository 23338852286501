import { Injectable } from '@angular/core';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { Subaward, GetProject } from '../../../api.service';

@Injectable()
export class SubawardExcelService {
  constructor(private http: HttpClient) {}

  public async createCollaboratorSpreadsheet(
    subaward: Subaward,
    project: GetProject,
  ) {
    const workbook = new Excel.Workbook();

    const response = await this.http

      .get('/assets/Collaborator_Information_Template.xlsx', {
        responseType: 'arraybuffer',
      })
      .toPromise();

    await workbook.xlsx.load(response);

    const collabInfoWorkSheet = workbook.getWorksheet(
      'CollaboratorInformation',
    );

    collabInfoWorkSheet.getCell('B2').value =
      subaward.washUInformation.subawardId;

    collabInfoWorkSheet.getCell('D2').value =
      subaward.washUInformation.awardYear;

    collabInfoWorkSheet.getCell('F2').value = subaward.washUInformation.fund;

    collabInfoWorkSheet.getCell('B3').value =
      subaward.washUInformation.proposalId;

    collabInfoWorkSheet.getCell('D3').value =
      subaward.washUInformation.amendment;

    collabInfoWorkSheet.getCell('F3').value =
      subaward.washUInformation.issuingFundSubClassBudgetObject;

    collabInfoWorkSheet.getCell('B4').value = project.awardDepartment
      ? project.awardDepartment
      : '';

    collabInfoWorkSheet.getCell('D4').value =
      subaward.washUInformation.issuingDepartment;

    const projectAdminFirstName = project.adminFirstName
      ? project.adminFirstName
      : '';

    const projectAdminLastName = project.adminLastName
      ? project.adminLastName
      : '';

    collabInfoWorkSheet.getCell('F4').value =
      projectAdminFirstName + ' ' + projectAdminLastName;

    collabInfoWorkSheet.getCell('B5').value = project.sponsorName
      ? project.sponsorName
      : '';

    collabInfoWorkSheet.getCell('F5').value = project.adminEmail
      ? project.adminEmail
      : '';

    const pIFirstName = project.investigatorFirstName
      ? project.investigatorFirstName
      : '';

    const pILastName = project.investigatorLastName
      ? project.investigatorLastName
      : '';

    collabInfoWorkSheet.getCell('B6').value = pIFirstName + ' ' + pILastName;

    collabInfoWorkSheet.getCell('F6').value = project.adminPhone
      ? project.adminPhone
      : '';

    collabInfoWorkSheet.getCell('B7').value = project.projectTitle
      ? project.projectTitle
      : '';

    const bufferedData = await workbook.xlsx.writeBuffer();

    const blob = new Blob([bufferedData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    fs.saveAs(blob, 'Collaborator Information.xlsx');
  }

  public async GetCollaboratorSpreadsheetData(
    subaward: Subaward,
    project: GetProject,
  ) {
    const workbook = new Excel.Workbook();

    const response = await this.http

      .get('/assets/Collaborator_Information_Template.xlsx', {
        responseType: 'arraybuffer',
      })
      .toPromise();

    await workbook.xlsx.load(response);

    const collabInfoWorkSheet = workbook.getWorksheet(
      'CollaboratorInformation',
    );

    collabInfoWorkSheet.getCell('B2').value =
      subaward.washUInformation.subawardId;

    collabInfoWorkSheet.getCell('D2').value =
      subaward.washUInformation.awardYear;

    collabInfoWorkSheet.getCell('F2').value = subaward.washUInformation.fund;

    collabInfoWorkSheet.getCell('B3').value =
      subaward.washUInformation.proposalId;

    collabInfoWorkSheet.getCell('D3').value =
      subaward.washUInformation.amendment;

    collabInfoWorkSheet.getCell('F3').value =
      subaward.washUInformation.issuingFundSubClassBudgetObject;

    collabInfoWorkSheet.getCell('B4').value = project.awardDepartment
      ? project.awardDepartment
      : '';

    collabInfoWorkSheet.getCell('D4').value =
      subaward.washUInformation.issuingDepartment;

    const projectAdminFirstName = project.adminFirstName
      ? project.adminFirstName
      : '';

    const projectAdminLastName = project.adminLastName
      ? project.adminLastName
      : '';

    collabInfoWorkSheet.getCell('F4').value =
      projectAdminFirstName + ' ' + projectAdminLastName;

    collabInfoWorkSheet.getCell('B5').value = project.sponsorName
      ? project.sponsorName
      : '';

    collabInfoWorkSheet.getCell('F5').value = project.adminEmail
      ? project.adminEmail
      : '';

    const pIFirstName = project.investigatorFirstName
      ? project.investigatorFirstName
      : '';

    const pILastName = project.investigatorLastName
      ? project.investigatorLastName
      : '';

    collabInfoWorkSheet.getCell('B6').value = pIFirstName + ' ' + pILastName;

    collabInfoWorkSheet.getCell('F6').value = project.adminPhone
      ? project.adminPhone
      : '';

    collabInfoWorkSheet.getCell('B7').value = project.projectTitle
      ? project.projectTitle
      : '';

    const bufferedData = await workbook.xlsx.writeBuffer();

    const blob = new Blob([bufferedData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    return blob;
  }
}
