import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { LookupItemsService } from '../../utilities/lookup-items.service';
import { LookupItem, UtilityTableKeyEnum } from '../../api.service';

@Component({
  selector: 'subs-address',
  templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() includeCongressionalDistrict: boolean;
  @Input()
  set hideRequiredItems(val: boolean) {
    if (val) {
      this.stateRequiredIndicator = '';
    }
  }
  @Input()
  set showCountryRequiredIndicator(val: boolean) {
    if (val) {
      this.countryRequiredIndicator = '* ';
    }
  }

  countryOptions: LookupItem[] = [];
  stateOptions: LookupItem[] = [];
  stateRequiredIndicator = '';
  countryRequiredIndicator = '';
  requiredStateZipByCountry = ['can', 'usa'];
  stateErrorMessage = '';
  zipErrorMessage = '';

  constructor(public lookupItemService: LookupItemsService) {}

  ngOnInit() {
    this.lookupItemService
      .lookupItems(UtilityTableKeyEnum.StateCodeLookup)
      .subscribe(value => {
        this.stateOptions = value;
      });
    this.lookupItemService
      .lookupItems(UtilityTableKeyEnum.CountryCodeLookup)
      .subscribe(value => {
        this.countryOptions = value;
      });
    this.form.controls.countryCode.valueChanges.subscribe(value => {
      if (value) {
        // set Country Name
        const countryName = this.countryOptions.find(x => x.key === value)
          .value;
        this.form.controls.countryName.patchValue(countryName);

        // set requirements
        if (
          this.form.controls.countryCode &&
          this.requiredStateZipByCountry.includes(value.toLowerCase())
        ) {
          // State and Zip - REQUIRED
          this.stateRequiredIndicator = '* ';

          if (
            this.form.controls.state &&
            this.form.controls.state.value === ''
          ) {
            this.stateErrorMessage = 'A selection is required.';
            this.form.controls.state.setValidators([Validators.required]);
            this.form.controls.state.markAsTouched();
            this.form.controls.state.updateValueAndValidity();
          } else {
            this.stateErrorMessage = '';
            this.form.controls.state.clearValidators();
            this.form.controls.state.markAsUntouched();
            this.form.controls.state.updateValueAndValidity();
          }

          if (this.form.controls.zip && this.form.controls.zip.value === '') {
            this.zipErrorMessage = 'A value is required.';
            this.form.controls.zip.setValidators([
              Validators.required,
              Validators.maxLength(30),
            ]);
            this.form.controls.zip.markAsTouched();
            this.form.controls.zip.updateValueAndValidity();
          } else {
            this.zipErrorMessage = '';
            this.form.controls.zip.clearValidators();
            this.form.controls.state.markAsUntouched();
            this.form.updateValueAndValidity();
          }
        } else {
          // State and Zip - NOT REQUIRED
          this.stateRequiredIndicator = '';
          this.stateErrorMessage = '';
          this.form.controls.state.clearValidators();
          this.form.controls.state.markAsUntouched();
          this.form.controls.state.updateValueAndValidity();
          this.zipErrorMessage = '';
          this.form.controls.zip.clearValidators();
          this.form.controls.zip.markAsUntouched();
          this.form.controls.zip.updateValueAndValidity();
          this.form.updateValueAndValidity();
        }
      }
    });

    this.form.controls.state.valueChanges.subscribe(value => {
      if (value !== '' || this.form.controls.countryCode.value === '') {
        this.stateErrorMessage = '';
      }
    });
  }

  setCountryCodeErrorMessage() {
    const invalidControl = this.form.controls.countryCode.invalid;
    return invalidControl && this.form.touched
      ? 'A selection is required.'
      : '';
  }
}
