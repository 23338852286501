<form clrForm [formGroup]="searchForm">
  <div>
    <h5>Enter the two fiscal years to compare:</h5>
    <subs-select
      class="clr-col-lg-auto"
      labelLength="7"
      label="Fiscal Year Selection 1"
      controlName="fyStart"
      errorMessage="A fiscal year must be entered."
    >
      <option *ngFor="let item of years" value="{{ item }}">{{ item }}</option>
    </subs-select>

    <subs-select
      class="clr-col-lg-auto"
      labelLength="7"
      label="Fiscal Year Selection 2"
      controlName="fyEnd"
      errorMessage="A fiscal year must be entered."
    >
      <option *ngFor="let item of years" value="{{ item }}">{{ item }}</option>
    </subs-select>
  </div>
  <div style="margin-top:24px;">
    <subs-alert [alertBehavior]="alertSubject"> </subs-alert>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="search()"
      data-test-id="search-btn"
      [disabled]="!searchForm.valid"
    >
      Export
    </button>
  </div>
</form>
