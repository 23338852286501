import { Component, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'subs-risk-notification-email',
  templateUrl: './risk-notification-email.component.html',
})
export class RiskNotificationEmailComponent {
  @Output() financialEmailClicked = new EventEmitter<boolean>();

  constructor(private authService: AuthService) {}

  sendFinancialRiskEmail() {
    this.financialEmailClicked.emit(true);
  }

  isAllowed() {
    return !this.authService.hasPermission('ReadOnlyGlobal');
  }
}
