import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Attachment, AttachmentsClient } from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { ExcelExportService } from 'src/app/reports/excel-export.service';
import { AuthService } from '../../auth.service';
import { LocalTimePipe } from '../../components/local-time/local-time.pipe';
import { SearchAttachmentService } from './search-attachment.service';
import { Location } from '@angular/common';
import { isEqual } from 'lodash';
import { DateConvert } from '../../converters/date-convert/date-convert';
import { Observable } from 'rxjs';

@Component({
  selector: 'subs-search-attachment',
  templateUrl: './search-attachment.component.html',
  providers: [FeedbackService, LocalTimePipe],
})
export class SearchAttachmentComponent implements OnInit {
  submitState = this.feedbackService.submitState;
  userHasSearched = false;
  searchResult = Array<Attachment>();
  searchResultCount = '';
  filteredResult = Array<Attachment>();
  alertSubject$ = this.feedbackService.alerts;

  searchForm = this.fb.group({
    templateId: ['', Validators.pattern('^\\d*$')],
    attachmentNum: [''],
    description: [''],
    includeArchived: [''],
  });

  shouldShowMaxResultsMessage = this.feedbackService.submitState.pipe(
    map(
      submitState =>
        this.searchResult.length === 300 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  shouldShowNoResultsMessage = this.feedbackService.submitState.pipe(
    map(
      submitState =>
        this.searchResult.length === 0 &&
        this.userHasSearched &&
        submitState !== 1,
    ),
  );

  constructor(
    private fb: UntypedFormBuilder,
    private attachmentClient: AttachmentsClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private feedbackService: FeedbackService,
    private excelExportService: ExcelExportService,
    private authService: AuthService,
    private localDatePipe: LocalTimePipe,
    private searchAttachmentService: SearchAttachmentService,
  ) {}

  ngOnInit() {
    this.searchForm.controls.includeArchived.valueChanges.subscribe(value => {
      this.filterSearchResult(value);
    });
    this.searchForm.valueChanges.subscribe(formValue => {
      if (formValue !== undefined && formValue !== null) {
        this.searchAttachmentService.setSearchFormValue(formValue);
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params.query) {
        this.search();
        this.location.replaceState(`/find-attachment`);
      }
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    this.clear();
    return true;
  }

  search() {
    this.feedbackService.beginLoading();
    this.searchAttachmentService
      .getSearchFormValue()
      .subscribe(searchFormValue => {
        if (searchFormValue) {
          if (!isEqual(searchFormValue, this.searchForm.value)) {
            this.searchForm.setValue(searchFormValue);
          }
        }
      });
    this.attachmentClient
      .getAll(
        this.searchForm.controls.templateId.value,
        this.searchForm.controls.attachmentNum.value,
        this.searchForm.controls.description.value,
        this.searchForm.controls.includeArchived.value,
      )
      .pipe(
        tap(
          val => {
            this.searchResult = val;
            this.searchResultCount = `${
              val.length === 0 ? 'No' : val.length
            } result${val.length === 1 ? '' : 's'}`;
            this.filterSearchResult(false);

            if (this.searchResult.length === 1) {
              this.router.navigateByUrl(
                `/attachment/${this.searchResult[0].id}`,
              );
            }

            this.userHasSearched = true;
          },
          err => {
            this.userHasSearched = false;
          },
        ),
      )
      .pipe(this.feedbackService.provideFeedback())
      .subscribe();
  }

  clear() {
    this.userHasSearched = false;
    this.searchForm.controls.templateId.setValue('');
    this.searchForm.controls.attachmentNum.setValue('');
    this.searchForm.controls.description.setValue('');
    this.searchForm.controls.includeArchived.setValue('');
    this.searchResult = [];
    this.filteredResult = [];
    this.feedbackService.clearAlert();
  }

  redirectToAddAttachment() {
    this.router.navigateByUrl('/attachment');
  }

  private generateExportData(results: Array<Attachment>) {
    const data = [];
    results.forEach(e => {
      data.push([
        e.id,
        e.attachmentNum,
        e.unilateral ? e.unilateral : '',
        e.sponsor ? e.sponsor : '',
        e.attachmentFileName ? e.attachmentFileName : '',
        e.description ? e.description : '',
        e.created ? this.transformDate(e.created) : '',
        e.modified ? this.transformDate(e.modified) : '',
        this.getAttachmentStatus(e.archive),
      ]);
    });
    return data;
  }

  transformDate(dateValue: Date) {
    return DateConvert.convertDateToLocalCulture(
      new Date(dateValue).toISOString(),
    );
  }

  async exportGrid() {
    const columns = [
      {
        header: 'Template ID',
        width: 12,
      },
      {
        header: 'Attachment Num',
        width: 16,
      },
      {
        header: 'Unilateral/Bilateral',
        width: 18,
      },
      {
        header: 'Sponsor Name',
        width: 45,
      },
      {
        header: 'File Name',
        width: 60,
      },
      {
        header: 'Description',
        width: 75,
      },
      {
        header: 'Created',
        width: 22,
      },
      {
        header: 'Last Modified',
        width: 22,
      },
      {
        header: 'Archived',
        width: 22,
      },
    ];

    const data = this.generateExportData(this.filteredResult);

    await this.excelExportService.generateExcel(
      'Attachment Search ' + new Date().toLocaleString(),
      'Attachment Search Result',
      data,
      columns,
    );
  }

  private filterSearchResult(showArchived: boolean) {
    if (!showArchived) {
      this.filteredResult = this.searchResult.filter(a => a.archive === false);
    } else {
      this.filteredResult = this.searchResult;
    }
  }

  private getAttachmentStatus(archived: boolean) {
    if (archived !== null && archived !== undefined) {
      return archived === true ? 'Archived' : 'Active';
    } else {
      return '';
    }
  }
}
