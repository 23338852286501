<div class="card" [formGroup]="form">
  <div class="card-header">
    Principal Investigator of Collaborating Institution
  </div>

  <div class="card-block">
    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        label="* First name"
        controlName="firstName"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        label="* Last name"
        controlName="lastName"
      ></subs-input>
    </div>

    <div class="clr-row">
      <subs-input class="clr-col-7" controlName="primaryDivision"></subs-input>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-7"
        label="* Phone"
        controlName="phone"
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="fax"
        labelStyle="width: 4rem;"
      ></subs-input>
    </div>

    <subs-input label="* Email address" controlName="emailAddress"></subs-input>

    <subs-address
      [form]="form.controls.address"
      [showCountryRequiredIndicator]="true"
    ></subs-address>
  </div>
</div>
