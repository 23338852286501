<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <div class="clr-col-6">
      <div *ngIf="loadingUtilityTables">
        <div class="clr-col-12">
          <clr-spinner clrInline> </clr-spinner>
          <span> Loading utility tables </span>
        </div>
      </div>
      <subs-select
        *ngIf="utilityTables"
        class="clr-col"
        controlName="utilityKey"
        label="Select utility table"
        labelLength="6"
        data-test-id="utility-key"
      >
        <option value=""></option>
        <option
          *ngFor="let table of this.utilityTables"
          [value]="table.tableName"
          >{{ table.tableDescription }}</option
        >
      </subs-select>
    </div>

    <div class="clr-col-6"></div>
  </div>

  <div style="margin-top:24px;">
    <subs-alert [alertBehavior]="alertSubject$"> </subs-alert>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="search()"
      [disabled]="!this.searchForm.controls.utilityKey.value"
      data-test-id="search-utility"
    >
      Search
    </button>

    <button
      *ngIf="searchResult.lookupItems.length > 0"
      class="btn btn-outline"
      (click)="clear()"
      data-test-id="clear-utility-search"
    >
      Clear
    </button>

    <button
      *ngIf="this.searchForm.controls.utilityKey.value"
      class="btn btn-outline"
      (click)="addUtilityItem(searchForm.controls.utilityKey.value)"
      data-test-id="add-utility"
    >
      Add Utility Item
    </button>
  </div>

  <div *ngIf="shouldShowNoResultsMessage | async">
    There were no results for your search.
  </div>
</form>

<subs-app-utilities-grid
  *ngIf="userHasSearched"
  [utilitiesSearchResults]="searchResult"
  data-test-id="search-result-grid"
>
</subs-app-utilities-grid>
