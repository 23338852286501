import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserProfileViewModel } from 'src/app/api.service';

@Injectable()
export class UserProfileFactory {
  constructor(private fb: UntypedFormBuilder) {}

  createControl(value: UserProfileViewModel): UntypedFormGroup {
    const control = this.createGroup();
    control.setValue(value);
    control.valueChanges.subscribe(x => {
      control.get('dateOfAction').setValue(new Date(), { emitEvent: false });
    });

    return control;
  }

  createMany(count: number): UntypedFormGroup[] {
    const output = [];

    for (let i = 0; i < count; i++) {
      const control = this.createGroup();
      control.valueChanges.subscribe(x => {
        control.get('dateOfAction').setValue(new Date(), { emitEvent: false });
      });
      output.push(control);
    }

    return output;
  }

  private createGroup() {
    return this.fb.group({
      id: [],
      wustlId: [],
      employeeName: [],
      role: [],
      email: [],
      jobTitle: [],
      isActive: [],
      dateOfAction: [],
      notes: [],
      signatureFileId: [],
      signatureFileName: [],
    });
  }
}
