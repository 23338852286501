import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'subs-checklist',
  templateUrl: './checklist.component.html',
})
export class ChecklistComponent {
  @Input()
  checklist: UntypedFormGroup;
}
